import { colors, fonts } from '@drivecapital/design-system';
import { ChevronRightIcon } from '@drivecapital/design-system/icons/arrows';
import { TrashBinIcon } from '@drivecapital/design-system/icons/document';
import { PlusIcon } from '@drivecapital/design-system/icons/system';
import React, { useCallback } from 'react';
import {
	Menu as AriaMenu,
	MenuItem as AriaMenuItem,
	type Key as AriaSelectionKey,
	MenuTrigger,
	SubmenuTrigger,
} from 'react-aria-components';
import styled from 'styled-components';

import type { Sorting } from '../types';

import DISPLAY_NAMES from './display-names';
import { Dialog, Heading, Popover } from './shared-components';

const Menu = styled(AriaMenu)`
	outline: none;
`;
const MenuItem = styled(AriaMenuItem)`
	${fonts.paragraph.paragraph}
	align-items: center;
	cursor: pointer;
	display: grid;
	gap: 4px;
	outline: none;
	/* icon label [icon] */
	grid-template-columns: min-content 1fr min-content;
	padding: 6px 8px;

	& > span {
		white-space: nowrap;
	}

	& > svg {
		height: 16px;
		width: 16px;

		&:not(:first-child) {
			color: ${colors.icon.secondary};
			margin-right: -3px;
		}
	}

	&[data-disabled] {
		color: ${colors.text.disabled};
		cursor: not-allowed;
	}

	&[data-hovered] {
		background: ${colors.layer.hover};
		& > svg {
			&:not(:first-child) {
				color: ${colors.icon.secondaryHover};
			}
		}
	}

	&[data-focus-visible] {
		outline: 2px solid ${colors.semantic.focus};
	}
`;
const AddSortAction = styled(MenuItem)`
	color: ${colors.text.interactive};

	&[data-hovered] {
		color: ${colors.text.interactiveHover};
	}
`;
const ClearSortsAction = styled(MenuItem)`
	color: ${colors.text.danger};

	&[data-hovered] {
		color: ${colors.text.dangerHover};
	}
`;
const NewSort = styled(AriaMenuItem)`
	${fonts.paragraph.paragraph}
	color: ${colors.text.secondary};
	cursor: pointer;
	outline: none;
	padding: 6px 8px;

	&[data-hovered] {
		background: ${colors.layer.hover};
		color: ${colors.text.secondaryHover};
	}

	&[data-focus-visible] {
		outline: 2px solid ${colors.semantic.focus};
	}
`;

interface Props {
	readonly fieldNameDisplayNames?: Partial<{
		[K in keyof typeof DISPLAY_NAMES]: string;
	}>;
	readonly isClearDisabled: boolean;
	readonly isOpen: boolean;
	readonly onSortAdd: (fieldName: Sorting['fieldName']) => void;
	readonly onSortClear: () => void;
	readonly unusedFieldNames: Sorting['fieldName'][];
}

export default function SortingActions({
	fieldNameDisplayNames,
	isClearDisabled,
	isOpen,
	onSortAdd,
	onSortClear,
	unusedFieldNames,
}: Props) {
	const handleSortAdd = useCallback(
		(key: AriaSelectionKey) => {
			onSortAdd(key as Sorting['fieldName']);
		},
		[onSortAdd],
	);
	const handleSortClear = useCallback(
		(key: AriaSelectionKey) => {
			if (key === 'CLEAR') {
				onSortClear();
			}
		},
		[onSortClear],
	);

	return (
		<MenuTrigger isOpen={isOpen}>
			<Menu
				autoFocus={false}
				aria-label="Sorting Actions"
				onAction={handleSortClear}
			>
				<SubmenuTrigger>
					<AddSortAction isDisabled={unusedFieldNames.length === 0}>
						<PlusIcon />
						<span>Add Sort</span>
						<ChevronRightIcon />
					</AddSortAction>
					<Popover
						$isOpen={unusedFieldNames.length > 0}
						placement="right top"
					>
						<Dialog>
							<Heading>
								<span>Add Sort</span>
							</Heading>
							<Menu
								aria-label="Add Sort"
								onAction={handleSortAdd}
								selectionMode="multiple"
							>
								{unusedFieldNames.map((fieldName) => {
									const displayName =
										fieldNameDisplayNames?.[fieldName]
										?? DISPLAY_NAMES[fieldName];

									return (
										<NewSort
											id={fieldName}
											key={fieldName}
											textValue={displayName}
										>
											{displayName}
										</NewSort>
									);
								})}
							</Menu>
						</Dialog>
					</Popover>
				</SubmenuTrigger>
				<ClearSortsAction id="CLEAR" isDisabled={isClearDisabled}>
					<TrashBinIcon />
					<span>Clear Sort</span>
				</ClearSortsAction>
			</Menu>
		</MenuTrigger>
	);
}
