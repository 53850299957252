import { useCallback, useEffect } from 'react';

import { trackEvent, type ViewType } from '../utils/analytics';
import useDebouncedCallback from '../utils/hooks/use-debounced-callback';
import type ImmutableURLSearchParams from '../utils/immutable-url-search-params';

import type { SearchQueryType } from './use-search-query-type';
import useImmutableSearchParams from './use-stable-immutable-search-params';

export type SimilarCompany = {
	id: number;
	description: string;
	name: string;
};

export type SearchQuery = string | SimilarCompany[];

export const SEARCH_QUERY_PARAM_KEY = 'q';

function getSimilarCompaniesFromCache(similarCompanies: string[]) {
	return similarCompanies.map((co) => JSON.parse(co) as SimilarCompany);
}

function searchParamIsValidArray(searchParams: ImmutableURLSearchParams) {
	try {
		searchParams.getAll(SEARCH_QUERY_PARAM_KEY).forEach((value) => {
			JSON.parse(value);
		});
		return true;
	} catch {
		return false;
	}
}

function getSearchQueryFromSearchParams(
	searchParams: ImmutableURLSearchParams,
	queryType: SearchQueryType,
): SearchQuery {
	if (queryType === 'similar_companies') {
		const value = searchParams.getAll(SEARCH_QUERY_PARAM_KEY);
		try {
			return getSimilarCompaniesFromCache(value);
		} catch {
			return [];
		}
	} else {
		const value = searchParams.get(SEARCH_QUERY_PARAM_KEY) ?? '';
		try {
			JSON.parse(value);
			return '';
		} catch {
			return value;
		}
	}
}

export function buildSearchParamsFromSearchQuery(
	query: SearchQuery,
	queryType: SearchQueryType,
	searchParams: ImmutableURLSearchParams,
) {
	if (queryType === 'similar_companies' && typeof query !== 'string') {
		return query.reduce((params, company) => {
			return params.append(
				SEARCH_QUERY_PARAM_KEY,
				JSON.stringify(company),
			);
		}, searchParams);
	} else if (typeof query === 'string') {
		return searchParams.set(SEARCH_QUERY_PARAM_KEY, query);
	}

	return searchParams;
}

export function toApi(query: SearchQuery): string | number[] {
	if (typeof query === 'string') {
		return query;
	}
	return query.map((co) => co.id);
}

export default function useSearchQuery(
	queryType: SearchQueryType,
	queryDebounceMs: number,
	analyticsConfig?: {
		componentIdentifier: string;
		viewType: ViewType;
	},
) {
	const [searchParams, setSearchParams] = useImmutableSearchParams();

	useEffect(() => {
		setSearchParams((currentParams) => {
			const isValidArray = searchParamIsValidArray(currentParams);
			if (queryType === 'similar_companies' && !isValidArray) {
				return currentParams.delete(SEARCH_QUERY_PARAM_KEY);
			} else if (queryType !== 'similar_companies' && isValidArray) {
				return currentParams.delete(SEARCH_QUERY_PARAM_KEY);
			}
			return currentParams;
		});
	}, [queryType, setSearchParams]);

	const handleSearchQueryChange = useCallback(
		(query: SearchQuery) => {
			if (analyticsConfig) {
				trackEvent(
					'Update Search Query',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ query },
				);
			}

			if (
				queryType === 'similar_companies'
				&& typeof query !== 'string'
			) {
				setSearchParams((currentParams) => {
					const newParams = currentParams.delete(
						SEARCH_QUERY_PARAM_KEY,
					);

					return query.reduce((params, company) => {
						return params.append(
							SEARCH_QUERY_PARAM_KEY,
							JSON.stringify(company),
						);
					}, newParams);
				});
			} else if (typeof query === 'string') {
				setSearchParams((currentParams) => {
					return currentParams.set(SEARCH_QUERY_PARAM_KEY, query);
				});
			} else {
				setSearchParams((currentParams) => {
					return currentParams.delete(SEARCH_QUERY_PARAM_KEY);
				});
			}
		},
		[analyticsConfig, queryType, setSearchParams],
	);

	const [handleDebouncedSearchQueryChange] = useDebouncedCallback(
		handleSearchQueryChange,
		queryDebounceMs,
	);

	return {
		query: getSearchQueryFromSearchParams(searchParams, queryType),
		handleDebouncedSearchQueryChange,
		handleSearchQueryChange,
	};
}
