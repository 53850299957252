import { colors } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { SearchSparkleIcon } from '@drivecapital/design-system/icons/system';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledChip = styled(Chip)`
	&:hover {
		background: ${colors.data.indigo.layerHover};
		color: ${colors.data.indigo.textSecondaryHover};
	}
`;

export default function BetaChip({
	className,
}: {
	readonly className?: string;
}) {
	return (
		<Link to="/new-search">
			<StyledChip
				className={className}
				background={colors.data.indigo.layerSubtle}
				color={colors.data.indigo.textSecondary}
				icon={<SearchSparkleIcon />}
				label="Beta Search"
			/>
		</Link>
	);
}
