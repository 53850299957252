import moment from 'moment';

export default (field: string) =>
	(entity: Record<string, any>): Record<string, any> => {
		return typeof entity[field] === 'string'
			? {
					...entity,
					[field]: moment(entity[field]).toDate(),
				}
			: entity;
	};
