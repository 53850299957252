import { colors, fonts } from '@drivecapital/design-system';
import { IconButton as UnstyledIconButton } from '@drivecapital/design-system/components';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
} from '@drivecapital/design-system/icons/arrows';
import { EllipsisHorizontalIcon } from '@drivecapital/design-system/icons/system';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { PaginationControl } from './control';
import type { Pagination } from './types';
import { usePaginationControls } from './utils';

const Container = styled.div<{ $isDisabled: boolean }>`
	align-items: center;
	display: flex;
	gap: 4px;

	${({ $isDisabled }) =>
		$isDisabled
		&& `
		opacity: 0.5;
		pointer-events: none;
	`}

	svg {
		color: ${colors.text.secondary};
		height: 24px;
		width: 24px;
	}
`;
const Totals = styled.span`
	${fonts.label.label}
	color: ${colors.text.secondary};
	white-space: nowrap;
`;
const IconButton = styled(UnstyledIconButton)`
	align-items: center;
	display: flex;
`;

interface Props {
	readonly className?: string;
	readonly controlsToShow: number;
	readonly isDisabled?: boolean;
	readonly onPageChange: (page: Pagination['page']) => void;
	readonly onPrefetch?: (page: Pagination['page']) => void;
	readonly pagination: Pagination;
	readonly resultsCount: number | null;
}

export default function PaginationControls({
	className,
	isDisabled = false,
	onPageChange,
	onPrefetch,
	controlsToShow,
	pagination,
	resultsCount,
}: Props) {
	const {
		beginningOfCurrentPage,
		controls,
		lastOnCurrentPage,
		leftArrow,
		rightArrow,
		totalPages,
	} = usePaginationControls({
		controlsToShow,
		pagination,
		resultsCount: resultsCount || 0,
	});
	const handlePageClick = useCallback(
		(page: Pagination['page']) => {
			onPageChange(page);
		},
		[onPageChange],
	);
	const handlePagePrefetch = useCallback(
		(page: Pagination['page']) => {
			if (onPrefetch) {
				onPrefetch(page);
			}
		},
		[onPrefetch],
	);

	const handleLeftClick = useCallback(() => {
		onPageChange(Math.max(pagination.page - 1, 1));
	}, [onPageChange, pagination.page]);
	const handleLeftPrefetch = useCallback(() => {
		if (onPrefetch) {
			onPrefetch(Math.max(pagination.page - 1, 1));
		}
	}, [onPrefetch, pagination.page]);

	const handleRightClick = useCallback(() => {
		onPageChange(Math.min(pagination.page + 1, totalPages));
	}, [onPageChange, pagination.page, totalPages]);
	const handleRightPrefetch = useCallback(() => {
		if (onPrefetch) {
			onPrefetch(Math.min(pagination.page + 1, totalPages));
		}
	}, [onPrefetch, pagination.page, totalPages]);

	const disabled = resultsCount == null || isDisabled;

	return (
		<Container className={className} $isDisabled={disabled}>
			<Totals>
				{resultsCount != null && (
					<span>
						{beginningOfCurrentPage}-{lastOnCurrentPage} of{' '}
						{resultsCount}
					</span>
				)}
			</Totals>
			{controls.map((control, index) => {
				if (control === 'GAP') {
					return <EllipsisHorizontalIcon key={`GAP-${index}`} />;
				}
				return (
					<PaginationControl
						isActive={control === pagination.page}
						key={control}
						onClick={handlePageClick}
						onPrefetch={handlePagePrefetch}
						page={control}
					/>
				);
			})}
			{leftArrow.visible && (
				<IconButton
					disabled={leftArrow.disabled || disabled}
					label="previous-page"
					onClick={handleLeftClick}
					onFocus={handleLeftPrefetch}
					onMouseEnter={handleLeftPrefetch}
					size="small"
				>
					<ChevronLeftIcon />
				</IconButton>
			)}
			{rightArrow.visible && (
				<IconButton
					disabled={rightArrow.disabled || disabled}
					label="next-page"
					onClick={handleRightClick}
					onFocus={handleRightPrefetch}
					onMouseEnter={handleRightPrefetch}
					size="small"
				>
					<ChevronRightIcon />
				</IconButton>
			)}
		</Container>
	);
}
