import React from 'react';
import styled from 'styled-components';

import Button from '../../../components/button';
import SnackBar from '../../snack-bar';

import type { ButtonStatus } from './index';

const DeleteTextButton = styled(Button)`
	color: red;
	border: none;
	background-color: transparent;
	box-shadow: none;
	&:disabled {
		box-shadow: none;
		opacity: 0.5;
		background-color: transparent;
	}
	&:hover {
		box-shadow: none;
		background-color: transparent;
	}
`;

const FailedWrapper = styled.div`
	color: red;
`;

interface Props {
	readonly onClick: () => void;
	readonly onConfirm: () => void;
	readonly disabled: boolean;
	readonly status: ButtonStatus | 'confirming';
	readonly message?: null | string;
	readonly confirmationMessage: string;
}

function Delete({
	confirmationMessage,
	disabled,
	message,
	onConfirm,
	onClick,
	status,
}: Props): JSX.Element {
	if (status === 'inProgress') {
		return (
			<Button disabled={disabled} type="button" danger>
				Deleting...
			</Button>
		);
	} else if (status === 'failed') {
		return (
			<FailedWrapper>
				{message && <SnackBar type="alert" text={message} />}
				<Button
					disabled={disabled}
					type="button"
					onClick={onClick}
					danger
				>
					Delete
				</Button>
			</FailedWrapper>
		);
	} else if (status === 'confirming') {
		return (
			<React.Fragment>
				{confirmationMessage}
				<Button type="button" onClick={onConfirm} danger>
					Delete
				</Button>
			</React.Fragment>
		);
	} else if (status === 'success') {
		return (
			<React.Fragment>
				{message && (
					<SnackBar clearTime={1500} type="success" text={message} />
				)}
				<Button type="button" onClick={onConfirm} danger>
					Delete
				</Button>
			</React.Fragment>
		);
	}
	return (
		<DeleteTextButton
			disabled={disabled}
			type="button"
			onClick={onClick}
			danger
		>
			Delete
		</DeleteTextButton>
	);
}

export default Delete;
