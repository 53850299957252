import React from 'react';
import { CheckboxGroup as AriaCheckboxGroup } from 'react-aria-components';
import styled from 'styled-components';

const StyledCheckboxGroup = styled(AriaCheckboxGroup)`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

type CheckboxGroupProps = React.ComponentProps<typeof AriaCheckboxGroup> & {
	readonly label: string;
};

export default function CheckboxGroup(props: CheckboxGroupProps) {
	return <StyledCheckboxGroup {...props} aria-labelledby={props.label} />;
}
