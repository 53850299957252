import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { stickiness } from '../';
import type { CellArgs, HeaderArgs, IColumn } from '../';

const SIZE = 40;

const imageClassName = 'HerbieTable__cell__image';
const Header = styled.th`
	/* Size is set to be 40 HxW, sticky-left adds padding that we want ignored */
	&.HerbieTable__header {
		padding: 0px !important;
	}
	min-width: ${SIZE}px;
	width: ${SIZE}px;
`;

const Image = styled.img`
	background-color: white;
	height: ${SIZE}px;
	object-fit: contain;
	object-position: center;
	width: ${SIZE}px;
`;

const ImageCell = styled.td`
	font-size: 0;
	min-height: ${SIZE}px;
	height: ${SIZE}px;
	min-width: ${SIZE}px;
	padding: 0;
	width: ${SIZE}px;
`;

interface Data {
	readonly alt: string;
	readonly href: string | null;
	readonly src: string | null;
}

export class NonStickyImageColumn<T> implements IColumn<T> {
	private readonly handleMouseDownEvent: (row: T) => void;
	private readonly select: (row: T) => Data;

	constructor({
		handleMouseDownEvent = () => noop,
		select,
	}: {
		handleMouseDownEvent?: (row: T) => void;
		select: (row: T) => Data;
	}) {
		this.handleMouseDownEvent = handleMouseDownEvent;
		this.select = select;

		this.cell.displayName = 'ImageColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, props, row }: CellArgs<T>) => {
		const className = classNames(props.className, imageClassName);
		if (deferred) {
			return <ImageCell {...props} className={className} />;
		}

		const data = this.select(row);

		return (
			<ImageCell {...props} className={className}>
				{data.href ? (
					<Link
						to={data.href}
						onMouseDown={(e) => {
							e.stopPropagation();
							this.handleMouseDownEvent(row);
						}}
					>
						{data.src && <Image alt={data.alt} src={data.src} />}
					</Link>
				) : (
					data.src && <Image alt={data.alt} src={data.src} />
				)}
			</ImageCell>
		);
	});

	header({ props }: HeaderArgs): JSX.Element {
		return <Header {...props} />;
	}
}

export default class ImageColumn<T> extends NonStickyImageColumn<T> {
	readonly position = {
		sticky: stickiness.left,
		width: SIZE,
	};
}
