import { match } from 'fuzzaldrin-plus';
import { useCallback, useMemo, useState } from 'react';

import {
	useUpdatePipelineItem,
	useUpdatePipelineItemTransitionDatum,
} from '../pipelines';
import type { PipelineStageItem } from '../pipelines';
import type { API_PipelineStageTransitionDatum } from '../pipelines/api-helpers';
import { get } from '../utils/api';
import assertExhaustive from '../utils/assert-exhaustive';
import useAbortableEffect from '../utils/hooks/use-abortable-effect';
import reportError from '../utils/sentry';
import type { KeysWithSuffix, PropertiesWithSuffix } from '../utils/types';

import type { JobOrder } from './types';

export type MAGIC_CANDIDATE_STATUS_NAMES =
	| 'PRESCREEN'
	| 'SUBMISSION'
	| 'HIRING_MANAGER_SCREEN'
	| 'SECOND_ROUND'
	| 'TAKE_HOME'
	| 'TECHNICAL'
	| 'ONSITE'
	| 'OFFER'
	| 'START'
	| 'ARCHIVE';

type FactTableRowRuleIds = PropertiesWithSuffix<FactTableRow, 'RuleId'>;
type FactTableRowTransitionIds = PropertiesWithSuffix<
	FactTableRow,
	'TransitionId'
>;

export type MagicCandidateStatusData = {
	dateDataNames: Array<string>;
	factTableRowDateKey: KeysWithSuffix<FactTableRow, 'On'>;
	factTableRowRuleIdKey: keyof FactTableRowRuleIds;
	factTableRowTransitionIdKey: keyof FactTableRowTransitionIds;
	sortKey: number;
	stageTitles: Array<string>;
};

export const MAGIC_CANDIDATE_STATUSES = {
	PRESCREEN: {
		dateDataNames: ['Screened On'],
		factTableRowDateKey: 'prescreenedOn',
		factTableRowRuleIdKey: 'prescreenedOnRuleId',
		factTableRowTransitionIdKey: 'prescreenTransitionId',
		sortKey: 0,
		stageTitles: ['Prescreen', 'Recruiter Phone Screen'],
	},
	SUBMISSION: {
		dateDataNames: ['Sent On'],
		factTableRowDateKey: 'submittedOn',
		factTableRowRuleIdKey: 'submittedOnRuleId',
		factTableRowTransitionIdKey: 'submittedTransitionId',
		sortKey: 1,
		stageTitles: ['Submission'],
	},
	HIRING_MANAGER_SCREEN: {
		dateDataNames: ['Screened On'],
		factTableRowDateKey: 'hmScreenedOn',
		factTableRowRuleIdKey: 'hmScreenedOnRuleId',
		factTableRowTransitionIdKey: 'hmScreenTransitionId',
		sortKey: 2,
		stageTitles: ['HM Screen', 'Hiring Manager Screen'],
	},
	SECOND_ROUND: {
		dateDataNames: ['Interviewed On'],
		factTableRowDateKey: 'secondRoundInterviewedOn',
		factTableRowRuleIdKey: 'secondRoundInterviewedOnRuleId',
		factTableRowTransitionIdKey: 'secondRoundInterviewTransitionId',
		sortKey: 3,
		stageTitles: ['2nd Round'],
	},
	TAKE_HOME: {
		dateDataNames: ['Interviewed On', 'Sent On'],
		factTableRowDateKey: 'takeHomeSentOn',
		factTableRowRuleIdKey: 'takeHomeSentOnRuleId',
		factTableRowTransitionIdKey: 'takeHomeSentTransitionId',
		sortKey: 4,
		stageTitles: [
			'Take Home/Technical',
			'Take Home Completed',
			'Take Home Sent',
			'Technical Interview',
		],
	},
	TECHNICAL: {
		dateDataNames: ['Interviewed On'],
		factTableRowDateKey: 'technicalInterviewedOn',
		factTableRowRuleIdKey: 'technicalInterviewedOnRuleId',
		factTableRowTransitionIdKey: 'technicalInterviewTransitionId',
		sortKey: 5,
		stageTitles: ['Technical', 'Technical Interview'],
	},
	ONSITE: {
		dateDataNames: ['Interviewed On'],
		factTableRowDateKey: 'onsiteInterviewedOn',
		factTableRowRuleIdKey: 'onsiteInterviewedOnRuleId',
		factTableRowTransitionIdKey: 'onsiteInterviewTransitionId',
		sortKey: 6,
		stageTitles: ['Onsite', 'Panel Interview'],
	},
	OFFER: {
		dateDataNames: ['Sent On'],
		factTableRowDateKey: 'offeredOn',
		factTableRowRuleIdKey: 'offeredOnRuleId',
		factTableRowTransitionIdKey: 'offerTransitionId',
		sortKey: 7,
		stageTitles: ['Offer'],
	},
	START: {
		dateDataNames: ['Start Date'],
		factTableRowDateKey: 'startedOn',
		factTableRowRuleIdKey: 'startedOnRuleId',
		factTableRowTransitionIdKey: 'startTransitionId',
		sortKey: 8,
		stageTitles: ['Offer Accepted', 'Start'],
	},
	ARCHIVE: {
		dateDataNames: ['Archived On', 'Date', 'Leaked On'],
		factTableRowDateKey: 'archivedOn',
		factTableRowRuleIdKey: 'archivedOnRuleId',
		factTableRowTransitionIdKey: 'archiveTransitionId',
		sortKey: 100,
		stageTitles: ['Archive', 'Resource Leak'],
	},
} as const;

interface API_FactTableRow {
	archive_reason: string | null;
	archive_reason_rule: number;
	archive_reasons: Record<string, string>;
	archive_transition: number | null;
	archived_on: string | null;
	archived_on_rule: number;
	candidate_name: string;
	candidate_profile: number;
	company_name: string;
	hiring_manager: string;
	hm_screen_transition: number | null;
	hm_screened_on: string | null;
	hm_screened_on_rule: number;
	id: number;
	is_editable: boolean;
	job_order: number;
	job_order_status: JobOrder['status'];
	job_order_title: string;
	latest_status: MAGIC_CANDIDATE_STATUS_NAMES;
	offer_accepted_on: string | null;
	offer_accepted_on_rule: number;
	offer_accepted_transition: number | null;
	offer_transition: number | null;
	offered_on: string | null;
	offered_on_rule: number;
	onsite_interview_transition: number | null;
	onsite_interviewed_on: string | null;
	onsite_interviewed_on_rule: number;
	pipeline: number;
	pipeline_item: number;
	prescreen_transition: number | null;
	prescreened_on: string | null;
	prescreened_on_rule: number;
	priority: PipelineStageItem['priority'];
	recruiter_name: string;
	second_round_interview_on: string | null;
	second_round_interview_on_rule: number;
	second_round_interview_transition: number | null;
	start_transition: number | null;
	started_on: string | null;
	started_on_rule: number;
	submitted_on: string | null;
	submitted_on_rule: number;
	submitted_transition: number | null;
	take_home_received_on: string | null;
	take_home_received_on_rule: number;
	take_home_received_transition: number | null;
	take_home_sent_on: string | null;
	take_home_sent_on_rule: number;
	take_home_sent_transition: number | null;
	talent_acquisition_managers: Array<string>;
	technical_interview_on: string | null;
	technical_interview_on_rule: number;
	technical_interview_transition: number | null;
}

export interface FactTableRow {
	archiveReason: string | null;
	archiveReasonRuleId: number;
	archiveReasons: Record<string, string>;
	archiveTransitionId: number | null;
	archivedOn: string | null;
	archivedOnRuleId: number;
	candidateName: string;
	candidateProfileId: number;
	companyName: string;
	hiringManager: string;
	hmScreenTransitionId: number | null;
	hmScreenedOn: string | null;
	hmScreenedOnRuleId: number;
	isEditable: boolean;
	jobOrderId: number;
	jobOrderStatus: JobOrder['status'];
	jobOrderTitle: string;
	latestStatus: MAGIC_CANDIDATE_STATUS_NAMES;
	offerAcceptedOn: string | null;
	offerAcceptedOnRuleId: number;
	offerAcceptedTransitionId: number | null;
	offerTransitionId: number | null;
	offeredOn: string | null;
	offeredOnRuleId: number;
	onsiteInterviewTransitionId: number | null;
	onsiteInterviewedOn: string | null;
	onsiteInterviewedOnRuleId: number;
	pipelineId: number;
	pipelineItemId: number;
	prescreenTransitionId: number | null;
	prescreenedOn: string | null;
	prescreenedOnRuleId: number;
	priority: PipelineStageItem['priority'];
	recruiterName: string;
	secondRoundInterviewTransitionId: number | null;
	secondRoundInterviewedOn: string | null;
	secondRoundInterviewedOnRuleId: number;
	startTransitionId: number | null;
	startedOn: string | null;
	startedOnRuleId: number;
	submittedOn: string | null;
	submittedOnRuleId: number;
	submittedTransitionId: number | null;
	takeHomeReceivedOn: string | null;
	takeHomeReceivedOnRuleId: number;
	takeHomeReceivedTransitionId: number | null;
	takeHomeSentOn: string | null;
	takeHomeSentOnRuleId: number;
	takeHomeSentTransitionId: number | null;
	talentAcquisitionManagers: Array<string>;
	technicalInterviewTransitionId: number | null;
	technicalInterviewedOn: string | null;
	technicalInterviewedOnRuleId: number;
}

export const CandidateStatusFilterOptions = {
	ALL: 'All',
	ACTIVE: 'Active',
	ARCHIVED: 'Archived',
} as const;
export const JobOrderStatusFilterOptions = {
	ALL: 'All',
	OPEN: 'Open',
	CLOSED: 'Closed',
} as const;

export function useFactTable(filters: {
	candidateStatus: keyof typeof CandidateStatusFilterOptions;
	companyName: string | null;
	jobOrderStatus: keyof typeof JobOrderStatusFilterOptions;
	jobOrderTitle: string | null;
	searchQuery: string | null;
}): {
	companyNameOptions: readonly { value: string; label: string }[];
	jobOrderTitleOptions: readonly { value: string; label: string }[];
	rows: Array<FactTableRow> | null;
	handleChange: (
		row: FactTableRow,
		partial: Partial<FactTableRow>,
		signal: AbortSignal,
	) => Promise<FactTableRow>;
} {
	const [rowData, setRowData] = useState<Array<FactTableRow> | null>(null);

	useAbortableEffect(
		useCallback(async (signal: AbortSignal) => {
			try {
				const response = await get<Array<API_FactTableRow>>(
					'/talent/job-orders/candidate_reporting',
					{ signal },
				);
				const transformedRows = response.map((datum) => {
					const sortedTams = [...datum.talent_acquisition_managers];
					sortedTams.sort((a, b) => a.localeCompare(b));
					const transformedDatum = {
						archiveReason:
							Object.entries(datum.archive_reasons)
								.find(
									([, label]) =>
										label === datum.archive_reason,
								)
								?.at(0) || null,
						archiveReasonRuleId: datum.archive_reason_rule,
						archiveReasons: datum.archive_reasons,
						archiveTransitionId: datum.archive_transition,
						archivedOn: datum.archived_on,
						archivedOnRuleId: datum.archived_on_rule,
						candidateName: datum.candidate_name,
						candidateProfileId: datum.candidate_profile,
						companyName: datum.company_name,
						hiringManager: datum.hiring_manager,
						hmScreenTransitionId: datum.hm_screen_transition,
						hmScreenedOn: datum.hm_screened_on,
						hmScreenedOnRuleId: datum.hm_screened_on_rule,
						isEditable: datum.is_editable,
						jobOrderId: datum.job_order,
						jobOrderStatus: datum.job_order_status,
						jobOrderTitle: datum.job_order_title,
						latestStatus: datum.latest_status,
						offerAcceptedOn: datum.offer_accepted_on,
						offerAcceptedOnRuleId: datum.offer_accepted_on_rule,
						offerAcceptedTransitionId:
							datum.offer_accepted_transition,
						offerTransitionId: datum.offer_transition,
						offeredOn: datum.offered_on,
						offeredOnRuleId: datum.offered_on_rule,
						onsiteInterviewTransitionId:
							datum.onsite_interview_transition,
						onsiteInterviewedOn: datum.onsite_interviewed_on,
						onsiteInterviewedOnRuleId:
							datum.onsite_interviewed_on_rule,
						pipelineId: datum.pipeline,
						pipelineItemId: datum.pipeline_item,
						prescreenTransitionId: datum.prescreen_transition,
						prescreenedOn: datum.prescreened_on,
						prescreenedOnRuleId: datum.prescreened_on_rule,
						priority: datum.priority,
						recruiterName: datum.recruiter_name,
						secondRoundInterviewTransitionId:
							datum.second_round_interview_transition,
						secondRoundInterviewedOn:
							datum.second_round_interview_on,
						secondRoundInterviewedOnRuleId:
							datum.second_round_interview_on_rule,
						submittedOn: datum.submitted_on,
						submittedOnRuleId: datum.submitted_on_rule,
						submittedTransitionId: datum.submitted_transition,
						startTransitionId: datum.start_transition,
						startedOn: datum.started_on,
						startedOnRuleId: datum.started_on_rule,
						takeHomeReceivedOn: datum.take_home_received_on,
						takeHomeReceivedOnRuleId:
							datum.take_home_received_on_rule,
						takeHomeReceivedTransitionId:
							datum.take_home_received_transition,
						takeHomeSentOn: datum.take_home_sent_on,
						takeHomeSentOnRuleId: datum.take_home_sent_on_rule,
						takeHomeSentTransitionId:
							datum.take_home_sent_transition,
						talentAcquisitionManagers: sortedTams,
						technicalInterviewTransitionId:
							datum.technical_interview_transition,
						technicalInterviewedOn: datum.technical_interview_on,
						technicalInterviewedOnRuleId:
							datum.technical_interview_on_rule,
					};
					// In theory, the fact table guarantees that there is a
					// transition ID if a date is present. If somehow that
					// guarantee falls through, there's nothing the user can do,
					// but we probably want to know about it.
					if (
						Object.values(MAGIC_CANDIDATE_STATUSES).some(
							({
								factTableRowDateKey,
								factTableRowTransitionIdKey,
							}) =>
								transformedDatum[factTableRowDateKey] !== null
								&& transformedDatum[factTableRowTransitionIdKey]
									=== null,
						)
					)
						reportError(
							new Error('InvalidFactTableRowTransitionId'),
							`Null transition ID for non-null transition date on item ${datum.pipeline_item} with pipeline ${datum.pipeline}`,
						);
					return transformedDatum;
				});

				setRowData(transformedRows);
			} catch (error) {
				if (error instanceof Error) {
					reportError(error);
				}
			}
		}, []),
	);

	const {
		candidateStatus,
		companyName,
		jobOrderStatus,
		jobOrderTitle,
		searchQuery,
	} = filters;
	const rowsAndOptions = useMemo(() => {
		let filteredRows = rowData || [];
		if (jobOrderStatus !== 'ALL') {
			filteredRows = filteredRows.filter(
				(row) => jobOrderStatus === row.jobOrderStatus,
			);
		}
		if (candidateStatus !== 'ALL') {
			filteredRows = filteredRows.filter(({ latestStatus }) => {
				switch (candidateStatus) {
					case 'ACTIVE':
						return latestStatus !== 'ARCHIVE';
					case 'ARCHIVED':
						return latestStatus === 'ARCHIVE';
					default:
						throw assertExhaustive(candidateStatus);
				}
			});
		}
		const companyNameOptions = [
			...new Set(
				(jobOrderTitle
					? filteredRows.filter(
							(row) => row.jobOrderTitle === jobOrderTitle,
						)
					: filteredRows
				).map((row) => row.companyName),
			),
		]
			.sort((a, b) => a.localeCompare(b))
			.map((name) => ({
				value: name,
				label: name,
			}));
		if (companyName) {
			filteredRows = filteredRows.filter(
				(row) => row.companyName === companyName,
			);
		}
		const jobOrderTitleOptions = [
			...new Set(filteredRows.map((row) => row.jobOrderTitle)),
		]
			.sort((a, b) => a.localeCompare(b))
			.map((title) => ({
				value: title,
				label: title,
			}));
		if (jobOrderTitle) {
			filteredRows = filteredRows.filter(
				(row) => row.jobOrderTitle === jobOrderTitle,
			);
		}
		if (searchQuery) {
			filteredRows = filteredRows.filter((row) => {
				if (match(row.candidateName, searchQuery).length > 0) {
					return true;
				}
				if (match(row.jobOrderTitle, searchQuery).length > 0) {
					return true;
				}
				if (match(row.companyName, searchQuery).length > 0) {
					return true;
				}
				if (match(row.hiringManager, searchQuery).length > 0) {
					return true;
				}
				if (match(row.recruiterName, searchQuery).length > 0) {
					return true;
				}
				if (match(row.latestStatus, searchQuery).length > 0) {
					return true;
				}
				if (
					row.talentAcquisitionManagers.some(
						(tam) => match(tam, searchQuery || '').length > 0,
					)
				) {
					return true;
				}
				return false;
			});
		}
		return { companyNameOptions, jobOrderTitleOptions, rows: filteredRows };
	}, [
		candidateStatus,
		companyName,
		jobOrderStatus,
		jobOrderTitle,
		rowData,
		searchQuery,
	]);
	const updatePipelineItem = useUpdatePipelineItem();
	const updateTransitionDatum = useUpdatePipelineItemTransitionDatum();
	const handleChange = useCallback(
		async (
			row: FactTableRow,
			partial: Partial<FactTableRow>,
		): Promise<FactTableRow> => {
			if (typeof partial.priority === 'string') {
				const newItem = await updatePipelineItem.mutateAsync({
					itemId: row.pipelineItemId,
					pipelineId: row.pipelineId,
					priority: partial.priority,
					profileId: row.candidateProfileId,
				});
				setRowData((currentRows) =>
					(currentRows || []).map((currentRow) => {
						if (currentRow.pipelineItemId !== row.pipelineItemId)
							return currentRow;
						return {
							...currentRow,
							priority: newItem.priority,
						};
					}),
				);

				return {
					...row,
					priority: newItem.priority,
				};
			}
			const magicData = Object.values(MAGIC_CANDIDATE_STATUSES).find(
				({ factTableRowDateKey }) => factTableRowDateKey in partial,
			);

			let newDatum: API_PipelineStageTransitionDatum | null = null;
			let updatedKey:
				| KeysWithSuffix<FactTableRow, 'On'>
				| 'archiveReason';
			if (typeof partial.archiveReason === 'string') {
				updatedKey = 'archiveReason';
				const response = await updateTransitionDatum.mutateAsync({
					extra: null,
					itemId: row.pipelineItemId,
					pipelineId: row.pipelineId,
					profileId: row.candidateProfileId,
					ruleId: row.archiveReasonRuleId,
					transitionId: row.archiveTransitionId || -1,
					type: 'MULTI_VALUE',
					value: partial.archiveReason,
				});
				newDatum = response[0];
			} else if (magicData) {
				updatedKey = magicData.factTableRowDateKey;
				const response = await updateTransitionDatum.mutateAsync({
					extra: null,
					itemId: row.pipelineItemId,
					pipelineId: row.pipelineId,
					profileId: row.candidateProfileId,
					ruleId: row[magicData.factTableRowRuleIdKey],
					transitionId:
						row[magicData.factTableRowTransitionIdKey] || -1,
					type: 'DATE',
					value: partial[magicData.factTableRowDateKey] || '',
				});
				newDatum = response[0];
			} else if (typeof partial.offerAcceptedOn === 'string') {
				updatedKey = 'offerAcceptedOn';
				const response = await updateTransitionDatum.mutateAsync({
					extra: null,
					itemId: row.pipelineItemId,
					pipelineId: row.pipelineId,
					profileId: row.candidateProfileId,
					ruleId: row.offerAcceptedOnRuleId,
					transitionId: row.offerAcceptedTransitionId || -1,
					type: 'DATE',
					value: partial.offerAcceptedOn || '',
				});
				newDatum = response[0];
			} else if (typeof partial.takeHomeReceivedOn === 'string') {
				updatedKey = 'takeHomeReceivedOn';
				const response = await updateTransitionDatum.mutateAsync({
					extra: null,
					itemId: row.pipelineItemId,
					pipelineId: row.pipelineId,
					profileId: row.candidateProfileId,
					ruleId: row.takeHomeReceivedOnRuleId,
					transitionId: row.takeHomeReceivedTransitionId || -1,
					type: 'DATE',
					value: partial.takeHomeReceivedOn || '',
				});
				newDatum = response[0];
			} else {
				return Promise.resolve(row);
			}

			setRowData((currentRows) =>
				(currentRows || []).map((currentRow) => {
					if (currentRow.pipelineItemId !== row.pipelineItemId)
						return currentRow;
					return {
						...currentRow,
						[updatedKey]: newDatum?.value || '',
					};
				}),
			);

			return {
				...row,
				[updatedKey]: newDatum.value,
			};
		},
		[updatePipelineItem, updateTransitionDatum],
	);

	if (!rowData)
		return {
			companyNameOptions: [],
			handleChange,
			jobOrderTitleOptions: [],
			rows: null,
		};

	return { ...rowsAndOptions, handleChange };
}
