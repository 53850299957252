import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { driveGreen } from '../colors';
import algoliaPicker from '../components/algolia-picker';
import { PlusIcon } from '../components/icons';
import type { State as StoreState } from '../reducers';
import type { AlgoliaHit, Filters } from '../search';

import type { AddPickerResult } from './column';
import { sortDirections } from './sort';
import SortableHeader from './sortable-header';
import type { Props as SortableHeaderProps } from './sortable-header';

const AddButton = styled.button`
	appearance: none;
	background: transparent;
	border: none;
	color: ${driveGreen.string()};
	height: 30px;
	/*
	 * Negative margin is almost always a smell, but here, it allows the
	 * button's click target to overlap into the padding that the header
	 * automatically wraps around the header's text. Usability is generally
	 * improved when click targets are forgiving by being a bit larger than they
	 * appear.
	 */
	margin: -10px 0;
	padding: 7px;
	vertical-align: middle;
	width: 30px;

	svg g {
		/*
		 * 30px square button less 2*7px padding = 16px square icon, so 0.125
		 * gives us a 2px stroke width for nice pixel-accurate edges.
		 */
		stroke-width: 0.125;
	}
`;

const SearchHeader = styled.th`
	/*
	 * When an empty table exists, the name column can be too small for a search
	 * box When content does exist it will likely be bigger than 150px so this
	 * is a 'middle of the road' size so it's usable on empty tables.
	 */
	min-width: 150px;
	/* Increase selector specificity to override the table's default styles. */
	&&& {
		padding: 0px;
	}
`;

const mapResults = (
	state: StoreState,
	results: Array<AlgoliaHit>,
): Array<AddPickerResult> =>
	results.map((hit: AlgoliaHit) => {
		return {
			id: hit.id,
			name:
				hit.type === 'companies'
					? hit.data.name
					: hit.data.primary_email
						? `${hit.data.first_name} ${hit.data.last_name} (${hit.data.primary_email.email})`
						: `${hit.data.first_name} ${hit.data.last_name}`,
			type: hit.type,
		};
	});

interface Props extends SortableHeaderProps {
	readonly onAdd: (value: AddPickerResult) => void;
	readonly types: Array<string>;
}

export default function AddableSortableHeader({
	children,
	initialSortDirection = sortDirections.ascending,
	onAdd,
	onSort,
	sort,
	types,
	...props
}: Props): JSX.Element {
	const [adding, setAdding] = useState(false);
	const Picker = useMemo(() => {
		const makeQuery = types
			? (query: string): Filters => ({ query, tags: types })
			: (query: string): Filters => ({ query });
		return algoliaPicker<AddPickerResult>(makeQuery, mapResults);
	}, [types]);

	if (adding) {
		return (
			<SearchHeader {...props}>
				<Picker
					autoFocus
					multi={false}
					onBlur={() => {
						setAdding(false);
					}}
					onValueChange={(
						value: null | AddPickerResult | Array<AddPickerResult>,
					) => {
						// We don't pass multi, so this shouldn't be an
						// array, but the type checker doesn't know that.
						if (Array.isArray(value)) {
							value.forEach((val) => onAdd(val));
						} else if (value) {
							onAdd(value);
						}
					}}
					placeholder="Search"
					value={null}
				/>
			</SearchHeader>
		);
	} else {
		return (
			<SortableHeader
				onSort={onSort}
				sort={sort}
				initialSortDirection={initialSortDirection}
				{...props}
			>
				{children}

				<AddButton
					onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
						// Prevent the click from propagating to the header and
						// causing it to change the sort.
						evt.stopPropagation();
						setAdding(true);
					}}
				>
					<PlusIcon />
				</AddButton>
			</SortableHeader>
		);
	}
}
