import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { disabledGray, green, primaryDriveBlue, red } from '../../colors';
import Authorized from '../../components/Authorized';
import { NewCheckIcon, NewTrashIcon, PlusIcon } from '../../components/icons';
import { appDefinedColumns } from '../../components/watchlists';
import { getColumnDescriptorKey } from '../../components/watchlists/column';
import type { ColumnDescriptor } from '../../components/watchlists/column';
import { trackEvent } from '../../utils/analytics';
import capitalize from '../../utils/capitalize';
import keys from '../../utils/key-codes';
import randomString from '../../utils/random-string';

import {
	Button,
	Container,
	DescriptorText,
	IconButton,
} from './column-descriptor';

const AddButton = styled(Button)`
	border-radius: 3px;
	color: white;
	font-size: 0;
	height: 34px;
	padding: 7px;
	width: 34px;
	margin: 0px;
`;

const CustomColumnSpan = styled.span`
	margin-left: 5px;
	color: ${disabledGray.toString()};
`;

const MenuWrapper = styled.div`
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	padding-left: 0px;
	border-radius: 0 0 3px 3px;
	background: #fff;
	position: absolute;
	z-index: 25;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	ul {
		flex: 1 1 0%;
		list-style-type: none;
		overflow: hidden scroll;
		padding-left: 0px;
		li {
			-webkit-box-align: baseline;
			align-items: baseline;
			background-color: white;
			color: inherit;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			padding: 6px 12px;
			transition: background-color 0.1s linear 0s;
			:hover {
				color: ${primaryDriveBlue.toString()};
				background-color: rgb(250, 250, 250);
			}
		}
	}
`;

const TrashIcon = styled(NewTrashIcon)`
	color: ${red.string()};
	g {
		stroke-width: 5;
	}
`;

type Props = {
	readonly columnDescriptors: Array<ColumnDescriptor>;
	readonly onAdd: (columnDescriptor: ColumnDescriptor) => void;
};

type AddingState = {
	status: 'adding';
	name: string;
	type: string;
	field: string | null;
};

type IdleState = {
	status: 'idle';
};

type SelectingState = {
	status: 'selecting';
};

type State = AddingState | IdleState | SelectingState;

// columnDescriptors gives us a list of already defined columns for our table.
// We should also accept a list of 'baked in' columns.
// When clicking 'add', we can show all baked in columns
// that do not exist in columnDescriptors to 'pre-fill' the column.
// Or allow custom text to default to just a custom text column
function AddColumnDescriptor({ columnDescriptors, onAdd }: Props): JSX.Element {
	const [state, setState] = useState<State>({ status: 'idle' });

	const keyExistsInExistingDescriptors = useCallback(
		(key: string): boolean => {
			return columnDescriptors.some(
				(descriptor) => getColumnDescriptorKey(descriptor) === key,
			);
		},
		[columnDescriptors],
	);

	const handleAdd = useCallback((): void => {
		if (state.status === 'adding') {
			const { name, field } = state;
			let fieldName = field;

			trackEvent(
				'Adding Watch List Column',
				'watchlist-column',
				'watchlist-detail',
				{
					action: 'saved',
					column_type: state.type,
				},
			);

			if (fieldName === null) {
				// We need to come up with a field that is unique and also works as a
				// unique key.
				do {
					fieldName = `${name
						.toLowerCase()
						.split(' ')
						.join('_')}_${randomString(10)}`;
				} while (keyExistsInExistingDescriptors(fieldName));
			}

			setState({
				status: 'idle',
			});

			onAdd({
				editable: true,
				field: fieldName,
				name,
				type: state.type,
			});
		}
	}, [state, onAdd, keyExistsInExistingDescriptors]);

	const handleCancel = useCallback((): void => {
		setState((currState) => ({
			...currState,
			status: 'idle',
		}));
	}, []);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const name = event.target.value;
			setState((currState) => ({
				...currState,
				name,
			}));
		},
		[],
	);

	const handleClick = useCallback(() => {
		// if idle, we are opening the list
		if (state.status === 'idle') {
			trackEvent(
				'Adding Watch List Column',
				'watchlist-column-add',
				'watchlist-detail',
				{
					action: 'open-options',
				},
			);
		} else {
			trackEvent(
				'Adding Watch List Column',
				'watchlist-column-add',
				'watchlist-detail',
				{
					action: 'closed-options',
				},
			);
		}
		setState((currState) => ({
			status: currState.status === 'idle' ? 'selecting' : 'idle',
		}));
	}, [state.status]);

	const handleSelect = useCallback(
		(column: { name: string; field: string | null; type: string }) => {
			trackEvent(
				'Adding Watch List Column',
				'watchlist-column-add',
				'watchlist-detail',
				{
					action: 'selected-option',
				},
			);

			setState({
				status: 'adding',
				name: column.name,
				type: column.type,
				field: column.field,
			});
		},
		[],
	);

	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (state.status === 'adding') {
				switch (event.keyCode) {
					case keys.ENTER:
						if (state.name && state.name.length > 0) {
							handleAdd();
						}
						break;
					case keys.ESC:
						handleCancel();
						break;
					default:
					// Do nothing
				}
			}
		},
		[handleAdd, handleCancel, state],
	);

	if (state.status === 'idle') {
		return (
			<li>
				<AddButton onClick={handleClick} type="button" primary>
					<PlusIcon />
				</AddButton>
			</li>
		);
	} else if (state.status === 'selecting') {
		const customColumnTypes = [
			'text',
			'date',
			'checkbox',
			'currency',
			'numeric',
		];
		return (
			<div>
				<AddButton onClick={handleClick} type="button" primary>
					<PlusIcon />
				</AddButton>
				<MenuWrapper>
					<ul>
						{customColumnTypes.map((customType) => (
							<li
								key={customType}
								onClick={() =>
									handleSelect({
										name: '',
										field: null,
										type: customType,
									})
								}
							>
								{capitalize(customType)}
								<i>
									<CustomColumnSpan>
										(Custom)
									</CustomColumnSpan>
								</i>
							</li>
						))}

						{appDefinedColumns

							.filter(
								(column) =>
									!columnDescriptors.some(
										(savedColumn) =>
											column.field === savedColumn.field,
									),
							)
							.map((column) => {
								const listItem = (
									<li
										key={column.field}
										onClick={() => handleSelect(column)}
									>
										{column.name}
									</li>
								);
								return column.field === 'notes' ? (
									<Authorized
										auth="contacts.notes"
										key={column.field}
									>
										{listItem}
									</Authorized>
								) : (
									listItem
								);
							})}
					</ul>
				</MenuWrapper>
			</div>
		);
	}

	return (
		<Container>
			<DescriptorText
				autoFocus
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				placeholder="Name"
				type="text"
				value={state.name}
			/>
			<IconButton onClick={handleCancel} type="button">
				<TrashIcon />
			</IconButton>
			<IconButton
				disabled={state.name.length < 1}
				onClick={handleAdd}
				type="button"
			>
				<NewCheckIcon
					fill={
						state.name.length < 1
							? disabledGray.toString()
							: green.toString()
					}
				/>
			</IconButton>
		</Container>
	);
}

export default AddColumnDescriptor;
