// @flow

import omit from 'lodash.omit';
import React from 'react';
import { connect } from 'react-redux';

import Authorized, { type Permission } from '../../components/Authorized';
import type { State as StoreState } from '../../reducers';
import { getAlgoliaKey } from '../../selectors';
import search from '../../utils/algolia';
import { trackEvent } from '../../utils/analytics';
import cancelablePromise from '../../utils/cancelable-promise';
import { difference, intersection, union } from '../../utils/sets';
import type { Filters } from '../behavior';

import './filters.scss';
import DealCountFilter from './deal-count-filter';
import DealSizeFilter from './deal-size-filter';
import EmployeeCountFilter from './employee-count-filter';
import SearchFilter from './filter';
import FoundedFilter from './founded-filter';
import FundingFilter from './funding-filter';
import IndustryFilter from './industry-filter';
import InvestmentIndustryFilter from './investment-industry-filter';
import InvestmentLocationFilter from './investment-location-filter';
import LocationFilter from './location-filter';
import RadioSearchFilterOption from './radio-filter-option';
import RecencyFilter from './recency-filter';
import RoleFilter from './role-filter';

type Tag = string;
type Tags = Set<Tag>;

type OwnProps = {|
	filters: Filters,
	onChange: (newFilters: Filters) => void,
|};
type StateProps = {| searchKey: string |};
type FilterProps = {|
	...OwnProps,
	...StateProps,
|};

// These are the props passed down to each filter component
// This should be imported (FilterProps is not appropriate due to its onChange
// that only applies to this SearchFilters component)
export type FilterComponentProps = {|
	...OwnProps,
	...StateProps,
	onChange: (filters: Filters, componentIdentifier: string) => void,
|};

type State = {
	industryOptions: Array<string>,
	locationOptions: Array<string>,
	roleOptions: Array<string>,
};

type FilterComponent = {
	auth?: Permission,
	Component: any,
	onChange?: (filters: Filters, componentIdentifier: string) => void,
	props?: (props: FilterProps, state: State) => Object, // eslint-disable-line flowtype/no-weak-types
	types: Tags,
};

const defaultFilterComponentProps = (
	/* eslint-disable no-unused-vars */
	props: FilterProps,
	state: State,
	/* eslint-enable no-unused-vars */
) => ({});

export const components: Array<FilterComponent> = [
	{
		Component: LocationFilter,
		props: (props, state) => ({
			options: state.locationOptions,
		}),
		types: new Set(['company', 'investor', 'person']),
	},
	{
		Component: RoleFilter,
		auth: 'contacts.advanced_person_search',
		props: (props, state) => ({
			options: state.roleOptions,
		}),
		types: new Set(['person']),
	},
	{
		Component: IndustryFilter,
		props: (props, state) => ({
			options: state.industryOptions,
		}),
		types: new Set(['company']),
	},
	{
		Component: EmployeeCountFilter,
		auth: 'contacts.advanced_company_search',
		types: new Set(['company']),
	},
	{
		Component: FoundedFilter,
		auth: 'contacts.advanced_company_search',
		types: new Set(['company']),
	},
	{
		Component: FundingFilter,
		auth: 'contacts.advanced_company_search',
		types: new Set(['company']),
	},
	{
		Component: InvestmentLocationFilter,
		props: (props, state) => ({
			options: state.locationOptions,
		}),
		types: new Set(['investor']),
	},
	{
		Component: InvestmentIndustryFilter,
		props: (props, state) => ({
			options: state.industryOptions,
		}),
		types: new Set(['investor']),
	},
	{
		Component: DealCountFilter,
		types: new Set(['investor']),
	},
	{
		Component: DealSizeFilter,
		types: new Set(['investor']),
	},
	{
		Component: RecencyFilter,
		types: new Set(['investor']),
	},
];

const allTags: Tags = new Set(['company', 'investor', 'person']);

function getTags({ tags }: Filters): Tags {
	return typeof tags !== 'undefined' ? new Set(tags) : allTags;
}

function getTag(filters: Filters): ?Tag {
	const tags = getTags(filters);
	return tags.size === 1 ? Array.from(tags)[0] : null;
}

const fieldToTagsMap: Map<string, Tags> = new Map();
const tagToFieldsMap: Map<Tag, Set<string>> = new Map();
components.forEach(({ Component, types }) => {
	fieldToTagsMap.set(Component.field, new Set(types));
	types.forEach((type) => {
		tagToFieldsMap.set(
			type,
			union(
				new Set([Component.field]),
				tagToFieldsMap.get(type) || new Set(),
			),
		);
	});
});

function getFieldsForTags(tags: Tags): Set<string> {
	return intersection(
		...Array.from(tags).map((tag) => tagToFieldsMap.get(tag) || new Set()),
	);
}

let industryOptions: Array<string> = [];
let locationOptions: Array<string> = [];
let roleOptions: Array<string> = [];

class SearchFilters extends React.Component<FilterProps, State> {
	multiSelectOptionsRequest: any; // TODO: Make a cancelablePromise type

	state: State = {
		industryOptions,
		locationOptions,
		roleOptions,
	};

	componentDidMount() {
		this.getMultiSelectOptions();
	}

	componentWillUnmount() {
		if (this.multiSelectOptionsRequest) {
			this.multiSelectOptionsRequest.cancel();
		}
	}

	async getMultiSelectOptions() {
		if (
			industryOptions.length > 0
			&& locationOptions.length > 0
			&& roleOptions.length > 0
		) {
			return;
		}

		this.multiSelectOptionsRequest = cancelablePromise(
			search(this.props.searchKey, {
				facets: ['data.industry', 'data.location', 'data.roles'],
			}).then(({ facets }) => {
				industryOptions = Object.keys(facets['data.industry'])
					.slice()
					.sort((a, b) => a.localeCompare(b));

				locationOptions = Object.keys(facets['data.location'])
					.slice()
					.sort((a, b) => a.localeCompare(b));

				roleOptions = Object.keys(facets['data.roles'])
					.slice()
					.sort((a, b) => a.localeCompare(b));
			}),
		);
		await this.multiSelectOptionsRequest;
		delete this.multiSelectOptionsRequest;
		this.setState({ industryOptions, locationOptions, roleOptions });
	}

	handleChange = (filters: Filters, componentIdentifier: string) => {
		// This gets called anytime a filter from the sidebar changes
		// (not when one is removed via 'filter text' listed underneath
		// the search bar )
		trackEvent(
			'Apply Search Filter',
			componentIdentifier,
			'advanced-search',
			{
				...filters,
			},
		);
		if (filters.tags) {
			this.props.onChange(filters);
		} else {
			const oldFilterFields = new Set(Object.keys(this.props.filters));
			const newFilterFields = new Set(Object.keys(filters));
			const added = difference(newFilterFields, oldFilterFields);
			const tags = intersection(
				...Array.from(added).map(
					(field) => fieldToTagsMap.get(field) || allTags,
				),
			);

			if (tags.size === 1) {
				this.props.onChange({
					...filters,
					tags: Array.from(tags),
				});
			} else {
				this.props.onChange(filters);
			}
		}
	};

	handleTypeChange = (value: Array<string>) => {
		const filters =
			value.length === 1
				? { ...this.props.filters, tags: value }
				: omit(this.props.filters, 'tags');

		const filterFields = new Set(
			Object.keys(filters).filter(
				(field) => field !== 'query' && field !== 'tags',
			),
		);
		const validFields = getFieldsForTags(getTags(filters));
		const toRemove = difference(filterFields, validFields);

		// If changing the tag would invalidate any filters, confirm beforehand.
		if (toRemove.size > 0) {
			const message =
				'Changing search type will clear some filters. Clear them and continue?';

			// eslint-disable-next-line no-alert
			if (!confirm(message)) {
				return;
			}
		}

		this.handleChange(omit(filters, Array.from(toRemove)), 'type-filter');
	};

	render() {
		const { filters } = this.props;
		const tags = getTags(filters);
		const tag = getTag(filters);

		return (
			<aside className="SearchFilters">
				<SearchFilter>
					<ul>
						<li>
							<RadioSearchFilterOption
								checked={!tag}
								label="All"
								name="tags"
								onChange={this.handleTypeChange}
								value={[]}
							/>
						</li>
						<li>
							<RadioSearchFilterOption
								checked={tag === 'person'}
								label="People"
								name="tags"
								onChange={this.handleTypeChange}
								value={['person']}
							/>
						</li>
						<li>
							<RadioSearchFilterOption
								checked={tag === 'company'}
								label="Companies"
								name="tags"
								onChange={this.handleTypeChange}
								value={['company']}
							/>
						</li>
						<li>
							<RadioSearchFilterOption
								checked={tag === 'investor'}
								label="Investors"
								name="tags"
								onChange={this.handleTypeChange}
								value={['investor']}
							/>
						</li>
					</ul>
				</SearchFilter>
				{components.map((filter) => {
					const {
						Component,
						auth = null,
						props = defaultFilterComponentProps,
						types,
					} = filter;
					if (intersection(tags, types).size < 1) return null;
					const element = (
						<Component
							{...props(this.props, this.state)}
							component
							filters={this.props.filters}
							key={Component.field}
							onChange={this.handleChange}
						/>
					);

					if (auth != null) {
						return (
							<Authorized auth={auth} key={Component.field}>
								{element}
							</Authorized>
						);
					}

					return element;
				})}
			</aside>
		);
	}
}

export default connect<_, OwnProps, _, _, _, _>(
	(state: StoreState): StateProps => ({
		searchKey: getAlgoliaKey(state),
	}),
	() => ({}),
)(SearchFilters);
