import { colors, fonts } from '@drivecapital/design-system';
import {
	ControlButton,
	IconButton,
} from '@drivecapital/design-system/components';
import {
	ChevronDownIcon,
	ChevronUpIcon,
} from '@drivecapital/design-system/icons/arrows';
import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
	align-items: center;
	display: grid;
	gap: 8px;
	grid-template:
		'title clear open-toggle' auto
		/ 1fr auto auto;
`;

const OpenToggleButton = styled(IconButton)`
	border: none;
	grid-area: open-toggle;

	&:hover {
		box-shadow: none;
	}

	&:focus-visible {
		outline: 2px solid ${colors.semantic.focus};
	}
`;

const HeaderTitle = styled.span`
	${fonts.paragraph.strong}
	color: ${colors.text.primary};
	cursor: pointer;
	grid-area: title;

	&:hover ~ ${OpenToggleButton} {
		color: ${colors.icon.primary};
	}
`;
const ClearButton = styled(ControlButton)`
	border: none;
	grid-area: clear;
`;

interface Props {
	readonly hasValue: boolean;
	readonly isOpen: boolean;
	readonly onClear: () => void;
	readonly onOpenToggle: () => void;
	readonly title: React.ReactNode;
}

export default function FilterControlHeader({
	hasValue,
	isOpen,
	onClear,
	onOpenToggle,
	title,
}: Props) {
	return (
		<Header>
			<HeaderTitle onClick={onOpenToggle}>{title}</HeaderTitle>
			{hasValue && (
				<ClearButton active={false} onClick={onClear} variant="warning">
					Clear
				</ClearButton>
			)}
			<OpenToggleButton
				label="toggle-filter-control"
				onClick={onOpenToggle}
				size="small"
			>
				{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
			</OpenToggleButton>
		</Header>
	);
}
