import { colors, effects } from '@drivecapital/design-system';
import { Chip as UnstyledChip } from '@drivecapital/design-system/components';
import { TextEditorFontColorIcon } from '@drivecapital/design-system/icons/document';
import { CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { AutoFocusPlugin as LexicalAutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import classNames from 'classnames';
import type { EditorState, LexicalEditor } from 'lexical';
import { $createParagraphNode, $getRoot, createEditor } from 'lexical';
import React from 'react';
import styled, { css } from 'styled-components';

import CommandEnterPlugin from './command-enter-plugin';
import UnstyledContentEditable from './content-editable';
import { TRANSFORMERS } from './markdown-transformers';
import themeStyleRules, { nodeClassMapping } from './theme';
import Toolbar, { type FormattingType } from './toolbar';
import { noop, safeJSONParse } from './utils';

export { TRANSFORMERS as MARKDOWN_TRANSFORMERS };

const Chip = styled(UnstyledChip)`
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	height: 34px;
	padding: 6px;

	&:hover {
		${effects.shadow.hover};
		border-color: ${colors.button.secondaryBorderHover};
		color: ${colors.icon.primary};
		cursor: pointer;
	}

	> svg {
		height: 16px;
		width: 16px;
	}
`;

const Container = styled.div<{ $editable: boolean }>`
	${({ $editable }) =>
		$editable
		&& `
	display: grid;
	gap: 12px;
	grid-template:
		'toolbar control' auto
		'text-area text-area' 1fr
		'footer footer' auto
		/ 1fr auto;
	`}
`;
const Control = styled.div`
	grid-area: control;
`;
const Footer = styled.div`
	grid-area: footer;
`;
const Placeholder = styled.div`
	color: ${colors.text.secondary};
	grid-area: text-area;
	padding: 8px 12px 6px 14px;
	pointer-events: none;
`;

export const StyledContentEditable = styled(UnstyledContentEditable)<{
	$editable: boolean;
}>`
	background: ${colors.field.field};
	border: 1px solid
		${({ $editable }) => ($editable ? colors.border.subtle : 'transparent')};
	border-radius: 8px;
	grid-area: text-area;
	max-height: 70vh;
	overflow-y: ${({ $editable }) => ($editable ? 'scroll' : 'hidden')};
	padding: 6px 12px;

	${({ $editable }) =>
		$editable
		&& css`
			&:hover {
				cursor: pointer;
				border: 1px solid ${colors.border.subtleHover};
				${effects.shadow.hover};
			}
		`}

	&:focus {
		border: 1px solid ${colors.semantic.focus};
		${effects.shadow.focus};
		cursor: text;
		outline: none;
	}

	${themeStyleRules}
`;

const ContentEditable = React.forwardRef(function _ContentEditable(
	{
		$editable,
		className,
	}: { readonly $editable: boolean; readonly className?: string },
	ref: React.Ref<HTMLDivElement>,
) {
	return (
		<StyledContentEditable
			$editable={$editable}
			className={className}
			ref={ref}
		/>
	);
});

export function ToolbarToggle({
	className,
	onClick,
	open,
}: {
	readonly className?: string;
	readonly onClick: (event: React.MouseEvent) => void;
	readonly onMouseDown?: (event: React.MouseEvent) => void;
	readonly open: boolean;
}) {
	return (
		<Chip
			background={colors.button.secondary}
			className={className}
			color={colors.icon.secondary}
			icon={<TextEditorFontColorIcon />}
			label=""
			menu
			menuDirection={open ? 'up' : 'down'}
			onClick={onClick}
		/>
	);
}

/**
 * Can we know for certain that the editor's root node has no children?
 * Returns True if the editor's root is confirmed to have zero children
 * Returns False if we can't tell or the root does have children
 */
function checkForEmptyRoot(initialContent: string): boolean {
	try {
		return createEditor({ nodes: editorNodes })
			.parseEditorState(initialContent)
			.read(() => {
				const root = $getRoot();

				if (root.isEmpty() || root.getTextContent() === '') {
					return true;
				}

				return false;
			});
	} catch {
		return false;
	}
}

function computeInitialState(initialContent: string) {
	const parsed = safeJSONParse(initialContent);
	const maybeValidEditorState =
		initialContent !== '' && parsed && typeof parsed === 'object';
	const confirmedEmptyRootNote =
		maybeValidEditorState && checkForEmptyRoot(initialContent);

	if (maybeValidEditorState && !confirmedEmptyRootNote) {
		return initialContent;
	} else if (initialContent === '' || confirmedEmptyRootNote) {
		// The lexical editor will not process the callbackFn in editor.focus()
		// if there is no contents in the editor. We need to add a paragraph
		// node to the editor if the initialContent is empty for the
		// AutoFocusPlugin to work.
		// https://github.com/facebook/lexical/blob/main/packages/lexical/src/LexicalEditor.ts#L1108
		return () => {
			const root = $getRoot();
			if (root.isEmpty()) {
				const paragraph = $createParagraphNode();
				root.append(paragraph);
			}
		};
	} else {
		return (editor: LexicalEditor) => {
			editor.update(() => {
				$convertFromMarkdownString(initialContent, TRANSFORMERS);
			});
		};
	}
}

function AutoFocusPlugin({ autofocus }: { readonly autofocus: boolean }) {
	return autofocus ? <LexicalAutoFocusPlugin /> : null;
}

// Stolen from https://lexical.dev/docs/react/plugins#lexicalautolinkplugin
const AUTO_LINK_URL_MATCHER =
	/((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/u;

const AUTO_LINK_URL_MATCHERS = [
	(text: string) => {
		const match = AUTO_LINK_URL_MATCHER.exec(text);
		if (match == null) {
			return null;
		}
		const fullMatch = match[0];
		return {
			attributes: {
				rel: 'noreferrer',
				target: '_blank',
			},
			index: match.index,
			length: fullMatch.length,
			text: fullMatch,
			url: fullMatch.startsWith('http')
				? fullMatch
				: `https://${fullMatch}`,
		};
	},
];

export const editorNodes = [
	AutoLinkNode,
	CodeNode,
	HeadingNode,
	LinkNode,
	ListItemNode,
	ListNode,
	QuoteNode,
	TableCellNode,
	TableRowNode,
	TableNode,
] as const;

interface Props {
	readonly autofocus?: boolean;
	readonly className?: string;
	readonly control?: React.ReactNode;
	readonly editable?: boolean;
	readonly extraToolbarControls?: React.ReactNode;
	readonly footer?: React.ReactNode;
	readonly initialContent?: string;
	readonly namespace: string;
	readonly onChange?: (editorState: EditorState) => void;
	readonly onEnter?: (clearEditor: () => void) => void;
	readonly onError: (error: Error) => void;
	readonly onFormatApply?: (format: FormattingType) => void;
	readonly placeholder?: string;
	readonly textFormats?: Array<FormattingType>;
	readonly toolbarOpen?: boolean;
}

// eslint-disable-next-line complexity
export default React.forwardRef(function Editor(
	{
		autofocus = false,
		className,
		control = null,
		editable = true,
		extraToolbarControls,
		footer = null,
		initialContent = '',
		namespace,
		onChange = noop,
		onEnter = noop,
		onError,
		onFormatApply = noop,
		placeholder = '...',
		textFormats = ['bold', 'italic', 'underline', 'strikethrough'],
		toolbarOpen = true,
	}: Props,
	ref: React.Ref<HTMLDivElement> | null,
) {
	return (
		<Container $editable={editable} className="HerbieTextEditor">
			<LexicalComposer
				initialConfig={{
					editable,
					editorState: computeInitialState(initialContent),
					namespace,
					nodes: editorNodes,
					onError,
					theme: nodeClassMapping,
				}}
			>
				<AutoFocusPlugin autofocus={autofocus} />
				<AutoLinkPlugin matchers={AUTO_LINK_URL_MATCHERS} />
				<ClearEditorPlugin />
				<ClickableLinkPlugin newTab />
				<CommandEnterPlugin onEnter={onEnter} />
				<Footer>{footer}</Footer>
				<HistoryPlugin />
				<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
				<ListPlugin />
				<OnChangePlugin ignoreSelectionChange onChange={onChange} />
				<RichTextPlugin
					ErrorBoundary={LexicalErrorBoundary}
					contentEditable={
						<ContentEditable
							$editable={editable}
							className={classNames(
								'HerbieTextEditor__content_editable',
								className,
							)}
							ref={ref}
						/>
					}
					placeholder={<Placeholder>{placeholder}</Placeholder>}
				/>
				<TabIndentationPlugin />
				<TablePlugin />
				<Toolbar
					extraControls={extraToolbarControls}
					formats={textFormats}
					onFormatApply={onFormatApply}
					open={editable && toolbarOpen}
				/>
				<Control>{control}</Control>
			</LexicalComposer>
		</Container>
	);
});
