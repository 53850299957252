import { useCallback, useEffect, useState } from 'react';

import { trackEvent, type ViewType } from '../../utils/analytics';
import type ImmutableURLSearchParams from '../../utils/immutable-url-search-params';
import useImmutableSearchParams from '../use-stable-immutable-search-params';

import type { Pagination } from './types';

const PAGE_NUMBER_PARAM_KEY = 'page';
const PAGE_SIZE_PARAM_KEY = 'pageSize';

function getPaginationFromSearchParams(
	searchParams: ImmutableURLSearchParams,
	defaultPageSize: number,
): Pagination {
	const pageNumber = parseInt(
		searchParams.get(PAGE_NUMBER_PARAM_KEY) ?? '1',
		10,
	);
	const pageSize = parseInt(
		searchParams.get(PAGE_SIZE_PARAM_KEY) ?? defaultPageSize.toString(),
		10,
	);

	return {
		page: pageNumber,
		pageSize,
	};
}

export function buildSearchParamsFromPagination(
	pagination: Pagination,
	searchParams: ImmutableURLSearchParams,
) {
	return searchParams
		.set(PAGE_NUMBER_PARAM_KEY, pagination.page.toString())
		.set(PAGE_SIZE_PARAM_KEY, pagination.pageSize.toString());
}

export function useResultsCount(
	resultsCount: number | null,
	onPageChange: (page: Pagination['page']) => void,
	page: Pagination['page'],
	pageSize: Pagination['pageSize'],
) {
	const [lastResultsCount, setLastResultsCount] = useState<number | null>(
		resultsCount,
	);
	useEffect(() => {
		setLastResultsCount(resultsCount);
		if (resultsCount == null) {
			return;
		}
		const totalPages = Math.ceil(resultsCount / pageSize);
		if (page > totalPages && page !== 1) {
			onPageChange(totalPages);
		}
	}, [page, pageSize, resultsCount, onPageChange]);

	return lastResultsCount;
}

export default function usePagination({
	defaultPageSize = 50,
	analyticsConfig,
}: {
	defaultPageSize?: number;
	analyticsConfig?: {
		componentIdentifier: string;
		viewType: ViewType;
	};
}): {
	pagination: Pagination;
	onPageChange: (page: Pagination['page']) => void;
	onPageSizeChange: (pageSize: number) => void;
} {
	const [searchParams, setSearchParams] = useImmutableSearchParams();
	const pagination = getPaginationFromSearchParams(
		searchParams,
		defaultPageSize,
	);
	const handlePageChange = useCallback(
		(page: Pagination['page']) => {
			if (analyticsConfig) {
				trackEvent(
					'Update Search Pagination',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ page },
				);
			}

			setSearchParams((params) =>
				params.set(PAGE_NUMBER_PARAM_KEY, page.toString()),
			);
		},
		[analyticsConfig, setSearchParams],
	);
	const handlePageSizeChange = useCallback(
		(pageSize: number) => {
			if (analyticsConfig) {
				trackEvent(
					'Update Search Pagination',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ pageSize },
				);
			}

			setSearchParams((params) =>
				params
					.set(PAGE_NUMBER_PARAM_KEY, '1')
					.set(PAGE_SIZE_PARAM_KEY, pageSize.toString()),
			);
		},
		[analyticsConfig, setSearchParams],
	);

	return {
		onPageChange: handlePageChange,
		onPageSizeChange: handlePageSizeChange,
		pagination,
	};
}
