import { EmptyStatePlaceholder } from '@drivecapital/design-system/components';
import React from 'react';
import styled from 'styled-components';

const IMAGE_URL_BASE = `https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/figma`;

const EMOJI_AND_COPY = [
	[
		'sparkles-160x160.png',
		"You're all caught up!",
		'There are no more follow ups for now. Nice work!',
	],
	[
		'eyes-160x160.png',
		'Nothing to see here.',
		'There are no more follow ups for now. Nice work!',
	],
	[
		'clapping-hands-160x160.png',
		'Bravo! Bravo!',
		'There are no more follow ups for now. Nice work!',
	],
	[
		'oncoming-automobile-160x160.png',
		"Herbie thinks you're awesome.",
		'There are no more follow ups for now. Nice work!',
	],
];

const Title = styled.h2``;
const Emoji = styled.img`
	height: 48px;
	width: 48px;
	align-self: center;
`;
const Subtitle = styled.p``;

export default function EmptyState() {
	const [emoji, title, subtitle] =
		EMOJI_AND_COPY[Math.floor(Math.random() * EMOJI_AND_COPY.length)];

	return (
		<EmptyStatePlaceholder>
			<Emoji alt={emoji} src={`${IMAGE_URL_BASE}/${emoji}`} />
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
		</EmptyStatePlaceholder>
	);
}
