import { breakpoints, colors, fonts } from '@drivecapital/design-system';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useUser } from '../../authentication';
import Header from '../../components/header';
import OutreachSection from '../../investor-outreach/outreach-section';
import { BetaChip, showNewSearch } from '../../new-search';
import { QuickSearch } from '../../search';

const View = styled.div`
	background-color: ${colors.background.background};
	display: flex;
	flex-direction: column;
	gap: 48px;
	overflow: scroll;
	padding-bottom: 48px;
`;

const Main = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 32px;
`;

const Welcome = styled.div`
	${fonts.h2.productive};
	color: ${colors.text.primary};
`;

const Search = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 90vw;

	@media (${breakpoints.md}) {
		min-width: 480px;
		width: unset;
	}
`;

const AdvancedSearchText = styled.div`
	${fonts.paragraph.paragraph};
	align-content: center;
	color: ${colors.text.secondary};
	display: flex;
	padding: 4px 0;
`;

const AdvancedSearchLink = styled(Link)`
	${fonts.paragraph.paragraph};
	color: ${colors.text.interactive};

	&:hover {
		color: ${colors.text.interactiveHover};
		cursor: pointer;
	}
`;

export default function InvestorRelationsView() {
	const user = useUser();
	const canShowNewSearch = showNewSearch(user);

	return (
		<View>
			<div>
				<Header search={false} />
			</div>
			<Main>
				<Welcome>Welcome, {user.first_name}!</Welcome>
				<Search>
					<QuickSearch
						autoFocus
						placeholder="Search companies and people"
					/>
					<AdvancedSearchText>
						Explore companies and people using{' '}
						{canShowNewSearch ? (
							<>
								the new&nbsp;
								<BetaChip />
								&nbsp;experience.
							</>
						) : (
							<AdvancedSearchLink to="/search">
								&nbsp;advanced search.
							</AdvancedSearchLink>
						)}
					</AdvancedSearchText>
				</Search>
				<OutreachSection />
			</Main>
		</View>
	);
}
