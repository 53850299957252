import type { User } from '../authentication';
import {
	googleOAuthClientId,
	googleOAuthRedirect,
	googleOAuthUrl,
} from '../config';

export function getOAuthUrl(user: User, location: URL) {
	const redirect_url = new URL(location.origin);
	redirect_url.pathname = googleOAuthRedirect;

	const scopes = Object.keys(user.oauth_scopes)
		.filter((scope) => !user.oauth_scopes[scope])
		.join(' ');

	const url = new URL(googleOAuthUrl);
	url.searchParams.append('client_id', googleOAuthClientId);
	url.searchParams.append('redirect_uri', redirect_url.href);
	url.searchParams.append('scope', scopes);
	url.searchParams.append('access_type', 'offline');
	url.searchParams.append('response_type', 'code');
	url.searchParams.append('include_granted_scopes', 'true');
	url.searchParams.append('enable_granular_consent', 'true');
	url.searchParams.append('login_hint', user.email);
	url.searchParams.append('prompt', 'consent');

	return url.href;
}
