import { colors, effects } from '@drivecapital/design-system';
import { ErrorBoundary } from '@sentry/react';
import React, { type ComponentProps, type ReactNode, Suspense } from 'react';
import { Popover } from 'react-aria-components';
import styled, { keyframes } from 'styled-components';

import SuspenseFallback from '../../suspense-fallback';

import { Message } from './message';
import { ScrollRefProvider, useScrollRefProvider } from './scroller';

const animateIn = keyframes`
	from {
		opacity: 0;
		scale: 1 0.9;
	}

	to {
		opacity: 1;
		scale: 1;
	}
`;

const animateOut = keyframes`
	from {
		opacity: 1;
		scale: 1;
	}

	to {
		opacity: 0;
		scale: 0.9;
	}
`;

const StyledPopover = styled(Popover)`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	outline: none;
	overflow: hidden;
	padding: 6px 0;

	&[data-placement='bottom'] {
		transform-origin: top left;
	}

	&[data-placement='top'] {
		transform-origin: bottom left;
	}

	&[data-entering] {
		animation: ${animateIn} 0.1s ease-in-out;
	}

	&[data-exiting] {
		animation: ${animateOut} 0.1s ease-in-out;
	}

	.react-aria-Menu {
		outline: none;
	}
`;

interface Props
	extends Pick<ComponentProps<typeof Popover>, 'placement' | 'triggerRef'> {
	readonly children: ReactNode;
}

export default function MenuPopover({
	children,
	placement = 'bottom left',
	triggerRef,
}: Props) {
	const { scrollRef, value } = useScrollRefProvider();

	return (
		<ErrorBoundary
			fallback={
				<StyledPopover
					offset={4}
					placement={placement}
					triggerRef={triggerRef}
				>
					<Message>Something went wrong.</Message>
				</StyledPopover>
			}
		>
			<Suspense
				fallback={
					<SuspenseFallback>
						<StyledPopover
							offset={4}
							placement={placement}
							triggerRef={triggerRef}
						>
							<Message>Loading&hellip;</Message>
						</StyledPopover>
					</SuspenseFallback>
				}
			>
				<StyledPopover
					offset={4}
					placement={placement}
					scrollRef={scrollRef}
					triggerRef={triggerRef}
				>
					<ScrollRefProvider value={value}>
						{children}
					</ScrollRefProvider>
				</StyledPopover>
			</Suspense>
		</ErrorBoundary>
	);
}
