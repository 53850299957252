import type Color from 'color';
import React, { useCallback, useEffect, useState } from 'react';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';

import * as colors from '../colors';
import { PipelineIcon } from '../components/icons';

const StyledPipelineIcon = styled(PipelineIcon)<{
	disabled: boolean;
	color: string;
}>`
	margin-top: 4px;
	height: 24px;
	width: 24px;
	stroke-width: 3.3px;
	color: ${({ color }) => color};

	${({ disabled }) =>
		disabled
			? `
	&:hover {
		cursor: not-allowed;
	}
	`
			: `
	&:hover {
		cursor: pointer;
		color: ${colors.charcoalGray.darken(1.5).string()};
			`}
`;
const StyledTooltip = styled(Tooltip)`
	&.__react_component_tooltip.show {
		opacity: 0.7;
	}
`;

type State = 'MOVING' | 'NORMAL' | 'SUCCESS';
interface Props {
	readonly disabled: boolean;
	readonly onMove: () => Promise<void>;
}

export default function MoveToPipeline({
	disabled,
	onMove,
}: Props): JSX.Element {
	const [state, setState] = useState<State>('NORMAL');

	const handleClick = useCallback(async () => {
		if (disabled) return;

		if (state === 'MOVING') return;

		setState('MOVING');
		await onMove();
		setState('SUCCESS');
	}, [disabled, onMove, state]);

	useEffect(() => {
		const returnToNormal = setTimeout(
			() =>
				setState((currentState) =>
					currentState === 'SUCCESS' ? 'NORMAL' : currentState,
				),
			2000,
		);
		return () => returnToNormal && clearTimeout(returnToNormal);
	});

	let iconColor: Color = colors.charcoalGray;
	if (state === 'SUCCESS') {
		iconColor = colors.moneyGreen;
	} else if (disabled) {
		iconColor = colors.disabledGray;
	}

	return (
		<>
			<StyledTooltip id="moveToPipelineIcon" type="dark" />
			<StyledPipelineIcon
				data-for="moveToPipelineIcon"
				data-tip="Move to interview pipeline"
				disabled={disabled || state !== 'NORMAL'}
				color={iconColor.string()}
				onClick={handleClick}
			/>
		</>
	);
}
