import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	CheckboxSelectedMulticolorIcon,
	CheckboxUnselectedMulticolorIcon,
} from '@drivecapital/design-system/icons/system';
import React from 'react';
import {
	Button,
	Dialog,
	DialogTrigger,
	ListBox,
	ListBoxItem,
	Popover,
} from 'react-aria-components';
import styled, { keyframes } from 'styled-components';

import { FunnelIcon } from '../../components/icons';

import usePointOfContactData, { NOBODY } from './data';

const StyledFunnelIcon = styled(FunnelIcon)`
	height: 16px;
	width: 16px;
`;

const StyledButton = styled(Button)<{ $isActive: boolean }>`
	appearance: none;
	background: transparent;
	border: none;
	color: ${({ $isActive }) => ($isActive ? colors.text.interactive : '#889')};
	cursor: pointer;
	font-size: 0;
	padding: 2px;

	&:hover {
		color: #445;
	}
`;

const animateIn = keyframes`
	from {
		opacity: 0;
		scale: 0.9;
	}

	to {
		opacity: 1;
		scale: 1;
	}
`;

const animateOut = keyframes`
	from {
		opacity: 1;
		scale: 1;
	}

	to {
		opacity: 0;
		scale: 0.9;
	}
`;

const StyledPopover = styled(Popover)`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transform-origin: top left;

	&[data-entering] {
		animation: ${animateIn} 0.1s ease-in-out;
	}

	&[data-exiting] {
		animation: ${animateOut} 0.1s ease-in-out;
	}
`;

const StyledMenu = styled(Dialog)`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	outline: none;
	overflow: hidden;
	padding: 6px 0;
`;

const SelectedCheckboxIcon = styled(CheckboxSelectedMulticolorIcon)`
	border: 2px solid transparent;
	height: 20px;
	width: 20px;

	.checkbox {
		color: ${colors.icon.onColor};
	}

	.fill {
		color: ${colors.icon.interactive};
	}
`;

const UnselectedCheckboxIcon = styled(CheckboxUnselectedMulticolorIcon)`
	border: 2px solid transparent;
	height: 20px;
	width: 20px;

	.fill {
		color: ${colors.icon.onColor};
	}

	.outline {
		color: ${colors.icon.secondary};
	}
`;

const StyledListBoxItem = styled(ListBoxItem)`
	${fonts.paragraph.paragraph}
	color: ${colors.text.secondary};
	display: flex;
	gap: 4px;
	padding: 6px 8px;
	cursor: pointer;

	&[data-focused] {
		outline: none;
	}

	&[data-focus-visible] > svg {
		${effects.shadow.focus}
		border: 2px solid ${colors.semantic.focus};
	}

	&[data-hovered] {
		background-color: ${colors.layer.hover};
	}

	&[data-selected] {
		color: ${colors.text.primary};
	}
`;

export default function FilterDropdown() {
	const { isSelected, onChange, options, selectedPointsOfContact } =
		usePointOfContactData();

	return (
		<DialogTrigger>
			<StyledButton $isActive={selectedPointsOfContact !== 'all'}>
				<StyledFunnelIcon />
			</StyledButton>
			<StyledPopover
				shouldFlip={false}
				offset={4}
				placement="bottom right"
			>
				<StyledMenu>
					<ListBox
						aria-label="Point of contact"
						onSelectionChange={onChange}
						selectionMode="multiple"
						selectedKeys={selectedPointsOfContact}
					>
						<StyledListBoxItem id={NOBODY} textValue="Nobody">
							{isSelected(NOBODY) ? (
								<SelectedCheckboxIcon />
							) : (
								<UnselectedCheckboxIcon />
							)}
							Nobody
						</StyledListBoxItem>
						{options.map((option) => (
							<StyledListBoxItem
								id={option.username}
								key={option.username}
								textValue={option.first_name}
							>
								{isSelected(option.username) ? (
									<SelectedCheckboxIcon />
								) : (
									<UnselectedCheckboxIcon />
								)}
								{option.first_name}
							</StyledListBoxItem>
						))}
					</ListBox>
				</StyledMenu>
			</StyledPopover>
		</DialogTrigger>
	);
}
