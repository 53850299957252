import { useQuery } from '@tanstack/react-query';

import { get } from '../utils/api';

export interface UserPreferences {
	email_client:
		| 'GMAIL'
		| 'SUPERHUMAN'
		| 'SUPERHUMAN_MAC_CLIENT'
		| 'SUPERHUMAN_WEB_CLIENT';
	investor_high_beam_assignments: boolean;
	lp_follow_up_reminder_email: boolean;
	lp_fundraising_drive_point_of_contact_option: boolean;
}

export function useUserPreferences() {
	return useQuery({
		queryKey: ['user-preferences'],
		queryFn: async ({ signal }) =>
			get<UserPreferences>('/preferences', { signal }),
		staleTime: 5 * 60 * 1000, // 5 minutes
	});
}
