import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SortableHeader, sortDirections, stickiness } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';

const WIDTH = 200;

const Text = styled.span`
	display: block;
	overflow: hidden;
	padding: 5px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledLink = styled(Link)`
	color: inherit;
	display: block;
	overflow: hidden;
	padding: 5px 10px;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:hover {
		text-decoration: underline;
	}
`;

const Cell = styled.td`
	max-width: ${WIDTH}px;
`;

interface Data {
	readonly href: string | null;
	readonly value: string;
}

export class NonStickyHyperlinkColumn<T> implements IColumn<T> {
	private readonly select: (row: T) => Data;
	name: string;

	constructor({ name, select }: { name: string; select: (row: T) => Data }) {
		this.select = select;
		this.name = name;

		this.cell.displayName = 'HyperlinkColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ props, row }: CellArgs<T>) => {
		const data = this.select(row);

		return (
			<Cell {...props}>
				{data.href ? (
					<StyledLink to={data.href} title={data.value}>
						{data.value}
					</StyledLink>
				) : (
					<Text>{data.value}</Text>
				)}
			</Cell>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				{this.name}
			</SortableHeader>
		);
	}

	search(query: string, row: T): boolean {
		const data = this.select(row);
		return data.value.toLowerCase().includes(query);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aName = this.select(a).value.toLowerCase();
		const bName = this.select(b).value.toLowerCase();

		if (aName === bName) return 0;
		if (aName === '') return 1;
		if (bName === '') return -1;
		return direction === sortDirections.ascending
			? aName.localeCompare(bName)
			: bName.localeCompare(aName);
	}

	toCSV(row: T): string {
		const data = this.select(row);
		return data.value;
	}
}

export default class HyperlinkColumn<T> extends NonStickyHyperlinkColumn<T> {
	readonly position = {
		sticky: stickiness.left,
		width: WIDTH,
	};
}
