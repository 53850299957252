import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	Button,
	Chip,
	IconButton,
	TextInput as Input,
} from '@drivecapital/design-system/components';
import { XIcon } from '@drivecapital/design-system/icons/system';
import { MapPinIcon } from '@drivecapital/design-system/icons/travel';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import assertExhaustive from '../../utils/assert-exhaustive';
import { ReadOnlyPriorityChip } from '../priority';
import type {
	PipelineStage,
	PipelineStageItem,
	StageTransitionDataRule,
	StageTransitionDataValue,
} from '../types';

import SelectInput from './select-input';

const DefaultTitle = styled.div`
	${fonts.h3.productive}
	color: ${colors.text.secondary};
`;

const Container = styled.div`
	display: grid;
	gap: 20px;
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
	justify-content: space-between;
	width: 100%;
`;

const Item = styled.div`
	${effects.shadow.elevated}
	background: ${colors.card.card};
	border: 1px solid ${colors.border.subtle};
	border-radius: 12px;
	display: grid;
	gap: 8px;
	grid-template:
		'photo titles chip' auto
		/ auto 1fr auto;
	padding: 16px;
`;

const ItemPhoto = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	flex: 0 0 12px;
	grid-area: photo;
	height: 44px;
	max-width: 44px;
	min-width: 44px;
	object-fit: contain;
	object-position: center;
`;

const ItemTitles = styled.div`
	display: grid;
	gap: 2px;
	grid-area: titles;
`;
const ItemTitleMain = styled.div`
	${fonts.h5.productive}
	color: ${colors.text.primary};
`;
const ItemTitleSecondary = styled.div`
	${fonts.label.label}
	color: ${colors.text.primary};
`;
const ItemTitleLocation = styled.div`
	${fonts.label.label}
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 2px;

	> svg {
		height: 16px;
		width: 16px;
	}
`;

const ItemChip = styled.div`
	grid-area: chip;
`;

const DestinationStage = styled.div`
	${fonts.paragraph.paragraph}
	align-items: center;
	border-radius: 12px;
	border: 1px solid ${colors.border.subtle};
	color: ${colors.text.secondary};
	display: flex;
	justify-content: space-between;
	padding: 8px;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-items: start;
	width: 100%;
`;

const Buttons = styled.div`
	align-self: end;
	display: flex;
	gap: 8px;
`;

const renderFormFields = (
	rules: Array<StageTransitionDataRule>,
): Array<JSX.Element> => {
	// eslint-disable-next-line array-callback-return
	return rules.map((rule) => {
		const { id, optional, title, type } = rule;
		const elementId = `${id}-${title}`;
		const required = !optional;
		switch (type) {
			case 'DATE':
				return (
					<Input
						key={elementId}
						id={elementId}
						label={title}
						name={elementId}
						required={required}
						type="date"
					/>
				);
			case 'NUMERIC':
				return (
					<Input
						key={elementId}
						id={elementId}
						label={title}
						name={elementId}
						required={required}
						step="any"
						type="number"
					/>
				);
			case 'MULTI_VALUE':
				return (
					<SelectInput
						key={elementId}
						elementId={elementId}
						title={title}
						required={required}
						options={rule.options}
					/>
				);
			case 'TEXT':
				return (
					<Input
						key={elementId}
						id={elementId}
						label={title}
						name={elementId}
						required={required}
					/>
				);
			default:
				assertExhaustive(type);
		}
	});
};

interface Props {
	readonly item: PipelineStageItem | null;
	readonly mapStageTitleToColors: (title: string) => {
		backgroundColor: string;
		textColor: string;
	};
	readonly onCancel: () => void;
	readonly onSubmit: (
		destinationStageId: number,
		transitionData: Array<StageTransitionDataValue>,
	) => void;
	readonly stage: PipelineStage;
	readonly subHeader?: React.ReactNode;
	readonly title: React.ReactNode;
}

export default function TransitionRuleEvaluation({
	item,
	mapStageTitleToColors,
	onCancel,
	onSubmit,
	stage,
	subHeader,
	title,
}: Props): JSX.Element {
	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const transitionData: Array<StageTransitionDataValue> = [];
			const data = new FormData(event.currentTarget);

			for (const rule of stage.rules) {
				const { id, title: ruleTitle, type } = rule;
				const elementId = `${id}-${ruleTitle}`;
				const elementValue = data.get(elementId) as string;

				if (elementValue != null && elementValue !== '') {
					switch (type) {
						case 'DATE':
						case 'TEXT':
							transitionData.push({
								ruleId: rule.id,
								value: elementValue,
								type,
							});
							break;
						case 'NUMERIC':
							transitionData.push({
								ruleId: rule.id,
								value: parseFloat(elementValue),
								type,
							});
							break;
						case 'MULTI_VALUE':
							transitionData.push({
								extra:
									(data.get(`${elementId}-extra`) as string)
									|| '',
								label:
									rule.options.find(
										(option) =>
											option.value === elementValue,
									)?.label || elementValue,
								ruleId: rule.id,
								value: elementValue,
								type,
							});
							break;
						default:
							assertExhaustive(type);
					}
				}
			}

			onSubmit(stage.id, transitionData);
		},
		[onSubmit, stage.id, stage.rules],
	);

	if (!item) return <Container />;

	return (
		<Container>
			<Header>
				{title || <DefaultTitle>Update Status</DefaultTitle>}
				<IconButton label="" onClick={onCancel} size="small">
					<XIcon />
				</IconButton>
			</Header>
			{subHeader}
			<Item>
				<ItemPhoto src={item.photoUrl} alt={item.title} />
				<ItemTitles>
					<ItemTitleMain>{item.title}</ItemTitleMain>
					<ItemTitleSecondary>{item.subTitle}</ItemTitleSecondary>
					{item.location && (
						<ItemTitleLocation>
							<MapPinIcon />
							{item.location}
						</ItemTitleLocation>
					)}
				</ItemTitles>
				<ItemChip>
					<ReadOnlyPriorityChip priority={item.priority} />
				</ItemChip>
			</Item>
			<DestinationStage>
				Status
				<Chip
					background={
						mapStageTitleToColors(stage.title).backgroundColor
					}
					color={mapStageTitleToColors(stage.title).textColor}
					label={stage.title}
				/>
			</DestinationStage>
			<Form onSubmit={handleSubmit}>
				{renderFormFields(stage.rules)}
				<Buttons>
					<Button
						type="button"
						variant="secondary"
						onClick={onCancel}
					>
						Cancel
					</Button>
					<Button type="submit" variant="primary">
						Submit
					</Button>
				</Buttons>
			</Form>
		</Container>
	);
}
