/** @typedef {import('../authentication').Action} AuthenticationAction */
/** @typedef {import('../lp-fundraising').LPData} LPData */
/** @typedef {import('../profiles/company').CompanyData} CompanyData */
/** @typedef {import('../profiles/person').PersonData} PersonData */
/** @typedef {import('../search').AlgoliaResponse} AlgoliaResponse */
/** @typedef {import('../talent/actions').LoadCandidatePoolsAction} LoadCandidatePoolsAction */

import { ActionTypes } from '../constants';
import store from '../store';
import * as api from '../utils/api';
import reportError from '../utils/sentry';

export const addCompanyFunnelAction = (payload) => ({
	type: ActionTypes.ADD_COMPANY_FUNNEL_ACTION,
	payload,
});

export const addItemToWatchList = (type, id, watchListId) => {
	store.dispatch({
		type: ActionTypes.WATCHLIST_ADD_ITEM_REQUEST,
		payload: {
			id,
			type,
			watchListId,
		},
	});

	api.post(`/watchlists/${watchListId}/items/`, {
		body: {
			type,
			id,
		},
	})
		.then((item) => {
			store.dispatch(addItemToWatchListSuccess(watchListId, item));
		})
		.catch((error) => {
			store.dispatch({
				type: ActionTypes.WATCHLIST_ADD_ITEM_FAILURE,
				error,
			});
		});
};

export const addItemToWatchListSuccess = (watchListId, item) => ({
	type: ActionTypes.WATCHLIST_ADD_ITEM_SUCCESS,
	payload: {
		watchListId,
		item,
	},
});

/*::
export type RemoveItemsFromWatchListAction = {|
	payload: {
		items: {
			companies?: Array<number>,
			people?: Array<number>,
		},
		watchListId: number,
	},
	type: typeof ActionTypes.WATCHLIST_REMOVE_ITEMS_SUCCESS,
|};
*/

export const removeItemsFromWatchListSuccess = (
	payload /*: typeof RemoveItemsFromWatchListAction.payload */,
) /*: RemoveItemsFromWatchListAction */ => ({
	payload,
	type: ActionTypes.WATCHLIST_REMOVE_ITEMS_SUCCESS,
});

export const createWatchList = (payload) => ({
	type: ActionTypes.WATCHLIST_CREATE,
	payload,
});

export const deleteLPData = (companyId /*: number */) => ({
	type: ActionTypes.DELETE_LP_DATA,
	payload: {
		companyId,
	},
});

export const deleteWatchList = (id) => ({
	type: ActionTypes.WATCHLIST_DELETE,
	payload: {
		id,
	},
});

export const editLPData = (companyId /*: number */, lpData /*: LPData */) => ({
	type: ActionTypes.EDIT_LP_DATA,
	payload: {
		companyId,
		lpData,
	},
});

export const editWatchList = (payload) => ({
	type: ActionTypes.WATCHLIST_EDIT,
	payload,
});

export const editWatchListItemSuccess = (watchListId, response) => {
	store.dispatch({
		type: ActionTypes.WATCHLIST_ITEM_EDIT_SUCCESS,
		payload: {
			id: watchListId,
			item: response,
		},
	});
};

export const invalidateWatchLists = () => ({
	type: ActionTypes.WATCHLISTS_INVALIDATE,
});

export const load = (payload) => ({
	type: ActionTypes.LOAD,
	payload,
});

export const loadFundTracker = () => {
	store.dispatch({
		type: ActionTypes.FUNDTRACKER_LOAD_REQUEST,
	});

	api.get('/fund_tracker')
		.then((payload) => {
			store.dispatch({
				type: ActionTypes.FUNDTRACKER_LOAD_SUCCESS,
				payload,
			});
		})
		.catch((error) => {
			store.dispatch({
				type: ActionTypes.FUNDTRACKER_LOAD_FAILURE,
				error,
			});
		});
};

export const loadCompanies = (payload) => ({
	type: ActionTypes.LOAD_COMPANIES,
	payload,
});

/*::
export type CompanyProfileSuccessAction = {|
	+payload: CompanyData,
	+type: typeof ActionTypes.COMPANYPROFILE_SUCCESS,
|};
*/

export const loadCompanySuccess = (
	payload /*: CompanyData */,
) /*: CompanyProfileSuccessAction */ => ({
	payload,
	type: ActionTypes.COMPANYPROFILE_SUCCESS,
});

/*::
export type PersonProfileSuccessAction = {|
	+payload: PersonData,
	+type: typeof ActionTypes.PERSONPROFILE_SUCCESS,
|};
*/
export const loadPersonSuccess = (
	payload /*: PersonData */,
) /*: PersonProfileSuccessAction */ => ({
	type: ActionTypes.PERSONPROFILE_SUCCESS,
	payload,
});

export const loadSemiAnnual = (payload) => ({
	type: ActionTypes.SEMIANNUAL_LOAD_SUCCESS,
	payload,
});

export const loadSemiAnnuals = () => {
	store.dispatch({
		type: ActionTypes.SEMIANNUALS_LOAD_REQUEST,
	});

	api.get('/semiannuals')
		.then((payload) => {
			store.dispatch({
				type: ActionTypes.SEMIANNUALS_LOAD_SUCCESS,
				payload,
			});
		})
		.catch((error) => {
			store.dispatch({
				type: ActionTypes.SEMIANNUALS_LOAD_FAILURE,
				error,
			});
		});
};

export const loadWatchListSuccess = (payload) => ({
	type: ActionTypes.WATCHLIST_LOAD_SUCCESS,
	payload,
});

export const loadWatchList = (id) => {
	store.dispatch({
		type: ActionTypes.WATCHLIST_LOAD_REQUEST,
		payload: {
			id,
		},
	});

	api.get(`/watchlists/${id}`)
		.then((payload) => {
			store.dispatch({
				type: ActionTypes.WATCHLIST_LOAD_SUCCESS,
				payload,
			});
		})
		.catch((error) => {
			store.dispatch({
				type: ActionTypes.WATCHLIST_LOAD_FAILURE,
				error,
			});
		});
};

export const loadWatchLists = () => {
	store.dispatch({
		type: ActionTypes.WATCHLISTS_LOAD_REQUEST,
	});

	api.get('/watchlists')
		.then((payload) => {
			store.dispatch({
				type: ActionTypes.WATCHLISTS_LOAD_SUCCESS,
				payload,
			});
		})
		.catch((error) => {
			store.dispatch({
				type: ActionTypes.WATCHLISTS_LOAD_FAILURE,
				error,
			});
		});
};

export const markRobot = (ids, robot = true) => {
	const payload = {
		ids,
		robot,
	};

	store.dispatch({
		type: ActionTypes.MARK_ROBOT_REQUEST,
		payload,
	});

	api.post('/people/robots', {
		body: payload,
	})
		.then(() =>
			store.dispatch({
				type: ActionTypes.MARK_ROBOT_SUCCESS,
				payload,
			}),
		)
		.catch((error) =>
			store.dispatch({
				type: ActionTypes.MARK_ROBOT_FAILURE,
				payload,
				error,
			}),
		);
};

export const merge = (duplicates, from) => {
	store.dispatch({
		type: ActionTypes.MERGE_REQUEST,
		payload: {
			duplicates,
		},
	});

	api.post(`/${duplicates[0].type}/merge`, {
		body: {
			ids: duplicates.map((e) => e.id),
			from,
		},
	})
		.then((merged) =>
			store.dispatch({
				type: ActionTypes.MERGE_SUCCESS,
				payload: {
					duplicates,
					merged,
				},
			}),
		)
		.catch((error) => {
			reportError(error, 'Error merging.');

			store.dispatch({
				type: ActionTypes.MERGE_FAILURE,
				payload: {
					duplicates,
				},
				error,
			});
		});
};

export const removeItemsFromWatchList = (
	items /*: typeof RemoveItemsFromWatchListAction.payload.items */,
	watchListId /*: typeof RemoveItemsFromWatchListAction.payload.watchListId */,
) => {
	const payload = {
		items,
		watchListId,
	};

	store.dispatch({
		type: ActionTypes.WATCHLIST_REMOVE_ITEMS_REQUEST,
		payload,
	});

	api.remove(`/watchlists/${watchListId}/items`, { body: items })
		.then(() => {
			store.dispatch(removeItemsFromWatchListSuccess(payload));
		})
		.catch((error) => {
			store.dispatch({
				type: ActionTypes.WATCHLIST_REMOVE_ITEMS_FAILURE,
				error,
			});
		});
};

export const saveSemiAnnual = (payload) => ({
	type: ActionTypes.SEMIANNUAL_SAVE,
	payload,
});

/*::
export type SearchSuccessAction = {|
	+payload: AlgoliaResponse,
	+type: typeof ActionTypes.SEARCH_SUCCESS,
|};
*/

export const searchSuccess = (
	payload /*: AlgoliaResponse */,
) /*: SearchSuccessAction */ => ({
	type: ActionTypes.SEARCH_SUCCESS,
	payload,
});

/*::
export type InitAction = {| +type: '@@redux/INIT' |};
*/

// TODO: Fully type our actions. For now, we're limited to this.
/*::
type StubAction = {
	+payload: any,
	+type: string,
};
*/

/*::
export type Action =
	| AuthenticationAction
	| CompanyProfileSuccessAction
	| InitAction
	| LoadCandidatePoolsAction
	| SearchSuccessAction
	| StubAction;
*/
