import { colors, fonts } from '@drivecapital/design-system';
import { IconButton as UnstyledIconButton } from '@drivecapital/design-system/components';
import { ArrowLoopRightIcon } from '@drivecapital/design-system/icons/arrows';
import {
	EnvelopeIcon,
	TrashBinIcon,
} from '@drivecapital/design-system/icons/document';
import {
	LockClosedLineIcon as PrivateIcon,
	LockOpenIconIcon as PublicIcon,
	XIcon,
} from '@drivecapital/design-system/icons/system';
import { CalendarIcon } from '@drivecapital/design-system/icons/time';
import UnstyledEditor, {
	type EditorState,
	type FormattingType,
	getTextContent,
	serializeEditorState,
} from '@drivecapital/text-editor';
import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { useBlocker } from 'react-router-dom';
import styled from 'styled-components';

import { trackEvent } from '../utils/analytics';
import useDebouncedCallback from '../utils/hooks/use-debounced-callback';
import reportError from '../utils/sentry';

import ContactedDropdown, { OTHER_OPTION } from './contacted-dropdown';
import {
	Container,
	EditorContainer,
	ExpandButton,
	Header,
	HeaderCommenterLogo,
	HeaderDate,
	HeaderLeft,
} from './shared-components';
import { type NoteData, notificationMethods, type ProfileType } from './types';
import { formatAddedAt, formatReminderDate, reminderInFuture } from './utils';

const Editor = styled(UnstyledEditor)<{ $editing: boolean }>`
	border-color: ${({ $editing }) =>
		$editing ? colors.border.subtle : 'transparent'};
`;

const Footer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	align-items: flex-start;
`;
const HeaderReminderDate = styled.span`
	${fonts.label.label};
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 2px;
	padding-left: 2px;

	> svg {
		height: 12px;
		width: 12px;
	}
`;
const HeaderRight = styled.div`
	${fonts.label.label};
	align-items: center;
	display: flex;
	gap: 6px;
`;
const DeleteContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;
const DeleteCancelButton = styled(UnstyledIconButton)`
	border-radius: 8px;

	svg {
		height: 16px;
		width: 16px;
	}
`;
const DeleteIconButton = styled(UnstyledIconButton)`
	background: ${colors.button.ghost};
	border-radius: 8px;
	color: ${colors.icon.danger};

	svg {
		height: 16px;
		width: 16px;
	}

	&:hover {
		color: ${colors.icon.dangerHover};
	}
`;
const PrivacyIconButton = styled(UnstyledIconButton)`
	border-radius: 8px;

	svg {
		height: 16px;
		width: 16px;
	}
`;
const Saving = styled.span<{ $isSaving: boolean }>`
	${fonts.label.label};
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 2px;
	margin-left: 8px;
	visibility: ${({ $isSaving }) => ($isSaving ? 'visible;' : 'hidden;')};

	> svg {
		height: 12px;
		width: 12px;
	}
`;

function DeleteControls({
	buttonSize,
	onDelete,
	visible,
}: {
	readonly buttonSize: 'default' | 'small';
	readonly onDelete: () => void;
	readonly visible: boolean;
}) {
	const [deleteConfirming, setDeleteConfirming] = useState(false);
	const handleDeleteClick = useCallback(
		(event: React.MouseEvent) => {
			if (deleteConfirming) {
				onDelete();
			} else {
				event.stopPropagation();
				setDeleteConfirming(true);
			}
		},
		[deleteConfirming, onDelete],
	);
	const handleDeleteCancel = useCallback((event: React.MouseEvent) => {
		event.stopPropagation();
		setDeleteConfirming(false);
	}, []);

	const deleteButton = (
		<DeleteIconButton
			label="Delete"
			onClick={handleDeleteClick}
			size={buttonSize}
		>
			<TrashBinIcon />
		</DeleteIconButton>
	);

	if (!visible) return null;
	return deleteConfirming ? (
		<DeleteContainer>
			{deleteButton}
			<DeleteCancelButton
				label="Delete"
				onClick={handleDeleteCancel}
				size={buttonSize}
			>
				<XIcon />
			</DeleteCancelButton>
		</DeleteContainer>
	) : (
		deleteButton
	);
}

export function FutureReminderDate({ note }: { readonly note: NoteData }) {
	const isInFuture = reminderInFuture(note);
	if (!isInFuture) return null;

	return (
		<HeaderReminderDate>
			{note.reminders[0].notification_method
			=== notificationMethods.EMAIL ? (
				<EnvelopeIcon />
			) : (
				<CalendarIcon />
			)}
			{formatReminderDate(note)}
		</HeaderReminderDate>
	);
}

export function SaveIndicator({ isSaving }: { readonly isSaving: boolean }) {
	return (
		<Saving $isSaving={isSaving}>
			Saving
			<ArrowLoopRightIcon />
		</Saving>
	);
}

function DefaultHeader({
	isSaving,
	note,
}: {
	readonly isSaving: boolean;
	readonly note: NoteData;
}) {
	return (
		<Header>
			<HeaderLeft>
				<HeaderCommenterLogo src={note.commenter.photo_url} />
				{note.commenter.first_name} {note.commenter.last_name}
				<FutureReminderDate note={note} />
				<SaveIndicator isSaving={isSaving} />
			</HeaderLeft>
			<HeaderRight>
				<HeaderDate>{formatAddedAt(note)}</HeaderDate>
			</HeaderRight>
		</Header>
	);
}

interface Props {
	readonly buttonSize?: 'default' | 'small';
	readonly contentHeight?: number;
	readonly footerVisible?: boolean;
	readonly HeaderComponent?: React.ComponentType<{
		isSaving: boolean;
		note: NoteData;
	}>;
	readonly htmlId?: string;
	readonly isSaving: boolean;
	readonly mixpanelIdentifier: string;
	readonly namespace: string;
	readonly note: NoteData;
	readonly onCollapse: (noteId: number) => void;
	readonly onDelete: (noteId: number) => void;
	readonly onUpdate: (
		note: Pick<
			NoteData,
			'comment' | 'contacted_via' | 'id' | 'public' | 'raw_comment'
		>,
	) => void;
	readonly placeholder?: string;
	readonly profileType: ProfileType;
	readonly toolbarOpen?: boolean;
}

export default React.forwardRef(function EditableNote(
	{
		buttonSize = 'default',
		contentHeight = 150,
		footerVisible = true,
		HeaderComponent = DefaultHeader,
		htmlId,
		isSaving,
		onCollapse,
		onDelete,
		onUpdate,
		mixpanelIdentifier,
		namespace,
		note,
		placeholder = 'Add a note here...',
		profileType,
		toolbarOpen = true,
	}: Props,
	ref: React.ForwardedRef<HTMLDivElement>,
) {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const editorRef = useRef<HTMLDivElement | null>(null);
	const wrapperRef = useCallback(
		(containerElement: HTMLDivElement | null) => {
			containerRef.current = containerElement;

			if (typeof ref === 'function') {
				ref(containerElement);
			} else if (ref) {
				ref.current = containerElement;
			}
		},
		[ref],
	);

	const [comment, setComment] = useState(note.comment);
	const [serializedEditorState, setSerializedEditorState] = useState(
		note.raw_comment,
	);

	const [contactedVia, setContactedVia] = useState(note.contacted_via);
	const [editing, setEditing] = useState(false);
	const [expandable, setExpandable] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [isPublic, setIsPublic] = useState(note.public);

	const _handleUpdate = useCallback(() => {
		if (contactedVia !== OTHER_OPTION && comment !== '') {
			trackEvent(
				'Edit Note',
				mixpanelIdentifier,
				profileType === 'lpfundraising'
					? 'lp-fundraising'
					: profileType,
			);
			onUpdate({
				comment,
				contacted_via: contactedVia,
				id: note.id,
				public: isPublic,
				raw_comment: serializedEditorState,
			});
		}
	}, [
		contactedVia,
		comment,
		mixpanelIdentifier,
		profileType,
		onUpdate,
		note.id,
		isPublic,
		serializedEditorState,
	]);
	const [handleUpdate, isDebouncing] = useDebouncedCallback(
		_handleUpdate,
		500,
	);

	const blocker = useBlocker(isDebouncing);

	const handleCommentChange = useCallback(
		(editorState: EditorState) => {
			const newEditorState = serializeEditorState(editorState);
			const newComment = getTextContent(editorState);

			setSerializedEditorState(newEditorState);
			setComment(newComment);

			handleUpdate();
		},
		[handleUpdate],
	);

	const handleContactedViaChange = useCallback(
		(newContactedVia: string) => {
			setContactedVia(newContactedVia);
			handleUpdate();
		},
		[handleUpdate],
	);

	const handleDelete = useCallback(() => {
		trackEvent(
			'Delete Note',
			mixpanelIdentifier,
			profileType === 'lpfundraising' ? 'lp-fundraising' : profileType,
		);
		onDelete(note.id);
	}, [mixpanelIdentifier, note.id, onDelete, profileType]);

	const handleEditorClick = useCallback(() => {
		setEditing(true);
	}, []);
	const handleExpandClick = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setExpanded((current) => !current);
			if (expanded) {
				onCollapse(note.id);
			}
		},
		[expanded, note.id, onCollapse],
	);
	const handleFormatApply = useCallback(
		(format: FormattingType) => {
			trackEvent(
				'Apply Note Format',
				mixpanelIdentifier,
				profileType === 'lpfundraising'
					? 'lp-fundraising'
					: profileType,
				{ format },
			);
		},
		[mixpanelIdentifier, profileType],
	);
	const handlePublicChange = useCallback(
		(newIsPublic: boolean) => {
			setIsPublic(newIsPublic);
			handleUpdate();
		},
		[handleUpdate],
	);

	useLayoutEffect(() => {
		if (editorRef.current) {
			const { offsetHeight } = editorRef.current;
			if (offsetHeight > contentHeight) {
				setExpandable(true);
			}
		}
	}, [contentHeight, editorRef.current?.offsetHeight]);

	useEffect(() => {
		const handleExteriorClick = (event: MouseEvent) => {
			if (!containerRef.current) return;
			if (
				!containerRef.current?.contains(event.target as Node)
				&& !dropdownRef.current?.contains(event.target as Node)
			) {
				setEditing(false);
			}
		};
		window.addEventListener('click', handleExteriorClick, true);

		return () => {
			window.removeEventListener('click', handleExteriorClick);
		};
	}, []);

	useEffect(() => {
		if (blocker.state === 'blocked') {
			_handleUpdate();
			blocker.proceed();
		}
	}, [_handleUpdate, blocker]);

	return (
		<Container className="EditableNote" id={htmlId} ref={wrapperRef}>
			<HeaderComponent isSaving={isSaving} note={note} />
			<EditorContainer
				$contentHeight={contentHeight}
				$expandable={expandable}
				$expanded={expanded || editing}
				onClick={handleEditorClick}
			>
				<Editor
					$editing={editing}
					editable
					control={
						<DeleteControls
							buttonSize={buttonSize}
							onDelete={handleDelete}
							visible={editing}
						/>
					}
					footer={
						editing
						&& footerVisible && (
							<Footer>
								<PrivacyIconButton
									title={
										isPublic
											? 'Private: On'
											: 'Private: Off'
									}
									label="toggle-privacy"
									onClick={(event: React.MouseEvent) => {
										event.stopPropagation();
										handlePublicChange(!isPublic);
									}}
									size={buttonSize}
								>
									{isPublic ? (
										<PublicIcon />
									) : (
										<PrivateIcon />
									)}
								</PrivacyIconButton>
								<ContactedDropdown
									contactedVia={contactedVia}
									disabled={false}
									onChange={handleContactedViaChange}
									ref={dropdownRef}
								/>
							</Footer>
						)
					}
					initialContent={note.raw_comment || note.comment}
					namespace={namespace}
					onChange={handleCommentChange}
					onError={reportError}
					onFormatApply={handleFormatApply}
					placeholder={placeholder}
					ref={editorRef}
					toolbarOpen={editing && toolbarOpen}
				/>
			</EditorContainer>
			{expandable && !editing && (
				<ExpandButton
					className="EditableNote__expand_button"
					onClick={handleExpandClick}
					expanded={expanded}
				/>
			)}
		</Container>
	);
});
