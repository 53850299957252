import { colors, fonts } from '@drivecapital/design-system';
import { nodeClassMapping } from '@drivecapital/text-editor';
import React from 'react';
import styled, { css } from 'styled-components';

import { NewNote, sortByDate } from '../../notes';
import { useCreatePipelineItemTransitionNote } from '../api';
import type { PersonProfileTransitionNote } from '../types';

import TransitionNote from './transition-note';

const containerStyles = css`
	${fonts.paragraph.paragraph};
	--box-shadow-color: ${colors.card.card};
	background: ${colors.card.card};
	border-radius: 0;
	border: unset;
	display: flex;
	flex-direction: column;
	gap: 2px;
	overflow: hidden;
	padding: 2px;

	&__expand_button {
		${fonts.label.label};
	}

	.HerbieTextEditor {
		${fonts.label.label};
		gap: 4px;
		padding: 2px;

		&__control {
			align-self: flex-end;
			display: flex;
		}

		&__content_editable {
			background: ${colors.card.card};
			padding: 4px;
		}

		.${nodeClassMapping.paragraph},
			.${nodeClassMapping.quote},
			.${nodeClassMapping.link},
			.${nodeClassMapping.list.ul},
			.${nodeClassMapping.list.ol},
			.${nodeClassMapping.list.listitem},
			.${nodeClassMapping.list.nested.listitem} {
			${fonts.label.label};
		}
	}
`;

const Notes = styled.div`
	background: ${colors.card.card};
	border-top: 1px solid ${colors.border.subtle};
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	display: flex;
	flex-direction: column;
	padding: 18px;
	gap: 6px;

	.EditableNote {
		${containerStyles}

		.HerbieTextEditor {
			grid-template:
				'toolbar toolbar' 0px
				'text-area control' 1fr
				'footer footer' 0px
				/ 1fr auto;

			&__content_editable {
				background: ${colors.card.card};
				padding: 4px;
			}
		}
	}

	.NewNote {
		${containerStyles}

		.HerbieTextEditor {
			grid-template:
				'toolbar toolbar' 0px
				'text-area control' 1fr
				'footer footer' 0px
				/ 1fr auto;

			&__content_editable {
				background: ${colors.field.field};
				padding: 6px 12px;
			}
		}
	}

	.ReadOnlyNote {
		${containerStyles}
	}
`;

function handleNoteCollapse(target: HTMLDivElement) {
	if (target.getBoundingClientRect().bottom > window.innerHeight) {
		target.scrollIntoView({
			block: 'end',
			inline: 'nearest',
			behavior: 'instant',
		});
	}

	if (target.getBoundingClientRect().top < 0) {
		target.scrollIntoView({
			block: 'start',
			inline: 'nearest',
			behavior: 'instant',
		});
	}
}

interface Props {
	readonly autoFocusNewNote: boolean;
	readonly editable: boolean;
	readonly itemId: number;
	readonly mixpanelIdentifier: string;
	readonly notes: PersonProfileTransitionNote[];
	readonly pipelineId: number;
	readonly profileId: number;
	readonly transitionId: number;
}

export default function TransitionNotesSection({
	autoFocusNewNote,
	editable,
	itemId,
	mixpanelIdentifier,
	notes,
	pipelineId,
	profileId,
	transitionId,
}: Props): JSX.Element {
	const { mutateAsync: createNote, isPending: isCreating } =
		useCreatePipelineItemTransitionNote(
			itemId,
			pipelineId,
			profileId,
			transitionId,
		);

	const namespace = `${mixpanelIdentifier}-note-${profileId}-${transitionId}`;

	return (
		<Notes>
			{sortByDate(notes)
				.toReversed()
				.map((note: PersonProfileTransitionNote) => (
					<TransitionNote
						key={note.id}
						itemId={itemId}
						mixpanelIdentifier={mixpanelIdentifier}
						namespace={`${namespace}-${note.id}`}
						onCollapse={(ref) => {
							if (ref) {
								handleNoteCollapse(ref);
							}
						}}
						pipelineId={pipelineId}
						profileId={profileId}
						transitionId={transitionId}
						{...note}
					/>
				))}
			{editable && (
				<NewNote
					autofocus={autoFocusNewNote}
					buttonSize="small"
					footerVisible={false}
					isCreating={isCreating}
					mixpanelIdentifier={mixpanelIdentifier}
					namespace={`${namespace}-new`}
					onCreate={createNote}
					placeholder="Add a reply here..."
					profileType="pipelines"
					toolbarOpen={false}
				/>
			)}
		</Notes>
	);
}
