import {
	Dropdown,
	DropdownOption as Option,
	TextInput as UnstyledTextInput,
} from '@drivecapital/design-system/components';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

export const CALL_OPTION = 'Call';
export const EMAIL_OPTION = 'Email';
export const EMPTY_OPTION = '';
export const LINKEDIN_OPTION = 'LinkedIn';
export const MEETING_OPTION = 'Meeting';
export const OTHER_OPTION = 'Other';
export const TEXT_OPTION = 'Text';

export const OPTIONS = [
	CALL_OPTION,
	EMAIL_OPTION,
	EMPTY_OPTION,
	LINKEDIN_OPTION,
	MEETING_OPTION,
	OTHER_OPTION,
	TEXT_OPTION,
];

const Container = styled.div`
	display: flex;
	gap: 8px;
	min-width: 260px;
`;
const TextInput = styled(UnstyledTextInput)<{ $visible: boolean }>`
	visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
	max-width: 170px;
`;

export default React.forwardRef(function ContactedDropdown(
	{
		contactedVia,
		disabled,
		onChange,
	}: {
		readonly contactedVia: string;
		readonly disabled: boolean;
		readonly onChange: (val: string) => void;
	},
	ref: React.ForwardedRef<HTMLDivElement>,
) {
	const [error, setError] = useState<string | null>(null);
	const isOther =
		!OPTIONS.includes(contactedVia) || contactedVia === OTHER_OPTION;
	const handleChange = useCallback(
		(newValue: string) => {
			onChange(newValue);
			setError(null);
			if (newValue === OTHER_OPTION) {
				setTimeout(() => {
					otherRef.current?.focus();
				});
			}
		},
		[onChange],
	);
	const handleTextInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setError(null);
			if (event.target.value === '') {
				onChange(OTHER_OPTION);
			} else {
				onChange(event.target.value);
			}
		},
		[onChange],
	);
	const onOtherInputBlur = useCallback(() => {
		if (contactedVia === OTHER_OPTION) {
			setError('Please specify a contact type');
		} else {
			setError(null);
		}
	}, [contactedVia]);
	const otherRef = useRef<HTMLInputElement>(null);

	return (
		<Container>
			<Dropdown
				buttonChildren={isOther ? OTHER_OPTION : contactedVia || null}
				disabled={disabled}
				onChange={handleChange}
				placeholder="Contact Type"
				value={contactedVia}
			>
				<div ref={ref}>
					<Option label="No Contact" value="">
						No Contact
					</Option>
					<Option label={CALL_OPTION} value={CALL_OPTION}>
						{CALL_OPTION}
					</Option>
					<Option label={EMAIL_OPTION} value={EMAIL_OPTION}>
						{EMAIL_OPTION}
					</Option>
					<Option label={LINKEDIN_OPTION} value={LINKEDIN_OPTION}>
						{LINKEDIN_OPTION}
					</Option>
					<Option label={MEETING_OPTION} value={MEETING_OPTION}>
						{MEETING_OPTION}
					</Option>
					<Option label={TEXT_OPTION} value={TEXT_OPTION}>
						{TEXT_OPTION}
					</Option>
					<Option label={OTHER_OPTION} value={OTHER_OPTION}>
						Other
					</Option>
				</div>
			</Dropdown>
			<TextInput
				$visible={isOther}
				disabled={disabled}
				onChange={handleTextInputChange}
				onBlur={onOtherInputBlur}
				placeholder="Please specify..."
				ref={otherRef}
				required={isOther}
				status={error ? 'danger' : void 0}
				statusText={error || void 0}
				value={contactedVia === OTHER_OPTION ? '' : contactedVia}
			/>
		</Container>
	);
});
