/*
 * Desktop Safari is the only supported browser that doesn't implement the HTML5
 * `date` input type. This "component" (it's technically two, but we export one)
 * gives us a single reusable API that runs feature detection and falls back to
 * a non-native date input in Safari.
 *
 * https://bugs.webkit.org/show_bug.cgi?id=119175
 */

import moment from 'moment';
import React, { Suspense, useCallback } from 'react';

import isDateInputSupported from '../utils/is-date-input-supported';

/*
 * The non-native date picker and its dependencies are ~50KB compressed, but
 * only users on Mac Safari will need it, so load it on demand so that other
 * users don't have to pay for it.
 *
 * `loadable` doesn't propagate types, so import the default export as a type
 * and re-associate it with the dynamically-imported value. The loadable
 * component probably has some of its own props, so the type is probably a
 * subset of the true type, but we don't use the extra props, so we don't care.
 */

const DateInputPolyfill = React.lazy(
	async () => import('./date-input-polyfill'),
);

interface Props {
	readonly autoFocus?: boolean;
	readonly className?: string;
	readonly disabled?: boolean;
	readonly min?: string;
	readonly onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
	/**
	 * This provides the lowest-common-denominator information for validation.
	 *
	 * The two alternative component implementations each implement a different
	 * version of the input's `onChange` callback to translate to this API.
	 *
	 * If Safari ever inputs native date inputs, it's easier for the state
	 * machine to directly accept the input's `onChange` events to access the
	 * built-in validation API, so delete this entire file, use (styled)
	 * `<input type="date" />` in Reminder's render, and look in the diff for
	 * this pull request to see how the state machine used to use the built-in
	 * validation API.
	 */
	readonly onChangeCompatible: (
		value: string,
		validationMessage: string | null,
	) => void;
	readonly onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	readonly required?: boolean;
	readonly value: string;
}

function NativeDateInput({
	autoFocus,
	className,
	disabled,
	min,
	onBlur,
	onChangeCompatible,
	onKeyDown,
	required,
	value,
}: Props): JSX.Element {
	const onChangeHandler = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChangeCompatible(
				event.currentTarget.value,
				event.currentTarget.checkValidity()
					? null
					: event.currentTarget.validationMessage,
			);
		},
		[onChangeCompatible],
	);
	return (
		<input
			autoFocus={autoFocus}
			className={className}
			disabled={disabled}
			min={min}
			onBlur={onBlur}
			onChange={onChangeHandler}
			onKeyDown={onKeyDown}
			required={required}
			type="date"
			value={value}
		/>
	);
}

function PolyfilledDateInput({
	autoFocus,
	className,
	disabled,
	min,
	onBlur,
	onChangeCompatible,
	onKeyDown,
	required,
	value,
}: Props): JSX.Element {
	const onChangeHandler = useCallback(
		(date: Date | null) => {
			onChangeCompatible(
				date ? moment(date).format(moment.HTML5_FMT.DATE) : '',
				date ? null : '',
			);
		},
		[onChangeCompatible],
	);

	return (
		<Suspense
			fallback={<input className={className} disabled type="date" />}
		>
			<DateInputPolyfill
				autoFocus={autoFocus}
				className={className}
				disabled={disabled}
				min={min}
				onBlur={onBlur}
				onChange={onChangeHandler}
				onKeyDown={onKeyDown}
				required={required}
				value={value}
			/>
		</Suspense>
	);
}

const DateInput = isDateInputSupported()
	? NativeDateInput
	: PolyfilledDateInput;

export default DateInput;
