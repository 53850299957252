/* global module, require */
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';

import { authenticationMiddleware } from '../authentication';
import reducer from '../reducers';

const middleware = [authenticationMiddleware];

if (process.env.NODE_ENV !== 'production') {
	middleware.push(
		createLogger({
			actionTransformer: (action) => ({
				...action,
				type: action.type.toString(),
			}),
		}),
	);
}

const store = createStore(reducer, compose(applyMiddleware(...middleware)));

if (module.hot) {
	module.hot.accept('../reducers', () => {
		const nextReducer = require('../reducers');

		store.replaceReducer(nextReducer);
	});
}

export default store;

window.dispatch = store.dispatch.bind(store);
