import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as colors from '../colors';

import { XIcon } from './icons';

interface XIconContainerProps {
	backgroundColor: string;
}
const XIconContainer = styled.div<XIconContainerProps>`
	border-radius: 3px;
	background-color: ${({ backgroundColor }) => backgroundColor};
	display: flex;
	align-items: center;
	margin-left: 8px;

	transition: background-color 0.1s linear;
	&:not(:disabled) {
		cursor: pointer;
	}
`;
interface ContainerProps {
	backgroundColor: string;
	borderColor: string;
	show: boolean;
	top: number;
}
const Container = styled.div<ContainerProps>`
	position: absolute;
	top: ${({ top }) => `${top}px`};
	left: 0;
	right: 0;
	margin: auto;
	z-index: 24;
	border: 1px solid ${({ borderColor }) => borderColor};
	border-radius: 3px;
	padding: 5px 10px;
	background-color: ${({ backgroundColor }) => backgroundColor};

	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 400px;

	${({ show }) => (show ? '' : 'display: none;')};
`;

interface Props {
	readonly clearTime?: number;
	readonly text: string;
	readonly topPosition?: number;
	// TODO: We can add other types (warning, notice) as needed
	readonly type: 'alert' | 'success';
}

const allColorOptions = {
	alert: {
		backgroundColor: colors.red.fade(0.8).string(),
		borderColor: colors.red.string(),
	},
	success: {
		backgroundColor: colors.green.fade(0.8).string(),
		borderColor: colors.green.string(),
	},
};
export default function SnackBar({
	clearTime = 3000,
	text,
	topPosition = 100,
	type,
}: Props): JSX.Element {
	const [acknowledged, setAcknowledged] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => {
			if (acknowledged) return;
			setAcknowledged(true);
		}, clearTime);
		return () => clearTimeout(timer);
	}, [acknowledged, clearTime, text]);

	const handleXClick = () => setAcknowledged(true);
	const colorOptions = allColorOptions[type];

	return (
		<Container show={!acknowledged} top={topPosition} {...colorOptions}>
			{text}
			<XIconContainer
				onClick={handleXClick}
				backgroundColor={colorOptions.backgroundColor}
			>
				<XIcon style={{ height: '16px', width: '16px' }} />
			</XIconContainer>
		</Container>
	);
}
