import styled from 'styled-components';

import { breakpoints, colors, effects } from '..';

const Section = styled.section`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 12px;
	flex-shrink: 0;
	padding: 16px;

	@media (${breakpoints.lg}) {
		padding: 20px;
	}
`;

export default Section;
