import isEqual from 'lodash.isequal';

import DefaultMap from '../utils/default-map';

import type {
	DetailedMarketMapCompanyData,
	DetailedMarketMapRegionData,
	MarketMapData,
} from './types';

export interface MarketMapTree<TCompany = DetailedMarketMapCompanyData>
	extends MarketMapData<DetailedMarketMapRegionData<TCompany>> {
	regionTree: RegionTree<TCompany>;
}

export const ROOT_NODE_ID = -1;
export type Side = 'bottom' | 'top' | 'left' | 'right';
export type CoordValues = {
	top: number;
	bottom: number;
	left: number;
	right: number;
};

const STARTING_NEW_NODE_ID_COUNTER = -2;
let newNodeCounter = STARTING_NEW_NODE_ID_COUNTER;
export const generateNewNodeId = (): number => {
	return newNodeCounter--;
};

const oppositeSides: { [side in Side]: Side } = {
	top: 'bottom',
	bottom: 'top',
	left: 'right',
	right: 'left',
};
export type CreatedMarketMapRegionData = Omit<
	DetailedMarketMapRegionData,
	'id' | 'companies'
>;

export interface RegionTreeDiff<TCompany> {
	created: Set<RegionTree<TCompany>>;
	deleted: Set<RegionTree<TCompany>>;
	edited: Set<RegionTree<TCompany>>;
}

export default class RegionTree<TCompany = DetailedMarketMapCompanyData> {
	_children: Set<RegionTree<TCompany>>;
	get children(): Set<RegionTree<TCompany>> {
		return new Set(this._children);
	}

	get coordinates(): CoordValues {
		return {
			top: this.top,
			bottom: this.bottom,
			left: this.left,
			right: this.right,
		};
	}

	public readonly bottom: number;

	public readonly left: number;

	public readonly right: number;

	public readonly top: number;

	_name: string;
	get name(): string {
		return this._name;
	}

	get isRoot(): boolean {
		return this.data.id === ROOT_NODE_ID;
	}

	get isNewNode(): boolean {
		return this.data.id <= STARTING_NEW_NODE_ID_COUNTER;
	}

	data: DetailedMarketMapRegionData<TCompany>;

	/**
	 * Builds a region tree from a map name and a list of regions within that
	 * map. Creates a synthetic root node with id -1 that fully contains all of
	 * the regions.
	 */
	static from<TCompany = DetailedMarketMapCompanyData>(
		name: string,
		regions: Array<DetailedMarketMapRegionData<TCompany>>,
	): RegionTree<TCompany> {
		const tree = new RegionTree<TCompany>({
			companies: [] as Array<TCompany>,
			id: ROOT_NODE_ID,
			name,
			top: 0,
			right: Infinity,
			bottom: Infinity,
			left: 0,
		});

		regions.forEach((region) => {
			tree.insert(new RegionTree<TCompany>(region));
		});

		return tree;
	}

	static withMarketMap<TCompany = DetailedMarketMapCompanyData>(
		marketMap: MarketMapData<DetailedMarketMapRegionData<TCompany>>,
	): MarketMapTree<TCompany> {
		return {
			...marketMap,
			regionTree: RegionTree.from(marketMap.name, marketMap.regions),
		};
	}

	constructor(
		data: DetailedMarketMapRegionData<TCompany>,
		children = new Set<RegionTree<TCompany>>(),
	) {
		this._children = children;

		this.bottom = data.bottom;
		this.left = data.left;
		this.right = data.right;
		this.top = data.top;
		this._name = data.name;

		this.data = data;
	}

	rename(target: RegionTree<TCompany>, name: string): RegionTree<TCompany> {
		if (!this.isRoot) {
			throw new Error('Can only be called from root node');
		}
		if (this === target) {
			throw new Error('Root node cannot be renamed');
		}

		return this.renameRecursive(target, name);
	}

	private renameRecursive(
		target: RegionTree<TCompany>,
		name: string,
	): RegionTree<TCompany> {
		if (this === target) {
			return new RegionTree<TCompany>(
				{ ...this.data, name },
				this._children,
			);
		} else if (this.contains(target)) {
			return new RegionTree<TCompany>(
				this.data,
				new Set(
					[...this._children].map((child) =>
						child.renameRecursive(target, name),
					),
				),
			);
		}
		return this;
	}

	resize(
		target: RegionTree<TCompany>,
		side: Side,
		growShrink: 'grow' | 'shrink',
	): RegionTree<TCompany> {
		if (!this.isRoot) {
			throw new Error('Can only be called from root node');
		}
		if (this === target) {
			throw new Error('Root node cannot be resized');
		}

		const shrinking = growShrink === 'shrink';
		const change =
			(shrinking && (side === 'bottom' || side === 'right'))
			|| (!shrinking && (side === 'top' || side === 'left'))
				? -1
				: 1;

		return this.resizeRecursive(target, side, change, shrinking);
	}

	private resizeRecursive(
		target: RegionTree<TCompany>,
		side: Side,
		change: 1 | -1,
		shrinking: boolean,
	): RegionTree<TCompany> {
		// If shrinking, only children may be affected.
		// If growing, only siblings may be affected.

		// Base case handling shrinking
		if (shrinking && this === target) {
			const canShrink = this.canShrinkWithAffectedRegions(side);
			if (!canShrink.answer) {
				throw new Error('Cannot shrink');
			}
			return new RegionTree<TCompany>(
				{ ...this.data, [side]: this[side] + change },
				new Set(
					[...this._children].map((child) => {
						if (canShrink.affectedRegions.has(child)) {
							return child.shiftRecursive(side, change);
						}
						return child;
					}),
				),
			);
		}

		// Base case handling growing
		if (!shrinking && this._children.has(target)) {
			const canGrow = target.canGrowWithAffectedRegions(side, this);
			if (!canGrow.answer) {
				throw new Error('Cannot grow');
			}

			return new RegionTree<TCompany>(
				this.data,
				new Set(
					[...this._children].map((child) => {
						if (child === target) {
							return new RegionTree<TCompany>(
								{ ...child.data, [side]: child[side] + change },
								child._children,
							);
						}

						if (canGrow.affectedRegions.has(child)) {
							return child.shiftRecursive(side, change);
						}
						return child;
					}),
				),
			);
		}

		if (this.contains(target)) {
			return new RegionTree<TCompany>(
				this.data,
				new Set(
					[...this._children].map((child) =>
						child.resizeRecursive(target, side, change, shrinking),
					),
				),
			);
		}

		return this;
	}

	private shiftRecursive(side: Side, change: 1 | -1): RegionTree<TCompany> {
		return new RegionTree<TCompany>(
			{
				...this.data,
				[side]: this[side] + change,
				[oppositeSides[side]]: this[oppositeSides[side]] + change,
			},
			new Set(
				[...this._children].map((child) =>
					child.shiftRecursive(side, change),
				),
			),
		);
	}

	public diff(original: RegionTree<TCompany>): RegionTreeDiff<TCompany> {
		const originalNodes = Array.from(original.nodes());
		const newNodes: Array<RegionTree<TCompany>> = Array.from(this.nodes());
		const created = new Set(newNodes.filter((region) => region.isNewNode));

		const edited = new Set<RegionTree<TCompany>>();
		const mappedNewNodes = new Map<number, RegionTree<TCompany>>();

		const deleted = new Set<RegionTree<TCompany>>();

		for (const region of newNodes) {
			if (!region.isNewNode) {
				mappedNewNodes.set(region.data.id, region);
			}
		}
		for (const region of originalNodes) {
			const updatedRegion = mappedNewNodes.get(region.data.id);
			if (updatedRegion) {
				if (
					!isEqual(region.coordinates, updatedRegion.coordinates)
					|| region.name !== updatedRegion.name
				) {
					edited.add(updatedRegion);
				}
			} else if (!region.isNewNode) {
				deleted.add(region);
			}
		}

		return {
			created,
			deleted,
			edited,
		};
	}

	delete(target: RegionTree<TCompany>): RegionTree<TCompany> {
		if (!this.isRoot) {
			throw new Error('Can only be called from root node');
		}
		if (this === target) {
			throw new Error('Root node cannot be deleted');
		}

		return this.deleteRecursive(target);
	}

	private deleteRecursive(
		target: RegionTree<TCompany>,
	): RegionTree<TCompany> {
		if (this.children.has(target)) {
			// A direct child is being deleted.
			// Create new RegionTree with all children EXCEPT
			// for the deleted child
			return new RegionTree<TCompany>(
				this.data,
				new Set(
					[...this._children].filter((child) => child !== target),
				),
			);
		}
		if (this.contains(target)) {
			return new RegionTree<TCompany>(
				this.data,
				new Set(
					[...this._children].map((child) =>
						child.deleteRecursive(target),
					),
				),
			);
		}
		return this;
	}

	public create(
		newRegionData: CreatedMarketMapRegionData,
	): RegionTree<TCompany> {
		if (!this.isRoot) {
			throw new Error('Can only be called from root node');
		}

		return this.createRecursive(
			new RegionTree<TCompany>({
				...newRegionData,
				companies: [],
				id: generateNewNodeId(),
			}),
		);
	}

	private createRecursive(
		newRegion: RegionTree<TCompany>,
	): RegionTree<TCompany> {
		if (this.contains(newRegion)) {
			// If any children contain the newRegion - keep going
			if (
				[...this._children].some((child) => child.contains(newRegion))
			) {
				return new RegionTree<TCompany>(
					this.data,
					new Set(
						[...this._children].map((child) => {
							if (child.contains(newRegion)) {
								return child.createRecursive(newRegion);
							}
							return child;
						}),
					),
				);
			}

			// If no children contain the newRegion - newRegion must be a direct child
			// of this region.
			return new RegionTree<TCompany>(
				this.data,
				new Set(this._children).add(newRegion),
			);
		}
		return this;
	}

	contains(other: RegionTree<TCompany>): boolean {
		return (
			this.top <= other.top
			&& other.right <= this.right
			&& other.bottom <= this.bottom
			&& this.left <= other.left
		);
	}

	insert(node: RegionTree<TCompany>): boolean {
		if (this.contains(node)) {
			for (const child of this._children) {
				if (child.insert(node)) return true;
			}

			for (const child of this._children) {
				if (node.insert(child)) this._children.delete(child);
			}

			this._children.add(node);
			return true;
		}

		return false;
	}

	/*
	 * Returns a list of coordinates for all empty 1x1 cells in the current tree node
	 */
	calculateEmptyGridSpaces(): Array<{
		top: number;
		left: number;
		right: number;
		bottom: number;
	}> {
		// Root has infinite right/bottom values.
		// Calculate true boundaries from children or else we get infinite
		// free spaces. Add 1 to these boundaries so there are free spaces
		// on the bottom/right sides
		const bottom = this.isRoot
			? Math.max(0, ...Array.from(this._children).map((r) => r.bottom))
				+ 1
			: this.bottom;
		const right = this.isRoot
			? Math.max(0, ...Array.from(this._children).map((r) => r.right)) + 1
			: this.right;

		// Start by creating a map of y values -> Set x values
		const gridMap = new Map<number, Set<number>>();
		for (let i = this.top; i < bottom; i++) {
			gridMap.set(
				i,
				new Set(
					Array.from(
						new Array(right - this.left),
						(x, j) => j + this.left,
					),
				),
			);
		}

		// Delete values from the sets if the grid space is filled
		this._children.forEach((region) => {
			for (let i = region.top; i < region.bottom; i++) {
				// Absolutely guaranteed this value exists
				const xCoordSet = gridMap.get(i);
				for (let j = region.left; j < region.right; j++) {
					xCoordSet?.delete(j);
				}
			}
		});

		// Convert everything to the final return type
		const freeSpaces = new Array<{
			top: number;
			left: number;
			right: number;
			bottom: number;
		}>();
		for (const [topValue, xValues] of gridMap) {
			xValues.forEach((xValue) =>
				freeSpaces.push({
					top: topValue,
					left: xValue,
					bottom: topValue + 1,
					right: xValue + 1,
				}),
			);
		}

		return freeSpaces;
	}

	private remainsWithinBounds(
		change: 1 | -1,
		containerSide: number,
		furthestChildSide: number,
	): boolean {
		if (change > 0) {
			return furthestChildSide <= containerSide;
		} else {
			return furthestChildSide >= containerSide;
		}
	}

	canShrink(side: Side): boolean {
		return this.canShrinkWithAffectedRegions(side).answer;
	}

	canGrow(side: Side, parent: RegionTree<TCompany>): boolean {
		return this.canGrowWithAffectedRegions(side, parent).answer;
	}

	private canShrinkWithAffectedRegions(side: Side):
		| { answer: false }
		| {
				answer: true;
				affectedRegions: Set<RegionTree<TCompany>>;
		  } {
		const change = side === 'bottom' || side === 'right' ? -1 : 1;
		// Regions must be at least 1 wide and 1 tall. If the side / opposite side
		// are the same after the change, it would break this rule.
		const shrinkIntoVoid =
			this[side] + change === this[oppositeSides[side]];
		if (shrinkIntoVoid) {
			return { answer: false };
		}

		const regionsMap = buildRegionsMap(
			this.coordinates,
			side,
			true,
			this._children,
		);
		const affectedRegions = collectAffectedRegions(
			this.coordinates,
			side,
			side,
			regionsMap,
		);
		const furthestSideValue =
			(change < 0 ? Math.min : Math.max)(
				this[side],
				...[...affectedRegions].map(
					(region) => region[oppositeSides[side]],
				),
			) + change;
		if (
			!this.remainsWithinBounds(
				change,
				this[oppositeSides[side]],
				furthestSideValue,
			)
		) {
			return { answer: false };
		}
		return { answer: true, affectedRegions };
	}

	private canGrowWithAffectedRegions(
		side: Side,
		parent: RegionTree<TCompany>,
	):
		| { answer: false }
		| {
				answer: true;
				affectedRegions: Set<RegionTree<TCompany>>;
		  } {
		const change = side === 'bottom' || side === 'right' ? 1 : -1;
		const regionsMap = buildRegionsMap(
			this.coordinates,
			side,
			false,
			parent._children,
		);
		const affectedRegions = collectAffectedRegions(
			this.coordinates,
			side,
			oppositeSides[side],
			regionsMap,
		);
		const furthestSideValue =
			(change < 0 ? Math.min : Math.max)(
				this[side],
				...[...affectedRegions].map((region) => region[side]),
			) + change;

		if (
			!this.remainsWithinBounds(change, parent[side], furthestSideValue)
		) {
			return { answer: false };
		}

		return { answer: true, affectedRegions };
	}

	/**
	 * Performs a depth-first preorder traversal of the tree.
	 */
	*nodes(): Generator<RegionTree<TCompany>, void, void> {
		yield this;
		for (const child of this.children) {
			yield* child.nodes();
		}
	}

	reduce<R>(cb: (acc: R, node: RegionTree<TCompany>) => R, init: R): R {
		let acc = init;
		for (const node of this.nodes()) {
			acc = cb(acc, node);
		}
		return acc;
	}
}

function isAxisOverlapped(
	axis: 'x' | 'y',
	baseCoordinates: CoordValues,
	otherCoordinates: CoordValues,
): boolean {
	if (axis === 'y') {
		return (
			baseCoordinates.top < otherCoordinates.bottom
			&& baseCoordinates.bottom > otherCoordinates.top
		);
	}
	return (
		baseCoordinates.left < otherCoordinates.right
		&& baseCoordinates.right > otherCoordinates.left
	);
}

/*
 * Given a map of regions, the side pushing, side being pushed, and the change,
 * find which regions are directly affected.
 */
export function collectAffectedRegions<TCompany>(
	pushedRegionCoordinates: CoordValues,
	pushingSide: Side,
	pushedSide: Side,
	regionsMap: DefaultMap<number, Array<RegionTree<TCompany>>>,
): Set<RegionTree<TCompany>> {
	// We want to collect all affected regions BEFORE
	// applying the edits.
	const affectedRegions = new Set<RegionTree<TCompany>>();

	const regions = regionsMap.get(pushedRegionCoordinates[pushingSide]);
	// Iterate through all regions on that grid interval
	for (const region of regions) {
		if (
			isAxisOverlapped(
				pushingSide === 'top' || pushingSide === 'bottom' ? 'x' : 'y',
				pushedRegionCoordinates,
				region.coordinates,
			)
		) {
			affectedRegions.add(region);
			const transitivelyPushedRegions = collectAffectedRegions(
				region.coordinates,
				oppositeSides[pushedSide],
				pushedSide,
				regionsMap,
			);

			transitivelyPushedRegions.forEach((transitivelypushedRegion) =>
				affectedRegions.add(transitivelypushedRegion),
			);
		}
	}

	return affectedRegions;
}

/*
 * Returns a map of every grid point that is potentially in the way
 * of the resize with the regions that are on that grid point.
 * This is done so we can incrementally check each grid point for regions
 * that need to shift, and what other regions that may be affected by that shifting.
 */
export function buildRegionsMap<TCompany>(
	resizingRegionCoordinates: CoordValues,
	resizingSide: Side,
	shrinking: boolean,
	regions: Set<RegionTree<TCompany>>,
): DefaultMap<number, Array<RegionTree<TCompany>>> {
	// For every region that is possibly affected by our resize (within the direction of change)
	// map it's side facing the resizing side. Having the regions organized in this fashion
	// make collecting which regions are affected easier, since we can
	// look at each grid point and check which regions are at that grid point.
	const mappedRegions = new DefaultMap<number, Array<RegionTree<TCompany>>>(
		() => [],
	);
	const facingSide = shrinking ? resizingSide : oppositeSides[resizingSide];

	const rightBottom = resizingSide === 'right' || resizingSide === 'bottom';

	regions.forEach((node) => {
		const facingSideValue = node[facingSide];
		const withinChangeDirection =
			(rightBottom && shrinking) || (!rightBottom && !shrinking)
				? node[facingSide] <= resizingRegionCoordinates[resizingSide]
				: node[facingSide] >= resizingRegionCoordinates[resizingSide];
		if (withinChangeDirection) {
			mappedRegions.get(facingSideValue).push(node);
		}
	});
	return mappedRegions;
}
