import type { EntityType } from '../use-entity-type';

type Direction = 'ASCENDING' | 'DESCENDING';

interface DescriptionSort {
	direction: Direction;
	fieldName: 'description';
}

interface EmployeeCountSort {
	direction: Direction;
	fieldName: 'employeeCount';
}

interface EmployeeGrowthSort {
	direction: Direction;
	fieldName: 'employeeGrowth';
}

interface FoundedYearSort {
	direction: Direction;
	fieldName: 'foundedYear';
}

interface LastContactSort {
	direction: Direction;
	fieldName: 'lastContact';
}

interface LastRaisedSort {
	direction: Direction;
	fieldName: 'lastRaised';
}

interface NameSort {
	direction: Direction;
	fieldName: 'name';
}

interface SearchQueryStrengthSort {
	direction: Direction;
	fieldName: 'searchQueryStrength';
}

interface TotalFundingSort {
	direction: Direction;
	fieldName: 'totalFunding';
}

type CompanySorting =
	| DescriptionSort
	| EmployeeCountSort
	| EmployeeGrowthSort
	| FoundedYearSort
	| LastRaisedSort
	| NameSort
	| SearchQueryStrengthSort
	| TotalFundingSort;
export const CompanyFieldNames = [
	'description',
	'employeeCount',
	'employeeGrowth',
	'foundedYear',
	'lastRaised',
	'name',
	'searchQueryStrength',
	'totalFunding',
] satisfies CompanySorting['fieldName'][];

type PersonSorting = LastContactSort | NameSort | SearchQueryStrengthSort;
export const PersonFieldNames = [
	'lastContact',
	'name',
	'searchQueryStrength',
] satisfies PersonSorting['fieldName'][];

export type Sorting = CompanySorting | PersonSorting;

export const COMPATIBLE_FIELD_NAMES: {
	readonly [K in EntityType]: Sorting['fieldName'][];
} = {
	companies: CompanyFieldNames,
	people: PersonFieldNames,
};
export const ALL_FIELD_NAMES = Object.values(COMPATIBLE_FIELD_NAMES).flat();
