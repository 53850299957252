import numeral from 'numeral';
import React from 'react';

import {
	herbieBlack,
	herbieSmartTeal,
	primaryDriveBlue,
} from '../../../colors';
import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import DiscreteBarChart from '../charts/discrete-bar-chart';
import GenericChart from '../charts/generic-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/portfolio_revenue_by_company_all_funds';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('0.0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: 'Revenue',
		values: dataArr.map((company) => ({
			color:
				company.fund === 'Fund I'
					? `${primaryDriveBlue.string()}`
					: company.fund === 'Fund II'
						? 'green'
						: company.fund === 'Fund III'
							? `${herbieSmartTeal.string()}`
							: `${herbieBlack.string()}`,
			label: company.semi_annuals__fused_company__name,
			value: company.revenue,
		})),
	},
];

export default class SemiAnnualAllFundsPortfolioRevenueByCompany extends React.Component {
	static getDetailCard() {
		return SemiAnnualAllFundsPortfolioRevenueByCompanyDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 75 }}
				onDataFetch={this.handleDataFetch}
				title="2021 Portfolio Revenue (Projected) by Company"
				xAxisLabel="Company"
				yAxisLabel="Revenue"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualAllFundsPortfolioRevenueByCompanyDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/industries_funded.png"
				statName="All Funds Portfolio Revenue by Company"
			/>
		);
	}
}
