import type { File } from '@drivecapital/box-ui-elements';
import {
	queryOptions,
	useMutation,
	useQuery,
	useSuspenseQuery,
} from '@tanstack/react-query';

import { get, post, queryClient, updateQueryData } from '../utils/api';

export const dataroomQueryKeys = {
	all: () => ['dataroom'] as const,
	boxDataRoom: () => [...dataroomQueryKeys.all(), 'box-data-room'] as const,
	nda: () => [...dataroomQueryKeys.all(), 'nda'] as const,
};

function boxDataRoomQueryOptions() {
	return queryOptions({
		queryKey: dataroomQueryKeys.boxDataRoom(),
		queryFn: async ({ signal }) =>
			get<{ access_token: string; folder_id: string }>('/box', {
				signal,
			}),
		retry: false,
	});
}

export async function loadBoxDataRoom() {
	return queryClient.ensureQueryData(boxDataRoomQueryOptions());
}

export function useBoxDataRoom() {
	return useQuery(boxDataRoomQueryOptions());
}

export function useDownloadBoxDataRoom() {
	return useMutation({
		mutationFn: async () => post<void>('/dataroom/download'),
	});
}

export async function trackBoxDataRoomAnalyticsEvent(body: {
	action: 'DOWNLOAD' | 'VIEW';
	files: Array<File>;
}) {
	return post<void>('/box/analytics', { body });
}

export function ndaQueryOptions() {
	return queryOptions({
		queryKey: dataroomQueryKeys.nda(),
		queryFn: async ({ signal }) =>
			get<{ can_see_performance_video: boolean; signed: boolean }>(
				'/dataroom/nda',
				{ signal },
			),
	});
}

export async function loadNDA() {
	return queryClient.ensureQueryData(ndaQueryOptions());
}

export function useNDA() {
	return useSuspenseQuery(ndaQueryOptions());
}

export function useAgreeToNDA() {
	return useMutation({
		mutationFn: async () =>
			post<{ can_see_performance_video: boolean; signed: boolean }>(
				'/dataroom/nda',
				{},
			),
		onSuccess: (data) => {
			updateQueryData(ndaQueryOptions(), () => data);
		},
	});
}
