/**
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1%3A5486&mode=dev
 */
import React from 'react';
import styled from 'styled-components';

import { colors } from '..';
import { ArrowDown, ArrowUp } from '../icons/arrows';
import { MinusIcon, PlusIcon, SlashIcon } from '../icons/system';

const ICONS = {
	POSITIVE: {
		SIGNAL: PlusIcon,
		TREND: ArrowUp,
	},
	NEGATIVE: {
		SIGNAL: MinusIcon,
		TREND: ArrowDown,
	},
	NEUTRAL: {
		SIGNAL: SlashIcon,
		TREND: SlashIcon,
	},
} as const;

const BACKGROUND_COLORS = {
	POSITIVE: colors.data.green.layerSubtle,
	NEGATIVE: colors.data.red.layerSubtle,
	NEUTRAL: colors.data.dust.layerSubtle,
} as const;
const TEXT_COLORS = {
	POSITIVE: colors.data.green.textSecondary,
	NEGATIVE: colors.data.red.textSecondary,
	NEUTRAL: colors.data.dust.textSecondary,
} as const;

const Circle = styled.div<{ $direction: Props['direction'] }>`
	align-items: flex-start;
	background: ${({ $direction }) => BACKGROUND_COLORS[$direction]};
	border-radius: 20px;
	display: inline-flex;
	height: 16px;
	padding: 2px;
	width: 16px;

	& > svg {
		color: ${({ $direction }) => TEXT_COLORS[$direction]};
		height: 100%;
		width: 100%;
	}
`;

interface Props {
	readonly className?: string;
	readonly direction: 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL';
	readonly icon: 'SIGNAL' | 'TREND';
}

export default function SignalIndicator({ className, direction, icon }: Props) {
	const Icon = ICONS[direction][icon];

	return (
		<Circle $direction={direction} className={className}>
			<Icon />
		</Circle>
	);
}
