import React, { lazy } from 'react';

import authorizedRoute from '../components/authorized-route';

export { type LPData } from './types';
export { default as LPFundraisingView } from './view';

const View = lazy(async () => import('./view'));

export const routes = authorizedRoute(
	{
		path: 'lps',
		element: <View />,
	},
	'contacts.lpfundraising',
);
