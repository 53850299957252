/**
 * @file
 * Travel icons from [Figma][1].
 *
 * [1]: https://www.figma.com/file/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1%3A5582&mode=dev
 */
import React from 'react';

export function CommunityOutlineIcon(
	props: React.SVGAttributes<SVGSVGElement>,
) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21 21H3C2.44772 21 2 20.5523 2 20V12.4868C2 12.1978 2.12501 11.9229 2.34282 11.733L6 8.54435V4C6 3.44772 6.44772 3 7 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21ZM9 19H12V12.9416L8 9.45402L4 12.9416V19H7V15H9V19ZM14 19H20V5H8V7.12729C8.23444 7.12729 8.46888 7.20938 8.65718 7.37355L13.6572 11.733C13.875 11.9229 14 12.1978 14 12.4868V19ZM16 11H18V13H16V11ZM16 15H18V17H16V15ZM16 7H18V9H16V7ZM12 7H14V9H12V7Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function CompassOutlineIcon(props: React.SVGAttributes<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
			<path
				d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.5 6.5L11.5 11.5L6.5 13.5L8.5 8.5L13.5 6.5Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function BuildingIcon(props: React.SVGAttributes<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21 19H23V21H1V19H3V4C3 3.44772 3.44772 3 4 3H14C14.5523 3 15 3.44772 15 4V19H19V11H17V9H20C20.5523 9 21 9.44772 21 10V19ZM5 5V19H13V5H5ZM7 11H11V13H7V11ZM7 7H11V9H7V7Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function Building2Icon(props: React.SVGAttributes<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21 20H23V22H1V20H3V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V20ZM19 20V4H5V20H19ZM8 11H11V13H8V11ZM8 7H11V9H8V7ZM8 15H11V17H8V15ZM13 15H16V17H13V15ZM13 11H16V13H13V11ZM13 7H16V9H13V7Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function MapPinIcon(props: React.SVGAttributes<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8 15.2431L3.75736 11.0005C1.41421 8.65728 1.41421 4.85832 3.75736 2.51517C6.10051 0.172026 9.89947 0.172026 12.2427 2.51517C14.5858 4.85832 14.5858 8.65728 12.2427 11.0005L8 15.2431ZM11.2998 10.0576C13.1223 8.23521 13.1223 5.28043 11.2998 3.45798C9.4774 1.63553 6.52261 1.63553 4.70017 3.45798C2.87772 5.28043 2.87772 8.23521 4.70017 10.0576L8 13.3575L11.2998 10.0576ZM8 8.09115C7.2636 8.09115 6.66667 7.49421 6.66667 6.75781C6.66667 6.02143 7.2636 5.42448 8 5.42448C8.7364 5.42448 9.33333 6.02143 9.33333 6.75781C9.33333 7.49421 8.7364 8.09115 8 8.09115Z"
				fill="currentColor"
			/>
		</svg>
	);
}
