import { Temporal } from '@js-temporal/polyfill';
import pluralize from 'pluralize';
import { useCallback, useEffect, useState } from 'react';

import type { CompanyOutreach } from './types';

type SnoozeUntilError = {
	message: string;
	status: 'danger' | 'default' | 'warning';
};

const NO_SNOOZE_ERROR: SnoozeUntilError = { message: '', status: 'default' };
const EMAIL_PURGE_DELAY = 30;

export default function useSnoozeForm(
	isVisible: boolean,
	outreaches: CompanyOutreach[],
) {
	const [snoozeUntil, setSnoozeUntil] = useState<string | null>(null);
	const [snoozeNotes, setSnoozeNotes] = useState<string | null>(null);
	const [snoozeUntilError, setSnoozeUntilError] =
		useState<SnoozeUntilError>(NO_SNOOZE_ERROR);

	const handleSnoozeNotesChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSnoozeNotes(event.target.value);
		},
		[],
	);

	const validateSnoozeUntil = useCallback(
		(until: string | null): SnoozeUntilError => {
			if (!until)
				return { message: 'Please select a date', status: 'danger' };
			const today = Temporal.Now.plainDateISO();

			if (Temporal.PlainDate.compare(until, today) <= 0) {
				return {
					message: 'Please select a date in the future',
					status: 'danger',
				};
			}
			const temporalUntil = Temporal.PlainDate.from(until);
			const outreachAtRiskOfPurge = outreaches.some(
				({ mostRecentOutreachAt }) => {
					if (!mostRecentOutreachAt) return false;
					const outreachPurgedAt = Temporal.PlainDate.from(
						mostRecentOutreachAt.add({ days: EMAIL_PURGE_DELAY }),
					);
					return (
						Temporal.PlainDate.compare(
							outreachPurgedAt,
							temporalUntil,
						) < 0
					);
				},
			);
			if (outreachAtRiskOfPurge) {
				return {
					message: `Note: The mail ${pluralize(
						'thread',
						outreaches.length,
					)} will be purged before this follow-up date`,
					status: 'warning',
				};
			}

			return NO_SNOOZE_ERROR;
		},
		[outreaches],
	);

	const handleSnoozeUntilChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSnoozeUntil(event.target.value);
			const error = validateSnoozeUntil(event.target.value);
			if (!error) {
				setSnoozeUntilError(NO_SNOOZE_ERROR);
			}
		},
		[validateSnoozeUntil],
	);

	const handleSnoozeUntilInputBlur = useCallback(() => {
		setSnoozeUntilError(validateSnoozeUntil(snoozeUntil));
	}, [snoozeUntil, validateSnoozeUntil]);

	useEffect(() => {
		if (!isVisible) {
			setSnoozeUntil(null);
			setSnoozeNotes(null);
			setSnoozeUntilError(NO_SNOOZE_ERROR);
		}
	}, [isVisible]);

	return {
		handleSnoozeNotesChange,
		handleSnoozeUntilChange,
		handleSnoozeUntilInputBlur,
		snoozeNotes,
		snoozeUntil,
		snoozeUntilError,
	};
}
