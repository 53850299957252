import React from 'react';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';

type Data = React.ReactNode;

export default class ReactNodeColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	name: string;
	private readonly initialSortDirection?: SortDirection;
	private readonly select: (row: T) => Data;
	private readonly _sort?: (direction: SortDirection, a: T, b: T) => number;

	constructor({
		name,
		initialSortDirection = sortDirections.ascending,
		select,
		sort,
	}: {
		name: string;
		initialSortDirection?: SortDirection;
		select: (row: T) => Data;
		sort?: (direction: SortDirection, a: T, b: T) => number;
	}) {
		this.name = name;
		this.initialSortDirection = initialSortDirection;
		this.select = select;
		this._sort = sort;

		this.cell.displayName = 'ReactNodeColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ row, props }: CellArgs<T>) => {
		return <td {...props}>{this.select(row)}</td>;
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		if (!this._sort) {
			return <th {...props}>{this.name}</th>;
		}
		return (
			<SortableHeader
				initialSortDirection={this.initialSortDirection}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				{this.name}
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		if (!this._sort) return 0;
		return this._sort(direction, a, b);
	}
}
