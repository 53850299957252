import React, { useCallback } from 'react';
import styled from 'styled-components';

import {
	Label,
	Radio,
	RadioGroup as UnstyledRadioGroup,
} from '../../components/radio-group';

import type {
	FilterControlHeaderProps,
	FilterControlProps,
	IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import type { CompanyStatusFilter } from './types';

const Container = styled.div`
	display: flex;
	gap: 16px;
`;
const RadioGroup = styled(UnstyledRadioGroup)``;

const FIELD_NAME: CompanyStatusFilter['fieldName'] = 'companyStatus';
function FilterControl({
	filter,
	onChange,
}: FilterControlProps<CompanyStatusFilter>) {
	const handleIPOStatusChange = useCallback(
		(newValue: string | null) => {
			if (newValue === 'PRIVATE' || newValue === 'PUBLIC') {
				onChange({
					fieldName: FIELD_NAME,
					value: {
						...filter?.value,
						ipoStatus: newValue,
					},
				});
				return;
			}
		},
		[filter, onChange],
	);
	const handleOperatingStatusChange = useCallback(
		(newValue: string | null) => {
			if (newValue === 'ACTIVE' || newValue === 'CLOSED') {
				onChange({
					fieldName: FIELD_NAME,
					value: {
						...filter?.value,
						operatingStatus: newValue,
					},
				});
				return;
			}
		},
		[filter, onChange],
	);

	return (
		<Container>
			<RadioGroup
				label="company-operating-status"
				onChange={handleOperatingStatusChange}
				value={filter?.value?.operatingStatus ?? null}
			>
				<Label>Operating Status</Label>
				<Radio value="ACTIVE" variant="checkbox">
					Active
				</Radio>
				<Radio value="CLOSED" variant="checkbox">
					Closed
				</Radio>
			</RadioGroup>

			<RadioGroup
				label="company-ipo-status"
				onChange={handleIPOStatusChange}
				value={filter?.value?.ipoStatus ?? null}
			>
				<Label>IPO Status</Label>
				<Radio value="PRIVATE" variant="checkbox">
					Private
				</Radio>
				<Radio value="PUBLIC" variant="checkbox">
					Public
				</Radio>
			</RadioGroup>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<CompanyStatusFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Company Status"
		/>
	);
}

const CompanyStatusFilterControl: IFilterControl<CompanyStatusFilter> = {
	control: FilterControl,
	description: (filter) => {
		const parts = [];
		if (filter.value.operatingStatus === 'ACTIVE') {
			parts.push('are active');
		}
		if (filter.value.operatingStatus === 'CLOSED') {
			parts.push('are closed');
		}
		if (filter.value.ipoStatus === 'PRIVATE') {
			parts.push('are private');
		}
		if (filter.value.ipoStatus === 'PUBLIC') {
			parts.push('are public');
		}
		return parts;
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default CompanyStatusFilterControl;
