import { $convertToMarkdownString } from '@lexical/markdown';
import { $getRoot, createEditor, type EditorState } from 'lexical';

import { editorNodes, MARKDOWN_TRANSFORMERS } from './editor';

type JSONValue =
	| null
	| boolean
	| number
	| string
	| Array<JSONValue>
	| { [key: string]: JSONValue };

export function safeJSONParse(json: string): JSONValue | undefined {
	try {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
		return JSON.parse(json) as JSONValue;
	} catch {
		return void 0;
	}
}

export function noop() {
	// No operation
}

export function getTextContent(editorState: EditorState) {
	return editorState.read(() =>
		$convertToMarkdownString(MARKDOWN_TRANSFORMERS, $getRoot()),
	);
}

export function deserializeEditorState(serializedEditorState: string): string {
	try {
		return getTextContent(
			createEditor({ nodes: editorNodes }).parseEditorState(
				serializedEditorState,
			),
		);
	} catch {
		return '';
	}
}

export function serializeEditorState(editorState: EditorState): string {
	return JSON.stringify(editorState);
}

export function isEditorEmpty(editorState: EditorState) {
	return editorState.read(() => {
		const root = $getRoot();
		const isEmpty =
			(root.getFirstChild()?.getTextContent() ?? '') === ''
			&& root.getChildrenSize() <= 1;

		return isEmpty;
	});
}
