import React, { memo, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import colors from 'tailwindcss/colors';

import { ProfileCardDragItemType } from './constants';
import ProfileCard from './profile-card';
import EvaluationModal from './transition-rule-evaluation';
import type {
	PipelineStage,
	PipelineStageItem,
	StageTransitionDataValue,
} from './types';

const Container = styled.div<{ isDragHovered: boolean }>`
	background-color: ${colors.slate[100]};
	border: 1px solid ${colors.slate[200]};
	border-radius: 6px;
	box-shadow: 1px 2px 4px 0px ${colors.slate[200]};
	display: flex;
	flex-direction: column;
	overflow: hidden;

	${({ isDragHovered }) =>
		isDragHovered ? `border: 1px solid ${colors.blue[400]};` : ''}
`;

const Header = styled.div`
	background-color: ${colors.slate[100]};
	border-bottom: 1px solid ${colors.slate[200]};
	box-shadow: 0 0 12px ${colors.slate[300]};
	color: ${colors.slate[700]};
	flex: 0 0 min-content;
	font-weight: 600;
	padding: 12px;
	position: sticky;
	top: 0px;
	margin-left: -25%;
	padding: 12px calc(25% + 12px);
`;

const Body = styled.div`
	align-content: flex-start;
	display: grid;
	flex: 1;
	gap: 8px;
	grid-auto-flow: row;
	overflow-y: scroll;
	padding: 12px;
`;

interface Props {
	readonly currentlyEvaluatingCard: PipelineStageItem | null;
	readonly dragAndDropAllowed: boolean;
	readonly items: Array<PipelineStageItem>;
	readonly onCardClick: (item: PipelineStageItem) => void;
	readonly onCardDrop: (
		item: PipelineStageItem,
		stageId: number,
		transitionData: Array<StageTransitionDataValue>,
	) => void;
	readonly onRuleEvaluation: (
		card: PipelineStageItem | null,
		stageId: number,
	) => void;
	readonly mapStageTitleToColors?: (title: string) => {
		backgroundColor: string;
		textColor: string;
	};
	readonly stage: PipelineStage;
}

function Stage({
	currentlyEvaluatingCard,
	dragAndDropAllowed,
	items,
	stage,
	mapStageTitleToColors,
	onCardClick,
	onCardDrop,
	onRuleEvaluation,
}: Props): JSX.Element {
	const [{ isDragHovered }, drop] = useDrop(() => ({
		accept: [ProfileCardDragItemType],
		canDrop: () => dragAndDropAllowed,
		collect: (monitor) => ({
			isDragHovered: monitor.isOver({ shallow: true }),
		}),
		drop: (item: PipelineStageItem) => {
			if (stage.id === item.currentStageId) return;
			if (stage.rules.length > 0) {
				onRuleEvaluation(item, stage.id);
			} else {
				onCardDrop(item, stage.id, []);
			}
		},
	}));

	const handleRuleEvaluationSubmit = useCallback(
		(
			destinationStageId: number,
			transitionData: Array<StageTransitionDataValue>,
		) => {
			if (currentlyEvaluatingCard) {
				onCardDrop(
					currentlyEvaluatingCard,
					destinationStageId,
					transitionData,
				);
			}
		},
		[currentlyEvaluatingCard, onCardDrop],
	);

	const handleCancel = useCallback(() => {
		onRuleEvaluation(null, stage.id);
	}, [onRuleEvaluation, stage.id]);

	return (
		<Container ref={drop} isDragHovered={isDragHovered}>
			<Header>{stage.title}</Header>
			<Body>
				{items.map((item) => (
					<ProfileCard
						key={item.id}
						onCardClick={onCardClick}
						canDrag={
							dragAndDropAllowed && !item.currentlyMovingStages
						}
						item={item}
					/>
				))}
			</Body>
			<EvaluationModal
				destinationStage={stage}
				isOpen={currentlyEvaluatingCard !== null}
				item={currentlyEvaluatingCard}
				mapStageTitleToColors={mapStageTitleToColors}
				onClose={handleCancel}
				onSubmit={handleRuleEvaluationSubmit}
			/>
		</Container>
	);
}

export default memo<Props>(Stage);
