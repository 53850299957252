import {
	AriaDropdown as Dropdown,
	AriaDropdownOption as DropdownOption,
	AriaDropdownOptions as DropdownOptions,
	TextInput as Input,
} from '@drivecapital/design-system/components';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
	.HerbieDropdown__button {
		justify-content: space-between;
		width: 100%;
	}
`;
const StyledDropdownOptions = styled(DropdownOptions)`
	&[data-trigger='Select'] {
		width: var(--trigger-width);
	}

	.HerbieDropdown__option-list {
		max-height: 400px;
	}
`;

interface Props {
	readonly elementId: string;
	readonly options: Array<{
		label: string;
		value: string;
		requireExtra: boolean;
	}>;
	readonly required: boolean;
	readonly title: string;
}

export default function SelectRule({
	elementId,
	required,
	title,
	options,
}: Props): JSX.Element {
	const [currentValue, setCurrentValue] = useState<string | null>(null);
	const optionIdx = options.findIndex(
		(option) => option.value === currentValue,
	);
	const currentlySelectedOption = options[optionIdx];

	return (
		<>
			<StyledDropdown
				id={elementId}
				label={title}
				name={elementId}
				onChange={(key) => setCurrentValue(key.toString())}
				placeholder="Select a Value"
				required={required}
				value={currentValue}
			>
				<StyledDropdownOptions>
					{options.map(({ label, value }) => (
						<DropdownOption key={value} value={value}>
							{label}
						</DropdownOption>
					))}
				</StyledDropdownOptions>
			</StyledDropdown>
			{currentlySelectedOption?.requireExtra && (
				<Input
					name={`${elementId}-extra`}
					placeholder="Please Specify"
					required
				/>
			)}
		</>
	);
}
