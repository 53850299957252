export default class KeyedMap<K, V> {
	private readonly _hash: (key: K) => string;

	private readonly _keys: Set<K>; // Save the unhashed keys for iteration on `entries()`
	private readonly _values: Map<string, V>; // Map hashed keys to values

	get size(): number {
		return this._values.size;
	}

	constructor(hash: (key: K) => string) {
		this._hash = hash;
		this._keys = new Set();
		this._values = new Map<string, V>();
	}

	delete(key: K): boolean {
		return this._values.delete(this._hash(key));
	}

	*entries(): IterableIterator<[K, V]> {
		for (const key of this._keys) {
			const value = this.get(key);
			// Here's another case where building this API with `Maybe<T>`
			// instead of overloading `undefined` would make things cleaner.
			if (typeof value !== 'undefined') yield [key, value];
		}
	}

	get(key: K): V | void {
		return this._values.get(this._hash(key));
	}

	has(key: K): boolean {
		return this._values.has(this._hash(key));
	}

	set(key: K, value: V): this {
		const hashed = this._hash(key);

		if (!this._values.has(hashed)) this._keys.add(key);
		this._values.set(hashed, value);

		return this;
	}
}
