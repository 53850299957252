import type { PersonEntityData } from '../components/watchlists/types';
import type { NoteData } from '../notes';
import type { State as StoreState } from '../reducers';
import { getPersonById, selectNoteById } from '../selectors';

import type { CandidatePool as CandidatePoolFromState } from './reducers';
import type { CandidatePool } from './types';

let candidatePoolCache: [number, StoreState, CandidatePool] | null = null;

export const selectCandidatePoolById = (
	store: StoreState,
	id: number,
): CandidatePool | null => {
	let candidatePool = null;

	if (
		candidatePoolCache != null
		&& candidatePoolCache[0] === id
		&& candidatePoolCache[1] === store
	) {
		candidatePool = candidatePoolCache[2];
	} else {
		const pool = store.candidatePools.byId[id];

		if (!pool?.loaded) {
			return null;
		}

		const items = pool.items
			.map(({ id: personId, extra }) => {
				const person = getPersonById(store, personId);
				if (!person) return null;

				return {
					...person,
					logo_url: person.photo_url,
					notes: (person.notes || [])
						.map((noteId) => selectNoteById(store, noteId))
						.filter((note): note is NoteData => Boolean(note)),
					...extra,
				};
			})
			.filter((item): item is PersonEntityData => Boolean(item));

		candidatePool = {
			...pool,
			items,
		};

		candidatePoolCache = [id, store, candidatePool];
	}

	return candidatePool;
};

export const selectCandidatePoolColumnsById = (
	store: StoreState,
	id: number,
): CandidatePool['columns'] => {
	const pool = selectCandidatePoolById(store, id);
	if (!pool) return [];
	return pool.columns;
};

export const selectCandidatePoolLoadedById = (
	store: StoreState,
	id: number,
): boolean => {
	const pool = selectCandidatePoolById(store, id);
	return Boolean(pool && pool.loaded);
};

export const selectCandidatePools = (
	store: StoreState,
): Array<CandidatePoolFromState> => Object.values(store.candidatePools.byId);

export const selectAreCandidatePoolsLoaded = (store: StoreState): boolean =>
	store.candidatePools.loaded;

export const selectCurrentCandidatePoolsByTypeAndId = (
	store: StoreState,
	entityType: string,
	entityId: number,
): Array<CandidatePoolFromState> => {
	if (entityType === 'people') {
		return selectCandidatePools(store).filter((candidatePool) =>
			candidatePool.items.some(
				({ id, type }) => id === entityId && type === entityType,
			),
		);
	} else {
		return [];
	}
};
