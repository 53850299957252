import { useCallback, useEffect, useRef } from 'react';

import { trackEvent, type ViewType } from '../../utils/analytics';
import type ImmutableURLSearchParams from '../../utils/immutable-url-search-params';
import type { EntityType } from '../use-entity-type';
import useImmutableSearchParams from '../use-stable-immutable-search-params';

import { ALL_FIELD_NAMES, type Sorting } from './types';
import { calculateValidFieldNames } from './utils';

const SORT_BY_PARAM_KEY = 'sort';

function paramIsSorting(param: unknown): param is Sorting {
	if (typeof param !== 'object' || param == null) return false;

	const hasFieldName = typeof (param as Sorting).fieldName === 'string';
	const hasDirection = typeof (param as Sorting).direction === 'string';

	return hasFieldName && hasDirection;
}

function getSortingFromSearchParams(
	searchParams: ImmutableURLSearchParams,
	defaultSorting: Sorting[] = [],
): Sorting[] {
	if (!searchParams.has(SORT_BY_PARAM_KEY)) return defaultSorting;
	return searchParams
		.getAll(SORT_BY_PARAM_KEY)
		.map((sort) => JSON.parse(sort) as Sorting)
		.filter(paramIsSorting);
}

export function buildSearchParamsFromSorting(
	sorting: Sorting[],
	searchParams: ImmutableURLSearchParams,
) {
	return sorting.reduce((params, sort) => {
		return params.append(SORT_BY_PARAM_KEY, JSON.stringify(sort));
	}, searchParams);
}

interface SortingControls {
	availableFieldNames: Sorting['fieldName'][];
	onSortAdd: (
		fieldName: Sorting['fieldName'],
		direction?: Sorting['direction'],
	) => void;
	onSortClear: () => void;
	onSortRemove: (fieldName: Sorting['fieldName']) => void;
	onSortReorder: (fieldName: Sorting['fieldName'], newIndex: number) => void;
	onSortToggle: (fieldName: Sorting['fieldName']) => void;
	sorting: Sorting[];
}

interface SortingOptions {
	analyticsConfig?: {
		componentIdentifier: string;
		viewType: ViewType;
	};
	entityType: EntityType;
	defaultSorting?: Sorting[];
	allowableFieldNames?: Sorting['fieldName'][];
}

/**
 * Hook to manage multi column sorting state via a URL search param.
 *
 * @param entityType - The entity type under search
 * @param defaultSorting - The default sorting to use if no sorting is present in the URL. Defaults to `[]`
 * @param allowableFieldNames - If provided, only these field names will be used for sorting
 */
export default function useSorting({
	analyticsConfig,
	entityType,
	defaultSorting = [],
	allowableFieldNames = ALL_FIELD_NAMES,
}: SortingOptions): SortingControls {
	const firstRender = useRef(true);
	const [searchParams, setSearchParams] = useImmutableSearchParams();
	const onSortAdd = useCallback(
		(
			fieldName: Sorting['fieldName'],
			direction: Sorting['direction'] = 'DESCENDING',
		) => {
			if (!allowableFieldNames.includes(fieldName)) return;
			if (analyticsConfig) {
				trackEvent(
					'Add Search Sort',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ fieldName, direction },
				);
			}
			setSearchParams((params) =>
				params.append(
					SORT_BY_PARAM_KEY,
					JSON.stringify({ fieldName, direction }),
				),
			);
		},
		[allowableFieldNames, analyticsConfig, setSearchParams],
	);
	const onSortClear = useCallback(() => {
		if (analyticsConfig) {
			trackEvent(
				'Clear Search Sort',
				analyticsConfig.componentIdentifier,
				analyticsConfig.viewType,
			);
		}

		setSearchParams((params) => params.delete(SORT_BY_PARAM_KEY));
	}, [analyticsConfig, setSearchParams]);
	const onSortRemove = useCallback(
		(fieldName: Sorting['fieldName']) => {
			if (analyticsConfig) {
				trackEvent(
					'Remove Search Sort',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ fieldName },
				);
			}

			setSearchParams((params) => {
				const currentSorting = getSortingFromSearchParams(params);
				let newParams = params.delete(SORT_BY_PARAM_KEY);

				for (const sort of currentSorting) {
					if (sort.fieldName !== fieldName) {
						newParams = newParams.append(
							SORT_BY_PARAM_KEY,
							JSON.stringify(sort),
						);
					}
				}
				return newParams;
			});
		},
		[analyticsConfig, setSearchParams],
	);
	const onSortReorder = useCallback(
		(fieldName: Sorting['fieldName'], newIndex: number) => {
			if (!allowableFieldNames.includes(fieldName)) return;

			if (analyticsConfig) {
				trackEvent(
					'Reorder Search Sort',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ fieldName, newIndex },
				);
			}

			setSearchParams((params) => {
				const currentSorting = getSortingFromSearchParams(params);
				const movedSort = currentSorting.find(
					(sort) => sort.fieldName === fieldName,
				);

				if (!movedSort) return params;
				const leftSide = currentSorting.slice(0, newIndex);
				const rightSide = currentSorting.slice(newIndex);
				const newSorting = [
					...leftSide.filter((sort) => sort.fieldName !== fieldName),
					movedSort,
					...rightSide.filter((sort) => sort.fieldName !== fieldName),
				];
				let newParams = params.delete(SORT_BY_PARAM_KEY);

				for (const sort of newSorting) {
					newParams = newParams.append(
						SORT_BY_PARAM_KEY,
						JSON.stringify(sort),
					);
				}
				return newParams;
			});
		},
		[allowableFieldNames, analyticsConfig, setSearchParams],
	);
	const onSortToggle = useCallback(
		(fieldName: Sorting['fieldName']) => {
			if (!allowableFieldNames.includes(fieldName)) return;

			if (analyticsConfig) {
				trackEvent(
					'Toggle Search Sort',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ fieldName },
				);
			}

			setSearchParams((params) => {
				const currentSorting = getSortingFromSearchParams(params);
				let newParams = params.delete(SORT_BY_PARAM_KEY);

				for (const sort of currentSorting) {
					if (sort.fieldName === fieldName) {
						newParams = newParams.append(
							SORT_BY_PARAM_KEY,
							JSON.stringify({
								fieldName: sort.fieldName,
								direction:
									sort.direction === 'ASCENDING'
										? 'DESCENDING'
										: 'ASCENDING',
							}),
						);
					} else {
						newParams = newParams.append(
							SORT_BY_PARAM_KEY,
							JSON.stringify(sort),
						);
					}
				}
				return newParams;
			});
		},
		[allowableFieldNames, analyticsConfig, setSearchParams],
	);

	useEffect(() => {
		setSearchParams((params) => {
			const currentSorting = getSortingFromSearchParams(params);
			const validFieldNames = calculateValidFieldNames(entityType);
			let newParams = params.delete(SORT_BY_PARAM_KEY);

			for (const sort of currentSorting) {
				if (
					validFieldNames.includes(sort.fieldName)
					&& allowableFieldNames.includes(sort.fieldName)
				) {
					newParams = newParams.append(
						SORT_BY_PARAM_KEY,
						JSON.stringify(sort),
					);
				}
			}
			return newParams;
		});
	}, [allowableFieldNames, entityType, setSearchParams]);

	const filteredDefaultSorting = defaultSorting.filter((sort) =>
		allowableFieldNames.includes(sort.fieldName),
	);

	useEffect(() => {
		if (!firstRender.current) return;
		firstRender.current = false;

		if (searchParams.has(SORT_BY_PARAM_KEY)) return;
		if (filteredDefaultSorting.length === 0) return;
		setSearchParams((params) => {
			let newParams = params;

			for (const sort of filteredDefaultSorting) {
				newParams = newParams.append(
					SORT_BY_PARAM_KEY,
					JSON.stringify(sort),
				);
			}
			return newParams;
		});
	}, [filteredDefaultSorting, searchParams, setSearchParams]);

	return {
		availableFieldNames: allowableFieldNames,
		onSortAdd,
		onSortClear,
		onSortRemove,
		onSortReorder,
		onSortToggle,
		sorting: getSortingFromSearchParams(
			searchParams,
			firstRender.current ? filteredDefaultSorting : void 0,
		),
	};
}
