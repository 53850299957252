/**
 * @file
 * This style of table shows up several places in Figma.
 * Examples:
 * - [Public Comps][1]
 * - [Company Employees][2]
 *
 * [1]: https://www.figma.com/file/9a4W4MPcOlwDXNUMhNcZaE/Herbie-Profiles?node-id=673%3A219343&mode=dev
 * [2]: https://www.figma.com/file/9a4W4MPcOlwDXNUMhNcZaE/Herbie-Profiles?node-id=621%3A143448&mode=dev
 */
import { colors, fonts } from '@drivecapital/design-system';
import styled from 'styled-components';

import UnstyledTable from './table';

const ThemeTable: typeof UnstyledTable = styled(UnstyledTable)`
	th,
	td {
		text-align: left;
		padding: 14px 8px;
	}

	max-height: 575px;

	.HerbieTable {
		&__table {
			border-collapse: separate;
			border-spacing: 0;
		}

		&__body {
			td {
				border-bottom: 1px solid ${colors.border.subtle};
			}

			&:last-of-type {
				td {
					border-bottom: none;
				}
			}
		}

		&__header {
			${fonts.label.label};
			background: ${colors.layer.layer};
			border-bottom: 1px solid ${colors.border.subtle};
			color: ${colors.text.secondary};
			white-space: nowrap;

			&--sortable {
				cursor: pointer;
			}

			&--sticky-left {
				background: ${colors.layer.layer};
			}

			&--sorted {
				color: ${colors.text.primary};
				border-bottom: 1px solid ${colors.border.strong};
			}

			&__sort-arrow {
				height: 12px;
				width: 12px;
			}
		}

		&__row {
			&:hover {
				td {
					box-shadow: none;
					color: ${colors.text.primary};
				}
			}

			&--focused {
				background: ${colors.layer.hover};
				outline: none;

				.HerbieTable__cell--sticky-left {
					background: ${colors.layer.hover};
				}
			}
		}

		&__cell {
			${fonts.paragraph.paragraph};
			color: ${colors.text.secondary};
			width: inherit; /* numeric cells hardcode width: 10ch; */

			&--sticky-left {
				background: ${colors.layer.layer};
				color: ${colors.text.primary};
			}

			&__name_image {
				border: 1px solid ${colors.border.subtle};
			}

			&__image {
				line-height: 0px;
				font-size: 0px;
				padding: 0px;

				img {
					font-size: 0;
				}
			}

			> a {
				overflow: hidden;
				padding: 0;
				text-overflow: ellipsis;
				white-space: nowrap;

				> span {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}

		&__foot {
			${fonts.paragraph.strong};
			background: ${colors.background.background};

			> tr:last-child > th {
				border-bottom: 1px solid ${colors.border.subtle};
			}
			> tr:last-child > td {
				border-bottom: 1px solid ${colors.border.subtle};
			}
		}

		&__footer {
			border-top: 1px solid ${colors.border.subtle};
		}
	}
`;
export default ThemeTable;
