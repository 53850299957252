import moment from 'moment';

import type { FundData } from './types';

const averageField = <K extends string>(
	arr: Array<Record<K, number>>,
	field: K,
): number => (arr.length > 0 ? sumField(arr, field) / arr.length : 0);

const sumField = <K extends string>(
	arr: Array<Record<K, number>>,
	field: K,
): number => arr.reduce((prev, curr) => prev + curr[field], 0);

const newestByQuartersEnd = <T extends { quarter_end?: string }>(
	arr: Array<T>,
): T =>
	arr
		.filter((a) => a.quarter_end)
		.sort((a, b) => moment(b.quarter_end).diff(moment(a.quarter_end)))[0];

const allLogos = (data: FundData): number => data.portfolio_summaries.length;

const activeLogos = (data: FundData): number =>
	data.portfolio_summaries.filter((s) => s.status === 'Active').length;

const averageCheckSize = (data: FundData): number =>
	averageField(data.initial_checks, 'check_size_dollars');

const averageCheckValuation = (data: FundData): number =>
	averageField(data.initial_checks, 'company_value');

const averageCheckOwnership = (data: FundData): number =>
	averageField(data.initial_checks, 'percent_ownership');

const calculateTargetInvestingPace = (
	inceptionDate: moment.Moment,
	fundSize: number,
	targetPaceYears: number,
	targetReservesMultiple: number,
): number =>
	(moment().diff(inceptionDate, 'days') / (targetPaceYears * 365))
	* targetReservesMultiple
	* fundSize;

const dpiDollars = (data: FundData): number =>
	newestByQuartersEnd(data.navs).lp_distribution;

const dpiMultiple = (data: FundData): number =>
	newestByQuartersEnd(data.navs).dpi_estimate;

const formatPercentage = (percent: number): string => `${percent}%`;

const formatQuarter = (quarter: string): string => {
	const date = moment(quarter);
	return `${date.quarter()}Q${date.format('YY')}`;
};

const irrPercentage = (data: FundData): number =>
	newestByQuartersEnd(data.irrs).net_irr;

const retiredLogos = (data: FundData): number =>
	allLogos(data) - activeLogos(data);

const totalInvested = (data: FundData): number =>
	sumField(data.portfolio_summaries, 'invested_dollars');

const totalFMV = (data: FundData): number =>
	sumField(data.portfolio_summaries, 'realized_gain_loss_dollars')
	+ sumField(data.portfolio_summaries, 'book_value_dollars');

const totalMoM = (data: FundData): number =>
	totalFMV(data) / totalInvested(data);

const totalReserves = (data: FundData): number =>
	sumField(data.portfolio_summaries, 'reserved_dollars');

const tvpiMultiple = (data: FundData): number =>
	newestByQuartersEnd(data.navs).tvpi_multiple;

export {
	activeLogos,
	allLogos,
	averageCheckOwnership,
	averageCheckSize,
	averageCheckValuation,
	calculateTargetInvestingPace,
	dpiDollars,
	dpiMultiple,
	formatPercentage,
	formatQuarter,
	irrPercentage,
	retiredLogos,
	totalFMV,
	totalInvested,
	totalMoM,
	totalReserves,
	tvpiMultiple,
};
