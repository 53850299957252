import { TextInput as UnstyledInput } from '@drivecapital/design-system/components';
import pluralize from 'pluralize';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Radio, RadioGroup } from '../../components/radio-group';
import reportError from '../../utils/sentry';

import type {
	FilterControlHeaderProps,
	FilterControlProps,
	IFilterControl,
} from './control';
import { useFilterClearSubscription } from './filter-clear-subscribable';
import FilterControlHeader from './filter-control-header';
import type { IntervalValue, LastRaisedDateFilter } from './types';
import useIntervalFilter from './use-interval-filter';

const InputContainer = styled.div`
	display: flex;
	gap: 8px;
	justify-content: space-between;
	margin-top: 8px;
`;

const TextInput = styled(UnstyledInput)`
	max-width: calc(50% - 8px);
	width: calc(50% - 8px);
`;

function validateInputs(lowerBound: string | null, upperBound: string | null) {
	if (!lowerBound || !upperBound) {
		return true as const;
	}

	const lowerBoundDate = new Date(lowerBound);
	const upperBoundDate = new Date(upperBound);
	if (lowerBoundDate > upperBoundDate) {
		return {
			lowerBoundError: 'Start date must be before end date',
			upperBoundError: 'End date must be after start date',
		};
	}

	return true as const;
}

const FIELD_NAME: LastRaisedDateFilter['fieldName'] = 'lastRaisedDate';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<LastRaisedDateFilter>) {
	const [radioGroupValue, setRadioGroupValue] = useState<string | null>(
		typeof filter?.value === 'number'
			? String(filter.value)
			: filter == null
				? null
				: 'custom',
	);
	const handleRadioGroupChange = useCallback(
		(value: string | null) => {
			if (value == null) {
				onClear(FIELD_NAME);
			} else if (value === 'custom') {
				// Clear the filter only if we're switching to custom from a relative date
				if (typeof filter?.value === 'number') {
					onClear(FIELD_NAME);
				}
			} else {
				onChange({
					fieldName: FIELD_NAME,
					value: parseInt(value, 10),
				});
			}
			setRadioGroupValue(value);
		},
		[filter, onChange, onClear],
	);
	const handleCustomInputSubmit = useCallback(
		(value: IntervalValue<string>) => {
			const { lowerBound, upperBound } = value;

			if (!lowerBound && !upperBound) {
				onClear(FIELD_NAME);
			} else if (lowerBound && !upperBound) {
				onChange({
					fieldName: FIELD_NAME,
					value: { lowerBound, upperBound: null },
				});
			} else if (!lowerBound && upperBound) {
				onChange({
					fieldName: FIELD_NAME,
					value: { lowerBound: null, upperBound },
				});
			} else {
				onChange({ fieldName: FIELD_NAME, value });
			}
		},
		[onChange, onClear],
	);
	const {
		handleBlur,
		handleKeyDown,
		handleLowerBoundChange,
		handleUpperBoundChange,
		lowerBound,
		lowerBoundError,
		upperBound,
		upperBoundError,
	} = useIntervalFilter({
		defaultValue:
			typeof filter?.value === 'number' ? null : filter?.value || null,
		onSubmit: handleCustomInputSubmit,
		validateInputs,
	});

	const handleClear = useCallback(() => {
		setRadioGroupValue(null);
	}, []);
	useFilterClearSubscription(FIELD_NAME, handleClear);

	return (
		<>
			<RadioGroup
				label="For the last..."
				onChange={handleRadioGroupChange}
				value={radioGroupValue}
			>
				<Radio value="30">Past 30 Days</Radio>
				<Radio value="60">Past 60 Days</Radio>
				<Radio value="90">Past 90 Days</Radio>
				<Radio value="365">Past Year</Radio>
				<Radio value="custom">Custom Range</Radio>
			</RadioGroup>
			{radioGroupValue === 'custom' && (
				<InputContainer>
					<TextInput
						label="Start Date"
						onBlur={handleBlur}
						onChange={handleLowerBoundChange}
						onKeyDown={handleKeyDown}
						status={lowerBoundError ? 'danger' : 'default'}
						statusText={lowerBoundError ? lowerBoundError : void 0}
						type="date"
						value={lowerBound == null ? '' : lowerBound}
					/>
					<TextInput
						label="End Date"
						onBlur={handleUpperBoundChange}
						onChange={handleUpperBoundChange}
						onKeyDown={handleKeyDown}
						status={upperBoundError ? 'danger' : 'default'}
						statusText={upperBoundError ? upperBoundError : void 0}
						type="date"
						value={upperBound == null ? '' : upperBound}
					/>
				</InputContainer>
			)}
		</>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<LastRaisedDateFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);

	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={filter != null}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Last Funding Date"
		/>
	);
}

const LastFundingDateFilterControl: IFilterControl<LastRaisedDateFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (typeof filter.value === 'number') {
			return [
				`last raised in the last ${filter.value} ${pluralize(
					'day',
					filter.value,
				)}`,
			];
		}

		if (
			filter.value.lowerBound != null
			&& filter.value.upperBound != null
		) {
			return [
				`last raised between ${filter.value.lowerBound} and ${filter.value.upperBound}`,
			];
		} else if (filter.value.lowerBound != null) {
			return [`last raised on or after ${filter.value.lowerBound}`];
		} else if (filter.value.upperBound != null) {
			return [`last raised on or before ${filter.value.upperBound}`];
		} else {
			reportError(
				new Error(
					`Cannot build description for FoundedYearFilter: ${JSON.stringify(
						filter,
					)}`,
				),
			);
			return [];
		}
	},
	fieldName: 'lastRaisedDate',
	header: Header,
} as const;

export default LastFundingDateFilterControl;
