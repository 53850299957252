import { combineReducers } from 'redux';

import type { Action } from '../../actions';
import type { OnePager, PartialOnePager } from '../types';

import {
	ONEPAGER_DELETE,
	ONEPAGER_LOAD_SUCCESS,
	ONEPAGER_SAVE,
	ONEPAGERS_LOAD_SUCCESS,
} from './constants';

type ByIdState =
	| { loaded: true; onePager: OnePager }
	| { loaded: false; onePager: PartialOnePager };

type OnePagersById = Record<number, ByIdState>;

// Redux reducers take an optional state on first call.
// eslint-disable-next-line @typescript-eslint/default-param-last
function byId(state: OnePagersById = {}, action: Action): OnePagersById {
	switch (action.type) {
		case ONEPAGER_DELETE: {
			const { [action.payload]: _, ...nextState } = state;

			return nextState;
		}
		case ONEPAGER_LOAD_SUCCESS: // Fall through
		case ONEPAGER_SAVE: {
			return {
				...state,
				[action.payload.id]: {
					loaded: true,
					onePager: {
						...state[action.payload.id],
						...action.payload,
					},
				},
			};
		}
		case ONEPAGERS_LOAD_SUCCESS: {
			const nextState = { ...state };

			action.payload.forEach((onePager: PartialOnePager) => {
				nextState[onePager.id] = {
					loaded: state[onePager.id]?.loaded ?? false,
					onePager: {
						...state[onePager.id]?.onePager,
						...onePager,
					},
				} as ByIdState;
			});

			return nextState;
		}
		default:
			return state;
	}
}

// Redux reducers take an optional state on first call.
// eslint-disable-next-line @typescript-eslint/default-param-last
function loaded(state = false, action: Action): boolean {
	switch (action.type) {
		case ONEPAGERS_LOAD_SUCCESS:
			return true;
		default:
			return state;
	}
}

export interface OnePagersState {
	byId: OnePagersById;
	loaded: boolean;
}

export default combineReducers({
	byId,
	loaded,
});
