import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	TextInput,
	Button as ThemeButton,
	ToastMessage,
	useAlert,
} from '@drivecapital/design-system/components';
import { PlusIcon } from '@drivecapital/design-system/icons/system';
import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogTrigger, Popover } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { path as marketMapPath } from '../market-maps';
import { useCreateMarketMap } from '../market-maps/api';
import { trackEvent } from '../utils/analytics';
import { ResponseError } from '../utils/api';
import delay from '../utils/delay';

const Container = styled(Button)`
	align-items: center;
	display: flex;
	justify-content: center;

	&:focus {
		outline: 0;
	}

	svg {
		color: ${colors.icon.secondary};
		height: 36px;
		width: 36px;
	}

	&:hover {
		cursor: pointer;

		svg {
			color: ${colors.icon.secondaryHover};
		}
	}
`;

const animateIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const animateOut = keyframes`
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
`;

const StyledDialogTrigger = styled(DialogTrigger)`
	overflow: inherit;
`;

const StyledPopover = styled(Popover)`
	display: flex;
	flex-direction: column;
	transform-origin: top left;

	&[data-entering] {
		animation: ${animateIn} 0.1s ease-in-out;
	}

	&[data-exiting] {
		animation: ${animateOut} 0.1s ease-in-out;
	}
`;

const StyledDialog = styled(Dialog)`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtleActive};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	outline: none;
	overflow: hidden;
	padding: 16px;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;

	> button {
		align-self: flex-start;
	}
`;
const FormHeader = styled.div`
	${fonts.h4.productive}
	color: ${colors.text.secondary};
	margin-bottom: 8px;
`;

const MESSAGE_DURATION_SECONDS = 3;

interface Props {
	readonly className?: string;
}

type CreationState =
	| { type: 'idle' }
	| { type: 'pending' }
	| { type: 'redirecting'; message: string }
	| { type: 'error'; errorMessage: string };

export default function CreateMarketMap({ className }: Props) {
	const navigate = useNavigate();
	const [alertStyle, openAlert] = useAlert({
		visibleSeconds: MESSAGE_DURATION_SECONDS,
	});
	const [creationState, setCreationState] = useState<CreationState>({
		type: 'idle',
	});

	const { mutateAsync: createMarketMap } = useCreateMarketMap();
	const handleSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const form = event.currentTarget;
			const formData = new FormData(form);
			const name = formData.get('market-map-name') as string;

			try {
				setCreationState({ type: 'pending' });

				const newMap = await createMarketMap(name);

				trackEvent(
					'Create Market Map',
					'landing-page-create-market-map-buttion',
					'home-page',
					{ name },
				);

				setCreationState({
					type: 'redirecting',
					message: `Redirecting you to ${newMap.name}`,
				});
				openAlert();

				await delay(1000);

				navigate(`${marketMapPath}/${newMap.id}/map?editing=true`);
			} catch (error) {
				if (error instanceof Error && error.name === 'AbortError') {
					return;
				}

				if (
					error instanceof ResponseError
					&& error.response.status === 400
				) {
					setCreationState({
						type: 'error',
						errorMessage: 'The name has already been taken',
					});
				} else if (
					error instanceof ResponseError
					&& error.response.status === 403
				) {
					setCreationState({
						type: 'error',
						errorMessage:
							'You do not have permission to create a Market Map',
					});
					openAlert();
				} else {
					setCreationState({
						type: 'error',
						errorMessage:
							'Something went wrong on our end. Please try again later as we look into the issue.',
					});
					openAlert();
				}

				await delay(MESSAGE_DURATION_SECONDS * 1000);
				setCreationState({ type: 'idle' });
			}
		},
		[createMarketMap, navigate, openAlert],
	);

	return (
		<>
			<ToastMessage
				headline={creationState.type === 'error' ? 'Error' : 'Success'}
				message={
					creationState.type === 'error'
						? creationState.errorMessage
						: creationState.type === 'redirecting'
							? creationState.message
							: ''
				}
				style={alertStyle}
				type={creationState.type === 'error' ? 'danger' : 'success'}
			/>
			<StyledDialogTrigger>
				<Container className={className}>
					<PlusIcon />
				</Container>
				<StyledPopover
					offset={4}
					shouldFlip={false}
					placement="bottom start"
				>
					<StyledDialog>
						<Form onSubmit={handleSubmit}>
							<FormHeader>Create a new Market Map</FormHeader>
							<TextInput
								autoFocus
								disabled={
									creationState.type === 'pending'
									|| creationState.type === 'redirecting'
								}
								name="market-map-name"
								placeholder="Market Map name"
								required
								status={
									creationState.type === 'error'
										? 'danger'
										: void 0
								}
								statusText={
									creationState.type === 'error'
										? creationState.errorMessage
										: void 0
								}
							/>
							<ThemeButton
								disabled={
									creationState.type === 'pending'
									|| creationState.type === 'redirecting'
								}
								type="submit"
							>
								Create
							</ThemeButton>
						</Form>
					</StyledDialog>
				</StyledPopover>
			</StyledDialogTrigger>
		</>
	);
}
