/**
 * @file
 * Document icons from [Figma][1].
 *
 * [1]: https://www.figma.com/file/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1%3A5578&mode=dev
 */
import React from 'react';

export function ArticleIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function BookmarkIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<path
				d="M5 2H19C19.5523 2 20 2.44772 20 3V22.1433C20 22.4194 19.7761 22.6434 19.5 22.6434C19.4061 22.6434 19.314 22.6168 19.2344 22.5669L12 18.0313L4.76559 22.5669C4.53163 22.7136 4.22306 22.6429 4.07637 22.4089C4.02647 22.3293 4 22.2373 4 22.1433V3C4 2.44772 4.44772 2 5 2ZM18 4H6V19.4324L12 15.6707L18 19.4324V4Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ChartLineIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M5 3V19H21V21H3V3H5ZM20.2929 6.29289L21.7071 7.70711L16 13.4142L13 10.415L8.70711 14.7071L7.29289 13.2929L13 7.58579L16 10.585L20.2929 6.29289Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function DraggableIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M5.66675 4.6665C6.21903 4.6665 6.66675 4.21879 6.66675 3.6665C6.66675 3.11422 6.21903 2.6665 5.66675 2.6665C5.11446 2.6665 4.66675 3.11422 4.66675 3.6665C4.66675 4.21879 5.11446 4.6665 5.66675 4.6665ZM5.66675 8.99984C6.21903 8.99984 6.66675 8.5521 6.66675 7.99984C6.66675 7.44757 6.21903 6.99984 5.66675 6.99984C5.11446 6.99984 4.66675 7.44757 4.66675 7.99984C4.66675 8.5521 5.11446 8.99984 5.66675 8.99984ZM6.66675 12.3332C6.66675 12.8854 6.21903 13.3332 5.66675 13.3332C5.11446 13.3332 4.66675 12.8854 4.66675 12.3332C4.66675 11.7809 5.11446 11.3332 5.66675 11.3332C6.21903 11.3332 6.66675 11.7809 6.66675 12.3332ZM10.3334 4.6665C10.8857 4.6665 11.3334 4.21879 11.3334 3.6665C11.3334 3.11422 10.8857 2.6665 10.3334 2.6665C9.78115 2.6665 9.33341 3.11422 9.33341 3.6665C9.33341 4.21879 9.78115 4.6665 10.3334 4.6665ZM11.3334 7.99984C11.3334 8.5521 10.8857 8.99984 10.3334 8.99984C9.78115 8.99984 9.33341 8.5521 9.33341 7.99984C9.33341 7.44757 9.78115 6.99984 10.3334 6.99984C10.8857 6.99984 11.3334 7.44757 11.3334 7.99984ZM10.3334 13.3332C10.8857 13.3332 11.3334 12.8854 11.3334 12.3332C11.3334 11.7809 10.8857 11.3332 10.3334 11.3332C9.78115 11.3332 9.33341 11.7809 9.33341 12.3332C9.33341 12.8854 9.78115 13.3332 10.3334 13.3332Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function EnvelopeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM20 7.23792L12.0718 14.338L4 7.21594V19H20V7.23792ZM4.51146 5L12.0619 11.662L19.501 5H4.51146Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function LayoutLeftLineIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 16 16"
			fill="none"
			{...props}
		>
			<path
				d="M14.0026 2C14.3708 2 14.6693 2.29848 14.6693 2.66667V13.3333C14.6693 13.7015 14.3708 14 14.0026 14H2.0026C1.63442 14 1.33594 13.7015 1.33594 13.3333V2.66667C1.33594 2.29848 1.63442 2 2.0026 2H14.0026ZM4.66927 3.33333H2.66927V12.6667H4.66927V3.33333ZM13.3359 3.33333H6.0026V12.6667H13.3359V3.33333Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function MarkerIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.2427 4.51138L8.50547 11.2486L7.79836 13.3699L6.7574 14.4109L9.58583 17.2393L10.6268 16.1983L12.7481 15.4912L19.4853 8.75402L15.2427 4.51138ZM21.6066 8.04692C21.9972 8.43744 21.9972 9.0706 21.6066 9.46113L13.8285 17.2393L11.7071 17.9464L10.2929 19.3606C9.90241 19.7511 9.26925 19.7511 8.87872 19.3606L4.63608 15.118C4.24556 14.7275 4.24556 14.0943 4.63608 13.7038L6.0503 12.2896L6.7574 10.1682L14.5356 2.39006C14.9261 1.99954 15.5593 1.99954 15.9498 2.39006L21.6066 8.04692ZM15.2427 7.33981L16.6569 8.75402L11.7071 13.7038L10.2929 12.2896L15.2427 7.33981ZM4.28253 16.8858L7.11096 19.7142L5.69674 21.1284L1.4541 19.7142L4.28253 16.8858Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function MenuLineIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M3 4H21V6H3V4ZM3 11H15V13H3V11ZM3 18H21V20H3V18Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function Page2Icon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function PageLine3Icon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21 8V20.9932C21 21.5501 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8ZM19 9H14V4H5V20H19V9ZM8 7H11V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function PaperclipIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<path
				d="M14.8287 7.7574L9.1718 13.4143C8.78127 13.8048 8.78127 14.4379 9.1718 14.8285C9.56232 15.219 10.1955 15.219 10.586 14.8285L16.2429 9.17161C17.4144 8.00004 17.4144 6.10055 16.2429 4.92897C15.0713 3.7574 13.1718 3.7574 12.0002 4.92897L6.34337 10.5858C4.39075 12.5384 4.39075 15.7043 6.34337 17.6569C8.29599 19.6095 11.4618 19.6095 13.4144 17.6569L19.0713 12L20.4855 13.4143L14.8287 19.0711C12.095 21.8048 7.66283 21.8048 4.92916 19.0711C2.19549 16.3374 2.19549 11.9053 4.92916 9.17161L10.586 3.51476C12.5386 1.56214 15.7045 1.56214 17.6571 3.51476C19.6097 5.46738 19.6097 8.63321 17.6571 10.5858L12.0002 16.2427C10.8287 17.4143 8.92916 17.4143 7.75759 16.2427C6.58601 15.0711 6.58601 13.1716 7.75759 12L13.4144 6.34319L14.8287 7.7574Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function PencilOutlineIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<path
				d="M15.7279 9.57629L14.3137 8.16207L5 17.4758V18.89H6.41421L15.7279 9.57629ZM17.1421 8.16207L18.5563 6.74786L17.1421 5.33365L15.7279 6.74786L17.1421 8.16207ZM7.24264 20.89H3V16.6474L16.435 3.21233C16.8256 2.8218 17.4587 2.8218 17.8492 3.21233L20.6777 6.04075C21.0682 6.43128 21.0682 7.06444 20.6777 7.45497L7.24264 20.89Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function PencilLineOutlineIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M6.41421 15.89L16.5563 5.74786L15.1421 4.33365L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6474L14.435 2.21233C14.8256 1.8218 15.4587 1.8218 15.8492 2.21233L18.6777 5.04075C19.0682 5.43128 19.0682 6.06444 18.6777 6.45497L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function QuillPenIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M6.93912 14.0327C6.7072 14.6562 6.51032 15.233 6.33421 15.8154C7.29345 15.1188 8.43544 14.6766 9.75193 14.512C12.2652 14.1979 14.4976 12.5384 15.6279 10.4535L14.1721 8.99878L15.5848 7.58407C15.9185 7.24993 16.2521 6.91603 16.5858 6.58237C17.0151 6.15301 17.5 5.35838 18.0129 4.21479C12.4197 5.08172 8.99484 8.50636 6.93912 14.0327ZM17 8.99728L18 9.99658C17 12.9966 14 15.9966 10 16.4966C7.33146 16.8301 5.66421 18.6635 4.99824 21.9966H3C4 15.9966 6 1.99658 21 1.99658C20.0009 4.99392 19.0018 6.99303 18.0027 7.99391C17.6662 8.33038 17.3331 8.66372 17 8.99728Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function TableIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
			<path
				d="M10 7V11H16V7H10ZM8 7H2V11H8V7ZM10 16H16V13H10V16ZM8 16V13H2V16H8ZM10 2V5H16V2H10ZM8 2H2V5H8V2ZM1 0H17C17.5523 0 18 0.44772 18 1V17C18 17.5523 17.5523 18 17 18H1C0.44772 18 0 17.5523 0 17V1C0 0.44772 0.44772 0 1 0Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function TextEditorBoldIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M5.33333 7.33073H8.33333C9.2538 7.33073 10 6.58454 10 5.66406C10 4.74359 9.2538 3.9974 8.33333 3.9974H5.33333V7.33073ZM12 10.3307C12 11.9876 10.6569 13.3307 9 13.3307H4V2.66406H8.33333C9.9902 2.66406 11.3333 4.00721 11.3333 5.66406C11.3333 6.46694 11.0179 7.19613 10.5043 7.7346C11.3986 8.25386 12 9.22206 12 10.3307ZM5.33333 8.66406V11.9974H9C9.92047 11.9974 10.6667 11.2512 10.6667 10.3307C10.6667 9.41026 9.92047 8.66406 9 8.66406H5.33333Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function TextEditorFontColorIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10.1639 9.33333H5.83605L4.76938 12H3.33333L7.33333 2H8.66667L12.6667 12H11.2306L10.1639 9.33333ZM9.6306 8L8 3.92344L6.36938 8H9.6306ZM2 13.3333H14V14.6667H2V13.3333Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function TextEditorItalicIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10.0013 13.3307H4.66797V11.9974H6.61904L8.02963 3.9974H6.0013V2.66406H11.3346V3.9974H9.38357L7.97297 11.9974H10.0013V13.3307Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function TextEditorStrikethroughIcon(
	props: React.SVGProps<SVGSVGElement>,
) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8.66667 5.9974H7.33333V3.9974H3.33333V2.66406H12.6667V3.9974H8.66667V5.9974ZM8.66667 9.9974V13.3307H7.33333V9.9974H8.66667ZM2 7.33073H14V8.66406H2V7.33073Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function TextEditorUnderlineIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M5.33464 2V8C5.33464 9.47273 6.52854 10.6667 8.0013 10.6667C9.47404 10.6667 10.668 9.47273 10.668 8V2H12.0013V8C12.0013 10.2091 10.2104 12 8.0013 12C5.79216 12 4.0013 10.2091 4.0013 8V2H5.33464ZM2.66797 13.3333H13.3346V14.6667H2.66797V13.3333Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function TrashBinIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
				fill="currentColor"
			/>
		</svg>
	);
}
