import React from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';

const WIDTH = 200;

const LineGraphCell = styled.td`
	line-height: 0;
	width: ${WIDTH}px;
`;
type Data = number;
type SortValue = number | null;

export default class LineGraphColumn<T> implements IColumn<T> {
	name: string;
	private readonly select: (row: T) => Array<Data>;
	private readonly sortValue: (val: T) => SortValue;

	constructor({
		name,
		select,
		sortValue,
	}: {
		name: string;
		select: (row: T) => Array<Data>;
		sortValue: (row: T) => SortValue;
	}) {
		this.name = name;
		this.select = select;
		this.sortValue = sortValue;

		this.cell.displayName = 'SparkLineColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ props, row }: CellArgs<T>) => {
		// select should return them in order
		const counts = this.select(row);
		const min = Math.floor(Math.min(...counts) * 0.9);
		const max = Math.ceil(Math.max(...counts) * 1.1);

		return (
			<LineGraphCell {...props}>
				{counts.length > 1 && (
					<Sparklines
						data={counts}
						max={max}
						min={min}
						width={200}
						height={40}
						margin={0}
						preserveAspectRatio="xMidYMid meet"
					>
						<SparklinesLine color="#007DC2" />
					</Sparklines>
				)}
			</LineGraphCell>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		// inline min-width ensures the column width at 200px
		// resizing ignores the cell styles
		// We want "up and to the right" lines first, and in the context
		// on table sorting, "descending" means "highest values"
		return (
			<SortableHeader
				initialSortDirection={sortDirections.descending}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
				style={{ minWidth: `${WIDTH}px` }}
			>
				{this.name}
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aVal: SortValue = this.sortValue(a);
		const bVal: SortValue = this.sortValue(b);

		if (aVal === bVal) return 0;
		if (aVal === null) return 1;
		if (bVal === null) return -1;
		return direction === sortDirections.ascending
			? aVal - bVal
			: bVal - aVal;
	}
}
