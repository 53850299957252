import { TextInput as UnstyledInput } from '@drivecapital/design-system/components';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import reportError from '../../utils/sentry';

import type {
	FilterControlHeaderProps,
	FilterControlProps,
	IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import type { FoundedYearFilter, IntervalValue } from './types';
import useIntervalFilter from './use-interval-filter';

const Container = styled.div`
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;

const TextInput = styled(UnstyledInput)`
	max-width: calc(50% - 8px);
	width: calc(50% - 8px);
`;

function safeParseInt(value: string | null) {
	if (value == null) {
		return null;
	}
	const parsed = parseInt(value, 10);
	return isNaN(parsed) ? null : parsed;
}

const MIN_YEAR = 1900;
function validateInputs(lowerBound: string | null, upperBound: string | null) {
	const lowerBoundInt = safeParseInt(lowerBound);
	const upperBoundInt = safeParseInt(upperBound);
	const lowerBoundValid = !lowerBound || (lowerBoundInt || 0) >= MIN_YEAR;
	const upperBoundValid = !upperBound || (upperBoundInt || 0) >= MIN_YEAR;

	if (lowerBoundValid && upperBoundValid) {
		return true as const;
	} else {
		return {
			lowerBoundError: lowerBoundValid
				? null
				: 'Please enter a valid year',
			upperBoundError: upperBoundValid
				? null
				: 'Please enter a valid year',
		};
	}
}

const FIELD_NAME: FoundedYearFilter['fieldName'] = 'foundedYear';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<FoundedYearFilter>) {
	const onSubmit = useCallback(
		(value: IntervalValue<string>) => {
			const lowerBound = safeParseInt(value.lowerBound);
			const upperBound = safeParseInt(value.upperBound);

			if (!lowerBound && !upperBound) {
				onClear(FIELD_NAME);
				return;
			}

			onChange({
				fieldName: FIELD_NAME,
				value: {
					lowerBound: safeParseInt(value.lowerBound),
					upperBound: safeParseInt(value.upperBound),
				},
			});
		},
		[onChange, onClear],
	);

	const {
		handleBlur,
		handleKeyDown,
		handleLowerBoundChange,
		handleUpperBoundChange,
		lowerBound,
		lowerBoundError,
		upperBound,
		upperBoundError,
	} = useIntervalFilter({
		defaultValue: filter?.value ?? null,
		onSubmit,
		validateInputs,
	});

	return (
		<Container>
			<TextInput
				label="After"
				min={1900}
				onBlur={handleBlur}
				onChange={handleLowerBoundChange}
				onKeyDown={handleKeyDown}
				placeholder="e.g. 2020"
				size={4}
				status={lowerBoundError ? 'danger' : 'default'}
				statusText={
					lowerBoundError ? 'Please enter a valid year' : void 0
				}
				step={1}
				type="number"
				value={lowerBound == null ? '' : lowerBound}
			/>
			<TextInput
				label="Before"
				min={1901}
				onBlur={handleBlur}
				onChange={handleUpperBoundChange}
				onKeyDown={handleKeyDown}
				placeholder="e.g. 2023"
				size={4}
				status={upperBoundError ? 'danger' : 'default'}
				statusText={
					upperBoundError ? 'Please enter a valid year' : void 0
				}
				step={1}
				type="number"
				value={upperBound == null ? '' : upperBound}
			/>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<FoundedYearFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);

	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={filter != null}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Year Founded"
		/>
	);
}

const FoundedYearFilterControl: IFilterControl<FoundedYearFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (
			filter.value.lowerBound != null
			&& filter.value.upperBound != null
		) {
			return [
				`were founded between ${filter.value.lowerBound} and ${filter.value.upperBound}`,
			];
		} else if (filter.value.lowerBound != null) {
			return [`were founded during or after ${filter.value.lowerBound}`];
		} else if (filter.value.upperBound != null) {
			return [`were founded during or before ${filter.value.upperBound}`];
		} else {
			reportError(
				new Error(
					`Cannot build description for FoundedYearFilter: ${JSON.stringify(
						filter,
					)}`,
				),
			);
			return [];
		}
	},
	fieldName: 'foundedYear',
	header: Header,
} as const;

export default FoundedYearFilterControl;
