import React, { type ReactNode } from 'react';

export default function listify(
	strings: Array<string>,
	separator = ',',
): string {
	if (strings.length < 1) {
		return '';
	}

	if (strings.length === 1) {
		return strings[0];
	}

	if (strings.length === 2) {
		return strings.join(' and ');
	}

	return `${strings
		.slice(0, strings.length - 1)
		.join(`${separator} `)}${separator} and ${strings[strings.length - 1]}`;
}

interface Props {
	readonly children: ReactNode;
	readonly separator?: string;
}

export function Listify({
	children,
	separator = ',',
}: Props): React.JSX.Element | null {
	const childArray = React.Children.toArray(children);

	if (childArray.length < 1) {
		return null;
	}
	if (childArray.length === 1) {
		return <React.Fragment>{childArray[0]}</React.Fragment>;
	}
	if (childArray.length === 2) {
		return (
			<React.Fragment>
				{childArray[0]} and {childArray[1]}
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			{React.Children.map(
				childArray.slice(0, childArray.length - 1),
				(element) => (
					// https://reactjs.org/docs/react-api.html#reactchildrentoarray
					// Each element from Children.toArray contains a key,
					// but typescript doesn't know that. Casting to ReactElement
					// lets typescript know that key will for sure exist
					<React.Fragment>
						{element}
						{separator}{' '}
					</React.Fragment>
				),
			)}
			and {childArray[childArray.length - 1]}
		</React.Fragment>
	);
}
