import {
	breakpoints,
	colors,
	effects,
	fonts,
} from '@drivecapital/design-system';
import {
	Chip,
	ControlButton,
	EmptyStatePlaceholder,
	Toggle,
	Search as UnstyledSearchInput,
} from '@drivecapital/design-system/components';
import { QuestionMarkCircleMulticolorIcon } from '@drivecapital/design-system/icons/system';
import { Temporal } from '@js-temporal/polyfill';
import { Set as ImmutableSet } from 'immutable';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';

import Tooltip, { UnstyledTooltipContent } from '../components/tooltip';
import {
	type IColumn,
	StyledTable as Table,
	type TableProps,
	useScrollHistory,
	useSortHistory,
} from '../table';
import SelectionColumn from '../table/columns/multi-select';
import { NonStickyNameColumn } from '../table/columns/name';
import ReactNodeColumn from '../table/columns/react-node';
import { trackEvent } from '../utils/analytics';
import fuzzyFilter from '../utils/fuzzy-filter';
import { useLocalStorage } from '../utils/hooks/use-browser-storage';

import { getRowKey, useOutreachCompanies } from './api';
import ContactsCell from './contacts-cell';
import DismissModal from './dismiss-modal';
import EmptyState from './empty-state';
import KeyboardShortcutExplanation from './keyboard-shortcut-explanation';
import MarketMapsTableCell from './market-maps-table-cell';
import OutreachActionsCell from './outreach-actions-cell';
import SnoozeModal from './snooze-modal';
import StateCell from './state-cell';
import type { CompanyOutreach } from './types';
import { useOpenEmailThread } from './utils';

const columns: IColumn<CompanyOutreach>[] = [
	new SelectionColumn<CompanyOutreach>(),
	new NonStickyNameColumn<CompanyOutreach>({
		name: 'Company',
		select: (row) => ({
			handleMouseDownEvent: () => {
				trackEvent('Open Profile', 'outreach-table', 'home-page', {
					target_profile_type: 'companies',
				});
			},
			href: `/companies/${row.company.id}`,
			imageUrl: row.company.logoUrl,
			name: row.company.name,
		}),
		types: ['company'],
	}),
	new ReactNodeColumn<CompanyOutreach>({
		name: 'Sent',
		select: (companyOutreach) => (
			<StateCell companyOutreach={companyOutreach} />
		),
		sort: (direction, outreachA, outreachB) => {
			const aTimestamp =
				outreachA.state === 'snoozed'
					? outreachA.snoozedUntil
					: outreachA.mostRecentOutreachAt;
			const bTimestamp =
				outreachB.state === 'snoozed'
					? outreachB.snoozedUntil
					: outreachB.mostRecentOutreachAt;

			if (aTimestamp !== null && bTimestamp !== null) {
				const compare = Temporal.ZonedDateTime.compare(
					bTimestamp,
					aTimestamp,
				);
				return direction === 'ascending' ? compare : -compare;
			} else if (aTimestamp == null && bTimestamp !== null) {
				return -1;
			} else if (aTimestamp !== null && bTimestamp == null) {
				return 1;
			} else {
				return 0;
			}
		},
	}),
	new ReactNodeColumn<CompanyOutreach>({
		name: 'Contact',
		select: (companyOutreach) => (
			<ContactsCell companyOutreach={companyOutreach} />
		),
	}),
	new ReactNodeColumn<CompanyOutreach>({
		name: 'Market Maps',
		select: (companyOutreach) => (
			<MarketMapsTableCell companyOutreach={companyOutreach} />
		),
	}),
	new ReactNodeColumn<CompanyOutreach>({
		name: '',
		select: (companyOutreach) => (
			<OutreachActionsCell companyOutreach={companyOutreach} />
		),
	}),
];
const HelpIconTooltip = styled(Tooltip)`
	display: flex;
	justify-content: flex-end;

	&:hover {
		cursor: default;
	}
`;
const HelpIcon = styled(QuestionMarkCircleMulticolorIcon)`
	height: 24px;
	width: 24px;
`;

const Section = styled.div`
	${effects.shadow.shadow};
	background-color: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
	width: 90vw;
`;
const SectionHeader = styled.header`
	${fonts.h4.expressive};
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
	justify-content: space-between;
`;
const SectionHeaderLeft = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
`;
const SectionHeaderRight = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
`;
const Stats = styled.div`
	display: none;

	@media (${breakpoints.lg}) {
		display: block;
	}
`;
const TableControls = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	justify-content: space-between;
`;
const Loading = styled.span`
	${fonts.h5.productive}
	color: ${colors.text.disabled};
`;
const SearchInput = styled(UnstyledSearchInput)`
	width: 320px;
`;

const StyledTable = styled(
	Table as React.ComponentType<TableProps<CompanyOutreach>>,
)`
	.HerbieTable__NameCell {
		color: ${colors.text.primary};

		> a {
			padding: 5px 0px;
		}
	}

	.HerbieTable__header {
		&:nth-child(5) {
			display: none;

			@media (${breakpoints.lg}) {
				display: table-cell;
			}
		}
	}

	.HerbieTable__cell {
		&:nth-child(5) {
			display: none;

			@media (${breakpoints.lg}) {
				display: table-cell;
			}
		}
	}
`;

export default function OutreachSection() {
	const { data, isLoading } = useOutreachCompanies();
	const openEmailThread = useOpenEmailThread();

	const [sort, onSort] = useSortHistory({
		direction: 'descending',
		index: 2, // Sent
	});
	const [scrollX, scrollY, onScroll] = useScrollHistory();

	const [searchFilter, setSearchFilter] = useState('');
	const [dismissalModalOpen, setDismissalModalOpen] = useState(false);
	const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
	const [pendingDismissal, setPendingDismissal] = useState<CompanyOutreach[]>(
		[],
	);
	const [pendingSnooze, setPendingSnooze] = useState<CompanyOutreach[]>([]);
	const handleDismissModalClose = useCallback(() => {
		setDismissalModalOpen(false);
	}, []);
	const handleSnoozeModalClose = useCallback(() => {
		setSnoozeModalOpen(false);
	}, []);
	const handleDismissModalSubmit = useCallback(() => {
		setDismissalModalOpen(false);
		setPendingDismissal([]);
		setSelectedRowKeys(ImmutableSet());
	}, []);
	const handleSnoozeModalSubmit = useCallback(() => {
		setSnoozeModalOpen(false);
		setPendingSnooze([]);
		setSelectedRowKeys(ImmutableSet());
	}, []);

	const companyOutreaches = useMemo(() => data || [], [data]);
	const [selectedRowKeys, setSelectedRowKeys] =
		useState<ImmutableSet<React.Key>>(ImmutableSet());
	const handleRowSelect = useCallback(
		(
			selection:
				| { rowKey: React.Key; selected: boolean }
				| 'ALL'
				| 'NONE',
		) => {
			if (selection === 'ALL') {
				setSelectedRowKeys(
					ImmutableSet(companyOutreaches.map(getRowKey)),
				);
			} else if (selection === 'NONE') {
				setSelectedRowKeys(ImmutableSet());
			} else {
				const { rowKey, selected } = selection;
				setSelectedRowKeys((prevSelectedRowKeys) =>
					selected
						? prevSelectedRowKeys.add(rowKey)
						: prevSelectedRowKeys.remove(rowKey),
				);
			}
		},
		[companyOutreaches],
	);

	const handleSearchFilterChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSearchFilter(event.target.value);
		},
		[],
	);
	const [showMonitoring, setShowMonitoring] = useLocalStorage<boolean>(
		'outreach-upcoming-toggle',
		false,
	);
	const byState = useMemo(
		() =>
			showMonitoring
				? companyOutreaches
				: companyOutreaches.filter(
						(companyOutreach) =>
							companyOutreach.state !== 'monitoring'
							&& companyOutreach.state !== 'snoozed',
					),
		[companyOutreaches, showMonitoring],
	);
	const filtered = useMemo(
		() =>
			searchFilter
				? fuzzyFilter(byState, searchFilter, (companyOutreach) =>
						[
							companyOutreach.company.name,
							...companyOutreach.contacts.map(
								(contact) => contact.name,
							),
							...companyOutreach.contacts.map(
								(contact) => contact.email,
							),
						].join(' '),
					)
				: byState,
		[byState, searchFilter],
	);

	const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

	// We wouldn't normally keep a ref of component state, but must to prevent unnecessary
	// rendering of the <Table />'s rows. _mapKeyToCommand is a dependency of handleFocusedKeyDown
	// which gets passed to each table row. Rows are memoized, so we must ensure handleFocusedKeyDown
	// doesn't change unless strictly necessary. If _mapKeyToCommand relies directly on selectedRowKeys,
	// we re-render the entire table each time a row is [un]selected
	const selectedRowKeysRef = useRef(selectedRowKeys);
	useEffect(() => {
		selectedRowKeysRef.current = selectedRowKeys;
	}, [selectedRowKeys]);

	const handleDismissButtonClick = useCallback(() => {
		const companiesForDismissal = filtered.filter((company) =>
			selectedRowKeysRef.current.has(getRowKey(company)),
		);

		if (companiesForDismissal.length > 0) {
			setPendingDismissal(companiesForDismissal);
			setDismissalModalOpen(true);
			trackEvent('Dismiss Outreach', 'outreach-table', 'home-page', {
				trigger: 'button',
			});
		}
	}, [filtered]);

	const potentialCompaniesForSnoozing = useMemo(
		() =>
			filtered.filter((outreach) => {
				if (outreach.state === 'snoozed') return false;
				return selectedRowKeys.has(getRowKey(outreach));
			}),
		[filtered, selectedRowKeys],
	);
	const handleSnoozeButtonClick = useCallback(() => {
		if (potentialCompaniesForSnoozing.length > 0) {
			setPendingSnooze(
				potentialCompaniesForSnoozing.filter(({ id }) => id),
			);
			setSnoozeModalOpen(true);
			trackEvent('Snooze Outreach', 'outreach-table', 'home-page', {
				trigger: 'button',
			});
		}
	}, [potentialCompaniesForSnoozing]);

	const _mapKeyToCommand = useCallback(
		(key: string, row: CompanyOutreach | null) => {
			switch (key) {
				case 'ArrowDown':
				case 'j':
				case 'J': {
					setFocusedIndex((currentFocusedIndex) =>
						currentFocusedIndex == null
							? 0
							: Math.min(
									currentFocusedIndex + 1,
									filtered.length - 1,
								),
					);
					break;
				}
				case 'ArrowUp':
				case 'k':
				case 'K': {
					setFocusedIndex((currentFocusedIndex) =>
						currentFocusedIndex == null
							? null
							: Math.max(currentFocusedIndex - 1, 0),
					);
					break;
				}
				case 'e':
				case 'E': {
					const companiesForDismissal = ImmutableSet<React.Key>(
						row ? [getRowKey(row)] : [],
					).concat(selectedRowKeysRef.current);

					if (companiesForDismissal.size > 0) {
						setPendingDismissal(
							filtered.filter((company) =>
								companiesForDismissal.has(getRowKey(company)),
							),
						);
						setDismissalModalOpen(true);
						trackEvent(
							'Dismiss Outreach',
							'outreach-table',
							'home-page',
							{
								trigger: 'keyboard-shortcut',
							},
						);
					}
					break;
				}
				case 'Escape': {
					setFocusedIndex(null);
					break;
				}
				case 'h':
				case 'H': {
					const companyKeysForSnooze = ImmutableSet<React.Key>(
						row ? [getRowKey(row)] : [],
					).concat(selectedRowKeysRef.current);
					const companiesForSnooze = filtered.filter((outreach) => {
						if (outreach.state === 'snoozed') return false;
						return companyKeysForSnooze.has(getRowKey(outreach));
					});

					if (companiesForSnooze.length > 0) {
						setPendingSnooze(
							companiesForSnooze.filter(({ id }) => id),
						);
						setSnoozeModalOpen(true);
						trackEvent(
							'Snooze Outreach',
							'outreach-table',
							'home-page',
							{
								trigger: 'keyboard-shortcut',
							},
						);
					}
					break;
				}
				case 'o':
				case 'O': {
					if (!row) return;
					openEmailThread(
						row.id,
						row.outreachLinkBase,
						true,
						'outreach-table',
					);
					break;
				}
				case 'x':
				case 'X': {
					if (!row) return;
					const rowKey = getRowKey(row);
					setSelectedRowKeys((prevSelectedRowKeys) =>
						prevSelectedRowKeys.has(rowKey)
							? prevSelectedRowKeys.remove(rowKey)
							: prevSelectedRowKeys.add(rowKey),
					);
					break;
				}
				default:
					return;
			}
		},
		[filtered, openEmailThread],
	);

	const handleFocusedKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLElement>, row: CompanyOutreach) => {
			if (event.altKey || event.ctrlKey || event.metaKey) {
				return;
			}
			_mapKeyToCommand(event.key, row);
		},
		[_mapKeyToCommand],
	);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (!event?.target) return;
			if (event.altKey || event.ctrlKey || event.metaKey) {
				return;
			}
			if (filtered.length === 0) return;
			if (
				(event.target as HTMLElement).tagName?.toLowerCase() !== 'body'
			) {
				return;
			}
			_mapKeyToCommand(event.key, null);
		};

		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [_mapKeyToCommand, filtered]);

	return (
		<>
			<DismissModal
				isOpen={dismissalModalOpen}
				onClose={handleDismissModalClose}
				onSubmit={handleDismissModalSubmit}
				companyOutreaches={pendingDismissal}
			/>
			<SnoozeModal
				isOpen={snoozeModalOpen}
				onClose={handleSnoozeModalClose}
				onSubmit={handleSnoozeModalSubmit}
				companyOutreaches={pendingSnooze}
			/>
			<Section>
				<SectionHeader>
					<SectionHeaderLeft>
						Sourcing Follow Ups
						<Chip
							background={colors.data.dust.layer}
							color={colors.text.secondary}
							label={filtered.length.toString()}
						/>
						{potentialCompaniesForSnoozing.length > 0 && (
							<ControlButton
								active={false}
								onClick={handleSnoozeButtonClick}
								variant="secondary"
							>
								Follow Up Later
							</ControlButton>
						)}
						{selectedRowKeys.size > 0 && (
							<ControlButton
								active={false}
								onClick={handleDismissButtonClick}
								variant="warning"
							>
								Dismiss
							</ControlButton>
						)}
					</SectionHeaderLeft>
					<SectionHeaderRight>
						<HelpIconTooltip
							content={
								<UnstyledTooltipContent placement="left bottom">
									<KeyboardShortcutExplanation />
								</UnstyledTooltipContent>
							}
							delay={100}
						>
							<HelpIcon
								shapeColor={colors.icon.onColor}
								surroundColor={colors.icon.helper}
							/>
						</HelpIconTooltip>
					</SectionHeaderRight>
				</SectionHeader>
				<Stats />
				<TableControls>
					<SearchInput
						onChange={handleSearchFilterChange}
						placeholder="Find a company, person, or email"
						value={searchFilter}
					/>
					<Toggle
						checked={showMonitoring}
						checkedLabel="Upcoming visible"
						onChange={setShowMonitoring}
						uncheckedLabel="Upcoming hidden"
					/>
				</TableControls>
				{filtered.length === 0 && isLoading && (
					<Loading>Loading...</Loading>
				)}
				{filtered.length === 0 && !isLoading && searchFilter === '' && (
					<EmptyState />
				)}
				{filtered.length === 0 && !isLoading && searchFilter !== '' && (
					<EmptyStatePlaceholder>
						No results matching "{searchFilter}".{' '}
						{!showMonitoring && (
							<>
								<br />
								Maybe it's upcoming? Try toggling the switch
								above.
							</>
						)}
					</EmptyStatePlaceholder>
				)}
				{filtered.length > 0 && (
					<StyledTable
						columns={columns}
						data={filtered}
						focusedIndex={focusedIndex}
						getRowKey={getRowKey}
						initialScrollX={scrollX}
						initialScrollY={scrollY}
						initialSort={sort}
						onFocus={setFocusedIndex}
						onRowKeyDown={handleFocusedKeyDown}
						onScroll={onScroll}
						onSelect={handleRowSelect}
						onSort={onSort}
						selectedRowKeys={selectedRowKeys}
					/>
				)}
			</Section>
		</>
	);
}
