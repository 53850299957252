import React from 'react';
import styled from 'styled-components';

import { charcoalGray } from '../../colors';

const Button = styled.button`
	background-color: transparent;
	border: none;
	color: ${charcoalGray.lighten(0.5).string()};
	cursor: pointer;
	outline: none;
	padding: 3px 6px;
	transition: color 100ms linear;

	&:focus,
	&:hover {
		color: ${charcoalGray.string()};
	}

	&:active {
		color: ${charcoalGray.darken(0.2).string()};
		transition-duration: 0s;
	}
`;

const Icon = styled.svg`
	fill: currentColor;
	height: 24px;
	vertical-align: middle;
	width: 24px;
`;

type Props = {
	readonly onDownload: () => void;
	readonly className?: string;
};

const DownloadButton = ({ onDownload, ...props }: Props): JSX.Element => (
	<Button title="Download CSV" onClick={onDownload} {...props}>
		<Icon
			xmlns="http://www.w3.org/2000/svg"
			version="1.0"
			viewBox="0 0 24 24"
		>
			<path
				d="M19.8,10.1C18.9,6.6,15.8,4,12,4C9,4,6.4,5.6,5,8.1C2.2,8.6,0,11,0,14c0,3.3,2.7,6,6,6c1,0,11.9,0,13,0c2.8,0,5-2.2,5-5 C24,12.5,22.2,10.4,19.8,10.1z M10,13V9h4v4h3l-5,5l-5-5H10z"
				fill="currentColor"
			/>
		</Icon>
	</Button>
);

export default DownloadButton;
