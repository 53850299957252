import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	Dialog as AriaDialog,
	Heading as AriaHeading,
	Popover as AriaPopover,
} from 'react-aria-components';
import styled from 'styled-components';

export const Dialog = styled(AriaDialog)`
	${effects.shadow.hover}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 6px 0;
`;
export const Heading = styled(AriaHeading)`
	${fonts.label.strong};
	color: ${colors.text.secondary};
	display: flex;
	padding: 0 8px;

	& > span {
		border-bottom: 1px solid ${colors.border.subtle};
		flex: 1;
	}
`;
export const Popover = styled(AriaPopover)<{ $isOpen?: boolean }>`
	${({ $isOpen = true }) => !$isOpen && 'display: none;'}
`;
