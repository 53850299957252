export const GROWTH_COMPANY_NOTES = {
	label: 'Growth Company Call Template',
	template: `
|Year|Top-Line Metric (GAAP/ARR/Bookings)|GAAP Revenue|GM|Burn|
|2023| $12M ARR | $10M | 45% | ($1.2M) |
|2024| | | | |
|2025| | | | |
`,
};

export const USER_TEMPLATES = {
	'cameron-clarke': `INTRO

I’m Cameron, a Partner at Drive Capital focused on investments into AI infra and LLMops. By way of background, I did research and built software at Uber, and also have experience as a founder.

A bit about Drive:

Based in the US, out of Columbus, OH. Largest venture firm between the Coasts of the US

- 2014 - raised $250mm for the first venture fund
- 2017 - raised $300mm for the second venture fund
- 2019 - raised $650mm across our third set of funds, a third venture fund and a first time expansion stage fund
- 2022 - raised a little over $1bn across our fourth set of funds, a fourth venture fund and a second expansion stage fund

—

PAUSE FOR [FOUNDER] INTRO

—

[ ] What drove you to start the company?
[ ] Where are you with company building right now?
[ ] Where is the team based?
[ ] How big do you estimate the market that you’re going after to be?
[ ] Business model?
[ ] Product
[ ] Pricing
[ ] Revenue
[ ] Who are some of the other players in the space? How are you different?
[ ] What growth directions do you want to tackle next?
[ ] How are you thinking about margins and unit economics for your offering?
[ ] What are your fundraising goals at present?
[ ] Do you have a deck?`,
} as const satisfies Record<string, string>;
