import React, { useMemo } from 'react';
import type { Key, ReactNode } from 'react';

import { type IColumn, isStickyLeft } from './column';

function transpose<T>(table: Array<Array<T>>): Array<Array<T>> {
	return table[0].map((_, index) => table.map((row) => row[index]));
}

interface Props<T> {
	readonly columns: Array<IColumn<T>>;
	readonly footers?: Array<string>;
	readonly getStickyProps: (
		column: IColumn<T>,
		columnIndex: number,
		className: string,
		key?: Key,
	) => Record<string, unknown>;
	readonly rows: Array<T>;
}

function Footers<T>({
	columns,
	footers: footerNamesProp,
	getStickyProps,
	rows,
}: Props<T>): JSX.Element | null {
	const footerNames = useMemo(
		() =>
			footerNamesProp
			|| (columns.some((column) => column.footer) ? [''] : []),
		[footerNamesProp, columns],
	);
	/**Please note - Interspersing sticky & non-sticky columns doesn't work
	 * (e.g. | Image (sticky) | Text (non-sticky) | Name (sticky) |) as this
	 * simply counts the number of sticky columns, not the indicies of the
	 * sticky columns. It does, however, allow for all columns to be sticky
	 * columns.*/
	const stickyColumnCount = columns.filter((column) =>
		isStickyLeft(column),
	).length;

	/*
	 * In the case of multiple footers, each column returns an object mapping
	 * footer name to the corresponding React element. We map over `footerNames`
	 * to select the cells in the correct order. The result of that mapping is
	 * an array oriented vertically along the column axis. We need to render
	 * footer cells into a `<tr>` along the row axis. This transposes the
	 * footers from the column axis to the row axis. For example, in a 5-column
	 * table with 2 footer rows, we'll start with an outer length-5 array where
	 * each element is an inner length-2 array containing each column's footers.
	 * We transpose that into an outer length-2 array where each element is an
	 * inner length-5 array containing each row's footers.
	 */
	const footerElements = transpose([
		footerNames.map((name) => (
			<th
				className="HerbieTable__footer HerbieTable__footer--sticky-left"
				colSpan={stickyColumnCount}
				key={name}
				style={{
					left: 0,
				}}
			>
				{name}
			</th>
		)),
		...columns.slice(stickyColumnCount).map((column, columnIndex) => {
			const key: Key = columnIndex + stickyColumnCount;
			const props = getStickyProps(
				column,
				columnIndex + stickyColumnCount,
				'HerbieTable__footer',
				key,
			);

			if (!column.footer) {
				// For some reason typescript cant infer type of arrays
				// with .fill()
				return new Array<JSX.Element>(footerNames.length).fill(
					<th {...props} />,
				);
			}

			const footer = column.footer({ props, rows });
			if (React.isValidElement(footer)) {
				return footerNames.map((name) =>
					name === '' ? footer : <th {...props} key={key} />,
				);
			} else {
				const elements = footer as {
					[name: string]: ReactNode;
				};
				return footerNames.map(
					(name) => elements[name] || <th {...props} key={key} />,
				);
			}
		}),
	]);

	if (footerNames.length <= 0) return null;

	return (
		<tfoot className="HerbieTable__foot">
			{footerNames.map((name: string, index: number) => (
				<tr className="HerbieTable__row" key={name}>
					{footerElements[index]}
				</tr>
			))}
		</tfoot>
	);
}

export default React.memo(Footers);
