import React, { lazy } from 'react';

import authorizedRoute from '../components/authorized-route';

import { loadBoxDataRoom, loadNDA } from './api';
import { LoaderErrorFallback as ErrorFallback } from './error-fallback';

const loadComponent = async () => import('./view');

const LPDataroomView = lazy(loadComponent);

async function loader() {
	// This endpoint hits the Box API, meaning it takes longer, so we don't want
	// it to block the initial render. We'll fire off the request now to give it
	// a head start, but we won't await it.
	void loadBoxDataRoom();

	return Promise.all([loadComponent(), loadNDA()]);
}

export const routes = [
	authorizedRoute(
		{
			path: 'dataroom',
			element: <LPDataroomView />,
			loader,
			errorElement: <ErrorFallback />,
		},
		'contacts.dataroom_for_lps',
	),
];
