import { type ReactNode, useEffect, useState } from 'react';

export interface Props {
	readonly children: ReactNode;
	/**
	 * Wait `delay` ms before showing the loader to avoid flashing it briefly
	 * on quick-loading components.
	 */
	readonly delay?: number;
}

/**
 * Show a time-delayed suspense fallback.
 *
 * A `<Suspense>` boundary [1] shows a loading state when any of its children
 * are waiting for data. Most of the time, we hope data loads quickly, in which
 * case briefly flashing the loading state would be jarring. Instead, wait
 * `delay` ms (300 by default) before showing the loading state. If data comes
 * back before the delay, the loading state will never be shown, and the user
 * will feel like [2] the system responded near-instantaneously despite the
 * delay. If data still isn't available after the delay, show the loading state
 * to demonstrate to the user that the system is still responsive.
 *
 * [1]: https://react.dev/reference/react/Suspense
 * [2]: https://www.nngroup.com/articles/response-times-3-important-limits/
 */
export default function SuspenseFallback({ children, delay = 300 }: Props) {
	const [show, setShow] = useState(false);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setShow(true);
		}, delay);
		return () => clearTimeout(timeout);
	}, [delay]);

	return show ? children : null;
}
