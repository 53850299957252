import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ChevronUpIcon } from '../icons/arrows';

import IconButton from './icon-button';

export type ArrowDirection = 'DOWN' | 'UP';
export type TurnDirection = 'CLOCKWISE' | 'COUNTERCLOCKWISE';

export const toggleDirection = (direction: ArrowDirection) =>
	direction === 'DOWN' ? 'UP' : 'DOWN';

interface Props {
	readonly className?: string;
	readonly onClick: (event: React.MouseEvent) => void;
	readonly direction: ArrowDirection;
	readonly size?: React.ComponentPropsWithoutRef<typeof IconButton>['size'];
	readonly turnDirection?: TurnDirection;
}

export function useToggleArrow(
	initialDirection: ArrowDirection = 'UP',
): [ArrowDirection, () => void] {
	const [direction, setDirection] =
		useState<ArrowDirection>(initialDirection);
	const onClick = useCallback(() => {
		setDirection(toggleDirection);
	}, []);

	return [direction, onClick];
}

const Icon = styled(ChevronUpIcon)<{ $rotateDirection: string }>`
	rotate: ${({ $rotateDirection }) => $rotateDirection};
	transition: 0.25s ease-in-out;
`;

export function ToggleArrow({
	className,
	onClick,
	direction,
	size = 'default',
	turnDirection = 'CLOCKWISE',
}: Props) {
	let rotateDirection;
	if (direction === 'UP') {
		rotateDirection = '0deg';
	} else if (direction === 'DOWN' && turnDirection === 'CLOCKWISE') {
		rotateDirection = '180deg';
	} else {
		rotateDirection = '-180deg';
	}
	return (
		<IconButton
			className={className}
			label="toggle-arrow"
			onClick={onClick}
			size={size}
		>
			<Icon $rotateDirection={rotateDirection} />
		</IconButton>
	);
}
