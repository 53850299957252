import React from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import * as colors from '../colors';
import useGet from '../utils/hooks/use-get';

import { ResponsiveChart } from './components';
import { formatQuarter } from './utils';

interface DataFix {
	quarter: string;
	person: number;
	company: number;
}

interface Props {
	readonly endDate: string;
	readonly startDate: string;
}

const tooltipLabelFormatter = (label: string | number): React.ReactNode => {
	if (typeof label === 'string') return formatQuarter(label);
	return label;
};

export default function DataFixes({ startDate, endDate }: Props): JSX.Element {
	const [data, Loader] = useGet<Array<DataFix>>(
		'/internal_dashboards/herbie_data_fixes',
		{ start_date: startDate, end_date: endDate },
	);

	if (!data) return <Loader />;

	return (
		<ResponsiveChart>
			<BarChart
				data={data}
				margin={{ top: -15, right: 5, left: -20, bottom: 0 }}
			>
				<Tooltip
					cursor={{ fill: colors.lightGray.lighten(0.15).string() }}
					labelFormatter={tooltipLabelFormatter}
				/>
				<Legend verticalAlign="top" height={28} align="right" />
				<CartesianGrid vertical={false} strokeDasharray="4" />
				<XAxis dataKey="quarter" tickFormatter={formatQuarter} />
				<YAxis />
				<Bar
					dataKey="person"
					name="Person"
					stackId="only"
					fill={colors.primaryDriveBlue.string()}
				/>
				<Bar
					dataKey="company"
					name="Company"
					stackId="only"
					fill={colors.driveGreen.string()}
				/>
			</BarChart>
		</ResponsiveChart>
	);
}
