import React, { lazy } from 'react';

import authorizedRoute from '../components/authorized-route';

const View = lazy(async () => import('./view'));

export const routes = authorizedRoute(
	{
		path: 'kpi',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	'contacts.kpi',
);
