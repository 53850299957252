import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { primaryDriveBlue } from '../colors';

const A = styled.a`
	color: ${primaryDriveBlue.string()};
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

interface Props extends Omit<React.ComponentProps<typeof A>, 'rel' | 'target'> {
	readonly children?: ReactNode;
	readonly href: string;
}

const ExternalLink = ({ children, href, ...props }: Props): JSX.Element => (
	<A {...props} href={href} rel="noopener noreferrer" target="_blank">
		{children}
	</A>
);

export default ExternalLink;
