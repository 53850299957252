import React, { useCallback } from 'react';
import type { Key } from 'react';

import './table.scss';
import type { AddPickerResult, IColumn } from './column';
import type { Sort, SortDirection } from './sort';

function Header<T>({
	column,
	columnIndex,
	itemsSelected,
	onAdd,
	onSort: onSortProp,
	onSelect,
	props,
	sort,
	sortIndex,
}: {
	readonly column: IColumn<T>;
	readonly columnIndex: number;
	readonly itemsSelected: 'NONE' | 'SOME' | 'ALL';
	readonly onAdd?: (value: AddPickerResult) => void;
	readonly onSelect?: (selection: 'ALL' | 'NONE') => void;
	readonly onSort: (index: number, direction: SortDirection) => void;
	readonly props: Record<string, unknown>;
	readonly sort: SortDirection | null;
	readonly sortIndex: number | null;
}): JSX.Element {
	const onSort = useCallback(
		(direction: SortDirection) => onSortProp(columnIndex, direction),
		[onSortProp, columnIndex],
	);

	if (!column.header) {
		return <th {...props} />;
	}

	return column.header({
		itemsSelected,
		onAdd,
		onSelect,
		onSort,
		props,
		sort,
		sortIndex,
	});
}

interface Props<T> {
	readonly columns: Array<IColumn<T>>;
	readonly getStickyProps: (
		column: IColumn<T>,
		columnIndex: number,
		className: string,
	) => Record<string, unknown>;
	readonly itemsSelected: 'NONE' | 'SOME' | 'ALL';
	readonly onAdd?: (value: AddPickerResult) => void;
	readonly onSort: (index: number, direction: SortDirection) => void;
	readonly onSelect?: (selection: 'ALL' | 'NONE') => void;
	readonly sort: Array<Sort>;
}

function Headers<T>({
	columns,
	getStickyProps,
	itemsSelected,
	onAdd,
	onSelect,
	onSort,
	sort,
}: Props<T>): JSX.Element {
	return (
		<thead className="HerbieTable__head">
			<tr className="HerbieTable__row">
				{columns.map((column, columnIndex) => {
					const sortIndex = sort.findIndex(
						(s) => s.index === columnIndex,
					);
					const direction = sort[sortIndex]?.direction || null;
					return (
						<Header
							column={column}
							columnIndex={columnIndex}
							itemsSelected={itemsSelected}
							key={columnIndex as Key}
							onAdd={onAdd}
							onSelect={onSelect}
							onSort={onSort}
							props={getStickyProps(
								column,
								columnIndex,
								'HerbieTable__header',
							)}
							sort={direction}
							sortIndex={
								sort.length > 1 && sortIndex !== -1
									? sortIndex + 1
									: null
							}
						/>
					);
				})}
			</tr>
		</thead>
	);
}

export default React.memo(Headers);
