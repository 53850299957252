import styled from 'styled-components';

import { colors, effects, fonts } from '..';

const TooltipCard = styled.div`
	${effects.shadow.hover}
	background-color: ${colors.card.card};
	border-radius: 8px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 250px;
	padding: 8px 12px;
`;
const TooltipTitle = styled.span`
	${fonts.paragraph.strong};
	color: ${colors.text.primary};
`;
const TooltipSubTitle = styled.span`
	${fonts.paragraph.paragraph};
	color: ${colors.text.secondary};
`;

export { TooltipCard, TooltipTitle, TooltipSubTitle };
