import React, { useCallback } from 'react';

import { useUser } from '../../authentication';
import { Radio, RadioGroup } from '../../components/radio-group';
import reportError from '../../utils/sentry';

import type {
	FilterControlHeaderProps,
	FilterControlProps,
	IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import type { ContactedFilter } from './types';

const FIELD_NAME: ContactedFilter['fieldName'] = 'contacted';
function FilterControl({
	filter,
	onChange,
}: FilterControlProps<ContactedFilter>) {
	const user = useUser();
	const tenantName = user.tenant?.name;

	const handleValueChange = useCallback(
		(value: string | null) => {
			switch (value) {
				case 'ME':
					onChange({
						fieldName: FIELD_NAME,
						negated: false,
						value,
					});
					break;
				case 'NOT_ME':
					onChange({
						fieldName: FIELD_NAME,
						negated: true,
						value: 'ME',
					});
					break;
				case 'TENANT':
					onChange({
						fieldName: FIELD_NAME,
						negated: false,
						value,
					});
					break;
				case 'NOT_TENANT':
					onChange({
						fieldName: FIELD_NAME,
						negated: true,
						value: 'TENANT',
					});
					break;
				default:
				// do nothing
			}
		},
		[onChange],
	);

	let value: string | null;
	if (filter == null) {
		value = null;
	} else if (filter.value === 'ME' && !filter.negated) {
		value = 'ME';
	} else if (filter.value === 'ME' && filter.negated) {
		value = 'NOT_ME';
	} else if (filter.value === 'TENANT' && !filter.negated) {
		value = 'TENANT';
	} else {
		value = 'NOT_TENANT';
	}

	return (
		<RadioGroup
			label="Contact with"
			onChange={handleValueChange}
			value={value}
		>
			<Radio value="ME">Contacted by me</Radio>
			<Radio value="NOT_ME">Not contacted by me</Radio>
			{tenantName != null && (
				<Radio value="TENANT">
					Contacted by anyone at {tenantName}
				</Radio>
			)}
			{tenantName != null && (
				<Radio value="NOT_TENANT">
					Not contacted by anyone at {tenantName}
				</Radio>
			)}
		</RadioGroup>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<ContactedFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);
	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Contacted"
		/>
	);
}

const ContactedFilterControl: IFilterControl<ContactedFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (filter.value === 'ME' && !filter.negated) {
			return ['have been contacted by you'];
		} else if (filter.value === 'ME' && filter.negated) {
			return ['have not been contacted by you'];
		} else if (filter.value === 'TENANT' && !filter.negated) {
			return ['have been contacted by anyone at your company'];
		} else if (filter.value === 'TENANT' && filter.negated) {
			return ['have not been contacted by anyone at your company'];
		} else {
			reportError(
				new Error(
					`Cannot build description for ContactedFilter: ${JSON.stringify(
						filter,
					)}`,
				),
			);
			return [];
		}
	},
	header: Header,
	fieldName: FIELD_NAME,
} as const;

export default ContactedFilterControl;
