import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { googleOAuthClientId } from '../config';
import { trackEvent } from '../utils/analytics';
import type { EventName, ViewType } from '../utils/analytics';
import { post } from '../utils/api';
import type { ResponseError } from '../utils/api';
import useAbortSignal from '../utils/hooks/use-abort-signal';
import reportError from '../utils/sentry';

import { isEnabled } from './google';

const Container = styled.div`
	text-align: center;
	width: 100%;
`;

const ErrorMessage = styled.div`
	text-align: center;
	color: red;
	padding: 10px;
`;

const SignInButton = styled.div`
	height: 44px;
	& > div {
		padding: 2px 0;
	}
`;

const defaultButtonOptions: google.accounts.id.GsiButtonConfiguration = {
	type: 'standard',
	width: 315,
	size: 'large',
	logo_alignment: 'center',
};

let signInHookCurrentlyInUse = false;
export function useGoogleSignIn({
	analyticsEventName,
	analyticsViewType,
	buttonOptions = defaultButtonOptions,
	onSuccess,
}: {
	analyticsEventName: EventName;
	analyticsViewType: ViewType;
	buttonOptions?: google.accounts.id.GsiButtonConfiguration;
	onSuccess: (apiJWT: string) => void;
}):
	| {
			enabled: true;
			renderButton: (node: HTMLDivElement | null) => void;
			signInError: string | null;
	  }
	| {
			enabled: false;
	  } {
	const [renderFailed, setRenderFailed] = useState(false);
	const signal = useAbortSignal();
	const [signInError, setSignInError] = useState<string | null>(null);
	const apiLogin = useCallback(
		({ credential }: google.accounts.id.CredentialResponse) => {
			post<{ token: string }>('/auth/google-sign-in', {
				body: { token: credential },
				signal,
			})
				.then(({ token }) => {
					trackEvent(
						analyticsEventName,
						'google-sign-in',
						analyticsViewType,
						{
							source: 'herbie',
							success: true,
						},
					);
					onSuccess(token);
				})
				.catch((error: ResponseError) => {
					if (error.name !== 'AbortError') {
						reportError(error);
						trackEvent(
							analyticsEventName,
							'google-sign-in',
							analyticsViewType,
							{
								source: 'herbie',
								success: false,
							},
						);

						if (error.response) {
							if (error.response.status === 404) {
								setSignInError(
									'No account matches that email. Email herbie-support@drivecapital.com to request an account.',
								);
							} else {
								void error.response
									.json()
									.then((data: { message: string }) =>
										setSignInError(
											data.message
												? data.message
												: 'Email herbie-support@drivecapital.com to request an account',
										),
									);
							}
						} else {
							setSignInError(
								'Error logging in. Please refresh the page and try again',
							);
						}
					}
				});
		},
		[analyticsEventName, analyticsViewType, onSuccess, signal],
	);
	const signInButtonParent = useRef<HTMLDivElement | null>(null);
	const renderSignInButton = useCallback(
		(node: HTMLDivElement | null) => {
			if (!isEnabled) return;
			if (!node) return;
			if (!signInButtonParent.current) {
				// https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize
				// Unfortunately, this configuration is global and can only be initialized once. If we call this multiple
				// times, the most recent call takes precedence. Rather than try to support simultaneous use of this hook,
				// we'll warn our future selves that to use this hook in multiple places, we must figure something else out
				if (signInHookCurrentlyInUse) {
					throw new Error(
						'Multiple uses of component GoogleSignIn detected',
					);
				}
				google.accounts.id.initialize({
					client_id: googleOAuthClientId,
					ux_mode: 'popup',
					callback: apiLogin,
				});
				signInHookCurrentlyInUse = true;
			}
			if (node !== signInButtonParent.current) {
				signInButtonParent.current = node;
				try {
					google.accounts.id.renderButton(
						signInButtonParent.current,
						buttonOptions,
					);
				} catch (error) {
					setRenderFailed(true);
					reportError(error);
				}
			}
		},
		[apiLogin, buttonOptions],
	);

	useEffect(() => {
		return () => {
			signInHookCurrentlyInUse = false;
		};
	}, []);

	if (isEnabled && !renderFailed) {
		return {
			enabled: true,
			renderButton: renderSignInButton,
			signInError,
		};
	} else {
		return {
			enabled: false,
		};
	}
}

interface Props {
	readonly errorMessage: string | null;
	readonly renderButton: (node: HTMLDivElement | null) => void;
}

export default function GoogleSignIn({
	errorMessage,
	renderButton,
}: Props): JSX.Element {
	return (
		<Container>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
			<SignInButton ref={renderButton} />
		</Container>
	);
}
