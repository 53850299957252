import {
	QueryClient,
	type QueryKey,
	type UseQueryOptions,
} from '@tanstack/react-query';
import querystring from 'querystring';

import { deleteToken, login as loginAction } from '../authentication';
import store from '../store';

import reportError from './sentry';

// https://datatracker.ietf.org/doc/html/rfc9457
// Matches server side definition
export type RFC9457ErrorDetail = {
	type: string;
	title: string;
	status: number;
	detail: string;
};

const RFC9457ErrorDetailKeys: Array<keyof RFC9457ErrorDetail> = [
	'type',
	'title',
	'status',
	'detail',
];

function dataIsRFC9457ErrorDetail(data: unknown): data is RFC9457ErrorDetail {
	if (typeof data !== 'object' || data == null) {
		return false;
	}

	const dataObject = data as Record<string, unknown>;
	return RFC9457ErrorDetailKeys.every(
		(key) => key in dataObject && dataObject[key] != null,
	);
}

export const queryClient = new QueryClient();

const getCsrfToken = () => {
	const name = 'csrftoken';
	const cookies = document.cookie.split(';').map((cookie) => cookie.trim());

	for (const cookie of cookies) {
		if (cookie.startsWith(`${name}=`)) {
			return decodeURIComponent(cookie.slice(name.length + 1));
		}
	}

	return null;
};

export class ResponseError<TErrorData = unknown> extends Error {
	response: Response;
	data?: TErrorData;

	constructor(response: Response, data?: TErrorData) {
		super(response.statusText);
		this.response = response;
		this.data = data;
	}

	get rfc9457(): RFC9457ErrorDetail | null {
		if (dataIsRFC9457ErrorDetail(this.data)) {
			return this.data;
		} else {
			return null;
		}
	}
}

export function getSecurityHeaders(): Record<string, string> {
	const headers: Record<string, string> = {};
	const jwt = localStorage.getItem('jwt');
	if (jwt != null) {
		headers.Authorization = `JWT ${jwt}`;
	}
	const csrfToken = getCsrfToken();
	if (typeof csrfToken === 'string') {
		headers['X-CSRFToken'] = csrfToken;
	}
	return headers;
}

type MethodType = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';

interface Options {
	// We don't need a specific type for `body` because it gets passed through
	// to `JSON.stringify()`, and we never read from `body` directly.
	body?: unknown;
	method?: MethodType;
	query?: querystring.ParsedUrlQueryInput;
	signal?: AbortSignal;
}

export async function request<T = any>(
	path: string,
	{ body, method = 'GET', query, ...options }: Options,
): Promise<T> {
	const url = new URL(window.location.origin);
	const [pathname, searchstring] = path.split('?');
	url.pathname = `/api/v2${pathname}`;
	if (typeof searchstring === 'string') {
		url.search = `?${searchstring}`;
	}
	if (query != null && typeof query === 'object') {
		url.search = querystring.stringify(query);
	}

	const headers = new Headers({
		Accept: 'application/json',
		...getSecurityHeaders(),
	});
	if (method !== 'GET' && typeof body !== 'undefined') {
		headers.append('Content-Type', 'application/json');
	}

	const requestInit: RequestInit = {
		method,
		headers,
		credentials: 'include',
		...options,
	};
	if (typeof body !== 'undefined') {
		requestInit.body = JSON.stringify(body);
	}

	const response: Response = await fetch(new Request(url.href, requestInit));

	if (!response.ok) {
		if (response.status === 401) {
			deleteToken();
			window.location.reload();
		}

		let data: unknown = null;
		try {
			data = await response.clone().json();
		} catch {
			// Ignore errors; we did our best.
		}
		throw new ResponseError(response, data);
	}

	const contentType = response.headers.get('Content-Type');
	if (contentType != null && contentType.includes('application/json')) {
		/*
		 * This type cast is necessary here because REST APIs are inherently
		 * unsafe at a type level. The type checker cannot know what data the
		 * server will return, so it's up to us to make sure that the server-
		 * side implementation matches the type that we tell the API to expect.
		 * If we do that, we can pinky promise the type checker that this method
		 * is correct, and the type checker can depend on that to fully type
		 * check all of the code that's outside of this method.
		 */
		return response.json() as Promise<T>;
	}

	/*
	 * TODO: Replace `{}` with `void 0`. This is unsafe and technically
	 * incorrect. If we don't parse a response body, we should return
	 * `undefined` instead of an empty object. If a request expects a response
	 * and doesn't get it, returning an empty object instead of undefined only
	 * shifts the location of the error message from when we try to access a
	 * property to when we try to do something with it. We should prefer earlier
	 * errors when we have logic bugs because they make the issue easier to
	 * track down. I'm leaving it as an object only because this is a type-only
	 * commit.
	 */
	// This `any` type cast is necessary for the same reason as the JSON type
	// cast in the branch above.
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return {} as any;
}

export async function get<T = any>(url: string, options?: Options): Promise<T> {
	return request<T>(url, { method: 'GET', ...options });
}

export async function patch<T = any>(
	url: string,
	options?: Options,
): Promise<T> {
	return request<T>(url, { method: 'PATCH', ...options });
}

export async function post<T = any>(
	url: string,
	options?: Options,
): Promise<T> {
	return request<T>(url, { method: 'POST', ...options });
}

export async function put<T = any>(url: string, options?: Options): Promise<T> {
	return request<T>(url, { method: 'PUT', ...options });
}

export async function remove<T = any>(
	url: string,
	options?: Options,
): Promise<T> {
	return request<T>(url, { method: 'DELETE', ...options });
}

/**
 * Type-safe query cache updates! Pass `TData` to upsert the cache entry,
 * replacing it if it exists or creating it if it doesn't. Pass an updater
 * function to compute new cache values based on any existing cache values
 * without creating any new cache entries. Types are inferred from the query
 * options, typically created by calling the built-in `queryOptions()` helper.
 *
 * Example:
 *
 * ```ts
 * interface Widget {
 * 	id: number;
 * 	name: string;
 * }
 *
 * const widgetQueryKeys = {
 * 	all: () => ['widgets'] as const,
 * 	list: () => [...widgetQueryKeys.all(), 'list'] as const,
 * 	details: () => [...widgetQueryKeys.all(), 'details'] as const,
 * 	detail: (widgetId: number) =>
 * 		[...widgetQueryKeys.details(), widgetId] as const,
 * } as const;
 *
 * function widgetsQueryOptions() {
 * 	return queryOptions({
 * 		queryKey: widgetQueryKeys.list(),
 * 		queryFn: ({ signal }) =>
 * 			get<ReadonlyArray<Widget>>('/widgets', { signal }),
 * 	});
 * }
 *
 * export function useWidgets() {
 * 	return useQuery(widgetsQueryOptions());
 * }
 *
 * function widgetQueryOptions(id: number) {
 * 	return queryOptions({
 * 		queryKey: widgetQueryKeys.detail(id),
 * 		queryFn: ({ signal }) => get<Widget>(`/widgets/${id}`, { signal }),
 * 	});
 * }
 *
 * export function useWidget(id: number) {
 * 	return useQuery(widgetQueryOptions(id));
 * }
 *
 * export function useCreateWidget() {
 * 	return useMutation({
 * 		mutationFn: (widget: Omit<Widget, 'id'>) =>
 * 			post<Widget>('/widgets', {
 * 				body: widget,
 * 			}),
 * 		onSuccess: (widget) => {
 * 			updateQueryData(widgetsQueryOptions(), (widgets) => [
 * 				...widgets,
 * 				widget,
 * 			]);
 * 			updateQueryData(widgetQueryOptions(widget.id), widget);
 * 		},
 * 	});
 * }
 * ```
 */
export function updateQueryData<
	TQueryFnData = unknown,
	TError = Error,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	{ queryKey }: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	updater: TQueryFnData | ((data: TQueryFnData) => TQueryFnData),
): void {
	queryClient.setQueryData<TQueryFnData>(queryKey, (data) => {
		if (typeof updater === 'function') {
			if (data) {
				return (updater as (data: TQueryFnData) => TQueryFnData)(data);
			}
		} else {
			return updater;
		}

		return void 0;
	});
}

export function getQueryData<
	TQueryFnData = unknown,
	TError = Error,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>({
	queryKey,
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>):
	| TQueryFnData
	| undefined {
	return queryClient.getQueryData<TQueryFnData>(queryKey);
}

// TODO: Move this auth-specific code to the `authentication/` folder. This was
// written before that existed. I'm leaving it here because this is a type-only
// commit, and I don't want to introduce the risk from logic changes.

type TokenResponse = { token: string };

export const login = async (
	username: string,
	password: string,
	code?: string,
	signal?: AbortSignal,
): Promise<TokenResponse> =>
	post<TokenResponse>('/auth/token', {
		body: {
			code,
			password,
			username,
		},
		signal,
	});

export const logout = async (): Promise<void> => remove<void>('/auth/token');

export const refreshLogin = (): void => {
	post<TokenResponse>('/auth/refresh', {
		body: {
			token: localStorage.getItem('jwt'),
		},
	})
		.then(({ token }) => {
			store.dispatch(loginAction(token));
		})
		.catch((error) => {
			reportError(error);
		});
};

if (localStorage.getItem('jwt') != null) {
	refreshLogin();
}
