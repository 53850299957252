import React, { useCallback } from 'react';

import {
	useLPFundraisingDataSuspense,
	useUpdateLPFundraisingData,
} from './api';
import LPTable from './table';
import type { LPData } from './types';

export default function Dashboard() {
	const query = useLPFundraisingDataSuspense();
	const mutation = useUpdateLPFundraisingData();

	const onChange = useCallback(
		async (
			row: LPData,
			body: Partial<LPData>,
			signal: AbortSignal,
		): Promise<LPData> => {
			const data = await mutation.mutateAsync({ id: row.id, ...body });

			signal.throwIfAborted();

			return data;
		},
		[mutation],
	);

	return <LPTable data={query.data} onChange={onChange} />;
}
