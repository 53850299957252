import {
	ControlButton,
	FilterLabel,
} from '@drivecapital/design-system/components';
import { XIcon } from '@drivecapital/design-system/icons/system';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useUser } from '../../authentication';
import { type MarketMapTree, useMarketMaps } from '../../market-maps';

import type {
	FilterControlHeaderProps,
	FilterControlProps,
	IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import UnstyledTextComboBox from './text-combo-box';
import type { MarketMapsFilter } from './types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const MarketMaps = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const MarketMapLabels = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const MarketMapLabel = styled.div`
	display: flex;
	gap: 4px;

	& > svg {
		height: 16px;
		width: 16px;
	}
`;

const TextComboBox = styled(UnstyledTextComboBox)`
	flex: 1 0 auto;
`;
const InputLine = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
	justify-content: space-between;

	& > button {
		align-self: stretch;
		/* 4px + 16px = offset for input label */
		margin-top: 20px;
	}
`;

function safeParseInt(value: string) {
	const parsed = parseInt(value, 10);
	return Number.isNaN(parsed) ? null : parsed;
}

type Option = {
	id: number;
	text: string;
	subtext: string;
	ownerId: number;
	status: MarketMapTree['status'];
};
function buildOption({ id, name, owner, status }: MarketMapTree): Option {
	return {
		id,
		text: name,
		subtext: `(${owner.first_name})`,
		ownerId: owner.id,
		status,
	};
}

interface MarketMapsComboBoxProps {
	readonly id: string;
	readonly label: string;
	readonly onDeselect: (mapIds: number[]) => void;
	readonly onSelect: (mapId: number[]) => void;
	readonly selectedMarketMaps: number[];
}

function MarketMapsComboBox({
	id,
	label,
	onDeselect,
	onSelect,
	selectedMarketMaps,
}: MarketMapsComboBoxProps) {
	const { data: allMarketMaps, isSuccess: marketMapsLoaded } =
		useMarketMaps();
	const user = useUser();

	const options = useMemo<Option[]>(
		() => (marketMapsLoaded && allMarketMaps.map(buildOption)) || [],
		[allMarketMaps, marketMapsLoaded],
	);
	const selectableOptions = useMemo(
		() =>
			options.filter((option) => !selectedMarketMaps.includes(option.id)),
		[options, selectedMarketMaps],
	);
	const recentOptions = useMemo<Option[]>(
		() =>
			(marketMapsLoaded
				&& allMarketMaps
					.filter((option) => !selectedMarketMaps.includes(option.id))
					.toSorted((a, b) =>
						(b.last_viewed_at ?? '').localeCompare(
							a.last_viewed_at ?? '',
						),
					)
					.map(buildOption)
					.slice(0, 5))
			|| [],

		[allMarketMaps, selectedMarketMaps, marketMapsLoaded],
	);

	const handleSelect = useCallback(
		(mapId: number | string | null) => {
			const parsed =
				typeof mapId === 'number' ? mapId : safeParseInt(mapId ?? '');
			if (parsed !== null) {
				onSelect([parsed]);
			}
		},
		[onSelect],
	);

	const allActiveUserMaps = useMemo(() => {
		return options.filter(
			(option) =>
				option.ownerId === user.id && option.status === 'ACTIVE',
		);
	}, [user.id, options]);

	const selectableActiveUserMaps = useMemo(() => {
		return selectableOptions
			.filter((option) => allActiveUserMaps.includes(option))
			.map((option) => option.id);
	}, [allActiveUserMaps, selectableOptions]);

	const handleSelectAllActive = useCallback(() => {
		onSelect(selectableActiveUserMaps);
	}, [selectableActiveUserMaps, onSelect]);

	const disableActiveMapsButton = allActiveUserMaps.length <= 0;

	return (
		<MarketMaps>
			<InputLine>
				<TextComboBox
					allOptions={selectableOptions}
					defaultOptions={recentOptions}
					defaultValue=""
					id={id}
					label={label}
					onSubmit={handleSelect}
					placeholder="Find a Market Map"
				/>
				<ControlButton
					active={false}
					disabled={disableActiveMapsButton}
					onClick={handleSelectAllActive}
					variant="secondary"
					title="Select all of your active maps"
				>
					<XIcon />
					My Maps
				</ControlButton>
			</InputLine>

			<MarketMapLabels>
				{marketMapsLoaded
					&& selectedMarketMaps.map((mapId) => (
						<FilterLabel
							isActive
							key={mapId}
							label={
								<MarketMapLabel>
									{
										options.find(
											(option) => option.id === mapId,
										)?.text
									}
									<XIcon />
								</MarketMapLabel>
							}
							onAction={() => {
								onDeselect([mapId]);
							}}
						/>
					))}
			</MarketMapLabels>
		</MarketMaps>
	);
}

function sortMarketMaps({
	include,
	exclude,
}: MarketMapsFilter['value']): MarketMapsFilter['value'] {
	return {
		include: include.toSorted((a, b) => a - b),
		exclude: exclude.toSorted((a, b) => a - b),
	};
}
type ValueType = keyof MarketMapsFilter['value'];
const FIELD_NAME: MarketMapsFilter['fieldName'] = 'marketMaps';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<MarketMapsFilter>) {
	let marketMaps: {
		include: number[];
		exclude: number[];
	};

	if (filter) {
		marketMaps = sortMarketMaps(filter.value);
	} else {
		marketMaps = {
			include: [],
			exclude: [],
		};
	}

	const handleSelect = useCallback(
		(type: ValueType, mapIds: number[]) => {
			onChange({
				fieldName: FIELD_NAME,
				value: sortMarketMaps({
					...marketMaps,
					[type]: [...marketMaps[type], ...mapIds],
				}),
			});
		},
		[marketMaps, onChange],
	);

	const handleRemove = useCallback(
		(type: ValueType, mapIds: number[]) => {
			const updatedMaps = marketMaps[type].filter(
				(id) => !mapIds.includes(id),
			);
			const otherType = type === 'include' ? 'exclude' : 'include';
			const otherTypeHasMaps = marketMaps[otherType].length > 0;

			if (updatedMaps.length === 0 && !otherTypeHasMaps) {
				onClear(FIELD_NAME);
				return;
			}
			onChange({
				fieldName: FIELD_NAME,
				value: sortMarketMaps({
					...marketMaps,
					[type]: updatedMaps,
				}),
			});
		},
		[marketMaps, onChange, onClear],
	);

	return (
		<Container>
			<MarketMapsComboBox
				id="include-market-maps-filter"
				label="Included in..."
				onDeselect={(ids) => handleRemove('include', ids)}
				onSelect={(ids) => handleSelect('include', ids)}
				selectedMarketMaps={marketMaps.include}
			/>

			<MarketMapsComboBox
				id="exclude-market-maps-filter"
				label="Excluded from..."
				onDeselect={(ids) => handleRemove('exclude', ids)}
				onSelect={(ids) => handleSelect('exclude', ids)}
				selectedMarketMaps={marketMaps.exclude}
			/>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<MarketMapsFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Market Maps"
		/>
	);
}

const Control: IFilterControl<MarketMapsFilter> = {
	control: FilterControl,
	description: () => {
		return [];
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default Control;
