import React, { lazy } from 'react';
import DocumentTitle from 'react-document-title';
import { Navigate } from 'react-router-dom';

import authorizedRoute from '../components/authorized-route';

import { PATH as GROWTH_PATH } from './growth-dashboard-view';
import { PATH as HERBIE_PATH } from './herbie-view';
import { PATH as INVESTOR_PATH } from './investors-view';

const GrowthDashboardView = lazy(async () => import('./growth-dashboard-view'));
const HerbieView = lazy(async () => import('./herbie-view'));
const InvestorView = lazy(async () => import('./investors-view'));
const View = lazy(async () => import('./view'));

export const routes = authorizedRoute(
	{
		path: 'internal-dashboards',
		element: <View />,
		children: [
			{
				path: GROWTH_PATH,
				element: (
					<DocumentTitle title="Growth Dashboard">
						<GrowthDashboardView />
					</DocumentTitle>
				),
			},
			{
				path: HERBIE_PATH,
				element: (
					<DocumentTitle title="Herbie">
						<HerbieView />
					</DocumentTitle>
				),
			},
			{
				path: INVESTOR_PATH,
				element: (
					<DocumentTitle title="Investors">
						<InvestorView />
					</DocumentTitle>
				),
			},
			{
				index: true,
				element: <Navigate to={GROWTH_PATH} replace />,
			},
		],
	},
	'contacts.kpi',
);
