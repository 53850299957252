import { colors, fonts } from '@drivecapital/design-system';
import {
	AriaDropdown as Dropdown,
	AriaDropdownOption as DropdownOption,
	AriaDropdownOptions as DropdownOptions,
	IconButton,
	TextInput,
	Button as ThemeButton,
} from '@drivecapital/design-system/components';
import { XIcon } from '@drivecapital/design-system/icons/system';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Modal from '../components/modal';

import { useDismissOutreachCompanies } from './api';
import type { CompanyOutreach, DismissReason } from './types';

const StyledModal = styled(Modal)`
	max-width: min(80vw, 420px);
	padding: 20px;

	.HerbieModal__container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		outline: none;
	}
`;

const Titles = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const Title = styled.div`
	${fonts.h4.productive}
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;

const Subtitle = styled.div`
	${fonts.paragraph.paragraph}
	color: ${colors.text.primary};
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const DropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Buttons = styled.div`
	display: flex;
	gap: 4px;
`;

interface Props {
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSubmit: () => void;
	readonly companyOutreaches: CompanyOutreach[];
}

export default function DismissModal({
	isOpen,
	onClose,
	onSubmit,
	companyOutreaches,
}: Props) {
	const { mutateAsync: dismissOutreachCompanies, isPending: isDismissing } =
		useDismissOutreachCompanies();

	const [dismissReason, setDismissReason] = useState<DismissReason>(
		'NOT_SOURCING_RELATED',
	);
	const [otherExplanation, setOtherExplanation] = useState<string>('');
	const handleDismissReasonChange = useCallback((value: string | number) => {
		setDismissReason(value as DismissReason);
	}, []);
	const handleOtherExplanationChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setOtherExplanation(event.target.value);
		},
		[],
	);
	const handleSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			await dismissOutreachCompanies({
				outreachIds: companyOutreaches.map(
					(companyOutreach) => companyOutreach.id,
				),
				reason: dismissReason,
				otherReason: otherExplanation,
			});
			onSubmit();
			setDismissReason('NOT_SOURCING_RELATED');
			setOtherExplanation('');
		},
		[
			companyOutreaches,
			dismissOutreachCompanies,
			dismissReason,
			onSubmit,
			otherExplanation,
		],
	);

	return (
		<StyledModal onClose={onClose} isOpen={isOpen} title="Dismiss">
			<Titles>
				<Title>
					Dismiss Outreach?{' '}
					<IconButton label="" size="small" onClick={onClose}>
						<XIcon />
					</IconButton>
				</Title>
				<Subtitle>
					Are you sure you want to dismiss{' '}
					{companyOutreaches.length > 1
						? `these ${companyOutreaches.length} outreaches`
						: companyOutreaches[0]?.company?.name}
					? This action cannot be undone.
				</Subtitle>
			</Titles>
			<Form onSubmit={handleSubmit}>
				<DropdownWrapper>
					<Dropdown
						autoFocus
						onChange={handleDismissReasonChange}
						label="Please tell us why"
						value={dismissReason}
					>
						<DropdownOptions>
							<DropdownOption value="NOT_SOURCING_RELATED">
								Outreach wasn't sourcing
							</DropdownOption>
							<DropdownOption value="FOLLOWED_UP_OUTSIDE_EMAIL">
								Followed up outside email
							</DropdownOption>
							<DropdownOption value="Other">Other</DropdownOption>
						</DropdownOptions>
					</Dropdown>
					{dismissReason === 'Other' && (
						<TextInput
							autoFocus
							name="OtherExplanation"
							onChange={handleOtherExplanationChange}
							placeholder="Enter reason here"
							required
							value={otherExplanation}
						/>
					)}
				</DropdownWrapper>
				<Buttons>
					<ThemeButton
						disabled={
							isDismissing
							|| (!otherExplanation && dismissReason === 'Other')
						}
						type="submit"
						variant="danger"
					>
						Dismiss
					</ThemeButton>
					<ThemeButton
						type="button"
						variant="secondary"
						onClick={onClose}
					>
						Cancel
					</ThemeButton>
				</Buttons>
			</Form>
		</StyledModal>
	);
}
