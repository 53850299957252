import React from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';
import assertExhaustive from '../../utils/assert-exhaustive';
import useEditableField, {
	EditingState,
	ErrorState,
	NormalState,
	SavingState,
	SuccessState,
} from '../../utils/state-machine/editable-field';

type Data = number | null;

const HeaderText = styled.div`
	text-align: right;
`;

export const NormalCell = styled.td`
	font-variant-numeric: tabular-nums;
	line-height: 20px;
	min-width: 10ch;
	overflow: hidden;
	padding: 10px;
	text-align: right;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 10ch;
`;

// In the editing state, we render an <input> inside of the <td>, and the
// <input> is responsible for all of the styling.
export const EditingCell = styled.td`
	font-variant-numeric: tabular-nums;
	line-height: 20px;
	min-width: 10ch;
	overflow: hidden;
	text-align: right;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 10ch;
`;

export const Input = styled.input`
	appearance: none;
	background-color: transparent;
	border: none;
	/* For some reason, in Chrome only, right-aligned inputs have 1px horizontal
	 * scrolling despite overflow rules to the contrary. Setting direction: rtl
	 * on right-aligned inputs appears to fix this. It also moves the up/down
	 * spinner controls over to the left of the input so that they don't insert
	 * themselves along the right edge, causing the input value to jump over and
	 * messing up vertical alignment. For negative numbers, the negative sign
	 * will be on the right side of the number in rtl mode, but so far we only
	 * deal with positive numbers in these fields. */
	direction: rtl;
	font-size: inherit;
	font-variant-numeric: tabular-nums;
	line-height: 20px;
	margin: 0;
	max-width: 100%;
	min-width: 0;
	overflow: hidden;
	padding: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
`;

export const SavingCell = styled(NormalCell)`
	background-color: #eee;
`;

export const SuccessCell = styled(NormalCell)`
	background-color: #dfd;
`;

export const ErrorCell = styled(NormalCell)`
	color: #800;
	/* Show as much of the error message as we can in the original column width
	 * as determined by the other cells in the column, but don't let it increase
	 * the column's width by itself. */
	max-width: 0px;
	text-align: left;
`;

type NumericCellProps = {
	readonly format: (val: number | null) => string;
	readonly initialValue: Data;
	readonly onChange: (value: Data, signal: AbortSignal) => Promise<Data>;
};

function EditableNumericCell({
	format,
	initialValue,
	onChange,
	...props
}: NumericCellProps) {
	const [currentState, transition] = useEditableField<Data>();

	if (currentState instanceof NormalState) {
		return (
			<NormalCell
				{...props}
				onFocus={() => {
					transition(currentState.onEdit(initialValue));
				}}
				// Setting tabIndex makes the cell focusable by the mouse or
				// in sequential order with the keyboard. <input>s have that
				// behavior by default, but since this is a <td> in the
				// normal state, we need to add it manually.
				tabIndex={0}
			>
				{initialValue == null ? '' : format(initialValue)}
			</NormalCell>
		);
	} else if (currentState instanceof EditingState) {
		return (
			<EditingCell {...props}>
				<Input
					autoFocus
					onBlur={() => {
						transition(
							currentState.value === initialValue
								? currentState.onCancel()
								: currentState.onSubmit(onChange),
						);
					}}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						transition(
							currentState.onChange(
								event.target.value.length === 0
									? null
									: parseFloat(event.target.value),
							),
						);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
						event.currentTarget.select()
					}
					onKeyDown={(
						event: React.KeyboardEvent<HTMLInputElement>,
					) => {
						switch (event.key) {
							case 'Enter':
								transition(currentState.onSubmit(onChange));
								return;
							case 'Escape':
								transition(currentState.onCancel());
								return;
							default: // Do nothing
						}
					}}
					type="number"
					value={currentState.value == null ? '' : currentState.value}
				/>
			</EditingCell>
		);
	} else if (currentState instanceof SavingState) {
		return (
			<SavingCell {...props}>
				{currentState.value == null ? '' : format(currentState.value)}
			</SavingCell>
		);
	} else if (currentState instanceof SuccessState) {
		return (
			<SuccessCell {...props}>
				{currentState.value == null ? '' : format(currentState.value)}
			</SuccessCell>
		);
	} else if (currentState instanceof ErrorState) {
		return (
			<ErrorCell {...props} title={currentState.error.message}>
				{currentState.error.message}
			</ErrorCell>
		);
	} else {
		throw assertExhaustive(currentState);
	}
}

export default class NumericColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	name: string;
	protected format: (val: number | null) => string;
	protected select: (row: T) => Data;
	protected update: null | ((value: Data) => Partial<T>);

	constructor({
		name,
		format,
		select,
		update = null,
	}: {
		format: (val: number | null) => string;
		name: string;
		select: (row: T) => Data;
		update?: null | ((value: Data) => Partial<T>);
	}) {
		this.name = name;
		this.format = format;
		this.select = select;
		this.update = update;

		this.cell.displayName = 'NumericColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, onChange, props, row }: CellArgs<T>) => {
		const value = this.select(row);

		const update = this.update;
		if (deferred || !update || !onChange) {
			return <NormalCell {...props}>{this.format(value)}</NormalCell>;
		}

		return (
			<EditableNumericCell
				initialValue={value}
				format={this.format}
				onChange={async (newValue, signal) => {
					const patch = update(newValue);
					const response: T = await onChange(row, patch, signal);
					const updatedValue: Data = this.select(response);
					return updatedValue;
				}}
				{...props}
			/>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				initialSortDirection={sortDirections.descending}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				<HeaderText>{this.name}</HeaderText>
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aVal: Data = this.select(a);
		const bVal: Data = this.select(b);

		if (aVal === bVal) return 0;
		if (aVal === null) return 1;
		if (bVal === null) return -1;
		return direction === sortDirections.ascending
			? aVal - bVal
			: bVal - aVal;
	}

	toCSV(row: T): string {
		const value = this.select(row);
		const formatted = this.format(value);
		if (!formatted) return '';
		return formatted;
	}
}
