// @flow

import listify from '@drivecapital/listify';
import { Set as ImmutableSet } from 'immutable';
import moment from 'moment';
import numeral from 'numeral';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { Key as ReactKey, Node as ReactNode } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
	addItemToWatchListSuccess,
	deleteWatchList as deleteWatchListAction,
	editWatchList as editWatchListAction,
	editWatchListItemSuccess,
	loadWatchList,
	removeItemsFromWatchListSuccess,
} from '../../actions';
import * as colors from '../../colors';
import type { ColumnDescriptor } from '../../components/watchlists/column';
import {
	useRefreshPeoplePipelines,
	useRefreshPipelineDetail,
} from '../../pipelines';
import {
	selectUser,
	selectWatchListById,
	selectWatchListColumnsById,
	selectWatchListLoadedById,
} from '../../selectors';
import Table, {
	type IColumn,
	searchData,
	sortDirections,
	type TableProps,
	useScrollHistory,
	useSearchHistory,
	useSortHistory,
} from '../../table';
import { type AddPickerResult } from '../../table/column';
import ChangeColumn from '../../table/columns/change';
import CheckboxColumn from '../../table/columns/checkbox';
import CurrencyColumn from '../../table/columns/currency';
import DateColumn from '../../table/columns/date';
import ImageColumn from '../../table/columns/image';
import LineGraphColumn from '../../table/columns/line-graph';
import MultiSelectColumn from '../../table/columns/multi-select';
import NameColumn from '../../table/columns/name';
import NotesColumn from '../../table/columns/notes';
import NumericColumn from '../../table/columns/numeric';
import TextColumn from '../../table/columns/text';
import type { Sort, SortDirection } from '../../table/sort';
import { addCandidatePoolItems } from '../../talent/actions';
import { trackEvent } from '../../utils/analytics';
import { patch, post, remove } from '../../utils/api';
import useAbortSignal from '../../utils/hooks/use-abort-signal';
import useAnalytics from '../../utils/hooks/use-analytics';
import reportError from '../../utils/sentry';
import HerbieLoader from '../herbie-loader';

import Header from './header';
import type {
	EntityData,
	NormalizedWatchListData,
	WatchListData,
	WatchListItem,
} from './types';

// Preserve API compatibility by re-exporting these types that were previously
// defined inline.
export type {
	CompanyEntityData,
	PersonEntityData,
	EntityData,
	WatchListData,
} from './types';

type WatchListEdit = {|
	isAutoUpdate: boolean,
	isPublic: boolean,
	isSendUpdateEmail: boolean,
	newColumns?: Array<ColumnDescriptor>,
	newName: string,
|};

const StyledTable = styled<TableProps<any>, void>(Table)`
	/* To avoid our algolia search results from being hidden due to
	overflow: scroll, this min-height lets it always be visible*/
	min-height: 300px;
	th {
		text-align: left;
	}
	.HerbieTable {
		&__body {
			background-color: white;
			&:nth-of-type(even) {
				background-color: ${colors.tableRowBlue.string()};
			}
		}
		&__cell {
			border-right: 1px solid #e6e8eb;
		}
		&__foot {
			background-color: #f4f6f9;
		}
		&__footer {
			border-right: 1px solid #e2e4e7;
			font-weight: bold;
			padding: 10px 10px;
			white-space: nowrap;
		}
		&__head {
			background-color: #eaecef;
		}
		&__header {
			border-right: 1px solid #dee0e3;
			padding: 10px 10px;
			white-space: nowrap;
		}
		&__table {
			border-top: 1px solid #dee0e3;
		}

		&__row-selected {
			background: ${colors.tableRowBlue.darken(0.1).string()};
		}
	}
`;

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
`;

function buildColumns(column_types, canEditCells = false) {
	const columns: Array<IColumn<EntityData>> = [];
	// assume if editable property doesn't exist that it is editable
	const handleMouseDownEvent = (row: EntityData) =>
		trackEvent('Open Profile', 'watchlist-detail', 'watchlists', {
			target_profile_type: row.type,
		});

	if (canEditCells) columns.push(new MultiSelectColumn());

	for (const column of column_types) {
		switch (column.type) {
			case 'checkbox':
				columns.push(
					new CheckboxColumn({
						name: column.name,
						select: (row) =>
							row.hasOwnProperty(column.field)
								? Boolean(row[column.field])
								: false,
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void false,
					}),
				);
				break;
			case 'current_job':
				// TODO: Should we make the company name a link?
				columns.push(
					new TextColumn({
						name: column.name,
						select: (row) => {
							if (row.type !== 'people' || !row.current_job)
								return '';

							return `${row.current_job.title} @ ${row.current_job.company.name}`;
						},
					}),
				);
				break;
			case 'currency':
				columns.push(
					new CurrencyColumn({
						name: column.name,
						format: (value) =>
							value === null
								? ''
								: CurrencyColumn.formatMillions(value),
						select: (row) =>
							row.hasOwnProperty(column.field)
							&& row[column.field] !== null
								? row[column.field]
								: 0,
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			case 'date':
				columns.push(
					new DateColumn({
						format: (date) => date.format('MMM Do, YYYY'),
						name: column.name,
						select: (row) =>
							row.hasOwnProperty(column.field)
							&& row[column.field] !== null
								? moment(row[column.field])
								: null,
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			case 'date_since':
				columns.push(
					new DateColumn({
						format: DateColumn.formatRelative,
						name: column.name,
						select: (row) =>
							row[column.field]
								? moment(row[column.field])
								: null,
					}),
				);
				break;
			case 'numeric':
				columns.push(
					new NumericColumn({
						name: column.name,
						select: (row) => row[column.field] || null,
						format: (value) =>
							value === null ? '' : value.toString(),
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			case 'email_address':
				// TODO: Should we make this a mailto: link?
				columns.push(
					new TextColumn({
						name: column.name,
						select: (row) => {
							const value = row[column.field];
							if (Array.isArray(value) && value.length) {
								return value[0];
							}

							return '';
						},
						update:
							column.editable !== false
								? (value) => ({ [column.field]: [value] })
								: void 0,
					}),
				);
				break;
			case 'employees':
				columns.push(
					new NumericColumn({
						name: column.name,
						select: (row) => row[column.field],
						format: (value) =>
							value != null
								? pluralize('employees', value, true)
								: '',
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			case 'employee_change':
				columns.push(
					new ChangeColumn({
						name: column.name,
						decimal: 1,
						select: (row) =>
							row.type === 'companies' ? row[column.field] : null,
					}),
				);
				break;
			case 'employee_growth':
				columns.push(
					new LineGraphColumn({
						name: column.name,
						select: (row) =>
							row.hasOwnProperty(column.field)
								? row[column.field].map(
										(elem) => elem.employees,
									)
								: [],
						sortValue: (row) =>
							row.type === 'companies' ? row.growth_score : null,
					}),
				);
				break;
			case 'funding':
				// with the way the api returns data, I dont see a great way around
				// not calling a function within loop
				columns.push(
					new CurrencyColumn({
						format: (value: number | null) =>
							value === null
								? 'N.M.'
								: `$${numeral(value)
										.format('0[.]0a')
										.toUpperCase()}`,
						name: column.name,
						select: (row) =>
							row.hasOwnProperty(column.field)
								? row[column.field]
								: null,
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			case 'locations':
				columns.push(
					new TextColumn({
						name: column.name,
						select: (row) =>
							Array.isArray(row[column.field])
								? listify(row[column.field])
								: row[column.field]
									? row[column.field]
									: '',
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			case 'name':
				columns.push(
					new NameColumn({
						select: (row) => ({
							href: `/${row.type}/${row.id}`,
							name: row[column.field],
						}),
						types: ['person', 'company'],
						handleMouseDownEvent,
					}),
				);
				break;
			case 'notes':
				columns.push(
					new NotesColumn({
						name: column.name,
						select: (row) =>
							row[column.field] ? row[column.field] : [],
					}),
				);
				break;
			case 'email_count':
				columns.push(
					new NumericColumn({
						name: column.name,
						format: (value) =>
							value === null ? '' : value.toString(),
						select: (row) =>
							row.hasOwnProperty(column.field)
							&& row[column.field] !== null
								? row[column.field]
								: 0,
					}),
				);
				break;
			case 'photo':
				columns.push(
					new ImageColumn({
						select: (row) => ({
							alt: row.name,
							href: `/${row.type}/${row.id}`,
							src: row[column.field],
						}),
						handleMouseDownEvent,
					}),
				);

				break;
			case 'roles':
				columns.push(
					new TextColumn({
						name: column.name,
						select: (row) =>
							Array.isArray(row[column.field])
								? listify(row[column.field])
								: row[column.field]
									? row[column.field]
									: '',
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			case 'text':
				columns.push(
					new TextColumn({
						name: column.name,
						select: (row) =>
							row.hasOwnProperty(column.field)
							&& row[column.field] !== null
								? row[column.field]
								: '',
						update:
							column.editable !== false
								? (value) => ({ [column.field]: value })
								: void 0,
					}),
				);
				break;
			default:
			//nothing
		}
	}
	return columns;
}

function buildRowKey(row: EntityData) {
	return `${row.type}_${row.id}`;
}

export function useWatchListControlBase<
	List: WatchListData,
	WatchListEditArgs,
>({
	columns,
	onItemAdd,
	onItemEdit,
	onItemsDelete,
	onLoad,
	onWatchListDelete,
	onWatchListEdit,
	watchList,
}: {
	columns: Array<ColumnDescriptor>,
	onItemAdd: (entity: AddPickerResult) => void,
	onItemEdit: (
		row: EntityData,
		body: {},
		signal: AbortSignal,
	) => Promise<EntityData>,
	onItemsDelete: (selectedItems: Array<EntityData>) => Promise<void>,
	onLoad: () => void,
	onWatchListDelete?: () => Promise<void>,
	onWatchListEdit: (args: WatchListEditArgs) => Promise<void>,
	watchList: List | null,
}) {
	const [scrollX, scrollY, handleScroll] = useScrollHistory();
	const [search, handleSearch] = useSearchHistory();
	const [sort, handleSort] = useSortHistory({
		direction: sortDirections.ascending,
		index: 1,
	});
	const currentUser = useSelector(selectUser);
	const isOwner = watchList && currentUser.id === watchList.owner.id;
	const canEditCells = Boolean(
		isOwner
			|| (watchList
				&& watchList.shared_with.indexOf(currentUser.id) !== -1),
	);
	const watchListColumns = useMemo(
		() => buildColumns(columns ? columns : [], canEditCells),
		[columns, canEditCells],
	);

	const items = watchList ? watchList.items : null;
	const [selectedItemKeys, setSelectedRowKeys] =
		useState<ImmutableSet<ReactKey>>(ImmutableSet());

	useEffect(() => {
		onLoad();
		setSelectedRowKeys(ImmutableSet());
	}, [onLoad, setSelectedRowKeys]);

	const handleItemsDelete = useCallback(async () => {
		if (!items) return;
		if (selectedItemKeys.size === 0) return;

		const rows = items.filter((item) =>
			selectedItemKeys.has(buildRowKey(item)),
		);
		const filteredRows = searchData(watchListColumns, search, rows);

		await onItemsDelete(filteredRows);

		setSelectedRowKeys((currentlySelected) =>
			currentlySelected.subtract(filteredRows.map(buildRowKey)),
		);
	}, [items, onItemsDelete, search, selectedItemKeys, watchListColumns]);

	const handleItemSelection = useCallback(
		(
			selection: { rowKey: ReactKey, selected: boolean } | 'ALL' | 'NONE',
		) => {
			setSelectedRowKeys((currentlySelected) => {
				if (selection === 'NONE') {
					return ImmutableSet();
				} else if (selection === 'ALL') {
					return ImmutableSet((items || []).map(buildRowKey));
				} else if (selection.selected) {
					return currentlySelected.add(selection.rowKey);
				} else if (!selection.selected) {
					return currentlySelected.delete(selection.rowKey);
				} else {
					return currentlySelected;
				}
			});
		},
		[items],
	);

	const selectedItems: ImmutableSet<EntityData> = useMemo(
		() =>
			ImmutableSet(
				(watchList ? watchList.items : []).filter((item) =>
					selectedItemKeys.has(buildRowKey(item)),
				),
			),
		[selectedItemKeys, watchList],
	);
	const filteredSelectedItems: Array<EntityData> = useMemo(
		() => searchData(watchListColumns, search, selectedItems.toArray()),
		[selectedItems, search, watchListColumns],
	);

	return {
		canEditCells,
		columns,
		filteredSelectedItems,
		handleItemAdd: onItemAdd,
		handleItemEdit: onItemEdit,
		handleItemSelection,
		handleItemsDelete,
		handleScroll,
		handleSearch,
		handleSort,
		handleWatchListDelete: onWatchListDelete,
		handleWatchListEdit: onWatchListEdit,
		isOwner,
		scrollX,
		scrollY,
		search,
		selectedItems,
		selectedItemKeys,
		sort,
		watchList,
		watchListColumns,
	};
}

export function useWatchListControl({
	onDelete,
	watchListId,
}: {
	onDelete?: () => void,
	watchListId: number,
}) {
	const dispatch = useDispatch();
	const abortSignal = useAbortSignal();
	const watchList = useSelector((state) =>
		selectWatchListById(state, watchListId),
	);
	const columns = useSelector((state) =>
		selectWatchListColumnsById(state, watchListId),
	);
	const loaded = useSelector((state) =>
		selectWatchListLoadedById(state, watchListId),
	);

	const onItemAdd = useCallback(
		(entity: AddPickerResult): void => {
			post<WatchListItem>(`/watchlists/${watchListId}/items/`, {
				body: { id: entity.id, type: entity.type },
			})
				.then((item) =>
					dispatch(addItemToWatchListSuccess(watchListId, item)),
				)
				.catch(reportError);
		},
		[dispatch, watchListId],
	);
	const onItemEdit = useCallback(
		async (
			row: EntityData,
			body: {},
			signal: AbortSignal,
		): Promise<EntityData> => {
			const entity = await patch<{
				entity: EntityData,
				extra: $Shape<EntityData>,
			}>(`/watchlists/${watchListId}/items/${row.type}/${row.id}`, {
				body,
				signal,
			});

			editWatchListItemSuccess(watchListId, entity);

			// extra contains the new updated value returned from api
			return entity.extra;
		},
		[watchListId],
	);
	const onItemsDelete = useCallback(
		(filteredRows: Array<EntityData>) => {
			const companyItemIds = filteredRows
				.filter(({ type }) => type === 'companies')
				.map(({ id }) => id);
			const peopleItemIds = filteredRows
				.filter(({ type }) => type === 'people')
				.map(({ id }) => id);

			return remove(`/watchlists/${watchListId}/items`, {
				body: {
					companies: companyItemIds,
					people: peopleItemIds,
				},
			})
				.then(() => {
					dispatch(
						removeItemsFromWatchListSuccess({
							items: {
								companies: companyItemIds,
								people: peopleItemIds,
							},
							watchListId,
						}),
					);
				})
				.catch(() => {
					// Probably a 404 if another tab deleted item already
				});
		},
		[dispatch, watchListId],
	);

	const onLoad = useCallback(() => {
		if (!loaded) {
			loadWatchList(watchListId);
		}
	}, [loaded, watchListId]);

	const onWatchListDelete = useCallback(async () => {
		await remove(`/watchlists/${watchListId}`, { signal: abortSignal });
		if (onDelete) onDelete();
		dispatch(deleteWatchListAction(watchListId));
	}, [abortSignal, dispatch, onDelete, watchListId]);

	const onWatchListEdit = useCallback(
		async ({
			isAutoUpdate,
			isPublic,
			isSendUpdateEmail,
			newColumns,
			newName,
		}: WatchListEdit) => {
			const body: {
				auto_update: boolean,
				columns?: Array<ColumnDescriptor>,
				name: string,
				public: boolean,
				send_email_update: boolean,
			} = {
				auto_update: isAutoUpdate,
				name: newName,
				public: isPublic,
				send_email_update: isSendUpdateEmail,
			};

			let columnsChanged = false;

			if (!Array.isArray(newColumns)) {
				columnsChanged = false;
			} else if (!watchList) {
				columnsChanged = false;
			} else if (watchList.columns.length !== newColumns.length) {
				columnsChanged = true;
			} else {
				columnsChanged = !watchList.columns.every((oldColumn, idx) => {
					const newColumn = newColumns[idx];

					return (
						oldColumn.editable === newColumn.editable
						&& oldColumn.field === newColumn.field
						&& oldColumn.name === newColumn.name
					);
				});
			}

			if (columnsChanged) {
				body.columns = newColumns;
			}

			const newWatchList = await patch<NormalizedWatchListData>(
				`/watchlists/${watchListId}`,
				{
					signal: abortSignal,
					body,
				},
			);

			dispatch(editWatchListAction(newWatchList));
		},
		[abortSignal, dispatch, watchList, watchListId],
	);

	const control = useWatchListControlBase<WatchListData, WatchListEdit>({
		columns,
		onItemAdd,
		onItemEdit,
		onItemsDelete,
		onLoad,
		onWatchListDelete,
		onWatchListEdit,
		watchList,
	});
	const refreshPeoplePipelines = useRefreshPeoplePipelines();
	const refreshPipelineDetail = useRefreshPipelineDetail();
	const handleCopyToCandidatePool = useCallback(
		async ({
			candidatePoolId,
			jobOrderId,
		}: {
			candidatePoolId: number,
			jobOrderId: number,
		}) => {
			if (!control.watchList) return Promise.resolve();

			const filteredRows = control.filteredSelectedItems.filter(
				({ type }) => type === 'people',
			);

			trackEvent(
				'Add to Candidate Pool',
				'watchlist',
				'watchlist-detail',
				{ count: filteredRows.length },
			);

			try {
				const newItems = await post<{
					candidate_pool_items: Array<WatchListItem>,
					pipeline_id: number,
				}>(
					`/talent/job-orders/${jobOrderId}/candidate-pools/${candidatePoolId}/items`,
					{
						body: {
							people: filteredRows.map(
								({ id: itemId }) => itemId,
							),
							watch_list_id: watchListId,
						},
						signal: abortSignal,
					},
				);
				dispatch(
					addCandidatePoolItems(
						newItems.candidate_pool_items,
						candidatePoolId,
					),
				);

				refreshPeoplePipelines(
					filteredRows.map(({ id: itemId }) => itemId),
				);
				refreshPipelineDetail(newItems.pipeline_id);
			} catch (e) {
				reportError(e);
			}
			return Promise.resolve();
		},
		[
			abortSignal,
			control.filteredSelectedItems,
			control.watchList,
			dispatch,
			refreshPeoplePipelines,
			refreshPipelineDetail,
			watchListId,
		],
	);

	return {
		...control,
		handleCopyToCandidatePool,
	};
}

export function DataTable({
	canEditCells,
	className,
	onItemAdd,
	onItemEdit,
	onScroll,
	onSelect,
	onSort,
	scrollX,
	scrollY,
	search,
	selectedRowKeys,
	sort,
	watchList,
	watchListColumns,
}: {
	canEditCells: boolean,
	className?: string,
	onItemAdd: (entity: AddPickerResult) => void,
	onItemEdit: (
		row: EntityData,
		body: {},
		signal: AbortSignal,
	) => Promise<EntityData>,
	onScroll: (
		event: SyntheticEvent<HTMLDivElement>,
		componentIdentifier?: string,
		viewType?: string,
	) => void,
	onSelect: (
		selection: { rowKey: ReactKey, selected: boolean } | 'ALL' | 'NONE',
	) => void,
	onSort: (index: number, direction: SortDirection) => void,
	scrollX: number | null,
	scrollY: number | null,
	search: string,
	selectedRowKeys: ImmutableSet<ReactKey>,
	sort: Sort | null,
	watchList: WatchListData,
	watchListColumns: Array<IColumn<EntityData>>,
}) {
	const mixpanelTracking = useMemo(
		() => ({
			componentIdentifier: 'watchlist-detail-table',
			viewType: 'watchlist-detail',
		}),
		[],
	);

	return canEditCells ? (
		<StyledTable
			className={className}
			columns={watchListColumns}
			data={watchList.items}
			getRowKey={buildRowKey}
			initialScrollX={scrollX}
			initialScrollY={scrollY}
			initialSort={sort}
			onScroll={onScroll}
			onSelect={onSelect}
			onSort={onSort}
			search={search}
			selectedRowKeys={selectedRowKeys}
			onChange={onItemEdit}
			onAdd={onItemAdd}
			mixpanelTracking={mixpanelTracking}
		/>
	) : (
		<StyledTable
			className={className}
			columns={watchListColumns}
			data={watchList.items}
			getRowKey={buildRowKey}
			initialScrollX={scrollX}
			initialScrollY={scrollY}
			initialSort={sort}
			onScroll={onScroll}
			onSort={onSort}
			search={search}
			mixpanelTracking={mixpanelTracking}
		/>
	);
}

export default function WatchList({
	hideEdit,
	id,
	onDelete,
}: {
	hideEdit?: boolean,
	id: number,
	onDelete?: () => void,
}): ReactNode {
	useAnalytics({
		eventName: 'Visit New Page',
		componentIdentifier: 'watchlist-detail-view',
		viewType: 'watchlist-detail',
	});

	const {
		canEditCells,
		isOwner,
		handleCopyToCandidatePool,
		handleItemAdd,
		handleItemEdit,
		handleItemsDelete,
		handleItemSelection,
		handleScroll,
		handleSearch,
		handleSort,
		handleWatchListDelete,
		handleWatchListEdit,
		scrollX,
		scrollY,
		search,
		selectedItems,
		selectedItemKeys,
		sort,
		watchList,
		watchListColumns,
	} = useWatchListControl({ onDelete, watchListId: id });

	if (!watchList) return <HerbieLoader />;

	return (
		<DocumentTitle title={watchList.name}>
			<Container>
				<Header
					autoUpdate={watchList.auto_update}
					canAutoUpdate={
						!!watchList.query || !!watchList.search_request
					}
					canEditCells={canEditCells}
					columnDescriptors={watchList.columns}
					description={watchList.description}
					hideEdit={hideEdit}
					isOwner={isOwner}
					isPublic={watchList.public}
					items={watchList.items}
					name={watchList.name}
					onCopyToCandidatePool={handleCopyToCandidatePool}
					onDelete={handleWatchListDelete}
					onEdit={handleWatchListEdit}
					onItemsDelete={handleItemsDelete}
					onSearch={handleSearch}
					owner={watchList.owner}
					search={search}
					selectedItems={selectedItems}
					sendEmailUpdate={watchList.send_email_update}
					sort={sort}
					tableColumns={watchListColumns}
				/>
				<DataTable
					canEditCells={canEditCells}
					onItemAdd={handleItemAdd}
					onItemEdit={handleItemEdit}
					onScroll={handleScroll}
					onSelect={handleItemSelection}
					onSort={handleSort}
					scrollX={scrollX || null}
					scrollY={scrollY || null}
					search={search}
					selectedRowKeys={selectedItemKeys}
					sort={sort}
					watchList={watchList}
					watchListColumns={watchListColumns}
				/>
			</Container>
		</DocumentTitle>
	);
}
