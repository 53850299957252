import { colors, effects } from '@drivecapital/design-system';
import { ControlMenuButton } from '@drivecapital/design-system/components';
import {
	ChevronDownIcon,
	ChevronUpIcon,
} from '@drivecapital/design-system/icons/arrows';
import React, {
	type Key,
	useCallback,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { Button, Menu, MenuTrigger, Popover } from 'react-aria-components';
import styled from 'styled-components';

import { Chevron, MenuPopover, MenuText } from '../menus';

const StyledPrimaryButton = styled(Button)`
	${effects.shadow.shadow}
	align-items: center;
	appearance: none;
	background: ${colors.button.primary};
	border: none;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	cursor: pointer;
	display: flex;
	gap: 4px;
	justify-self: stretch;
	transition: all 0.1s ease-in-out;
	flex: auto;

	&:hover {
		${effects.shadow.hover}
		background: ${colors.button.primaryHover};
	}
`;

const StyledSecondaryButton = styled(Button)`
	${effects.shadow.shadow}
	appearance: none;
	background: ${colors.button.primary};
	border: none;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	cursor: pointer;
	display: flex;
	gap: 4px;
	justify-self: stretch;
	transition: all 0.1s ease-in-out;

	&:hover {
		${effects.shadow.hover}
		background: ${colors.button.primaryHover};
	}
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-self: stretch;
`;

interface Props {
	readonly label: string;
	readonly onAction: (action: Key) => void;
	readonly onHoverStart?: (() => void) | undefined;
	readonly primaryButtonRef?: React.RefObject<HTMLButtonElement | null>;
	readonly primaryButtonAction: Key;
	readonly secondaryMenuChildren: React.ReactElement;
	readonly secondaryMenuIsOpenOverride: boolean;
}

export default function SplitButton({
	onHoverStart,
	label,
	secondaryMenuChildren,
	secondaryMenuIsOpenOverride,
	primaryButtonRef,
	primaryButtonAction,
	onAction,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const internalPrimaryButtonRef = useRef<HTMLButtonElement | null>(null);

	useImperativeHandle<HTMLButtonElement | null, HTMLButtonElement | null>(
		primaryButtonRef,
		() => internalPrimaryButtonRef.current,
		[],
	);

	return (
		<StyledButtonWrapper>
			<StyledPrimaryButton
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
				ref={internalPrimaryButtonRef}
				onPress={() => {
					onAction(primaryButtonAction);
				}}
			>
				<MenuText>{label}</MenuText>
			</StyledPrimaryButton>
			<MenuTrigger onOpenChange={setIsOpen}>
				<StyledSecondaryButton>
					<Chevron
						as={
							secondaryMenuIsOpenOverride || isOpen
								? ChevronUpIcon
								: ChevronDownIcon
						}
					/>
				</StyledSecondaryButton>
				<MenuPopover triggerRef={internalPrimaryButtonRef}>
					<Menu onAction={onAction}>{secondaryMenuChildren}</Menu>
				</MenuPopover>
			</MenuTrigger>
		</StyledButtonWrapper>
	);
}

interface SplitControlButtonProps {
	readonly active: React.ComponentPropsWithoutRef<
		typeof ControlMenuButton
	>['active'];
	readonly label: React.ReactNode;
	readonly onAction: (action: Key) => void;
	readonly onHoverStart?: () => void;
	readonly popoverPlacement?: React.ComponentProps<
		typeof Popover
	>['placement'];
	readonly primaryButtonAction: Key;
	readonly primaryButtonRef?: React.RefObject<HTMLButtonElement | null>;
	readonly secondaryMenuChildren: React.ReactNode;
	readonly secondaryMenuIsOpenOverride: boolean;
	readonly variant?: React.ComponentPropsWithoutRef<
		typeof ControlMenuButton
	>['variant'];
}

export function SplitControlButton({
	active,
	label,
	onAction,
	onHoverStart,
	popoverPlacement,
	primaryButtonAction,
	primaryButtonRef,
	secondaryMenuChildren,
	secondaryMenuIsOpenOverride,
	variant,
}: SplitControlButtonProps) {
	const [isOpen, setIsOpen] = useState(false);
	const internalPrimaryButtonRef = useRef<HTMLButtonElement | null>(null);
	const handlePrimaryButtonPress = useCallback(() => {
		onAction(primaryButtonAction);
	}, [onAction, primaryButtonAction]);

	useImperativeHandle<HTMLButtonElement | null, HTMLButtonElement | null>(
		primaryButtonRef,
		() => internalPrimaryButtonRef.current,
		[],
	);

	return (
		<ControlMenuButton active={active} variant={variant}>
			<Button
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
				ref={internalPrimaryButtonRef}
				onPress={handlePrimaryButtonPress}
			>
				{label}
			</Button>
			{secondaryMenuChildren != null && (
				<MenuTrigger onOpenChange={setIsOpen}>
					<Button>
						{secondaryMenuIsOpenOverride || isOpen ? (
							<ChevronUpIcon />
						) : (
							<ChevronDownIcon />
						)}
					</Button>
					<Popover
						className="SplitControlMenu__SecondaryMenu"
						crossOffset={
							popoverPlacement === 'bottom right' ? 28 : void 0
						}
						placement={popoverPlacement}
						triggerRef={internalPrimaryButtonRef}
					>
						<Menu onAction={onAction}>{secondaryMenuChildren}</Menu>
					</Popover>
				</MenuTrigger>
			)}
		</ControlMenuButton>
	);
}
