import { range } from 'lodash';

import type { Pagination } from './types';

interface PageControlsResult {
	controls: Array<number | 'GAP'>;
	leftArrow: { disabled: boolean; visible: boolean };
	rightArrow: { disabled: boolean; visible: boolean };
}

/**
 * Compute the layout of pagination control buttons. Make best effort to keep selected page
 * in the middle of the control buttons. Show GAP to either side of the pages range
 * if our current selection isn't near an edge
 *
 * @params currentPage - The current page number
 * @params controlsToShow - The total number of slots[1] to show
 * @params totalPages - The total number of pages
 *
 * [1] A slot is either a clickable page number of an ellipsis to indicate overflow
 */
export function pageControls({
	controlsToShow,
	currentPage,
	totalPages,
}: {
	controlsToShow: number;
	currentPage: number;
	totalPages: number;
}): PageControlsResult {
	if (controlsToShow < 5) {
		throw new Error('controlsToShow must be at least 5');
	}

	if (totalPages <= 1) {
		return {
			controls: [],
			leftArrow: { disabled: true, visible: false },
			rightArrow: { disabled: true, visible: false },
		};
	}
	const leftArrow = { disabled: currentPage === 1, visible: true };
	const rightArrow = { disabled: currentPage === totalPages, visible: true };

	if (totalPages <= controlsToShow) {
		return {
			controls: Array.from({ length: totalPages }, (_, i) => i + 1),
			leftArrow,
			rightArrow,
		};
	}

	// 4 = min page, ellipsis, ellipsis, max page
	// How many non boundary slots do we have?
	const cursorWidth = controlsToShow - 4;
	const idealLeftCursor = Math.ceil(currentPage - (cursorWidth - 1) / 2);
	const idealRightCursor = idealLeftCursor + cursorWidth - 1;

	const tooCloseToLeft = idealLeftCursor < 3;
	const tooCloseToRight = idealRightCursor > totalPages - 2;

	let actualLeftCursor = idealLeftCursor;
	let actualRightCursor = idealRightCursor;
	if (!tooCloseToLeft && tooCloseToRight) {
		actualRightCursor = totalPages - 2;
		actualLeftCursor = actualRightCursor - cursorWidth + 1;
	} else if (tooCloseToLeft && !tooCloseToRight) {
		actualLeftCursor = 3;
		actualRightCursor = actualLeftCursor + cursorWidth - 1;
	}

	const controls: PageControlsResult['controls'] = [
		1,
		'GAP',
		// +1 is intentional as range is exclusive
		...range(actualLeftCursor, actualRightCursor + 1),
		'GAP',
		totalPages,
	];

	// If the value to the right of the first ellipsis is 3, just show two
	if (controls[2] === 3) {
		controls[1] = 2;
	}

	// if the value to the left of the second ellipsis is the third to last page, just show second to last
	if (controls[controls.length - 3] === totalPages - 2) {
		controls[controls.length - 2] = totalPages - 1;
	}

	return {
		controls,
		leftArrow,
		rightArrow,
	};
}

export function usePaginationControls({
	pagination,
	controlsToShow,
	resultsCount,
}: {
	pagination: Pagination;
	controlsToShow: number;
	resultsCount: number;
}) {
	const totalPages = Math.ceil(resultsCount / pagination.pageSize);
	const currentPage = Math.max(1, Math.min(pagination.page, totalPages));

	if (resultsCount <= pagination.pageSize) {
		return {
			beginningOfCurrentPage: Math.min(resultsCount, 1),
			controls: [],
			lastOnCurrentPage: resultsCount,
			leftArrow: { disabled: true, visible: false },
			rightArrow: { disabled: true, visible: false },
			totalPages,
		};
	}

	const { controls, leftArrow, rightArrow } = pageControls({
		controlsToShow,
		currentPage,
		totalPages,
	});
	const beginningOfCurrentPage = pagination.pageSize * (currentPage - 1) + 1;
	const lastOnCurrentPage = Math.min(
		resultsCount,
		pagination.pageSize * currentPage,
	);

	return {
		beginningOfCurrentPage,
		controls,
		lastOnCurrentPage,
		leftArrow,
		rightArrow,
		totalPages,
	};
}
