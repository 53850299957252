import { colors, fonts } from '@drivecapital/design-system';
import { Button } from '@drivecapital/design-system/components';
import { XIcon } from '@drivecapital/design-system/icons/system';
import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	${fonts.paragraph.paragraph};
	--box-shadow-color: ${colors.layer.layer};
	background: ${colors.layer.layer};
	border-radius: 12px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow: hidden;
	padding: 20px;
`;
export const EditorContainer = styled.div<{
	$contentHeight: number;
	$expandable: boolean;
	$expanded: boolean;
}>`
	position: relative;
	${({ $contentHeight, $expandable, $expanded }) =>
		$expandable
		&& !$expanded
		&& `
	max-height: ${$contentHeight}px;
	overflow: hidden;
	`}

	${({ $expandable, $expanded }) =>
		$expandable
		&& !$expanded
		&& `
	&:before {
		box-shadow: inset 0 0 0 0,
			inset 0 -22px 10px -12px var(--box-shadow-color);
		content: '';
		height: 100%;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
	}
	`}
`;

const StyledButton = styled(Button)`
	align-items: center;
	align-self: flex-start;
	display: flex;
	gap: 4px;

	> svg {
		height: 12px;
		width: 12px;
	}
`;
export function ExpandButton({
	className,
	expanded,
	onClick,
}: {
	readonly className?: string;
	readonly expanded: boolean;
	readonly onClick: (event: React.MouseEvent) => void;
}) {
	return (
		<StyledButton
			className={className}
			onClick={onClick}
			variant="secondary"
		>
			{expanded ? 'Collapse' : 'Expand'}
			{expanded ? <XIcon /> : null}
		</StyledButton>
	);
}

export const Header = styled.header`
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;

export const HeaderLeft = styled.div`
	${fonts.paragraph.strong};
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
	gap: 4px;
`;

export const HeaderCommenterLogo = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	flex: 0 0 12px;
	height: 20px;
	max-width: 20px;
	min-width: 20px;
	object-fit: contain;
	object-position: center;
`;

export const HeaderDate = styled.span`
	${fonts.paragraph.paragraph};
	color: ${colors.text.secondary};
`;
