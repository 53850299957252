/**
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2094%3A20244&mode=dev
 */
import React from 'react';
import styled from 'styled-components';

import { colors, effects } from '..';

const IconLinkButton = styled.a`
	${effects.shadow.shadow};
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	display: inline-block;
	height: 28px;
	padding: 4px;
	transition:
		border-color 0.1s ease-in-out,
		box-shadow 0.1s ease-in-out;
	width: 28px;

	&:hover {
		${effects.shadow.hover};
		border-color: ${colors.border.subtleHover};
	}

	& > svg {
		color: ${colors.icon.secondary};
		height: 100%;
		width: 100%;
	}
`;

export default function IconLink(
	props: Omit<React.ComponentProps<typeof IconLinkButton>, 'rel' | 'target'>,
) {
	return (
		<IconLinkButton rel="noopener noreferrer" target="_blank" {...props} />
	);
}
