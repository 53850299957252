import { useMemo } from 'react';

import { useUser } from '../authentication';
import { useUserPreferences } from '../authentication/user-preferences';
import { hasConnectedInbox } from '../components/Authorized';
import capitalize from '../utils/capitalize';
import ImmutableURLSearchParams from '../utils/immutable-url-search-params';
import toSentence from '../utils/to-sentence';

import {
	type AllFilters,
	ContactedFilterControl,
	descriptionParts as filterDescriptionParts,
	FunnelActionFilterControl,
} from './filters';
import { buildSearchParamsFromFilters } from './filters/use-filters';
import { buildSearchParamsFromPagination } from './pagination/use-pagination';
import type { Sorting } from './sorting';
import { buildSearchParamsFromSorting } from './sorting/use-sorting';
import type { FilterControls, SearchRequest } from './types';
import { buildSearchParamsFromEntityType } from './use-entity-type';
import { buildSearchParamsFromSearchQuery } from './use-search-query';
import { buildSearchParamsFromSearchQueryType } from './use-search-query-type';

export function isSearchRequestEmpty(
	searchRequest: SearchRequest | null,
): boolean {
	if (searchRequest == null) return true;
	const emptyQuery =
		typeof searchRequest.query === 'string'
			? !searchRequest.query
			: searchRequest.query.length === 0;

	return emptyQuery && searchRequest.filters.length === 0;
}

export function hashSearchRequest(searchRequest: SearchRequest) {
	return JSON.stringify(searchRequest);
}

export function buildSearchParamsFromSearchRequest(
	searchRequest: SearchRequest,
) {
	let searchParams = new ImmutableURLSearchParams();
	searchParams = buildSearchParamsFromSearchQueryType(
		searchRequest.queryType,
		searchParams,
	);
	searchParams = buildSearchParamsFromSearchQuery(
		searchRequest.query,
		searchRequest.queryType,
		searchParams,
	);
	searchParams = buildSearchParamsFromEntityType(
		searchRequest.entityType,
		searchParams,
	);
	searchParams = buildSearchParamsFromFilters(
		searchRequest.filters,
		searchParams,
	);
	searchParams = buildSearchParamsFromPagination(
		searchRequest.pagination,
		searchParams,
	);
	searchParams = buildSearchParamsFromSorting(
		searchRequest.sorting,
		searchParams,
	);

	return searchParams;
}

function queryDescription(query: SearchRequest['query']) {
	if (typeof query === 'string' && query) {
		return `matching '${query}'`;
	} else if (typeof query === 'string') {
		return '';
	} else {
		return `similar to ${toSentence(query.map((q) => q.name))}`;
	}
}

export function plainTextDescription(searchRequest: SearchRequest) {
	const parts: string[] = [];

	parts.push(capitalize(searchRequest.entityType));

	const queryDesc = queryDescription(searchRequest.query);
	if (queryDesc) {
		parts.push(queryDesc);
	}

	if (searchRequest.filters.length > 0) {
		parts.push('that');
		parts.push(
			toSentence(
				filterDescriptionParts(searchRequest.filters as AllFilters[]),
			),
		);
	}

	return `${parts.join(' ')}.`;
}

export interface DisabledSearchQueryAttributes {
	disabledFieldNames: Array<Sorting['fieldName']>;
	disabledFilters: Array<FilterControls>;
}

export function useDisabledSearchQueryAttributes(): DisabledSearchQueryAttributes {
	const user = useUser();
	const { data: userPreferences } = useUserPreferences();
	return useMemo(() => {
		const userHasConnectedInbox = hasConnectedInbox(user);
		const userHasHighBeamAssignments =
			userPreferences?.investor_high_beam_assignments;
		let disabledAttributes: DisabledSearchQueryAttributes = {
			disabledFieldNames: [],
			disabledFilters: [],
		};

		if (!userHasConnectedInbox) {
			disabledAttributes = {
				disabledFieldNames: [
					...disabledAttributes.disabledFieldNames,
					'lastContact',
				],
				disabledFilters: [
					...disabledAttributes.disabledFilters,
					ContactedFilterControl,
				],
			};
		}

		if (!userHasHighBeamAssignments) {
			disabledAttributes = {
				...disabledAttributes,
				disabledFilters: [
					...disabledAttributes.disabledFilters,
					FunnelActionFilterControl,
				],
			};
		}
		return disabledAttributes;
	}, [user, userPreferences]);
}
