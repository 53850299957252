/**
 * @file A rounded button containing only an icon.
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2029%3A19732&mode=dev
 */
import React from 'react';
import styled, { css } from 'styled-components';

import { colors, effects } from '..';

export const styles = css<{ size?: 'default' | 'small' }>`
	appearance: none;
	background: ${colors.button.secondary};
	border: 1px solid ${colors.button.secondaryBorder};
	border-radius: 999px;
	color: ${colors.icon.secondary};
	cursor: pointer;
	height: ${({ size }) => (size === 'small' ? 26 : 34)}px;
	padding: ${({ size }) => (size === 'small' ? 4 : 8)}px;
	transition: all 0.1s ease-in-out;
	width: ${({ size }) => (size === 'small' ? 26 : 34)}px;

	&:hover {
		${effects.shadow.hover};
		border-color: ${colors.button.secondaryBorderHover};
		color: ${colors.icon.primary};
	}

	&:disabled {
		border-color: ${colors.border.disabled};
		background: ${colors.button.disabled};
		box-shadow: none;
		color: ${colors.icon.disabled};
		cursor: not-allowed;
	}
`;
const Button = styled.button`
	${styles}
`;

interface Props extends React.ComponentPropsWithoutRef<'button'> {
	readonly as?: React.ComponentPropsWithoutRef<typeof Button>['as'];
	readonly children: React.ReactNode;
	readonly disabled?: boolean;
	readonly label: string;
	readonly size?: 'default' | 'small';
}

export default React.forwardRef(function IconButton(
	{ children, label, ...props }: Props,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<Button aria-label={label} {...props} ref={ref}>
			{children}
		</Button>
	);
});
