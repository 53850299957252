import { combineReducers } from 'redux';

import type { Action } from '../actions';
import type { NormalizedWatchListData } from '../components/watchlists/types';
import { ActionTypes } from '../constants';

export interface CandidatePool extends NormalizedWatchListData {
	job_order_id?: number;
	loaded: boolean;
}

type CandidatePoolById = Record<number, CandidatePool>;
const byId = (
	// Redux reducers take an optional state on first call.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state: CandidatePoolById = {},
	action: Action,
): CandidatePoolById => {
	switch (action.type) {
		case ActionTypes.CANDIDATE_POOLS_INVALIDATE: {
			return {};
		}
		case ActionTypes.CANDIDATE_POOLS_LOAD_SUCCESS: {
			const newState = { ...state };

			for (const candidatePool of action.payload) {
				const loaded = Boolean(newState[candidatePool.id]);
				const items =
					newState[candidatePool.id]?.items
					?? candidatePool.items.map((item) => ({
						...item,
						entity: {},
					}));

				newState[candidatePool.id] = {
					...newState[candidatePool.id],
					...candidatePool,
					items,
					loaded,
				};
			}

			return newState;
		}
		case ActionTypes.CANDIDATE_POOL_EDIT_SUCCESS: {
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					auto_update: action.payload.auto_update,
					columns: action.payload.columns,
					name: action.payload.name,
					public: action.payload.public,
					send_email_update: action.payload.send_email_update,
				},
			};
		}
		case ActionTypes.CANDIDATE_POOL_ITEMS_ADD_SUCCESS: {
			const newState = { ...state };
			const items = [
				...(newState[action.payload.candidatePoolId]?.items ?? []),
			];

			for (const newItem of action.payload.items) {
				const itemIndex = items.findIndex(
					(i) => i.id === newItem.entity.id,
				);

				if (itemIndex === -1) {
					items.push({
						extra: newItem.extra,
						id: newItem.entity.id,
						type: newItem.entity.type,
					});
				} else {
					items[itemIndex] = {
						...items[itemIndex],
						extra: newItem.extra,
					};
				}
			}

			newState[action.payload.candidatePoolId] = {
				...newState[action.payload.candidatePoolId],
				items,
			};

			return newState;
		}
		case ActionTypes.CANDIDATE_POOL_ITEM_ADD_SUCCESS: {
			return {
				...state,
				[action.payload.candidatePoolId]: {
					...state[action.payload.candidatePoolId],
					items: [
						...state[action.payload.candidatePoolId].items.filter(
							(item) => item.id !== action.payload.item.entity.id,
						),
						{
							extra: action.payload.item.extra,
							id: action.payload.item.entity.id,
							type: action.payload.item.entity.type,
						},
					],
				},
			};
		}
		case ActionTypes.CANDIDATE_POOL_ITEM_EDIT_SUCCESS: {
			return {
				...state,
				[action.payload.candidatePoolId]: {
					...state[action.payload.candidatePoolId],
					items: state[action.payload.candidatePoolId].items.map(
						(item) => {
							const id = action.payload.item.entity.id;

							if (item.id === id) {
								return {
									...item,
									extra: action.payload.item.extra,
								};
							} else {
								return item;
							}
						},
					),
				},
			};
		}
		case ActionTypes.CANDIDATE_POOL_ITEMS_REMOVE_SUCCESS: {
			const nextState = { ...state };
			const deletedPeopleIds = action.payload.people || [];
			const newItems = nextState[
				action.payload.candidatePoolId
			].items.filter((item) => {
				if (item.type !== 'people') {
					return true;
				} else {
					return !deletedPeopleIds.includes(item.id);
				}
			});
			nextState[action.payload.candidatePoolId].items = newItems;

			return nextState;
		}
		case ActionTypes.CANDIDATE_POOL_LOAD_SUCCESS: {
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					...action.payload,
					items: action.payload.items.map(({ entity, extra }) => ({
						extra,
						id: entity.id,
						type: entity.type,
					})),
					loaded: true,
				},
			};
		}
		default: {
			return state;
		}
	}
};

type CandidatePoolLoaded = boolean;
const loaded = (
	// Redux reducers take an optional state on first call.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state: CandidatePoolLoaded = false,
	action: Action,
): CandidatePoolLoaded => {
	switch (action.type) {
		case ActionTypes.CANDIDATE_POOLS_LOAD_SUCCESS:
			return true;
		case ActionTypes.CANDIDATE_POOLS_INVALIDATE:
			return false;
		default:
			return state;
	}
};

export interface CandidatePoolState {
	byId: CandidatePoolById;
	loaded: boolean;
}

const reducers = {
	candidatePools: combineReducers({ byId, loaded }),
};

export default reducers;
