import { queryOptions, useQuery } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import ErrorFallback from '../components/error-fallback';
import { getSecurityHeaders } from '../utils/api';

export const PATH = 'growth-investing';

const IFrame = styled.iframe`
	border: none;
	height: 100%;
	overflow: scroll;
	width: 100%;
`;

const queryKey = ['internal_dashboards', 'growth_investing'];

function growthDashboardHTMLQueryOptions() {
	return queryOptions({
		queryKey,
		queryFn: async ({ signal }) =>
			fetch('/api/v2/internal_dashboards/growth_investing', {
				signal,
				headers: {
					...getSecurityHeaders(),
					'Content-Type': 'text/html',
				},
			}).then(async (response) => {
				if (!response.ok) {
					throw new Error('Failed to fetch');
				}
				return response.text();
			}),
		staleTime: 1000 * 60 * 20, // 20 minutes
	});
}

export default function GrowthDashboardView() {
	const query = useQuery(growthDashboardHTMLQueryOptions());
	const { data: html, isLoading, isError, refetch } = query;
	const [actualRetryCount, setActualRetryCount] = useState(0);
	const onInitialLoad = isLoading && actualRetryCount === 0;
	const onSubsequentLoad = isLoading && actualRetryCount > 0;

	const handleRefresh = useCallback(() => {
		void refetch();
		setActualRetryCount((count) => count + 1);
	}, [refetch]);

	if (onInitialLoad) {
		return <div>Loading...</div>;
	}

	if (isError || onSubsequentLoad) {
		return (
			<ErrorFallback
				isRefreshDisabled={isLoading}
				onRefresh={handleRefresh}
			/>
		);
	}

	return <IFrame srcDoc={html} />;
}
