import { colors, effects } from '@drivecapital/design-system';
import React, { useCallback } from 'react';
import { Modal as AriaModal, Dialog } from 'react-aria-components';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const animateIn = keyframes`
	from {
		opacity: 0;
		scale: 0.9;
	}

	to {
		opacity: 1;
		scale: 1;
	}
`;

const animateOut = keyframes`
	from {
		opacity: 1;
		scale: 1;
	}

	to {
		opacity: 0;
		scale: 0.9;
	}
`;

const ModalOverlayStyles = createGlobalStyle<{ topPositionOffset: number }>`
	.react-aria-ModalOverlay {
		background: rgba(0, 0, 0, 0.50);
		display: flex;
		height: var(--visual-viewport-height);
		justify-content: center;
		left: 0;
		padding-top: calc(var(--visual-viewport-height) * ${(props) =>
			props.topPositionOffset});
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 100;
	}
`;

const StyledModal = styled(AriaModal)<{ $topPositionOffset: number }>`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border-radius: 8px;
	border: 1px solid ${colors.border.subtle};
	height: min-content;
	max-height: calc(
		var(--visual-viewport-height) *
			calc(1 - 2 * ${(props) => props.$topPositionOffset})
	);
	max-width: 80vw;
	outline: none;
	overflow: scroll;
	transform-origin: top center;

	&[data-entering] {
		animation: ${animateIn} 0.1s ease-in-out;
	}

	&[data-exiting] {
		animation: ${animateOut} 0.1s ease-in-out;
	}
`;

interface Props {
	readonly children: React.ReactNode;
	readonly className?: string;
	readonly isOpen: boolean;
	readonly onClose?: () => void;
	readonly onOpen?: () => void;
	readonly onOpenChange?: (isOpen: boolean) => void;
	readonly title: string;
	readonly topPositionOffset?: number;
}

/**
 * Render an aria compliant modal
 * @component
 * @param {number} topPositionOffset - Offset percentage from the top of the screen (0.1 = 10%, 0.5 = 50%, etc)
 */
export default function Modal({
	children,
	className,
	isOpen,
	onClose,
	onOpen,
	onOpenChange,
	title,
	topPositionOffset = 0.1,
}: Props) {
	const handleOpenChange = useCallback(
		(open: boolean) => {
			if (open && onOpen) {
				onOpen();
			}
			if (!open && onClose) {
				onClose();
			}
			if (onOpenChange) {
				onOpenChange(open);
			}
		},
		[onClose, onOpen, onOpenChange],
	);

	return (
		<>
			<ModalOverlayStyles topPositionOffset={topPositionOffset} />
			<StyledModal
				$topPositionOffset={topPositionOffset}
				className={className}
				isDismissable
				isOpen={isOpen}
				onOpenChange={handleOpenChange}
			>
				<Dialog aria-label={title} className="HerbieModal__container">
					{children}
				</Dialog>
			</StyledModal>
		</>
	);
}
