import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Button from '../../../components/button';

import DeleteButton from './delete';
import SaveButton from './save';

export * from './buttons';

// Herbie has fairly consistent styling for headers on each page.
// For Market Maps/WatchLists they are very similar.
// These buttons are to be shared whenever we need cancel/save/delete actions
// in the header

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	button {
		margin: 5px;
	}
`;

export type ActionResult = {
	message: null | string;
	success: boolean;
	// Allows us to update buttons first, then the parent component
	// can do whatever it needs to do. Helps resolve state
	// conflicts if the parent wants to hide these buttons after delete/save
	callback?: () => void;
};

interface Props {
	readonly deleteConfirmationMessage: string;
	readonly deleteEnabled: boolean;
	readonly onSave: () => Promise<ActionResult>;
	readonly onCancel: () => void;
	readonly onDelete: () => Promise<ActionResult>;
	readonly saveEnabled: boolean;
}

export type ButtonStatus = 'failed' | 'inProgress' | 'success' | 'idle';
type SaveButtonStatus = {
	type: 'saving';
	status: ButtonStatus;
	message: null | string;
};
type DeleteButtonStatus = {
	type: 'deleting';
	status: ButtonStatus | 'confirming';
	message: null | string;
};

type State = null | SaveButtonStatus | DeleteButtonStatus;

function HeaderActionButtons({
	onCancel,
	onDelete,
	onSave,
	saveEnabled,
	deleteEnabled,
	deleteConfirmationMessage,
}: Props): JSX.Element {
	const [state, setState] = useState<State>(null);

	const handleSave = useCallback(async () => {
		setState({
			type: 'saving',
			status: 'inProgress',
			message: null,
		});
		const result = await onSave();
		setState({
			type: 'saving',
			status: result.success ? 'success' : 'failed',
			message: result.message,
		});
		if (result.callback) result.callback();
	}, [onSave]);

	const handleDelete = useCallback(async () => {
		setState({
			type: 'deleting',
			status: 'inProgress',
			message: null,
		});
		const result = await onDelete();
		setState({
			type: 'deleting',
			status: result.success ? 'success' : 'failed',
			message: result.message,
		});
		if (result.callback) result.callback();
	}, [onDelete]);

	if (state !== null && state.type === 'saving') {
		return (
			<Container>
				<Button
					disabled={state.status === 'inProgress'}
					onClick={onCancel}
					neutral
					type="button"
				>
					Cancel
				</Button>
				<SaveButton
					disabled={!saveEnabled}
					status={state.status}
					message={state.message}
					onClick={handleSave}
				/>
			</Container>
		);
	} else if (state != null && state.type === 'deleting') {
		// Not showing save button
		return (
			<Container>
				<DeleteButton
					status={state.status}
					onClick={() =>
						setState({
							type: 'deleting',
							status: 'confirming',
							message: null,
						})
					}
					disabled={!deleteEnabled}
					confirmationMessage={deleteConfirmationMessage}
					onConfirm={handleDelete}
				/>
				{state.status === 'confirming' && (
					<Button
						onClick={() => setState(null)}
						neutral
						type="button"
					>
						Cancel
					</Button>
				)}
			</Container>
		);
	}
	// If no state we know neither the delete or save button has a special state
	return (
		<Container>
			{/* For now we only have market maps and watchlists using this.
			Watchlists do not disable the delete button. Market Maps cannot
			delete until that option is available in the
			api. Just Hide the delete button if its' disabled for now. */}
			{deleteEnabled && (
				<DeleteButton
					status="idle"
					onClick={() =>
						setState({
							type: 'deleting',
							status: 'confirming',
							message: null,
						})
					}
					disabled={!deleteEnabled}
					confirmationMessage={deleteConfirmationMessage}
					onConfirm={handleDelete}
				/>
			)}
			<Button onClick={onCancel} neutral type="button">
				Cancel
			</Button>
			<SaveButton
				// Disable button if in any state other than idle
				disabled={!saveEnabled}
				status="idle"
				message={null}
				onClick={handleSave}
			/>
		</Container>
	);
}

/** @deprecated Use Buttons and useHeaderActions instead **/
export default HeaderActionButtons;
