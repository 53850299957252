import { Map as ImmutableMap } from 'immutable';
import { useEffect } from 'react';

class ClearSubscribable {
	public subscriptions = ImmutableMap<string, () => void>();

	public subscribe(key: string, callback: () => void) {
		this.subscriptions = this.subscriptions.set(key, callback);

		return () => {
			this.unsubscribe(key);
		};
	}

	public unsubscribe(key: string) {
		this.subscriptions = this.subscriptions.delete(key);
	}

	public publish(key: string) {
		if (key === 'ALL') {
			this.subscriptions.forEach((callback) => {
				callback?.();
			});
		} else {
			this.subscriptions.get(key)?.();
		}
	}
}

const subscribable = new ClearSubscribable();

function useFilterClearSubscription(key: string, callback: () => void) {
	useEffect(() => {
		return subscribable.subscribe(key, callback);
	}, [key, callback]);
}

export { subscribable, useFilterClearSubscription };
