export default class ImmutableURLSearchParams {
	private readonly instance: URLSearchParams;

	constructor(
		init?: string[][] | Record<string, string> | string | URLSearchParams,
	) {
		this.instance = new URLSearchParams(init);
	}

	append(name: string, value: string): ImmutableURLSearchParams {
		const result = new ImmutableURLSearchParams(this.instance);
		result.instance.append(name, value);
		return result;
	}

	delete(name: string, value?: string): ImmutableURLSearchParams {
		const result = new ImmutableURLSearchParams(this.instance);
		result.instance.delete(name, value);
		return result;
	}

	entries(): IterableIterator<[string, string]> {
		return this.instance.entries();
	}

	equals(other: ImmutableURLSearchParams): boolean {
		return this.sort().toString() === other.sort().toString();
	}

	get(name: string): string | null {
		return this.instance.get(name);
	}

	getAll(name: string): string[] {
		return this.instance.getAll(name);
	}

	has(name: string, value?: string): boolean {
		return this.instance.has(name, value);
	}

	set(name: string, value: string): ImmutableURLSearchParams {
		const result = new ImmutableURLSearchParams(this.instance);
		result.instance.set(name, value);
		return result;
	}

	sort(): ImmutableURLSearchParams {
		const result = new ImmutableURLSearchParams(this.instance);
		result.instance.sort();
		return result;
	}

	toString(): string {
		return this.instance.toString();
	}

	// TODO: Support iteration with forEach, keys, values
}
