import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	RadioCheckboxIndeterminateSelectedIcon,
	RadioCheckboxSelectedIcon,
	RadioCheckboxUnselectedIcon,
	RadioCheckCircleIcon,
	RadioSelectedMultiColorIcon,
	RadioUnselectedMultiColorIcon,
} from '@drivecapital/design-system/icons/input-control';
import React from 'react';
import {
	Radio as AriaRadio,
	type RadioRenderProps,
} from 'react-aria-components';
import styled from 'styled-components';

const StyledRadio = styled(AriaRadio)`
	${fonts.paragraph.paragraph}
	align-items: center;
	color: ${colors.text.primary};
	cursor: pointer;
	display: flex;
	gap: 4px;
`;
const IconWrapper = styled.div<{ $isFocused: boolean }>`
	${({ $isFocused }) => $isFocused && effects.shadow.focus}

	border: 2px solid transparent;
	${({ $isFocused }) =>
		$isFocused && `border: 2px solid ${colors.semantic.focus};`}

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		height: 16px;
		width: 16px;
	}
`;
const Children = styled.span``;

function calculateIconColors({ isDisabled, isHovered }: RadioRenderProps) {
	if (isDisabled) {
		return {
			selected: {
				shapeColor: colors.icon.disabled,
				surroundColor: colors.icon.disabled,
			},
			unselected: {
				shapeColor: colors.icon.disabled,
				surroundColor: colors.icon.disabled,
			},
		};
	}
	if (isHovered) {
		return {
			selected: {
				shapeColor: colors.icon.onColor,
				surroundColor: colors.icon.interactiveHover,
			},
			unselected: {
				shapeColor: colors.icon.onColor,
				surroundColor: colors.icon.secondaryHover,
			},
		};
	}
	return {
		selected: {
			shapeColor: colors.icon.onColor,
			surroundColor: colors.icon.interactive,
		},
		unselected: {
			shapeColor: colors.icon.onColor,
			surroundColor: colors.icon.secondary,
		},
	};
}

type RadioVariant = 'default' | 'checklist' | 'checkbox' | 'indeterminate';

const SELECTED_ICONS = {
	default: RadioSelectedMultiColorIcon,
	checklist: RadioCheckCircleIcon,
	checkbox: RadioCheckboxSelectedIcon,
	indeterminate: RadioCheckboxIndeterminateSelectedIcon,
};
const UNSELECTED_ICONS = {
	default: RadioUnselectedMultiColorIcon,
	checklist: RadioUnselectedMultiColorIcon,
	checkbox: RadioCheckboxUnselectedIcon,
	indeterminate: RadioCheckboxUnselectedIcon,
};

function renderRadio(
	props: RadioRenderProps,
	children: React.ReactNode,
	variant: RadioVariant,
): React.ReactNode {
	const iconColors = calculateIconColors(props);
	const SelectedIcon = SELECTED_ICONS[variant];
	const UnselectedIcon = UNSELECTED_ICONS[variant];

	return (
		<>
			<IconWrapper $isFocused={props.isFocusVisible}>
				{props.isSelected ? (
					<SelectedIcon {...iconColors.selected} />
				) : (
					<UnselectedIcon {...iconColors.unselected} />
				)}
			</IconWrapper>
			<Children>{children}</Children>
		</>
	);
}

interface RadioProps
	extends Omit<React.ComponentProps<typeof AriaRadio>, 'children'> {
	readonly children: React.ReactNode;
	readonly variant?: RadioVariant;
}

export default function Radio({
	children,
	variant = 'default',
	...props
}: RadioProps) {
	return (
		<StyledRadio {...props}>
			{(renderProps) => renderRadio(renderProps, children, variant)}
		</StyledRadio>
	);
}
