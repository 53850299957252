import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import './dropdown.scss';

export type Alignment = 'left' | 'right' | 'justify';

interface Props {
	readonly align: Alignment;
	readonly children?: ReactNode;
	readonly className?: string;
	readonly control: ReactNode;
	readonly onClose: () => void;
	readonly open: boolean;
}

/**
 * @deprecated use `src/theme/components/aria-dropdown` instead
 */
export default class Dropdown extends React.Component<Props> {
	refContainer = React.createRef<HTMLDivElement>();

	static defaultProps = {
		align: 'left',
	};

	public override UNSAFE_componentWillReceiveProps(newProps: Props): void {
		if (this.props.open && !newProps.open) {
			this.removeEventListeners();
		} else if (!this.props.open && newProps.open) {
			this.addEventListeners();
		}
	}

	public override componentWillUnmount(): void {
		this.removeEventListeners();
	}

	addEventListeners = (): void => {
		window.addEventListener('click', this.handleGlobalClick);
		window.addEventListener('keydown', this.handleGlobalKeyDown);
	};

	removeEventListeners(): void {
		window.removeEventListener('click', this.handleGlobalClick);
		window.removeEventListener('keydown', this.handleGlobalKeyDown);
	}

	handleGlobalClick = ({ target }: MouseEvent): void => {
		if (
			this.props.open
			&& !this.refContainer.current?.contains(target as Node)
		) {
			this.props.onClose();
		}
	};

	handleGlobalKeyDown = ({ key }: KeyboardEvent): void => {
		if (key === 'Escape' && this.props.open) {
			this.props.onClose();
		}
	};

	public override render(): React.ReactElement<HTMLDivElement> {
		const classes = classNames(
			'Dropdown',
			`Dropdown--${this.props.align}`,
			this.props.className,
		);

		return (
			<div className={classes} ref={this.refContainer}>
				<div className="Dropdown-control">{this.props.control}</div>
				{this.props.open && (
					<div className="Dropdown-dropdown">
						{this.props.children}
					</div>
				)}
			</div>
		);
	}
}
