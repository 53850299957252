import partition from 'lodash.partition';
import uniqBy from 'lodash.uniqby';

import { get } from '../utils/api';
import assertExhaustive from '../utils/assert-exhaustive';
import ImmutableURLSearchParams from '../utils/immutable-url-search-params';
import reportError from '../utils/sentry';

import type { AutoCompleteItem, LocationType } from './types';

interface HereApiAdministrativeArea {
	administrativeAreaType: 'country' | 'county' | 'state';
	resultType: 'administrativeArea';
}

interface HereApiHouseNumber {
	resultType: 'houseNumber';
}

interface HereApiLocality {
	localityType: 'city' | 'district' | 'subdistrict' | 'postalCode';
	resultType: 'locality';
}

interface HereApiStreet {
	resultType: 'street';
}

interface HereApiIntersection {
	resultType: 'intersection';
}

interface BaseHereApiAutoCompleteItem {
	address: {
		block: string;
		building: string;
		city: string;
		countryCode: string;
		countryName: string;
		county: string;
		countyCode: string;
		district: string;
		houseNumber: string;
		label: string;
		postalCode: string;
		state: string;
		stateCode: string;
		street: string;
		streets: string[];
		subblock: string;
		subdistrict: string;
		unit: string;
	};
	administrativeAreaType: string;
	estimatedPointAddress: boolean;
	houseNumberType: string;
	id: string;
	language: string;
	localityType: string;
	politicalView: string;
	resultType: string;
	title: string;
}

type HereApiAutoCompleteItem =
	| (BaseHereApiAutoCompleteItem & HereApiAdministrativeArea)
	| (BaseHereApiAutoCompleteItem & HereApiHouseNumber)
	| (BaseHereApiAutoCompleteItem & HereApiIntersection)
	| (BaseHereApiAutoCompleteItem & HereApiLocality)
	| (BaseHereApiAutoCompleteItem & HereApiStreet);

function buildUnitedStatesOrCanadaName(item: HereApiAutoCompleteItem): string {
	switch (item.resultType) {
		case 'administrativeArea': {
			const adminAreaType = item.administrativeAreaType;
			switch (adminAreaType) {
				case 'state': {
					return item.address.state;
				}
				case 'country': {
					return item.address.countryName;
				}
				case 'county': {
					return `${item.address.county}, ${item.address.state}`;
				}
				default: {
					return assertExhaustive(adminAreaType);
				}
			}
		}
		case 'houseNumber': {
			return item.address.label.replace(/, (United States|Canada)$/u, '');
		}
		default: {
			return `${item.address.city}, ${item.address.state}`;
		}
	}
}

/**
 * BREADCRUMB: api.locations.canonical_location._get_canonical_name
 */
function buildAutoCompleteName(item: HereApiAutoCompleteItem): string {
	const isCanadaOrUS =
		item.address.countryCode === 'USA'
		|| item.address.countryCode === 'CAN';

	if (isCanadaOrUS) {
		return buildUnitedStatesOrCanadaName(item);
	} else if (
		item.resultType === 'administrativeArea'
		&& item.administrativeAreaType === 'country'
	) {
		return item.address.countryName;
	} else if (
		item.resultType === 'street'
		|| item.resultType === 'intersection'
	) {
		const nameParts = [item.address.city];
		const stateName = item.address.state || item.address.county;

		if (stateName.toLowerCase() !== item.address.city.toLowerCase()) {
			nameParts.push(stateName);
		}
		nameParts.push(item.address.countryName);

		return nameParts.join(', ');
	} else {
		return item.address.label;
	}
}

/**
 * BREADCRUMB: api.locations.canonical_location._create_canonical_location
 */
function buildAutoCompleteLocationType(
	item: HereApiAutoCompleteItem,
): LocationType {
	const { resultType } = item;

	switch (resultType) {
		case 'administrativeArea': {
			const { administrativeAreaType } = item;
			switch (administrativeAreaType) {
				case 'country': {
					return 'COUNTRY';
				}
				case 'county': {
					return 'COUNTY';
				}
				case 'state': {
					return 'STATE_PROVINCE';
				}
				default: {
					return assertExhaustive(administrativeAreaType);
				}
			}
		}
		case 'locality': {
			const { localityType } = item;
			switch (localityType) {
				case 'city':
				case 'district':
				case 'subdistrict':
				case 'postalCode': {
					return 'CITY';
				}
				default: {
					return assertExhaustive(localityType);
				}
			}
		}
		case 'houseNumber': {
			return 'ADDRESS';
		}
		case 'street':
		case 'intersection': {
			return 'CITY';
		}
		default: {
			return assertExhaustive(resultType);
		}
	}
}

/**
 * Normalize Here API response to something resembling a Herbie canonical location.
 * Mimics the behavior of `api.locations.canonical_location._create_canonical_location`
 */
function buildAutoCompleteItem(
	item: HereApiAutoCompleteItem,
): AutoCompleteItem {
	return {
		id: item.id,
		name: buildAutoCompleteName(item),
		type: buildAutoCompleteLocationType(item),
	};
}

interface AutoCompleteApiResponse {
	items: HereApiAutoCompleteItem[];
}

export interface Options {
	prioritizedCountryCodes: string[];
}

const defaultOptions: Options = {
	prioritizedCountryCodes: [],
};

// https://www.here.com/docs/bundle/geocoding-and-search-api-v7-api-reference/page/index.html#/paths/~1autocomplete/get
export async function autoComplete(
	query: string,
	options: Options = defaultOptions,
): Promise<AutoCompleteItem[]> {
	if (query === '') {
		return [];
	}

	const params = new ImmutableURLSearchParams()
		.set('query', query)
		.set('limit', '10');

	const url = `/locations/autocomplete/?${params.toString()}`;

	try {
		const data = await get<AutoCompleteApiResponse>(url);

		const uniqueItems = uniqBy(data.items, 'title');
		const [prioritizedItems, nonPrioritizedItems] = partition(
			uniqueItems,
			(item) =>
				options.prioritizedCountryCodes.includes(
					item.address.countryCode,
				)
				|| buildAutoCompleteName(item).toLowerCase().startsWith(query),
		);

		return uniqBy(
			[...prioritizedItems, ...nonPrioritizedItems].map(
				buildAutoCompleteItem,
			),
			'name',
		);
	} catch (error) {
		reportError(error);
		return [];
	}
}
