/**
 * @file
 * Semantic Symbol Utility Icon from [Figma][1].
 *
 * [1]: https://www.figma.com/file/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1%3A5206&mode=dev
 */
import React from 'react';
import styled from 'styled-components';

import { colors } from '..';

import {
	CheckCircleIcon,
	DiagonalCircleIcon,
	ExclamationMarkCircleIcon,
	InformationCircleIcon,
	QuestionMarkCircleMulticolorIcon,
	WarningIcon,
} from './system';

const shapeColors = {
	danger: colors.icon.onColor,
	forbidden: colors.icon.onColor,
	generic: colors.icon.onColor,
	info: colors.icon.onColor,
	success: colors.icon.onColor,
	warning: colors.icon.primary,
} as const;
const surroundColors = {
	danger: colors.border.danger,
	forbidden: colors.border.danger,
	generic: colors.border.inverse,
	info: colors.semantic.info,
	success: colors.semantic.success,
	warning: colors.semantic.warning,
} as const;

const Container = styled.div<{ $size: 'default' | 'small' }>`
	align-items: center;
	display: flex;
	flex-shrink: 0;
	height: ${({ $size }) => ($size === 'default' ? '24px' : '12px')};
	justify-content: center;
	width: ${({ $size }) => ($size === 'default' ? '24px' : '12px')};
`;

function assertExhaustive(value: never): never {
	throw new Error(
		`Unexpected value ${String(value)} in exhaustive switch/case.`,
	);
}

type SymbolType =
	| 'danger'
	| 'forbidden'
	| 'generic'
	| 'info'
	| 'success'
	| 'warning';

export default function SemanticSymbol({
	className,
	size = 'default',
	type,
}: {
	readonly className?: string;
	readonly size?: 'default' | 'small';
	readonly type: SymbolType;
}) {
	const iconProps = {
		shapeColor: shapeColors[type],
		surroundColor: surroundColors[type],
	};
	let icon: React.ReactNode;
	switch (type) {
		case 'danger':
			icon = <ExclamationMarkCircleIcon {...iconProps} />;
			break;
		case 'forbidden':
			icon = <DiagonalCircleIcon {...iconProps} />;
			break;
		case 'generic':
			icon = <QuestionMarkCircleMulticolorIcon {...iconProps} />;
			break;
		case 'info':
			icon = <InformationCircleIcon {...iconProps} />;
			break;
		case 'success':
			icon = <CheckCircleIcon {...iconProps} />;
			break;
		case 'warning':
			icon = <WarningIcon {...iconProps} />;
			break;
		default:
			assertExhaustive(type);
	}

	return (
		<Container className={className} $size={size}>
			{icon}
		</Container>
	);
}
