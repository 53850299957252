import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	RadioCheckboxIndeterminateSelectedIcon,
	RadioCheckboxSelectedIcon,
	RadioCheckboxUnselectedIcon,
	RadioCheckCircleIcon,
	RadioUnselectedMultiColorIcon,
} from '@drivecapital/design-system/icons/input-control';
import React from 'react';
import {
	Checkbox as AriaCheckbox,
	type CheckboxRenderProps,
} from 'react-aria-components';
import styled from 'styled-components';

const StyledCheckbox = styled(AriaCheckbox)`
	${fonts.paragraph.paragraph}
	align-items: center;
	color: ${colors.text.primary};
	cursor: pointer;
	display: flex;
	gap: 4px;
`;
const IconWrapper = styled.div<{ $isFocused: boolean }>`
	${({ $isFocused }) => $isFocused && effects.shadow.focus}

	border: 2px solid transparent;
	${({ $isFocused }) =>
		$isFocused && `border: 2px solid ${colors.semantic.focus};`}

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		height: 16px;
		width: 16px;
	}
`;
const Children = styled.span``;

function calculateIconColors({ isDisabled, isHovered }: CheckboxRenderProps) {
	if (isDisabled) {
		return {
			selected: {
				shapeColor: colors.icon.disabled,
				surroundColor: colors.icon.disabled,
			},
			unselected: {
				shapeColor: colors.icon.disabled,
				surroundColor: colors.icon.disabled,
			},
		};
	}
	if (isHovered) {
		return {
			selected: {
				shapeColor: colors.icon.onColor,
				surroundColor: colors.icon.interactiveHover,
			},
			unselected: {
				shapeColor: colors.icon.onColor,
				surroundColor: colors.icon.secondaryHover,
			},
		};
	}
	return {
		selected: {
			shapeColor: colors.icon.onColor,
			surroundColor: colors.icon.interactive,
		},
		unselected: {
			shapeColor: colors.icon.onColor,
			surroundColor: colors.icon.secondary,
		},
	};
}

type CheckboxVariant = 'default' | 'checklist' | 'indeterminate';

const SELECTED_ICONS = {
	default: RadioCheckboxSelectedIcon,
	checklist: RadioCheckCircleIcon,
	indeterminate: RadioCheckboxIndeterminateSelectedIcon,
};
const UNSELECTED_ICONS = {
	default: RadioCheckboxUnselectedIcon,
	checklist: RadioUnselectedMultiColorIcon,
	indeterminate: RadioCheckboxUnselectedIcon,
};

function renderCheckbox(
	props: CheckboxRenderProps,
	children: React.ReactNode,
	variant: CheckboxVariant,
): React.ReactNode {
	const iconColors = calculateIconColors(props);
	const SelectedIcon = SELECTED_ICONS[variant];
	const UnselectedIcon = UNSELECTED_ICONS[variant];

	return (
		<>
			<IconWrapper $isFocused={props.isFocusVisible}>
				{props.isSelected ? (
					<SelectedIcon {...iconColors.selected} />
				) : (
					<UnselectedIcon {...iconColors.unselected} />
				)}
			</IconWrapper>
			<Children>{children}</Children>
		</>
	);
}

interface CheckboxProps
	extends Omit<React.ComponentProps<typeof AriaCheckbox>, 'children'> {
	readonly children: React.ReactNode;
	readonly variant?: CheckboxVariant;
}

export default function Checkbox({
	children,
	variant = 'default',
	...props
}: CheckboxProps) {
	return (
		<StyledCheckbox {...props}>
			{(renderProps) => renderCheckbox(renderProps, children, variant)}
		</StyledCheckbox>
	);
}
