import React from 'react';
import { Navigate, type RouteObject } from 'react-router-dom';

import { useAuthorized } from './Authorized';
import type { Permission } from './Authorized';

export function AuthorizedElement({
	auth,
	children,
}: {
	readonly auth: Permission;
	readonly children: React.ReactNode;
}): JSX.Element {
	const authorized = useAuthorized(auth);
	if (authorized) return <>{children}</>;
	return <Navigate to="/" />;
}

export default function authorizedRoute(
	route: RouteObject,
	auth: Permission,
): RouteObject {
	return {
		...route,
		element: (
			<AuthorizedElement auth={auth}>{route.element}</AuthorizedElement>
		),
	};
}
