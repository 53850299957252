import type { SpringValue } from '@react-spring/web';
import { animated, useSpring } from '@react-spring/web';
import React, { useEffect, useRef, useState } from 'react';

type CollapsedState = 'COLLAPSED' | 'NOT_COLLAPSED';
type Styles = {
	height: SpringValue<string>;
	overflow: SpringValue<string>;
};

function hasOffsetHeight(element: Element | null): element is HTMLElement {
	return element !== null && 'offsetHeight' in element;
}

export function useCollapsibleContent(
	defaultCollapsed: CollapsedState = 'NOT_COLLAPSED',
): [
	React.MutableRefObject<HTMLDivElement | null>,
	Styles,
	React.Dispatch<React.SetStateAction<CollapsedState>>,
] {
	const bodyRef = useRef<HTMLDivElement | null>(null);
	const [collapsed, setCollapsed] =
		useState<CollapsedState>(defaultCollapsed);
	const [bodyStyle, animateBody] = useSpring(
		{
			height: '0px',
			config: { mass: 1, tension: 3000, friction: 275 },
			overflow: 'hidden',
		},
		[],
	);

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			const entry = entries[0];
			if (!hasOffsetHeight(entry.target)) return;
			const height =
				collapsed === 'NOT_COLLAPSED'
					? `${entry.target.offsetHeight}px`
					: '0px';
			const overflow = collapsed === 'COLLAPSED' ? 'hidden' : 'visible';

			void animateBody({
				height,
				overflow,
			});
		});
		if (bodyRef.current) observer.observe(bodyRef.current);

		return () => {
			observer.disconnect();
		};
	}, [animateBody, bodyRef, collapsed]);

	return [bodyRef, bodyStyle, setCollapsed];
}

interface Props {
	readonly children: React.ReactNode;
	readonly style: Styles;
}

export function CollapsibleContent({ children, style }: Props) {
	return <animated.div style={{ ...style }}>{children}</animated.div>;
}
