import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import styled from 'styled-components';

import type { ColumnDescriptor } from '../../components/watchlists/column';
import { getColumnDescriptorKey } from '../../components/watchlists/column';
import { trackEvent } from '../../utils/analytics';
import isTouchScreen from '../../utils/touchscreen';

import AddColumnDescriptor from './add-column-descriptor';
import EditColumnDescriptor from './column-descriptor';

const Container = styled.ol`
	display: grid;
	grid-auto-rows: auto;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	list-style-type: none;
	padding: 0;
	margin: 0;
`;

type Props = {
	readonly columnDescriptors: Array<ColumnDescriptor>;
	readonly protectedDescriptorKeys: Array<string>;
};

type State = {
	columnDescriptors: Array<ColumnDescriptor>;
};

export default class EditColumnDescriptors extends React.Component<
	Props,
	State
> {
	public override state: State = {
		columnDescriptors: this.props.columnDescriptors,
	};

	getColumnDescriptors(): Array<ColumnDescriptor> {
		return this.state.columnDescriptors;
	}

	handleColumnDescriptorAdd = (descriptor: ColumnDescriptor): void => {
		this.setState((state) => ({
			columnDescriptors: [...state.columnDescriptors, descriptor],
		}));
	};

	handleColumnDescriptorDelete = (index: number): void => {
		this.setState((state) => {
			const descriptors = [...state.columnDescriptors];
			descriptors.splice(index, 1);
			return {
				columnDescriptors: descriptors,
			};
		});
	};

	handleColumnDescriptorEdit = (
		index: number,
		descriptor: ColumnDescriptor,
	): void => {
		this.setState((state) => {
			const descriptors = [...state.columnDescriptors];
			descriptors[index] = descriptor;

			return {
				columnDescriptors: descriptors,
			};
		});
	};

	handleSort = (fromIndex: number, toIndex: number): void => {
		this.setState((state) => {
			const descriptors = [...state.columnDescriptors];
			// set at correct index
			descriptors.splice(toIndex, 0, descriptors.splice(fromIndex, 1)[0]);
			trackEvent(
				'Dragged Watch List Column',
				'watchlist-column',
				'watchlist-detail',
			);
			return {
				columnDescriptors: descriptors,
			};
		});
	};

	public override render(): JSX.Element {
		return (
			<Container>
				<DndProvider
					backend={isTouchScreen() ? TouchBackend : HTML5Backend}
				>
					{this.state.columnDescriptors.map((descriptor, i) => (
						<EditColumnDescriptor
							columnDescriptor={descriptor}
							editable={
								!this.props.protectedDescriptorKeys.includes(
									getColumnDescriptorKey(descriptor),
								)
							}
							index={i}
							key={getColumnDescriptorKey(descriptor)}
							onDelete={this.handleColumnDescriptorDelete}
							onEdit={this.handleColumnDescriptorEdit}
							onSort={this.handleSort}
						/>
					))}
				</DndProvider>
				<AddColumnDescriptor
					columnDescriptors={this.state.columnDescriptors}
					onAdd={this.handleColumnDescriptorAdd}
				/>
			</Container>
		);
	}
}
