import React, { lazy } from 'react';

import authorizedRoute from '../components/authorized-route';

const View = lazy(async () => import('./view'));

export const path = 'market-maps';
export const routes = authorizedRoute(
	{
		path,
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	'contacts.market_maps',
);

export { useMarketMaps } from './api';
export type { MarketMapTree } from './region-tree';
export { decodeReferrerQuery } from './utils';
