import { ErrorBoundary } from '@sentry/react';
import React, { Suspense, useEffect } from 'react';
import DocumentTitle from 'react-document-title';

import Header from '../components/header';
import HerbieLoader from '../components/herbie-loader';
import SuspenseFallback from '../components/suspense-fallback';
import View from '../components/view';
import { trackEvent } from '../utils/analytics';

import Dashboard from './dashboard';

function Fallback({ error }: { readonly error: unknown }) {
	if (error instanceof Error) {
		return <p>{error.message}</p>;
	}

	return <p>An unknown error occurred.</p>;
}

export default function LPFundraisingView() {
	useEffect(() => {
		// This tracks: anytime a user visits the LP Fundraising page
		trackEvent('Visit New Page', 'lp-fundraising-view', 'lp-fundraising');
	}, []);

	return (
		<DocumentTitle title="LPs">
			<View>
				<Header />
				<ErrorBoundary fallback={Fallback}>
					<Suspense
						fallback={
							<SuspenseFallback>
								<HerbieLoader />
							</SuspenseFallback>
						}
					>
						<Dashboard />
					</Suspense>
				</ErrorBoundary>
			</View>
		</DocumentTitle>
	);
}
