import { colors, effects, fonts } from '@drivecapital/design-system';
import { TextInput } from '@drivecapital/design-system/components';
import React, { useCallback, useMemo, useState } from 'react';
import {
	type Key as AriaKey,
	ComboBox,
	InputContext,
	type InputProps,
	ListBox,
	ListBoxItem,
	Popover,
	useContextProps,
} from 'react-aria-components';
import styled from 'styled-components';

import { base64Decode, base64Encode } from '../../utils/safe-b64';
import type { AutoCompleteItem } from '../types';

import useAutoComplete, {
	type AutoCompleteOptions,
	defaultOptions as defaultAutoCompleteOptions,
} from './use-auto-complete';

const Container = styled.div``;
const StyledPopover = styled(Popover)<{ visible: boolean }>`
	${(props) => !props.visible && 'display: none;'}
	&[data-trigger='ComboBox'] {
		// 2 1px borders + 12px horizontal padding
		width: calc(var(--trigger-width) + 26px);
		margin-left: -13px;

		.react-aria-ListBox {
			${effects.shadow.hover}
			-webkit-overflow-scrolling: touch;
			background: ${colors.layer.layer};
			border-radius: 8px;
			border: 1px solid ${colors.border.subtle};
			max-height: 20vh;
			overflow-scrolling: touch;
			overflow-x: hidden;
			overflow-y: scroll;
		}

		.react-aria-ListBoxItem {
			${fonts.paragraph.paragraph}
			cursor: pointer;
			padding: 8px 12px;

			span {
				color: ${colors.text.secondary};
			}

			&[data-focus-visible] {
				background: ${colors.layer.hover};
			}

			&[data-hovered] {
				background: ${colors.layer.hover};
			}

			&[data-disabled] {
				color: ${colors.text.disabled};
			}
		}
	}
`;

const Input = React.forwardRef(function Search(
	props: React.ComponentProps<typeof TextInput> & InputProps,
	ref: React.ForwardedRef<HTMLInputElement>,
) {
	// https://react-spectrum.adobe.com/react-aria/ComboBox.html#custom-children
	// eslint-disable-next-line no-param-reassign
	[props, ref] = useContextProps(props, ref, InputContext);

	return <TextInput {...props} ref={ref} />;
});

interface ComboBoxItem {
	id: string;
	text: string;
}

const EMPTY_ITEM: ComboBoxItem = {
	id: 'isAnEmptyItem',
	text: 'No results found',
};

function autoCompleteToComboBoxItem(item: AutoCompleteItem): ComboBoxItem {
	return {
		id: base64Encode(JSON.stringify(item)),
		text: item.name,
	};
}

function itemKeyToAutoComplete(itemKey: string): AutoCompleteItem {
	return JSON.parse(base64Decode(itemKey)) as AutoCompleteItem;
}

interface ControlledProps {
	readonly ariaLabel: string;
	readonly autoCompleteItems: AutoCompleteItem[];
	readonly autoCompleteItemsLoading: boolean;
	readonly autoFocus?: boolean;
	readonly className?: string;
	readonly htmlId: string;
	readonly label?: React.ReactNode;
	readonly onQueryChange: (query: string) => void;
	readonly onSelect: (item: AutoCompleteItem | null) => void;
	readonly placeholder?: string;
	readonly query: string;
}

export function ControlledAutoCompleteInput({
	ariaLabel,
	autoCompleteItems,
	autoCompleteItemsLoading,
	autoFocus,
	className,
	htmlId,
	label,
	onQueryChange,
	onSelect,
	placeholder,
	query,
}: ControlledProps) {
	const items = useMemo(
		() =>
			!autoCompleteItemsLoading && autoCompleteItems.length === 0
				? [EMPTY_ITEM]
				: autoCompleteItems.map(autoCompleteToComboBoxItem),
		[autoCompleteItems, autoCompleteItemsLoading],
	);
	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onQueryChange(event.target.value);
		},
		[onQueryChange],
	);
	const handleSelectionChange = useCallback(
		(key: AriaKey | null) => {
			if (typeof key !== 'string') return;
			if (key === EMPTY_ITEM.id) return;

			onSelect(itemKeyToAutoComplete(key));
		},
		[onSelect],
	);
	const handleBlur = useCallback(() => {
		if (query === '') {
			onSelect(null);
		}
	}, [onSelect, query]);

	return (
		<ComboBox
			allowsCustomValue
			allowsEmptyCollection
			aria-label={ariaLabel}
			items={items}
			menuTrigger="focus"
			name={htmlId}
			onBlur={handleBlur}
			onSelectionChange={handleSelectionChange}
		>
			<Container className={className}>
				<Input
					autoFocus={autoFocus}
					label={label}
					onChange={handleInputChange}
					placeholder={placeholder}
					value={query}
				/>
				<StyledPopover visible={items.length > 0}>
					<ListBox<ComboBoxItem>>
						{(item) => (
							<ListBoxItem
								id={item.id}
								isDisabled={item.id === EMPTY_ITEM.id}
							>
								{item.text}
							</ListBoxItem>
						)}
					</ListBox>
				</StyledPopover>
			</Container>
		</ComboBox>
	);
}

type UncontrolledProps = Omit<
	ControlledProps,
	'query' | 'onQueryChange' | 'autoCompleteItems' | 'autoCompleteItemsLoading'
> & {
	readonly clearOnSelect?: boolean;
	readonly defaultQuery?: string;
	readonly options?: AutoCompleteOptions;
};

export function UncontrolledAutoCompleteInput({
	clearOnSelect = true,
	defaultQuery = '',
	onSelect,
	options = defaultAutoCompleteOptions,
	...props
}: UncontrolledProps) {
	const [query, setQuery] = useState(defaultQuery);
	const { isLoading, items } = useAutoComplete(query, options);

	const handleSelectionChange = useCallback(
		(item: AutoCompleteItem | null) => {
			if (clearOnSelect) {
				setQuery('');
			} else if (item != null) {
				setQuery(item.name);
			}
			onSelect(item);
		},
		[clearOnSelect, onSelect],
	);

	return (
		<ControlledAutoCompleteInput
			{...props}
			autoCompleteItems={items}
			autoCompleteItemsLoading={isLoading}
			onQueryChange={setQuery}
			onSelect={handleSelectionChange}
			query={query}
		/>
	);
}
