import React from 'react';
import styled from 'styled-components';

import { colors } from '..';

import Chip from './chip';

const Container = styled.div`
	display: grid;

	&:hover {
		cursor: pointer;
	}
`;

const Indicator = styled(Chip)<{ $show: boolean }>`
	grid-column: 1;
	grid-row: 1;
	justify-content: right;

	${(props) => !props.$show && 'opacity: 0; pointer-events: none;'}
`;

export default function QuantityIndicator({
	className,
	menu = true,
	onClick,
	open,
	quantity,
}: {
	readonly className?: string;
	readonly menu?: boolean;
	readonly onClick: () => void;
	readonly open: boolean;
	readonly quantity: number;
}) {
	return (
		<Container className={className}>
			<Indicator
				$show={open}
				background={colors.data.dust.layer}
				color={colors.text.secondary}
				label={`-${quantity}`}
				onClick={onClick}
				menu={menu}
				menuDirection="up"
			/>
			<Indicator
				$show={!open}
				background={colors.data.dust.layer}
				color={colors.text.secondary}
				label={`+${quantity}`}
				onClick={onClick}
				menu={menu}
				menuDirection="down"
			/>
		</Container>
	);
}
