import React from 'react';
import styled from 'styled-components';

import { type HeaderArgs, SortableHeader } from '../../table';
import SelectColumn from '../../table/columns/select';
import type { DrivePointOfContactOption, LPData } from '../types';

import { NOBODY } from './data';
import FilterDropdown from './filter-dropdown';

const Container = styled.div`
	align-items: baseline;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export default class PointOfContactColumn extends SelectColumn<LPData, string> {
	public constructor({
		drivePointOfContactOptions,
		...args
	}: Omit<
		ConstructorParameters<typeof SelectColumn>[0],
		'options' | 'select' | 'update'
	> & {
		drivePointOfContactOptions: ReadonlyArray<DrivePointOfContactOption>;
	}) {
		super({
			...args,
			disabledOptions: drivePointOfContactOptions
				.filter(({ assignable }) => !assignable)
				.map(({ username }) => username),
			options: Object.fromEntries([
				[NOBODY, ''],
				...drivePointOfContactOptions.map(
					({ username, first_name }) =>
						[username, first_name] as const,
				),
			]),
			select: (row) => row.drive_point_of_contact ?? NOBODY,
			update: (value) => ({
				drive_point_of_contact: value === NOBODY ? null : value,
			}),
		});
	}

	public override header({ onSort, props, sort, sortIndex }: HeaderArgs) {
		return (
			<SortableHeader
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				<Container>
					{this.name}
					<FilterDropdown />
				</Container>
			</SortableHeader>
		);
	}
}
