import TextEditor, { ToolbarToggle } from './editor';

export { type EditorState } from 'lexical';

export { nodeClassMapping } from './theme';
export { default as TemplatePlugin } from './template-plugin';
export { type FormattingType } from './toolbar';
export { default as useEditorClear } from './use-editor-clear';
export {
	deserializeEditorState,
	getTextContent,
	isEditorEmpty,
	serializeEditorState,
} from './utils';

export { ToolbarToggle };
export default TextEditor;
