import React, { type ComponentPropsWithoutRef, useCallback } from 'react';
import styled from 'styled-components';

import { colors, fonts } from '..';
import { XIcon } from '../icons/system';

import { inputStyles, StyledIcon, Wrapper } from './text-input';

import type { TextInput } from '.';

const Container = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 4px;

	&:has(input:disabled) {
		color: ${colors.text.disabled};
	}
`;

const StyledWrapper = styled(Wrapper)`
	flex-wrap: wrap;
	gap: 4px;

	input {
		${inputStyles}
		min-width: 250px;
	}
`;

const ChipButton = styled.button`
	${fonts.label.label}
	align-items: center;
	appearance: none;
	background: ${colors.layer.selected};
	border-radius: 4px;
	border: 1px solid ${colors.border.interactive};
	color: ${colors.text.interactive};
	cursor: pointer;
	display: flex;
	gap: 2px;
	outline: none;
	white-space: nowrap;

	svg {
		height: 12px;
		width: 12px;
	}

	&:focus-visible {
		outline: 2px solid ${colors.semantic.focus};
	}

	&:focus-visible,
	&:hover {
		background: ${colors.layer.selectedHover};
		border-color: ${colors.border.interactive};
	}
`;

const ClearButton = styled.button`
	align-items: center;
	appearance: none;
	background: none;
	border: none;
	border-radius: 4px;
	color: ${colors.icon.secondary};
	cursor: pointer;
	display: flex;
	outline: none;
	white-space: nowrap;

	&:focus-visible {
		outline: 2px solid ${colors.semantic.focus};
	}

	&:focus-visible,
	&:hover {
		color: ${colors.icon.secondaryHover};
	}

	svg {
		height: 16px;
		width: 16px;
	}
`;

export interface ChipData {
	id: number;
	text: string;
}

type SelectedTextInputProps = Omit<
	ComponentPropsWithoutRef<typeof TextInput>,
	'status' | 'label'
>;

export type DeletionTrigger = 'BACKSPACE' | 'CHIP_CLICK';

interface Props<T extends ChipData> extends SelectedTextInputProps {
	readonly chips: Array<T>;
	readonly containerRef?: React.RefObject<HTMLDivElement>;
	readonly onChipAdd?: (text: string) => void;
	readonly onChipDelete?: (
		id: T['id'],
		deletionTrigger: DeletionTrigger,
	) => void;
	readonly onClearAll?: () => void;
}

export default React.forwardRef(function ChipInput<T extends ChipData>(
	{
		chips,
		className,
		containerRef,
		icon,
		mode = 'light',
		onChipAdd,
		onChipDelete,
		onClearAll,
		onKeyDown,
		value,
		...props
	}: Props<T>,
	ref: React.Ref<HTMLInputElement>,
) {
	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Backspace' && !value && chips.length) {
				const lastChip = chips[chips.length - 1];
				event.preventDefault();
				event.stopPropagation();
				onChipDelete?.(lastChip.id, 'BACKSPACE');
			}

			if (event.key === 'Enter' && value && !onKeyDown) {
				const stringifiedValue =
					typeof value === 'string' ? value : String(value);
				onChipAdd?.(stringifiedValue);
				return;
			}

			onKeyDown?.(event);
		},
		[chips, onChipAdd, onChipDelete, onKeyDown, value],
	);

	const handleChipDelete = useCallback(
		(elementHtmlId: string) => {
			const chipId = parseInt(elementHtmlId, 10);
			if (!Number.isNaN(chipId)) {
				onChipDelete?.(chipId, 'CHIP_CLICK');
			}
		},
		[onChipDelete],
	);

	const handleChipClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			handleChipDelete(event.currentTarget.id);
		},
		[handleChipDelete],
	);
	const handleChipKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLButtonElement>) => {
			if (event.key === 'Backspace' || event.key === 'Delete') {
				handleChipDelete(event.currentTarget.id);
			}
		},
		[handleChipDelete],
	);

	const renderedChips = chips.map((chip) => (
		<ChipButton
			id={chip.id.toString()}
			key={chip.id}
			onClick={handleChipClick}
			onKeyDown={handleChipKeyDown}
		>
			{chip.text}
			<XIcon />
		</ChipButton>
	));

	return (
		<Container className={className} ref={containerRef}>
			<StyledWrapper $mode={mode} $status="default">
				{icon && <StyledIcon $mode={mode}>{icon}</StyledIcon>}
				{renderedChips}
				<input
					{...props}
					onKeyDown={handleKeyDown}
					ref={ref}
					value={value}
				/>
				{onClearAll && (chips.length > 0 || value) && (
					<ClearButton onClick={onClearAll}>
						<XIcon />
					</ClearButton>
				)}
			</StyledWrapper>
		</Container>
	);
});
