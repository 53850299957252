// @flow

import omit from 'lodash.omit';
import { Range } from 'rc-slider';
import React from 'react';
import type { ComponentType as ReactComponentType } from 'react';

import './range-filter.scss';
import { charcoalGray } from '../../colors';

import type { DisplayProps } from './description';
import SearchFilter from './filter';
import type { FilterComponentProps } from './filters';

type Position = [number, number];
type Value = [number | null, number | null];
type Text = [string, string];

export type RangeDisplayProps = {
	onRemoveMax: () => void,
	onRemoveMin: () => void,
	punctuator: string,
	resultCount: ?number,
	value: Value,
};

type Options = {
	convertPositionToValue?: (position: Position) => Value,
	convertValueToPosition?: (value: Value) => Position,
	convertValueToText?: (value: Value) => Text,
	max: number,
	min?: number,
};

type State = {
	position: Position,
};

export default (
	label: string,
	field: string,
	Display: ReactComponentType<RangeDisplayProps>,
	options: Options,
	componentIdentifier: string,
): ReactComponentType<FilterComponentProps> => {
	const maxValue = options.max;
	const minValue = typeof options.min === 'number' ? options.min : 0;

	const defaultConvertValueToPosition = ([min, max]: Value): Position => [
		min === null ? minValue : min,
		max === null ? maxValue : max,
	];
	const convertValueToPosition =
		typeof options.convertValueToPosition === 'function'
			? options.convertValueToPosition
			: defaultConvertValueToPosition;

	const [minPosition, maxPosition] = convertValueToPosition([
		minValue,
		maxValue,
	]);
	const defaultConvertPositionToValue = ([min, max]: Position): Value => [
		min === minPosition ? null : min,
		max === maxPosition ? null : max,
	];
	const convertPositionToValue =
		typeof options.convertPositionToValue === 'function'
			? options.convertPositionToValue
			: defaultConvertPositionToValue;

	const defaultConvertValueToText = ([min, max]: Value): Text => [
		String(min === null ? minValue : min),
		String(max === null ? maxValue : max),
	];
	const convertValueToText =
		typeof options.convertValueToText === 'function'
			? options.convertValueToText
			: defaultConvertValueToText;

	const convertPositionToText = (position: Position): Text =>
		convertValueToText(convertPositionToValue(position));

	const selectFilter = (filters) =>
		typeof filters[field] === 'undefined' ? [null, null] : filters[field];

	const updateFilters = (filters, value) =>
		value[0] === null && value[1] === null
			? omit(filters, field)
			: {
					...filters,
					[field]: value,
				};

	class RangeSearchFilter extends React.Component<
		FilterComponentProps,
		State,
	> {
		static get displayComponent() {
			return RangeSearchFilterDisplay;
		}

		static get field() {
			return field;
		}

		state: State = {
			position: convertValueToPosition(selectFilter(this.props.filters)),
		};

		UNSAFE_componentWillReceiveProps(newProps: FilterComponentProps) {
			this.setState({
				position: convertValueToPosition(
					selectFilter(newProps.filters),
				),
			});
		}

		handleAfterChange = (position: Position) => {
			const oldValue = selectFilter(this.props.filters);
			const newValue = convertPositionToValue(position);

			if (oldValue[0] !== newValue[0] || oldValue[1] !== newValue[1]) {
				this.props.onChange(
					updateFilters(this.props.filters, newValue),
					componentIdentifier,
				);
			}
		};

		/**
		 * Updates internal position.
		 * Prevents handles from crossing or intersecting.
		 */
		handleChange = (newPosition: Position) => {
			const oldPosition = this.state.position;

			if (oldPosition[0] !== newPosition[0]) {
				this.setState({
					position: [
						Math.min(newPosition[0], oldPosition[1] - 1),
						oldPosition[1],
					],
				});
			} else {
				this.setState({
					position: [
						oldPosition[0],
						Math.max(oldPosition[0] + 1, newPosition[1]),
					],
				});
			}
		};

		render() {
			const [minText, maxText] = convertPositionToText(
				this.state.position,
			);

			return (
				<SearchFilter label={label}>
					<div className="RangeSearchFilter-content">
						<div className="RangeSearchFilter-labels">
							<span className="RangeSearchFilter-label">
								{minText}
							</span>
							<span className="RangeSearchFilter-label">
								{maxText}
							</span>
						</div>
						<Range
							allowCross={false}
							handleStyle={[
								{
									borderColor: charcoalGray.string(),
								},
								{
									borderColor: charcoalGray.string(),
								},
							]}
							max={maxPosition}
							min={minPosition}
							onAfterChange={this.handleAfterChange}
							onChange={this.handleChange}
							railStyle={{
								height: '2px',
								marginTop: '1px',
							}}
							trackStyle={[
								{
									backgroundColor: charcoalGray.string(),
									height: '2px',
									marginTop: '1px',
								},
							]}
							value={this.state.position}
						/>
					</div>
				</SearchFilter>
			);
		}
	}

	class RangeSearchFilterDisplay extends React.Component<DisplayProps> {
		static defaultProps = {
			punctuator: '',
		};

		static get key() {
			return field;
		}

		static countValues(filters) {
			const [min, max] = selectFilter(filters);

			return (min !== null ? 1 : 0) + (max !== null ? 1 : 0);
		}

		handleRemoveMax = () => {
			this.props.onChange(
				updateFilters(this.props.filters, [
					selectFilter(this.props.filters)[0],
					null,
				]),
				`clear-${componentIdentifier}`,
			);
		};

		handleRemoveMin = () => {
			this.props.onChange(
				updateFilters(this.props.filters, [
					null,
					selectFilter(this.props.filters)[1],
				]),
				`clear-${componentIdentifier}`,
			);
		};

		render() {
			return (
				<Display
					onRemoveMax={this.handleRemoveMax}
					onRemoveMin={this.handleRemoveMin}
					punctuator={this.props.punctuator}
					resultCount={this.props.resultCount}
					value={selectFilter(this.props.filters)}
				/>
			);
		}
	}

	return RangeSearchFilter;
};
