/**
 * @file [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=149%3A118292&mode=dev
 */
import classnames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import type { Key as SelectKey } from 'react-aria-components';
import {
	Button,
	Label,
	ListBox,
	ListBoxItem,
	Popover,
	Select,
	SelectValue,
} from 'react-aria-components';
import styled, { keyframes } from 'styled-components';

import { colors, effects, fonts } from '../../';
import { ChevronDownIcon, ChevronUpIcon } from '../../icons/arrows';
import {
	CheckCircleIcon,
	ExclamationMarkCircleIcon,
	WarningIcon,
} from '../../icons/system';
import Chip from '../chip';

type Status = 'default' | 'success' | 'warning' | 'danger';

const statusBorderColors = {
	default: colors.border.subtle,
	success: colors.semantic.success,
	warning: colors.border.strong,
	danger: colors.semantic.danger,
} as const;

const statusIcons = {
	default: null,
	success: CheckCircleIcon,
	warning: WarningIcon,
	danger: ExclamationMarkCircleIcon,
} as const;

const statusTextColors = {
	warning: colors.text.warning,
	danger: colors.text.danger,
} as const;

const StatusText = styled.span<{
	$disabled: boolean;
	$status: 'warning' | 'danger';
}>`
	${fonts.label.label}
	color: ${({ $disabled, $status }) =>
		$disabled ? colors.text.disabled : statusTextColors[$status]};
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const StyledLabel = styled(Label)`
	${fonts.label.label}
	color: ${colors.text.secondary};
`;

const StyledButton = styled(Button)<{ $status: Status }>`
	${fonts.paragraph.paragraph}
	align-items: center;
	background: ${colors.field.field};
	border-radius: 8px;
	border: 1px solid ${({ $status }) => statusBorderColors[$status]};
	color: ${colors.text.primary};
	display: flex;
	flex-direction: row;
	padding: 6px 12px;
	transition: all 0.1s ease-in-out;
	white-space: nowrap;

	&:hover {
		background: ${colors.field.hover};
	}

	&:focus-visible {
		${effects.shadow.focus}
		background: ${colors.field.field};
		border-color: ${colors.semantic.focus};
		outline: none;
	}
`;

const StyledValue = styled(SelectValue)`
	align-items: flex-start;
	display: flex;
	flex: 1;
	gap: 4px;
	margin-right: 8px;
`;

const Quantity = styled(Chip)`
	padding: 0px 8px;
	margin-right: 8px;
`;

const Icon = styled.svg`
	height: 16px;
	margin-right: 2px;
	width: 16px;
`;

const Chevron = styled.svg`
	color: ${colors.icon.secondary};
	height: 16px;
	width: 16px;
`;

const StyledSelect = styled(Select)`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 4px;

	&[data-disabled] {
		${StyledLabel} {
			color: ${colors.text.disabled};
		}

		${StyledButton} {
			background: ${colors.field.disabled};
			border-color: ${colors.border.disabled};
			color: ${colors.text.disabled};
			cursor: not-allowed;
		}

		${Chevron} {
			color: ${colors.icon.disabled};
		}
	}
`;

const popoverAnimateIn = keyframes`
	from {
		opacity: 0;
		scale: 1 0.9;
	}

	to {
		opacity: 1;
		scale: 1;
	}
`;

const popoverAnimateOut = keyframes`
	from {
		opacity: 1;
		scale: 1;
	}

	to {
		opacity: 0;
		scale: 1 0.9;
	}
`;
const StyledPopover = styled(Popover)`
	&[data-trigger='Select'] {
		${effects.shadow.shadow}
		background: ${colors.layer.layer};
		border: 1px solid ${colors.border.subtle};
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		outline: none;
		overflow: scroll;
		padding: 6px 0;
		transform-origin: top left;

		&[data-entering] {
			animation: ${popoverAnimateIn} 0.1s ease-in-out;
		}

		&[data-exiting] {
			animation: ${popoverAnimateOut} 0.1s ease-in-out;
		}

		.react-aria-Menu {
			outline: none;
		}
	}
`;

const StyledOptions = styled(ListBox)`
	border: none;
	display: block;
	max-height: inherit;
	min-height: unset;
	outline: none;
	width: unset;
`;
const StyledOption = styled(ListBoxItem)`
	${fonts.paragraph.paragraph}
	align-items: center;
	background: ${colors.layer.layer};
	color: ${colors.text.secondary};
	display: flex;
	flex-direction: row;
	gap: 4px;
	outline: none;
	padding: 6px 8px;
	transition: all 0.1s ease-out;

	&[data-selected] {
		${fonts.paragraph.strong}
		background: ${colors.layer.active};
		color: ${colors.text.secondaryActive};
	}

	&:hover:not(:disabled),
	&[data-focus-visible] {
		&:not([data-disabled]) {
			cursor: pointer;
			background: ${colors.layer.hover};
			color: ${colors.text.secondaryHover};
		}
	}

	&[data-disabled] {
		cursor: not-allowed;
		opacity: 0.2;
	}
`;

export function DropdownOption({
	children,
	className,
	value,
}: {
	readonly children: React.ReactNode;
	readonly className?: string;
	readonly value: SelectKey;
}) {
	return (
		<StyledOption
			className={classnames(className, 'HerbieDropdown__option')}
			id={value}
			textValue={`${value}`}
		>
			{children}
		</StyledOption>
	);
}

/**
 * Container for a list of dropdown options
 *
 * @param {React.ReactNode} children - A list of <DropdownOption />'s
 */
export function DropdownOptions({
	children,
	className,
	placement,
}: {
	readonly children: React.ReactNode;
	readonly className?: string;
	readonly placement?: React.ComponentProps<typeof Popover>['placement'];
}) {
	const popoverProps: Partial<React.ComponentProps<typeof Popover>> = {};
	if (placement) popoverProps.placement = placement;
	if (className) popoverProps.className = className;

	return (
		<StyledPopover {...popoverProps}>
			<StyledOptions className="HerbieDropdown__option-list">
				{children}
			</StyledOptions>
		</StyledPopover>
	);
}

interface _DropdownProps {
	readonly autoFocus?: boolean;
	readonly children: React.ReactNode;
	readonly className?: string;
	readonly disabled?: boolean;
	readonly disabledKeys?: Array<SelectKey>;
	readonly id?: string;
	readonly name?: string;
	readonly placeholder?: string;
	readonly quantity?: number;
	readonly required?: boolean;
	readonly status?: Status;
	readonly statusText?: string;
	readonly valueChildren?: React.ReactNode;
}

interface _ControlledProps {
	defaultValue?: never;
	value: SelectKey | null;
	onChange: (value: SelectKey) => void;
}

interface _UncontrolledProps {
	defaultValue?: SelectKey;
	value?: never;
	onChange?: (value: SelectKey) => void;
}

interface _TitleAndLabel {
	label: React.ReactNode;
	title: string;
}

interface _TitleAndNoLabel {
	title: string;
	label?: never;
}

interface _NoTitleAndLabel {
	title?: never;
	label: React.ReactNode;
}

// react-aria-components requires either a label or aria-title
// rather than asserting in code, we can allow the type system to enforce this
type DropdownProps = (_ControlledProps | _UncontrolledProps)
	& (_TitleAndLabel | _TitleAndNoLabel | _NoTitleAndLabel)
	& _DropdownProps;

/**
 * An aria compliant dropdown
 *
 * @param {React.ReactNode} children - Must be <DropdownOptions /> or a react-aria <Popover />
 *
 * @example
 * <AriaDropdown>
 *   <DropdownOptions>
 *     <DropdownOption value="1">Option 1</DropdownOption>
 *     <DropdownOption value="2">Option 2</DropdownOption>
 *     <DropdownOption value="3">Option 3</DropdownOption>
 *  </DropdownOptions>
 * </AriaDropdown>
 */
// eslint-disable-next-line complexity
export default function Dropdown({
	autoFocus,
	children,
	className = '',
	defaultValue,
	disabled = false,
	disabledKeys,
	id,
	label,
	name,
	onChange,
	placeholder,
	quantity,
	required,
	status = 'default',
	statusText,
	title,
	value,
	valueChildren,
}: DropdownProps) {
	const [isOpen, setIsOpen] = useState(false);

	const staticAutoFocusValue = useRef(autoFocus);
	const hackyAutoFocusWorkaround = useCallback(
		(button: HTMLButtonElement) => {
			if (staticAutoFocusValue.current && button) {
				button.focus();
			}
		},
		[],
	);

	const StatusIcon = statusIcons[status];
	const selectProps: Partial<React.ComponentProps<typeof StyledSelect>> =
		id == null ? {} : { id };

	return (
		<Container className={className}>
			<StyledSelect
				aria-label={title}
				className={className}
				disabledKeys={disabledKeys}
				defaultSelectedKey={defaultValue}
				isDisabled={disabled}
				isRequired={required}
				name={name}
				onOpenChange={setIsOpen}
				onSelectionChange={onChange}
				placeholder={placeholder}
				selectedKey={value}
				{...selectProps}
			>
				{label && <StyledLabel>{label}</StyledLabel>}
				<StyledButton
					$status={status}
					className="HerbieDropdown__button"
					ref={hackyAutoFocusWorkaround}
				>
					<StyledValue className="HerbieDropdown__value">
						{valueChildren}
					</StyledValue>
					{quantity != null && (
						<Quantity
							color={
								disabled
									? colors.text.disabled
									: colors.text.secondary
							}
							background={
								disabled
									? colors.layer.disabled
									: colors.data.dust.layer
							}
							label={`+${quantity}`}
						/>
					)}
					{StatusIcon && <Icon as={StatusIcon} />}
					<Chevron>
						{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
					</Chevron>
				</StyledButton>
				{children}
			</StyledSelect>
			{(status === 'warning' || status === 'danger') && statusText && (
				<StatusText $disabled={disabled} $status={status}>
					{statusText}
				</StatusText>
			)}
		</Container>
	);
}
