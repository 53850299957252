import moment from 'moment';
import React, { useCallback } from 'react';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';

import * as colors from '../colors';
import { HelpIcon as UnstyledHelpIcon } from '../components/icons';
import Table from '../table';
import type { TableProps } from '../table';
import DateColumn from '../table/columns/date';
import HyperlinkColumn from '../table/columns/hyperlink';
import ImageColumn from '../table/columns/image';
import TextColumn from '../table/columns/text';

const Container = styled.div`
	border-radius: 6px;
	border: 1px solid ${colors.sectionBorder.toString()};
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
	background-color: ${colors.white.string()};
	width: 100%;
	height: 100%;
`;

const HelpIcon = styled(UnstyledHelpIcon)`
	height: 18px;
	width: 18px;
`;

const Title = styled.div`
	padding: 8px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 15%;
`;
const TableContainer = styled.div`
	overflow-x: auto;
	overflow-y: scroll;
	height: 85%;
`;

const StyledTable = styled(
	Table as React.ComponentType<TableProps<MissedDeal>>,
)`
	.HerbieTable__cell {
		&:last-child {
			text-align: right;
		}

		width: unset;
	}

	.HerbieTable__body {
		&:nth-of-type(odd) {
			background-color: ${colors.tableRowBlue.string()};
		}
	}
`;

const tableColumns = [
	new ImageColumn<MissedDeal>({
		select: (row) => ({
			src: row.company_logo_url,
			alt: `${row.company_name} url`,
			href: null,
		}),
	}),
	new HyperlinkColumn<MissedDeal>({
		name: '',
		select: (row) => ({
			value: row.company_name,
			href: `/companies/${row.company_id}`,
		}),
	}),
	new DateColumn<MissedDeal>({
		name: '',
		select: (row) => moment(row.funding_round_date),
	}),
	new TextColumn<MissedDeal>({
		name: '',
		select: (row) => row.funding_round_series,
	}),
];

export interface MissedDeal {
	company_id: number;
	company_logo_url: string;
	company_name: string;
	funding_round_date: string;
	funding_round_series: string;
}
export interface MissedDealApiResponse {
	most_recent_missed_deals: Array<MissedDeal>;
	total_missed_deals: number;
}

export default function InvestorMissedDeals({
	data,
}: {
	readonly data: MissedDealApiResponse;
}): JSX.Element {
	const getRowKey = useCallback(
		(row: MissedDeal) => `${row.company_id}-${row.funding_round_date}`,
		[],
	);
	return (
		<Container>
			<Title>
				<h3>Total Missed Deals: {data.total_missed_deals}</h3>{' '}
				<span
					data-for="helpIcon"
					data-tip="Funding rounds in a market map without a pass or contact"
				>
					<HelpIcon />
				</span>
				<Tooltip id="helpIcon" type="dark" delayShow={100} />
			</Title>
			<TableContainer>
				<StyledTable
					columns={tableColumns}
					data={data.most_recent_missed_deals}
					getRowKey={getRowKey}
				/>
			</TableContainer>
		</Container>
	);
}
