import { useCallback } from 'react';

import { useUser } from '../authentication';
import {
	type UserPreferences,
	useUserPreferences,
} from '../authentication/user-preferences';
import { trackEvent } from '../utils/analytics';
import assertExhaustive from '../utils/assert-exhaustive';

import {
	useCreateHerbieCatalyzedFollowUp,
	useSetOutreachCompanyPending,
} from './api';
import type { CompanyOutreach } from './types';

interface EmailClientUrl {
	opensNewTab: boolean;
	url: string;
}

const OBSERVED_REDIRECTED_USER_AGENTS = ['android', 'ipad', 'iphone'];

function detectGmailMobileSiteRedirect(): boolean {
	return OBSERVED_REDIRECTED_USER_AGENTS.some((observedUserAgent) =>
		navigator.userAgent.toLowerCase().includes(observedUserAgent),
	);
}

const willRedirect = detectGmailMobileSiteRedirect();

/**
 * Deep link to a specific thread in Gmail's web app. During manual testing, the only
 * way we've observed to get this reliabily working is through Drive's mail server. Google
 * redirects to their mobile site based on the user agent and does not preserve the desired
 * url during redirect. Therefore, we must do our best to predict whether they'll redirect
 * and adjust the url accordingly.
 *
 * NOTE: As of 2024-04 mobile Chrome straight up just doesn't work under any circumstances. It
 * will briefly show the intended mail thread before performing a client side redirect to the
 * top of the user's inbox.
 */
function gmailUrl(threadId: string) {
	if (willRedirect) {
		return `http://mail.drivecapital.com/mail/mu/mp/0/#cv/all/${threadId}`;
	}
	return `http://mail.drivecapital.com/mail/#all/${threadId}`;
}

function superhumanUrl(threadId: string, userEmail: string) {
	return `https://mail.superhuman.com/${userEmail}/thread/${threadId}`;
}

function superhumanMacClientUrl(threadId: string, userEmail: string) {
	return `superhuman://${userEmail}/thread/${threadId}`;
}

function emailThreadUrl(
	threadId: string,
	userEmail: string,
	preferences: UserPreferences,
): EmailClientUrl {
	const emailClient = preferences.email_client;
	switch (emailClient) {
		case 'GMAIL': {
			return { url: gmailUrl(threadId), opensNewTab: true };
		}
		case 'SUPERHUMAN':
		case 'SUPERHUMAN_WEB_CLIENT': {
			return {
				url: superhumanUrl(threadId, userEmail),
				opensNewTab: true,
			};
		}
		case 'SUPERHUMAN_MAC_CLIENT': {
			return willRedirect
				? {
						url: superhumanUrl(threadId, userEmail),
						opensNewTab: true,
					}
				: {
						url: superhumanMacClientUrl(threadId, userEmail),
						opensNewTab: false,
					};
		}
		default: {
			return assertExhaustive(emailClient);
		}
	}
}

export function useEmailThreadUrl() {
	const user = useUser();
	const { data: userPreferences } = useUserPreferences();
	const calculateUrl = useCallback(
		(outreachLinkBase: CompanyOutreach['outreachLinkBase']) => {
			if (userPreferences && outreachLinkBase) {
				return emailThreadUrl(
					outreachLinkBase,
					user.email,
					userPreferences,
				);
			}
			return null;
		},
		[user.email, userPreferences],
	);

	return calculateUrl;
}

export function useOpenEmailThread() {
	const user = useUser();
	const { data: userPreferences } = useUserPreferences();
	const { mutate: createHerbieCatalyzedFollowUp } =
		useCreateHerbieCatalyzedFollowUp();
	const { mutate: setOutreachCompanyPending } =
		useSetOutreachCompanyPending();

	return useCallback(
		(
			outreachId: CompanyOutreach['id'],
			outreachLinkBase: CompanyOutreach['outreachLinkBase'],
			handleNavigation: boolean,
			analyticsComponentName: string,
		) => {
			trackEvent(
				'Open Outreach Communication Thread',
				analyticsComponentName,
				'home-page',
			);
			if (outreachLinkBase && userPreferences) {
				createHerbieCatalyzedFollowUp(outreachId);
				setOutreachCompanyPending({ outreachId });

				if (handleNavigation) {
					const { url, opensNewTab } = emailThreadUrl(
						outreachLinkBase,
						user.email,
						userPreferences,
					);
					if (opensNewTab) {
						window.open(url, '_blank');
					} else {
						window.location.replace(url);
					}
				}
			}
		},
		[
			createHerbieCatalyzedFollowUp,
			setOutreachCompanyPending,
			user,
			userPreferences,
		],
	);
}
