import { colors, effects, fonts } from '@drivecapital/design-system';
import { Chip } from '@drivecapital/design-system/components';
import { Temporal } from '@js-temporal/polyfill';
import moment from 'moment';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import type {
	PipelineStageItem,
	PipelineStageTransition,
	PipelineStageTransitionDatum,
	StageTransitionDataRule,
	StageTransitionDataValue,
	StageTransitionMultiValueDataValue,
} from '../pipelines';
import { useUpdatePipelineItemTransitionDatum } from '../pipelines';
import TransitionNotesSection, { useReplyControls } from '../pipelines/notes';
import assertExhaustive from '../utils/assert-exhaustive';

import EditableDatum from './editable-transition-datum';
import type { TransitionData } from './editable-transition-datum';
import { stageChipColors } from './job-order-detail';

const FeedEvent = styled.div`
	${effects.shadow.shadow};
	background-color: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 12px;
	display: flex;
	flex-direction: column;
`;
const Transition = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 20px;
`;
const EventHeader = styled.div`
	${fonts.label.strong};
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
const RecruiterAndDate = styled.div`
	align-items: center;
	display: flex;
	gap: 16px;

	span {
		${fonts.label.label};
		color: ${colors.text.secondary};
	}
`;
const Recruiter = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;

	img {
		border-radius: 50%;
		border: 1px solid ${colors.border.subtle};
		flex: 0 0 12px;
		height: 20px;
		max-width: 20px;
		min-width: 20px;
		object-fit: contain;
		object-position: center;
	}
`;
const StageTransition = styled.div``;
const StageTransitionData = styled.div`
	color: ${colors.text.primary};
	display: flex;
	flex-direction: column;
`;
const StageTransitionDatum = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
	max-width: max-content;
`;
const StageTransitionDatumTitle = styled.div`
	color: ${colors.text.secondary};
`;
const StageTransitionDatumValue = styled.p``;

function parseTransitionDate(
	date: PipelineStageTransition['createdAt'],
): Temporal.Instant {
	try {
		return Temporal.Instant.from(date);
	} catch {
		const plainDate = Temporal.PlainDate.from(date);

		return Temporal.ZonedDateTime.from({
			timeZone: 'UTC',
			year: plainDate.year,
			month: plainDate.month,
			day: plainDate.day,
			hour: 0,
			minute: 0,
			second: 0,
		}).toInstant();
	}
}

function formatTransitionDate(
	date: PipelineStageTransition['createdAt'],
): string {
	return parseTransitionDate(date)
		.toZonedDateTimeISO('UTC')
		.toPlainDate()
		.toLocaleString('en-Us', {
			day: '2-digit',
			month: '2-digit',
			year: '2-digit',
		});
}

function EditableStageTransitionDatum({
	handleDataEdit,
	rule,
	datum,
}: {
	readonly handleDataEdit: (
		ruleId: number,
		type: StageTransitionDataRule['type'],
		value: StageTransitionDataValue['value'],
		extra: string | null,
	) => Promise<void>;
	readonly rule: StageTransitionDataRule;
	readonly datum: PipelineStageTransitionDatum | null;
}): JSX.Element {
	const ruleType = rule.type;
	const initialData: TransitionData = {
		extra: null,
		type: ruleType,
		value: null,
	};
	switch (ruleType) {
		case 'DATE':
		case 'NUMERIC':
		case 'TEXT': {
			initialData.value = datum === null ? '' : datum.value;
			break;
		}
		case 'MULTI_VALUE': {
			initialData.value = datum === null ? '' : datum.value;
			initialData.extra =
				(datum as StageTransitionMultiValueDataValue)?.extra || null;
			break;
		}
		default: {
			assertExhaustive(ruleType);
		}
	}
	return (
		<StageTransitionDatum key={rule.id}>
			<EditableDatum
				initialData={initialData}
				onEnter={handleDataEdit}
				ruleId={rule.id}
				ruleOptions={rule.type === 'MULTI_VALUE' ? rule.options : []}
				ruleTitle={rule.title}
			/>
		</StageTransitionDatum>
	);
}

function ReadOnlyStageTransitionDatum({
	rule,
	datum,
}: {
	readonly rule: StageTransitionDataRule;
	readonly datum: PipelineStageTransitionDatum | null;
}): JSX.Element {
	let renderedValue = '';
	let extra = '';
	if (datum == null || datum.value === '') {
		renderedValue = '--';
	} else if (datum.type === 'MULTI_VALUE') {
		renderedValue = datum.label;
		extra = datum.extra ? ` (${datum.extra})` : '';
	} else if (datum.type === 'DATE') {
		renderedValue = moment(datum.value).format('MM/DD/YYYY');
	} else {
		renderedValue = String(datum.value);
	}

	return (
		<StageTransitionDatum>
			<StageTransitionDatumTitle>
				{rule.title}:{' '}
			</StageTransitionDatumTitle>
			<StageTransitionDatumValue>
				{renderedValue} {extra}
			</StageTransitionDatumValue>
		</StageTransitionDatum>
	);
}

interface Props {
	readonly editable: boolean;
	readonly item: PipelineStageItem;
	readonly pipelineId: number;
	readonly transition: PipelineStageTransition;
	readonly transitionData: Array<{
		rule: StageTransitionDataRule;
		value: PipelineStageTransitionDatum | null;
	}>;
}

export default function PipelineProfileCardTransition({
	editable,
	item,
	pipelineId,
	transition,
	transitionData,
}: Props): JSX.Element {
	const { renderNotes, autoFocusNewNote, ReplyControls } = useReplyControls(
		transition.notes,
		editable,
	);

	const updateTransitionDatum = useUpdatePipelineItemTransitionDatum();
	const handleDataEdit = useCallback(
		async (
			ruleId: number,
			transitionId: number,
			type: StageTransitionDataRule['type'],
			value: StageTransitionDataValue['value'],
			extra: string | null,
		) => {
			await updateTransitionDatum.mutateAsync({
				extra,
				itemId: item.id,
				pipelineId,
				profileId: item.profileId,
				ruleId,
				transitionId,
				type,
				value,
			});
			return void 0;
		},
		[updateTransitionDatum, item.id, pipelineId, item.profileId],
	);

	return (
		<FeedEvent key={transition.id}>
			<Transition>
				<EventHeader>
					<Chip
						background={
							stageChipColors(transition.destinationStage.title)
								.backgroundColor
						}
						color={
							stageChipColors(transition.destinationStage.title)
								.textColor
						}
						label={transition.destinationStage.title}
					/>
					<RecruiterAndDate>
						<Recruiter>
							<img src={transition.createdBy.photoUrl} />
							{transition.createdBy.firstName} {''}
							{transition.createdBy.lastName}
						</Recruiter>
						<span>
							{formatTransitionDate(transition.createdAt)}
						</span>
					</RecruiterAndDate>
				</EventHeader>
				<StageTransition>
					<StageTransitionData>
						{!editable
							&& transitionData.map(({ rule, value }) => (
								<ReadOnlyStageTransitionDatum
									key={rule.id}
									rule={rule}
									datum={value}
								/>
							))}
						{editable
							&& transitionData.map(({ rule, value }) => (
								<EditableStageTransitionDatum
									key={rule.id}
									datum={value}
									handleDataEdit={async (
										ruleId,
										type,
										val,
										extra,
									) =>
										handleDataEdit(
											ruleId,
											transition.id,
											type,
											val,
											extra,
										)
									}
									rule={rule}
								/>
							))}
					</StageTransitionData>
				</StageTransition>
				<ReplyControls />
			</Transition>
			{renderNotes && (
				<TransitionNotesSection
					autoFocusNewNote={autoFocusNewNote}
					editable={editable}
					itemId={item.id}
					mixpanelIdentifier="pipeline-profile-card"
					notes={transition.notes}
					pipelineId={pipelineId}
					profileId={item.profileId}
					transitionId={transition.id}
				/>
			)}
		</FeedEvent>
	);
}
