import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { disabledGray, green, herbieGray } from '../../colors';
import { NewCheckIcon, RefreshIcon } from '../../components/icons';
import type { EventName, ViewType } from '../../utils/analytics';
import { trackEvent } from '../../utils/analytics';
import keys from '../../utils/key-codes';

const TextInput = styled.input`
	border: none;
	flex: 1 1 0;
	font-size: 14px;
	line-height: 34px;
	min-width: 0;
	padding: 0 10px;
	text-overflow: ellipsis;

	&:disabled {
		color: ${disabledGray.toString()};
	}
`;

export const IconButton = styled.button`
	align-self: flex-start;
	background-color: white;
	border: none;
	border-left: 0.5px solid ${herbieGray.toString()};
	cursor: pointer;
	font-size: 0;
	opacity: 0.7;
	outline: 0;
	padding: 7px;

	&:disabled {
		cursor: not-allowed;
	}

	&:not(:disabled):hover {
		opacity: 1;
	}
	svg {
		height: 20px;
		width: 20px;
	}
`;

const CancelIcon = styled(RefreshIcon)`
	transform: scaleX(-1);
`;

type Props = {
	readonly disabled?: boolean;
	readonly mixpanelTracking?: {
		cancelEventName: EventName;
		confirmEventName: EventName;
		componentIdentifier: string;
		viewType: ViewType;
	};
	readonly onEdit: (text: string) => void;
	readonly originalText: string;
	readonly render?: (text: string) => React.ReactNode;
};

export default function TextEdit({
	disabled,
	mixpanelTracking,
	onEdit,
	originalText,
	render,
}: Props): JSX.Element {
	const [text, setText] = useState<string>(originalText);
	const handleNameChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) =>
			setText(event.currentTarget.value),
		[],
	);

	const handleCancel = useCallback(() => {
		if (mixpanelTracking) {
			trackEvent(
				mixpanelTracking.cancelEventName,
				mixpanelTracking.componentIdentifier,
				mixpanelTracking.viewType,
			);
		}
		setText(originalText);
	}, [originalText, mixpanelTracking]);

	const handleConfirm = useCallback(() => {
		if (mixpanelTracking) {
			trackEvent(
				mixpanelTracking.confirmEventName,
				mixpanelTracking.componentIdentifier,
				mixpanelTracking.viewType,
			);
		}
		onEdit(text);
	}, [onEdit, text, mixpanelTracking]);

	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			const key = event.keyCode;
			switch (key) {
				case keys.ENTER:
					if (text.length > 0) {
						handleConfirm();
					}
					break;
				case keys.ESC:
					handleCancel();
					break;
				default:
				// Do nothing
			}
		},
		[text, handleConfirm, handleCancel],
	);
	return (
		<React.Fragment>
			<TextInput
				disabled={disabled}
				onChange={handleNameChange}
				onKeyDown={handleKeyDown}
				type="text"
				value={text}
			/>
			{!disabled && originalText !== text && (
				<React.Fragment>
					<IconButton onClick={handleCancel} type="button">
						<CancelIcon />
					</IconButton>
					<IconButton
						disabled={text.length < 1}
						onClick={handleConfirm}
						type="button"
					>
						<NewCheckIcon
							fill={
								text.length < 1
									? disabledGray.toString()
									: green.toString()
							}
						/>
					</IconButton>
				</React.Fragment>
			)}
			{render?.(text)}
		</React.Fragment>
	);
}
