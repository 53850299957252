import classNames from 'classnames';
import React, { type InputHTMLAttributes } from 'react';
import './search-box.scss';

export default function SearchBox({
	className,
	type = 'text',
	...props
}: InputHTMLAttributes<HTMLInputElement>) {
	return (
		<input
			{...props}
			className={classNames('SearchBox', className)}
			type={type}
		/>
	);
}
