import { FilterLabel } from '@drivecapital/design-system/components';
import { XIcon } from '@drivecapital/design-system/icons/system';
import { useQuery } from '@tanstack/react-query';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { get } from '../../utils/api';
import toSentence from '../../utils/to-sentence';

import type {
	FilterControlHeaderProps,
	FilterControlProps,
	IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import TextComboBox from './text-combo-box';
import type { IndustryFilter } from './types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: space-between;
`;
const Industries = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const IndustryLabel = styled.div`
	display: flex;
	gap: 4px;

	& > svg {
		height: 16px;
		width: 16px;
	}
`;

function useIndustries() {
	return useQuery({
		queryKey: ['search', 'industry-filter-control', 'industries'],
		queryFn: async () =>
			get<{ name: string; companies_count: number }[]>(
				'/search/industries',
			),
		staleTime: Infinity,
	});
}

const FIELD_NAME: IndustryFilter['fieldName'] = 'industry';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<IndustryFilter>) {
	const { data: industryNames } = useIndustries();
	const comboboxItems = useMemo(
		() =>
			(industryNames || []).map(({ name }) => ({
				id: name,
				text: name,
			})),
		[industryNames],
	);
	const selectableComboItems = useMemo(
		() =>
			comboboxItems.filter(
				({ id }) => !filter?.value.some((industry) => industry === id),
			),
		[comboboxItems, filter?.value],
	);

	const handleIndustryDeselect = useCallback(
		(industryName: string) => {
			if (!filter) return;

			const newValue = filter.value.filter(
				(name) => name !== industryName,
			);

			if (newValue.length === 0) {
				onClear(FIELD_NAME);
			} else {
				onChange({
					...filter,
					value: newValue,
				});
			}
		},
		[filter, onChange, onClear],
	);
	const handleIndustrySelect = useCallback(
		(industryName: number | string | null) => {
			const parsed =
				typeof industryName === 'number'
					? industryName.toString()
					: (industryName ?? '');

			if (parsed == null) return;

			if (filter && filter.value.some((name) => name === industryName)) {
				return;
			} else {
				const industry = comboboxItems.find(
					({ id }) => id === industryName,
				);
				if (industry) {
					onChange({
						fieldName: FIELD_NAME,
						value: [...(filter?.value || []), parsed],
					});
				}
			}
		},
		[comboboxItems, filter, onChange],
	);

	return (
		<Container>
			<TextComboBox
				allOptions={selectableComboItems}
				defaultOptions={selectableComboItems.slice(0, 5)}
				defaultValue=""
				disabled={!industryNames}
				id="industries"
				label="Operating in..."
				limit={10}
				onSubmit={handleIndustrySelect}
				placeholder="SaaS, Cloud Computing, etc"
			/>
			<Industries>
				{(filter?.value || []).map((name) => (
					<FilterLabel
						isActive
						key={name}
						label={
							<IndustryLabel>
								<span>{name}</span>
								<XIcon />
							</IndustryLabel>
						}
						onAction={() => {
							handleIndustryDeselect(name);
						}}
					/>
				))}
			</Industries>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<IndustryFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Industries"
		/>
	);
}

const IndustryFilterControl: IFilterControl<IndustryFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (filter.value.length === 0) {
			return [];
		}
		return [
			`operate in the ${toSentence(filter.value, 'or')} ${pluralize(
				'industry',
				filter.value.length,
			)}`,
		];
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default IndustryFilterControl;
