import { colors, effects, fonts } from '@drivecapital/design-system';
import {
	Chip,
	QuantityIndicator,
} from '@drivecapital/design-system/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Tooltip, { UnstyledTooltipContent } from '../components/tooltip';
import { useCompanyMarketMaps } from '../market-maps/api';

import { compareMarketMapDates } from './api';
import type { CompanyOutreach } from './types';

const Cell = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	grid-area: chips;
	overflow: hidden;
`;
const StyledQuantityIndicator = styled(QuantityIndicator)`
	&:hover {
		cursor: default;
	}
`;

const TooltipContent = styled(UnstyledTooltipContent)`
	${effects.shadow.shadow};
	${fonts.paragraph.paragraph};
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 4px;
	color: ${colors.text.secondary};
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 8px;
	width: 180px;
`;
const Emphasized = styled.span`
	color: ${colors.text.primary};
`;

const MARKET_MAP_LIMIT = 2;

export default function MarketMapsTableCell({
	companyOutreach,
}: {
	readonly companyOutreach: CompanyOutreach;
}) {
	const companyId = companyOutreach.company.id;

	// staleTime must be set to Infinity to prevent each cell from refetching market-map data
	const { data: companyMarketMaps } = useCompanyMarketMaps(companyId, {
		staleTime: Infinity,
	});
	const sorted = useMemo(
		() =>
			(companyMarketMaps || []).toSorted((a, b) =>
				compareMarketMapDates(a, b, 'descending'),
			),
		[companyMarketMaps],
	);

	return (
		<Cell>
			{sorted.slice(0, MARKET_MAP_LIMIT).map((marketMap) => (
				<Chip
					key={marketMap.id}
					background={colors.data.dust.layer}
					color={colors.text.secondary}
					label={marketMap.name}
				/>
			))}
			{sorted.length > MARKET_MAP_LIMIT && (
				<Tooltip
					content={
						<TooltipContent>
							<span>And:</span>
							{sorted
								.slice(MARKET_MAP_LIMIT)
								.map(({ id, name }) => (
									<Emphasized key={id}>{name}</Emphasized>
								))}
						</TooltipContent>
					}
				>
					<StyledQuantityIndicator
						menu={false}
						onClick={() => {
							// no-op
						}}
						open={false}
						quantity={sorted.length - MARKET_MAP_LIMIT}
					/>
				</Tooltip>
			)}
		</Cell>
	);
}
