import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import gfm from 'remark-gfm';
import styled from 'styled-components';

import { primaryDriveBlue } from '../colors';

interface Props {
	readonly children: string;
}

const StyledReactMarkdown = styled(ReactMarkdown)`
	a {
		color: ${primaryDriveBlue.string()};
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}

	strong {
		font-weight: 600;
	}

	p {
		margin-top: 0;
		margin-bottom: 16px;
	}

	hr {
		height: 0.25em;
		padding: 0;
		margin: 24px 0;
		background-color: #e1e4e8;
		border: 0;
	}

	blockquote {
		margin: 0 0 16px 0;
		padding: 0 1em;
		color: #6a737d;
		border-left: 0.25em solid #dfe2e5;

		& :last-child {
			margin-bottom: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 24px;
		margin-bottom: 16px;
		font-weight: 600;
		line-height: 1.25;
	}

	h1 {
		font-size: 2em;
	}

	h1,
	h2 {
		padding-bottom: 0.3em;
		border-bottom: 1px solid #eaecef;
	}

	h2 {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.25em;
	}

	h4 {
		font-size: 1em;
	}

	h5 {
		font-size: 0.875em;
	}

	h6 {
		font-size: 0.85em;
		color: #6a737d;
	}

	ol,
	ul {
		padding-left: 2em;
		margin-bottom: 16px;
		clear: left;
	}

	ol ol,
	ul ol {
		list-style-type: lower-roman;
	}

	ol ol,
	ol ul,
	ul ol,
	ul ul {
		margin-top: 0;
		margin-bottom: 0;
	}

	ol ol ol,
	ol ul ol,
	ul ol ol,
	ul ul ol {
		list-style-type: lower-alpha;
	}

	ul {
		list-style-type: initial;
	}

	li > p {
		margin-top: 16px;
	}

	li + li {
		margin-top: 0.25em;
	}

	code {
		padding: 0.2em 0.4em;
		margin: 0;
		font-size: 85%;
		background-color: rgba(27, 31, 35, 0.05);
		border-radius: 3px;
		font-family:
			SFMono-Regular,
			Consolas,
			Liberation Mono,
			Menlo,
			monospace;
	}

	pre {
		word-wrap: normal;
		margin-top: 0;
		margin-bottom: 16px;
	}
`;

const StyledMarkdown = ({ children, ...props }: Props): JSX.Element => (
	<StyledReactMarkdown remarkPlugins={[remarkBreaks, gfm]} {...props}>
		{children}
	</StyledReactMarkdown>
);

export default StyledMarkdown;
