import { breakpoints, colors, fonts } from '@drivecapital/design-system';
import { HerbieLogoIcon } from '@drivecapital/design-system/icons/brand';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as UnstyledLink } from 'react-router-dom';
import styled from 'styled-components';

import { NavigationHeaderButton } from '../../new-search';
import { QuickSearch } from '../../search';
import { selectUser } from '../../selectors';
import { trackEvent } from '../../utils/analytics';
import isStandalone from '../../utils/is-standalone';
import { getOAuthUrl } from '../../utils/oauth';
import Authorized, { and, hasConnectedInbox, not } from '../Authorized';

import AccountMenu from './account-menu';
import { DropdownMenu, ShortcutsMenu } from './apps-menu';
import BackButton from './back-button';

const Container = styled.div`
	align-items: center;
	background: ${colors.background.inverse};
	display: grid;
	z-index: 100;

	grid-template:
		'logo search menus' 1fr
		/ auto 1fr auto;

	@media (${breakpoints.sm}) {
		grid-template:
			'logo search shortcuts menus' 1fr
			/ auto 1fr 1fr auto;
	}

	gap: 8px;
	padding: 8px;
	@media (${breakpoints.md}) {
		gap: 32px;
		padding: 8px 32px;
	}

	@media print {
		display: none;
	}
`;

const Menus = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	grid-area: menus;
`;
const SearchContainer = styled.div`
	display: flex;
	gap: 8px;
	grid-area: search;
`;

const Link = styled(UnstyledLink)`
	align-items: center;
	justify-content: flex-start;
	display: flex;

	gap: 4px;
	@media (${breakpoints.sm}) {
		gap: 8px;
	}
`;

const Logo = styled(HerbieLogoIcon)`
	color: ${colors.icon.onColor};
	height: 30px;
	width: 48px;

	@media (${breakpoints.sm}) {
		height: 40px;
		width: 64px;
	}

	&:hover {
		cursor: pointer;
	}
`;

const ConnectInboxBanner = styled.div`
	${fonts.h5.productive};
	background-color: ${colors.semantic.focus};
	color: ${colors.text.onColor};
	padding: 6px 12px;
	text-align: center;

	&:hover {
		text-decoration: underline;
	}
`;

interface Props {
	readonly search?: boolean;
}
export default function Header({ search = true }: Props) {
	const user = useSelector(selectUser);
	const renderBackbutton = isStandalone;
	const [searchQuery, setSearchQuery] = useState<string | null>(null);

	return (
		<>
			<Container>
				<Link to="/">
					{renderBackbutton && (
						<BackButton
							onClick={() => {
								trackEvent(
									'Click Header',
									'standalone-back-button',
									'global',
								);
							}}
						/>
					)}
					{!renderBackbutton && (
						<Logo
							onClick={() => {
								trackEvent(
									'Click Header',
									'herbie-logo',
									'global',
								);
							}}
						/>
					)}
				</Link>
				{search && (
					<SearchContainer>
						<QuickSearch
							onQueryChange={setSearchQuery}
							placeholder="Search"
							textInputMode="dark"
						/>
						<NavigationHeaderButton query={searchQuery} />
					</SearchContainer>
				)}
				<ShortcutsMenu
					gridArea="shortcuts"
					includeHomeLink={renderBackbutton}
				/>
				<Menus>
					<DropdownMenu
						includeHomeLink={renderBackbutton}
						onClick={() => {
							trackEvent('Click Header', 'apps-menu', 'global');
						}}
					/>
					<AccountMenu
						onClick={() => {
							trackEvent(
								'Click Header',
								'account-menu',
								'global',
							);
						}}
					/>
				</Menus>
			</Container>
			{user && (
				<Authorized
					auth={and(
						not(hasConnectedInbox),
						'contacts.google_oauth_gmail_metadata',
					)}
				>
					<ConnectInboxBanner>
						<a
							href={getOAuthUrl(
								user,
								new URL(window.location.href),
							)}
							onClick={() => {
								trackEvent(
									'Click Header',
									'connect-inbox-banner',
									'global',
								);
							}}
							rel="noopener noreferrer"
							target="_blank"
						>
							Connect your inbox to unlock more Herbie features
						</a>
					</ConnectInboxBanner>
				</Authorized>
			)}
		</>
	);
}
