/**
 * @file
 * Alert toast notification component from [Figma][1].
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=597%3A61209&mode=dev
 */
import {
	animated,
	config,
	type SpringValues,
	useSpring,
} from '@react-spring/web';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { colors, effects, fonts } from '..';

const backgrounds: Record<NonNullableVariant, string> = {
	error: colors.semantic.danger,
	info: colors.semantic.info,
	success: colors.semantic.success,
	warning: colors.semantic.warning,
};

const AlertBody = styled.div<{ variant: NonNullableVariant }>`
	${effects.shadow.shadow};
	${fonts.label.label};
	align-items: center;
	background: ${({ variant }) => backgrounds[variant]};
	border-radius: 8px;
	color: ${colors.text.onColor};
	display: flex;
	flex-direction: column;
	min-width: 300px;
	padding: 10px;
`;

export type Styles = SpringValues;

export function useAlert({
	onClose,
	placement = 'top',
	visibleSeconds = 3,
}: {
	onClose?: () => void;
	placement?: 'bottom' | 'top';
	visibleSeconds?: number;
} = {}): [Styles, () => void, () => void] {
	const [show, setShow] = useState(false);
	const springArgs: Parameters<typeof useSpring>[0] = {
		[placement]: placement === 'top' ? (show ? 95 : 125) : show ? 25 : 0,
		config: { ...config.gentle, clamp: true },
		display: show ? 'flex' : 'none',
		flexDirection: 'row',
		justifyContent: 'center',
		opacity: show ? 1 : 0,
		position: 'absolute',
		width: '100vw',
	};

	const style = useSpring(springArgs);
	const open = useCallback(() => {
		setShow(true);
	}, []);
	const close = useCallback(() => {
		setShow(false);
	}, []);

	useEffect(() => {
		if (show) {
			const timeout = setTimeout(() => {
				setShow(false);
				if (onClose) {
					onClose();
				}
			}, visibleSeconds * 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
		return () => {
			// noop
		};
	}, [onClose, visibleSeconds, show]);

	return [style, open, close];
}

type NonNullableVariant = Exclude<Props['variant'], undefined>;
interface Props {
	children: React.ReactNode;
	style: Styles;
	variant?: 'error' | 'info' | 'success' | 'warning';
}

export function Alert({ children, style, variant = 'info' }: Props) {
	return createPortal(
		<animated.div style={style}>
			<AlertBody variant={variant}>{children}</AlertBody>
		</animated.div>,
		document.body,
	);
}
