import { colors } from '@drivecapital/design-system';
import { ChevronLeftIcon } from '@drivecapital/design-system/icons/arrows';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.button`
	appearance: none;
	background: none;
	border: none;
	outline: 0;
	padding: 0;

	&:disabled {
		svg {
			color: ${colors.icon.disabled};
		}
	}
`;
const Icon = styled(ChevronLeftIcon)`
	color: ${colors.icon.onColor};
	height: 25px;
	width: 25px;
`;

export default function BackButton({
	onClick,
}: {
	readonly onClick: () => void;
}): JSX.Element {
	const navigate = useNavigate();
	const location = useLocation();

	// Quoting remix-run's history docs [1]
	// > The location.key property is a unique string associated with this location.
	// > On the initial location, this will be the string default.
	//
	// [1]: https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
	const isBeginningOfHistory = location.key === 'default';

	return (
		<Button
			disabled={isBeginningOfHistory}
			onClick={() => {
				onClick();
				navigate(-1);
			}}
		>
			<Icon />
		</Button>
	);
}
