import {
	Menu,
	MenuButton,
	MenuItem,
} from '@drivecapital/design-system/components';
import React from 'react';

import {
	priorityOptions,
	ReadOnlyPriorityChip,
	useUpdatePipelineItem,
} from '../../../pipelines';
import type { PipelineStageItem } from '../../../pipelines';

export function EditablePriorityChip({
	pipelineId,
	pipelineItem,
}: {
	readonly pipelineId: number;
	readonly pipelineItem: PipelineStageItem;
}) {
	const { mutate } = useUpdatePipelineItem();
	const priority = pipelineItem.priority || '';

	return (
		<Menu
			menu={
				<MenuButton>
					<ReadOnlyPriorityChip
						priority={priority}
						chipProps={{ menu: true, menuDirection: 'down' }}
					/>
				</MenuButton>
			}
		>
			{(
				Object.keys(priorityOptions) as Array<
					keyof typeof priorityOptions
				>
			).map((priorityOption) => (
				<MenuItem
					key={priorityOption}
					onSelect={() => {
						mutate({
							itemId: pipelineItem.id,
							pipelineId,
							priority: priorityOption,
							profileId: pipelineItem.profileId,
						});
					}}
				>
					<ReadOnlyPriorityChip priority={priorityOption} />
				</MenuItem>
			))}
		</Menu>
	);
}
