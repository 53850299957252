import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { get } from '../../utils/api';
import fuzzyFilter from '../../utils/fuzzy-filter';
import identity from '../../utils/identity';
import {
	autoComplete,
	type Options as HereAutoCompleteOptions,
} from '../here-api';
import type { AutoCompleteItem } from '../types';

/**
 * Options to control autocomplete behavior.
 *
 * @property excludedLocationTypes - Do not autocomplete items of these types
 * @property prioritizedCountryCodes - Sort items with these country codes before others
 * @property suggestions - Show these AutoCompleteItems when the query is empty and the input is focused. When not specified, custom locations are used.
 */
export interface AutoCompleteOptions {
	excludedLocationTypes: AutoCompleteItem['type'][];
	prioritizedCountryCodes: string[];
	suggestions?: AutoCompleteItem[];
}

function mapOptions(options: AutoCompleteOptions): HereAutoCompleteOptions {
	return {
		prioritizedCountryCodes: options.prioritizedCountryCodes,
	};
}

function autoCompleteItemFromCustomLocation(name: string): AutoCompleteItem {
	return {
		id: `custom-location-${name}`,
		name,
		type: 'CUSTOM_LOCATION',
	};
}

export function useCustomLocations(): AutoCompleteItem[] {
	return (
		useQuery({
			queryKey: ['locations', 'customLocations'],
			queryFn: async ({ signal }) =>
				get<string[]>('/locations/custom_locations/', { signal }),
			staleTime: Infinity,
			select: (data) => data.map(autoCompleteItemFromCustomLocation),
		}).data || []
	);
}

function useHereAutoComplete(query: string, options: AutoCompleteOptions) {
	return useQuery({
		queryKey: ['locations', 'here', query, options],
		queryFn: async () => autoComplete(query, mapOptions(options)),
		placeholderData: identity,
		staleTime: 1 * 60 * 1000, // 1 minute
	});
}

export const defaultOptions: AutoCompleteOptions = {
	excludedLocationTypes: [],
	prioritizedCountryCodes: ['CAN', 'USA'],
};

export default function useAutoComplete(
	query: string,
	options: AutoCompleteOptions = defaultOptions,
) {
	const customLocations = useCustomLocations();
	const filteredCustomLocations = useMemo(
		() => fuzzyFilter(customLocations, query, ({ name }) => name, true),
		[customLocations, query],
	);
	const suggestions =
		options.suggestions == null ? customLocations : options.suggestions;
	const {
		data: hereAutoComplete,
		isLoading,
		isFetching,
	} = useHereAutoComplete(query, options);
	const items = useMemo(
		() =>
			query === ''
				? suggestions
				: filteredCustomLocations
						.concat(hereAutoComplete || [])
						.filter(
							({ type }) =>
								!options.excludedLocationTypes.includes(type),
						),
		[
			filteredCustomLocations,
			hereAutoComplete,
			options.excludedLocationTypes,
			query,
			suggestions,
		],
	);

	return { isLoading: isLoading || isFetching, items };
}
