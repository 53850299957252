import {
	Button,
	AriaDropdown as Dropdown,
	AriaDropdownOption as DropdownOption,
	AriaDropdownOptions as DropdownOptions,
	TextInput,
} from '@drivecapital/design-system/components';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useDismissOutreachCompanies } from './api';
import type { DismissReason } from './types';

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-width: 250px;
	padding: 0 8px;

	> button {
		align-self: flex-start;
	}
`;
const DropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const Buttons = styled.div`
	display: flex;
	gap: 4px;
`;

interface Props {
	readonly onClose: () => void;
	readonly outreachIds: number[];
}

export default function DismissActionForm({ onClose, outreachIds }: Props) {
	const { mutateAsync: dismissOutreachCompany, isPending: isDismissing } =
		useDismissOutreachCompanies();
	const [dismissReason, setDismissReason] = useState<DismissReason>(
		'NOT_SOURCING_RELATED',
	);
	const [otherExplanation, setOtherExplanation] = useState<string>('');
	const handleDismissReasonChange = useCallback((value: string | number) => {
		setDismissReason(value as DismissReason);
	}, []);
	const handleOtherExplanationChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setOtherExplanation(event.target.value);
		},
		[],
	);

	const handleDismissSubmit = useCallback(
		async (event: React.FormEvent) => {
			event.preventDefault();
			await dismissOutreachCompany({
				outreachIds,
				reason: dismissReason,
				otherReason: otherExplanation,
			});
			onClose();
		},
		[
			dismissOutreachCompany,
			dismissReason,
			onClose,
			otherExplanation,
			outreachIds,
		],
	);

	return (
		<Form onSubmit={handleDismissSubmit}>
			<DropdownWrapper>
				<Dropdown
					autoFocus
					onChange={handleDismissReasonChange}
					label="Please share feedback"
					value={dismissReason}
				>
					<DropdownOptions>
						<DropdownOption value="NOT_SOURCING_RELATED">
							Outreach wasn't for investment sourcing
						</DropdownOption>
						<DropdownOption value="FOLLOWED_UP_OUTSIDE_EMAIL">
							Followed up outside email
						</DropdownOption>
						<DropdownOption value="Other">Other</DropdownOption>
					</DropdownOptions>
				</Dropdown>
				{dismissReason === 'Other' && (
					<TextInput
						autoFocus
						name="OtherExplanation"
						onChange={handleOtherExplanationChange}
						placeholder="Enter reason here"
						required
						value={otherExplanation}
					/>
				)}
			</DropdownWrapper>
			<Buttons>
				<Button disabled={isDismissing} type="submit" variant="danger">
					Dismiss
				</Button>
				<Button
					disabled={isDismissing}
					onClick={onClose}
					type="button"
					variant="secondary"
				>
					Cancel
				</Button>
			</Buttons>
		</Form>
	);
}
