import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import styled from 'styled-components';

import * as colors from '../colors';

import type { InvestorDashboardData } from './types';
import * as dataUtils from './utils';

const FUND_IV_INCEPTION_DATE = moment('2021/12/3');
const FUND_IV_SIZE = 408;
const FUND_IV_TARGET_PACE_YEARS = 3;
const FUND_IV_TARGET_INVESTMENT_PLUS_RESERVES_MULTIPLE = 1.5;
const OVERDRIVE_II_INCEPTION_DATE = moment('2022/1/28');
const OVERDRIVE_II_SIZE = 648;
const OVERDRIVE_II_TARGET_PACE_YEARS = 3;
const OVERDRIVE_II_TARGET_INVESTMENT_PLUS_RESERVES_MULTIPLE = 1.0;

const TableStylingWrapper = styled.div`
	border-radius: 6px;
	border: 1px solid ${colors.sectionBorder.toString()};
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.04);
	overflow-x: auto;
	width: 100%;
	height: 100%;
`;

const Table = styled.table`
	background-color: white;
	border-collapse: collapse;
	text-align: left;
	width: 100%;

	tr {
		height: 40px;
	}

	tbody > tr:nth-of-type(odd) {
		background-color: ${colors.tableRowBlue.string()};
	}

	td,
	th {
		padding: 0 8px;
		&:first-child {
			text-align: left;
		}
		&:not(:first-child) {
			text-align: right;
		}
	}
`;

const PREVIOUS_FUNDS: Array<keyof InvestorDashboardData> = [
	'Fund I',
	'Fund II',
	'Fund III',
	'Overdrive I',
];
const FUND_LABEL_MAPPING = {
	'Fund I': 'I',
	'Fund II': 'II',
	'Fund III': 'III',
	'Overdrive I': 'OD I',
	'Fund IV': 'Fund IV',
	'Overdrive II': 'OD II',
};

interface StatTableProps {
	readonly data: InvestorDashboardData;
}

export function CurrentFundStats({ data }: StatTableProps): JSX.Element {
	return (
		<TableStylingWrapper>
			<Table>
				<thead>
					<tr>
						<th>Current Funds</th>
						<th>{FUND_LABEL_MAPPING['Fund IV']}</th>
						<th>vs Target</th>
						<th>{FUND_LABEL_MAPPING['Overdrive II']}</th>
						<th>vs Target</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Size</td>
						<td>{numeral(FUND_IV_SIZE).format('$0.0')}</td>
						<td />
						<td>{numeral(OVERDRIVE_II_SIZE).format('$0.0')}</td>
						<td />
					</tr>
					<tr>
						<td>Logos</td>
						<td>{dataUtils.activeLogos(data['Fund IV'])}</td>
						<td />
						<td>{dataUtils.activeLogos(data['Overdrive II'])}</td>
						<td />
					</tr>
					<tr>
						<td>$ invested + reserves</td>
						<td>
							{numeral(
								(dataUtils.totalInvested(data['Fund IV'])
									+ dataUtils.totalReserves(data['Fund IV']))
									/ 1e6,
							).format('$0.0')}
						</td>
						<td>
							{numeral(
								dataUtils.calculateTargetInvestingPace(
									FUND_IV_INCEPTION_DATE,
									FUND_IV_SIZE,
									FUND_IV_TARGET_PACE_YEARS,
									FUND_IV_TARGET_INVESTMENT_PLUS_RESERVES_MULTIPLE,
								),
							).format('$0.0')}
						</td>
						<td>
							{numeral(
								(dataUtils.totalInvested(data['Overdrive II'])
									+ dataUtils.totalReserves(
										data['Overdrive II'],
									))
									/ 1e6,
							).format('$0.0')}
						</td>
						<td>
							{numeral(
								dataUtils.calculateTargetInvestingPace(
									OVERDRIVE_II_INCEPTION_DATE,
									OVERDRIVE_II_SIZE,
									OVERDRIVE_II_TARGET_PACE_YEARS,
									OVERDRIVE_II_TARGET_INVESTMENT_PLUS_RESERVES_MULTIPLE,
								),
							).format('$0.0')}
						</td>
					</tr>
					<tr>
						<td>Avg 1st check valuation</td>
						<td>
							{numeral(
								dataUtils.averageCheckValuation(data['Fund IV'])
									/ 1e6,
							).format('$0.0')}
						</td>
						<td />
						<td>
							{numeral(
								dataUtils.averageCheckValuation(
									data['Overdrive II'],
								) / 1e6,
							).format('$0.0')}
						</td>
						<td />
					</tr>
					<tr>
						<td>Avg 1st check ownership %</td>
						<td>
							{numeral(
								dataUtils.averageCheckOwnership(
									data['Fund IV'],
								),
							).format('0.00%')}
						</td>
						<td />
						<td>
							{numeral(
								dataUtils.averageCheckOwnership(
									data['Overdrive II'],
								),
							).format('0.00%')}
						</td>
						<td />
					</tr>
				</tbody>
			</Table>
		</TableStylingWrapper>
	);
}

export function PreviousFundStats({ data }: StatTableProps): JSX.Element {
	return (
		<TableStylingWrapper>
			<Table>
				<thead>
					<tr>
						<th>Past Funds</th>
						{PREVIOUS_FUNDS.map((fund) => (
							<th key={fund}>{FUND_LABEL_MAPPING[fund]}</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Active Logos</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{dataUtils.activeLogos(data[fund])}
							</td>
						))}
					</tr>
					<tr>
						<td>Retired Logos</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{dataUtils.retiredLogos(data[fund])}
							</td>
						))}
					</tr>
					<tr>
						<td>Total Logos</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>{dataUtils.allLogos(data[fund])}</td>
						))}
					</tr>
					<tr>
						<td>FMV (active and exited)</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{numeral(
									dataUtils.totalFMV(data[fund]) / 1e6,
								).format('$0,0')}
							</td>
						))}
					</tr>
					<tr>
						<td>MoM (gross)</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{`${numeral(
									dataUtils.totalMoM(data[fund]),
								).format('0.0')}x`}
							</td>
						))}
					</tr>
					<tr>
						<td>Net IRR</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{numeral(
									dataUtils.irrPercentage(data[fund]),
								).format('0.0%')}
							</td>
						))}
					</tr>
					<tr>
						<td>TVPI</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{`${numeral(
									dataUtils.tvpiMultiple(data[fund]),
								).format('0.0')}x`}
							</td>
						))}
					</tr>
					<tr>
						<td>DPI</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{`${numeral(
									dataUtils.dpiMultiple(data[fund]),
								).format('0.0')}x`}
							</td>
						))}
					</tr>
					<tr>
						<td>DPI ($)</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{numeral(
									dataUtils.dpiDollars(data[fund]),
								).format('$0.0')}
							</td>
						))}
					</tr>
					<tr>
						<td>Avg 1st Check Size</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{numeral(
									dataUtils.averageCheckSize(data[fund])
										/ 1e6,
								).format('$0.00')}
							</td>
						))}
					</tr>
					<tr>
						<td>Avg 1st Check Valuation</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{numeral(
									dataUtils.averageCheckValuation(data[fund])
										/ 1e6,
								).format('$0.00')}
							</td>
						))}
					</tr>
					<tr>
						<td>Avg 1st Check Ownership %</td>
						{PREVIOUS_FUNDS.map((fund) => (
							<td key={fund}>
								{numeral(
									dataUtils.averageCheckOwnership(data[fund]),
								).format('0.0%')}
							</td>
						))}
					</tr>
				</tbody>
			</Table>
		</TableStylingWrapper>
	);
}
