import { queryOptions, useMutation, useQuery } from '@tanstack/react-query';

import type { NoteData } from '../notes';
import {
	get,
	patch,
	post,
	queryClient,
	remove,
	updateQueryData,
} from '../utils/api';
import delay from '../utils/delay';
import reportError from '../utils/sentry';

import transformPipelineFromApi from './api-helpers';
import type {
	API_Pipeline,
	API_PipelineStageItem,
	API_PipelineStageTransitionDatum,
} from './api-helpers';
import type {
	PipelineStageItem,
	StageTransitionDataRuleType,
	StageTransitionDataValue,
} from './types';

const pipelineQueryKeys = {
	detail: (pipelineId: number) =>
		['pipelines', 'detail', pipelineId] as const,
	personProfiles: () => ['pipelines', 'person-profiles'] as const,
	personProfile: (profileId: number) =>
		[...pipelineQueryKeys.personProfiles(), profileId] as const,
} as const;

function personPipelinesQueryOptions(profileId: number) {
	return queryOptions({
		queryKey: pipelineQueryKeys.personProfile(profileId),
		queryFn: async ({ signal }) =>
			get<API_Pipeline[]>(`/people/${profileId}/pipelines`, {
				signal,
			}),
		select: (pipelines) => pipelines.map(transformPipelineFromApi),
	});
}

export function usePersonPipelines(profileId: number) {
	return useQuery(personPipelinesQueryOptions(profileId));
}

export function useRefreshPersonPipelines() {
	const refreshPeoplePipelines = useRefreshPeoplePipelines();

	return (profileId: number) => {
		refreshPeoplePipelines([profileId]);
	};
}

export function useRefreshPeoplePipelines() {
	return (profileIds: number[]) => {
		const queryKeys = profileIds.map((profileId) =>
			personPipelinesQueryOptions(profileId).queryKey.join(','),
		);
		void queryClient.invalidateQueries({
			predicate: (query) => queryKeys.includes(query.queryKey.join(',')),
			refetchType: 'all',
		});
	};
}

function pipelineDetailQueryOptions(pipelineId: number) {
	return queryOptions({
		queryKey: pipelineQueryKeys.detail(pipelineId),
		queryFn: async ({ signal }) =>
			get<API_Pipeline>(`/pipelines/${pipelineId}`, { signal }),
		select: transformPipelineFromApi,
	});
}

export function usePipeline(pipelineId: number) {
	return useQuery(pipelineDetailQueryOptions(pipelineId));
}

/**
 * Refresh the query cache for a given pipeline. Calling this function
 * will force react-query to refetch server data and update any
 * components using this data with refreshed state.
 *
 * This is considered an anti-pattern and should only be used in
 * the case where refreshing is necessary and there isn't a way to use
 * this api module to update server state directly. If at all possible,
 * see whether you can add functionality here (maybe a new mutation) to
 * update the client side state via the mutation.
 */
export function useRefreshPipelineDetail() {
	const refreshPipelineDetails = useRefreshPipelineDetails();
	return (pipelineId: number) => {
		refreshPipelineDetails([pipelineId]);
	};
}

export function useRefreshPipelineDetails() {
	return (pipelineIds: number[]) => {
		const queryKeys = pipelineIds.map((pipelineId) =>
			pipelineDetailQueryOptions(pipelineId).queryKey.join(','),
		);
		void queryClient.invalidateQueries({
			predicate: (query) => {
				return queryKeys.includes(query.queryKey.join(','));
			},
			refetchType: 'all',
		});
	};
}

function optimisticallyMovePipelineItem(
	pipeline: API_Pipeline,
	item: PipelineStageItem,
	destinationStageId: number,
): API_Pipeline {
	const existingItem = pipeline.items?.find(({ id }) => id === item.id);
	if (!existingItem) return pipeline;

	const newItem = {
		...existingItem,
		currently_moving_stages: true,
		most_recent_stage_id: destinationStageId,
	};

	return {
		...pipeline,
		items: (pipeline.items || []).map((oldItem) =>
			oldItem.id === item.id ? newItem : oldItem,
		),
	};
}

export function useMovePipelineItem() {
	return useMutation({
		mutationFn: async ({
			destinationStageId,
			item,
			pipelineId,
			transitionData,
		}: {
			destinationStageId: number;
			item: PipelineStageItem;
			pipelineId: number;
			transitionData: StageTransitionDataValue[];
		}) =>
			post<API_PipelineStageItem>(
				`/pipelines/${pipelineId}/person_profile_transitions`,
				{
					body: {
						destination_stage: destinationStageId,
						profile: item.id,
						transition_data: transitionData.map(
							({ ruleId, ...transitionDatum }) => ({
								...transitionDatum,
								requirement: ruleId,
							}),
						),
					},
				},
			),
		onMutate: ({ destinationStageId, item, pipelineId }) => {
			const previousPipelines = queryClient.getQueryData(
				personPipelinesQueryOptions(item.profileId).queryKey,
			);
			updateQueryData(
				personPipelinesQueryOptions(item.profileId),
				(pipelines) =>
					pipelines.map((pipeline) =>
						pipeline.id === pipelineId
							? optimisticallyMovePipelineItem(
									pipeline,
									item,
									destinationStageId,
								)
							: pipeline,
					),
			);

			const previousPipelineDetail = queryClient.getQueryData(
				pipelineDetailQueryOptions(pipelineId).queryKey,
			);
			updateQueryData(
				pipelineDetailQueryOptions(pipelineId),
				(pipeline) =>
					optimisticallyMovePipelineItem(
						pipeline,
						item,
						destinationStageId,
					),
			);

			return {
				previousPipelines,
				previousPipelineDetail,
			};
		},
		onSuccess: (updatedItem, { item, pipelineId }) => {
			updateQueryData(
				personPipelinesQueryOptions(item.profileId),
				(pipelines) =>
					pipelines.map((pipeline) => ({
						...pipeline,
						items: (pipeline?.items || []).map((oldItem) =>
							oldItem.id === item.id ? updatedItem : oldItem,
						),
					})),
			);
			updateQueryData(
				pipelineDetailQueryOptions(pipelineId),
				(pipeline) => ({
					...pipeline,
					items: (pipeline?.items || []).map((oldItem) =>
						oldItem.id === item.id ? updatedItem : oldItem,
					),
				}),
			);
		},
		onError: (error, { item, pipelineId }, context) => {
			reportError(error);

			if (context?.previousPipelines) {
				updateQueryData(
					personPipelinesQueryOptions(item.profileId),
					context.previousPipelines,
				);
			}

			if (context?.previousPipelineDetail) {
				updateQueryData(
					pipelineDetailQueryOptions(pipelineId),
					context.previousPipelineDetail,
				);
			}
		},
	});
}

export function useUpdatePipelineItem() {
	return useMutation({
		mutationFn: async ({
			itemId,
			pipelineId,
			priority,
		}: {
			itemId: number;
			pipelineId: number;
			priority: PipelineStageItem['priority'];
			profileId: number;
		}) =>
			patch<API_PipelineStageItem>(
				`/pipelines/${pipelineId}/person_profiles/${itemId}`,
				{
					body: {
						priority,
					},
				},
			),
		onMutate: ({ itemId, pipelineId, priority, profileId }) => {
			const previousPipelines = queryClient.getQueryData(
				personPipelinesQueryOptions(profileId).queryKey,
			);
			const previousItem = previousPipelines
				?.find((pipeline) => pipeline.id === pipelineId)
				?.items?.find((item) => item.id === itemId);

			if (previousItem) {
				const newItem = { ...previousItem, priority };
				updateQueryData(
					personPipelinesQueryOptions(previousItem.profile_id),
					(pipelines) =>
						pipelines.map((pipeline) =>
							pipeline.id === pipelineId
								? {
										...pipeline,
										items: (pipeline?.items || []).map(
											(oldItem) =>
												previousItem.id === itemId
													? newItem
													: oldItem,
										),
									}
								: pipeline,
						),
				);
			}

			const previousPipelineDetail = queryClient.getQueryData(
				pipelineDetailQueryOptions(pipelineId).queryKey,
			);

			updateQueryData(
				pipelineDetailQueryOptions(pipelineId),
				(pipeline) => ({
					...pipeline,
					items: (pipeline?.items || []).map((item) =>
						item.id === itemId ? { ...item, priority } : item,
					),
				}),
			);

			return { previousPipelineDetail, previousPipelines };
		},
		onSuccess: (updatedItem, { pipelineId, itemId }) => {
			updateQueryData(
				personPipelinesQueryOptions(updatedItem.profile_id),
				(pipelines) =>
					pipelines.map((pipeline) =>
						pipeline.id === pipelineId
							? {
									...pipeline,
									items: (pipeline?.items || []).map(
										(oldItem) =>
											updatedItem.id === itemId
												? updatedItem
												: oldItem,
									),
								}
							: pipeline,
					),
			);

			updateQueryData(
				pipelineDetailQueryOptions(pipelineId),
				(pipeline) => {
					return {
						...pipeline,
						items: (pipeline?.items || []).map((oldItem) =>
							oldItem.id === itemId ? updatedItem : oldItem,
						),
					};
				},
			);
		},
		onError: (error, { pipelineId, profileId }, context) => {
			reportError(error);

			if (context?.previousPipelines) {
				updateQueryData(
					personPipelinesQueryOptions(profileId),
					context.previousPipelines,
				);
			}

			if (context?.previousPipelineDetail) {
				updateQueryData(
					pipelineDetailQueryOptions(pipelineId),
					context.previousPipelineDetail,
				);
			}
		},
	});
}

async function updateTransitionData(
	pipelineId: number,
	itemId: number,
	transitionId: number,
	data: Array<{
		ruleId: number;
		type: StageTransitionDataRuleType;
		value: StageTransitionDataValue['value'];
		extra: string | null;
	}>,
) {
	const url = `/pipelines/${pipelineId}/person_profiles/${itemId}/transition_data/${transitionId}`;

	return post<API_PipelineStageTransitionDatum[]>(url, {
		body: {
			data: data.map(({ ruleId, type, value, extra }) => {
				const datum: {
					requirement: number;
					type: StageTransitionDataRuleType;
					value: StageTransitionDataValue['value'];
					extra?: string;
				} = {
					requirement: ruleId,
					type,
					value,
				};

				if (extra) {
					datum.extra = extra;
				}

				return datum;
			}),
		},
	});
}

function updateExistingTransitionData(
	pipeline: API_Pipeline,
	newTransitionData: API_PipelineStageTransitionDatum[],
	itemId: number,
): API_Pipeline {
	const newItems = [...(pipeline.items || [])];
	const itemIndex = newItems.findIndex((item) => item.id === itemId);
	if (itemIndex === -1) return pipeline;

	const newItem = {
		...newItems[itemIndex],
		stage_transitions: [...newItems[itemIndex].stage_transitions],
	};
	newItems[itemIndex] = newItem;

	for (const newDatum of newTransitionData) {
		const transitionIndex = newItem.stage_transitions.findIndex(
			({ id }) => id === newDatum.person_profile_transition,
		);

		if (transitionIndex !== -1) {
			const newTransition = {
				...newItem.stage_transitions[transitionIndex],
			};
			const newStateTransitionData = [...newTransition.transition_data];
			const datumIndex = newStateTransitionData.findIndex(
				({ id }) => id === newDatum.id,
			);

			if (datumIndex === -1) {
				newStateTransitionData.push(newDatum);
			} else {
				newStateTransitionData[datumIndex] = newDatum;
			}

			newTransition.transition_data = newStateTransitionData;
			newItem.stage_transitions[transitionIndex] = newTransition;
		}
	}

	return {
		...pipeline,
		items: newItems,
	};
}

export function useUpdatePipelineItemTransitionDatum() {
	return useMutation({
		mutationFn: async ({
			extra,
			itemId,
			pipelineId,
			ruleId,
			transitionId,
			type,
			value,
		}: {
			extra: string | null;
			itemId: number;
			pipelineId: number;
			profileId: number;
			ruleId: number;
			transitionId: number;
			type: StageTransitionDataRuleType;
			value: StageTransitionDataValue['value'];
		}) =>
			updateTransitionData(pipelineId, itemId, transitionId, [
				{ ruleId, type, value, extra },
			]),

		onSuccess: (newTransitionData, { itemId, pipelineId, profileId }) => {
			updateQueryData(
				personPipelinesQueryOptions(profileId),
				(pipelines) => {
					return pipelines.map((pipeline) => {
						if (pipeline.id !== pipelineId) return pipeline;

						return updateExistingTransitionData(
							pipeline,
							newTransitionData,
							itemId,
						);
					});
				},
			);

			updateQueryData(
				pipelineDetailQueryOptions(pipelineId),
				(pipeline) => {
					return updateExistingTransitionData(
						pipeline,
						newTransitionData,
						itemId,
					);
				},
			);
		},
	});
}

function upsertTransitionNote(
	pipeline: API_Pipeline,
	newNote: NoteData,
	itemId: number,
	transitionId: number,
): API_Pipeline {
	const newItems = [...(pipeline.items || [])];
	const itemIndex = newItems.findIndex((item) => item.id === itemId);
	if (itemIndex === -1) return pipeline;

	const newItem = {
		...newItems[itemIndex],
		stage_transitions: [...newItems[itemIndex].stage_transitions],
	};
	newItems[itemIndex] = newItem;
	const transitionIndex = newItem.stage_transitions.findIndex(
		({ id }) => id === transitionId,
	);
	if (transitionIndex === -1) return pipeline;

	const newTransition = {
		...newItem.stage_transitions[transitionIndex],
	};
	const newTransitionNotes = [...newTransition.notes];
	const noteIndex = newTransitionNotes.findIndex(
		({ id }) => id === newNote.id,
	);
	if (noteIndex === -1) {
		newTransitionNotes.push(newNote);
	} else {
		newTransitionNotes[noteIndex] = newNote;
	}

	newTransition.notes = newTransitionNotes;
	newItem.stage_transitions[transitionIndex] = newTransition;

	return {
		...pipeline,
		items: newItems,
	};
}

function updateTransitionNoteState({
	itemId,
	note,
	pipelineId,
	profileId,
	transitionId,
}: {
	itemId: number;
	note: NoteData;
	pipelineId: number;
	profileId: number;
	transitionId: number;
}) {
	updateQueryData(personPipelinesQueryOptions(profileId), (pipelines) => {
		return pipelines.map((pipeline) => {
			if (pipeline.id !== pipelineId) return pipeline;

			return upsertTransitionNote(pipeline, note, itemId, transitionId);
		});
	});

	updateQueryData(pipelineDetailQueryOptions(pipelineId), (pipeline) => {
		return upsertTransitionNote(pipeline, note, itemId, transitionId);
	});
}

export function useCreatePipelineItemTransitionNote(
	itemId: number,
	pipelineId: number,
	profileId: number,
	transitionId: number,
) {
	return useMutation({
		mutationFn: async (note: Pick<NoteData, 'comment' | 'raw_comment'>) =>
			post<NoteData>(
				`/pipelines/${pipelineId}/person_profile_transitions/${transitionId}/notes/`,
				{
					body: { ...note },
				},
			),

		onSuccess: (newNote) => {
			updateTransitionNoteState({
				itemId,
				note: newNote,
				pipelineId,
				profileId,
				transitionId,
			});
		},
	});
}

function removeTransitionNote(
	pipeline: API_Pipeline,
	noteId: number,
	itemId: number,
	transitionId: number,
): API_Pipeline {
	const newItems = [...(pipeline.items || [])];
	const itemIndex = newItems.findIndex((item) => item.id === itemId);
	if (itemIndex === -1) return pipeline;

	const newItem = {
		...newItems[itemIndex],
		stage_transitions: [...newItems[itemIndex].stage_transitions],
	};
	newItems[itemIndex] = newItem;
	const transitionIndex = newItem.stage_transitions.findIndex(
		({ id }) => id === transitionId,
	);
	if (transitionIndex === -1) return pipeline;

	const newTransition = {
		...newItem.stage_transitions[transitionIndex],
	};
	const newTransitionNotes = [...newTransition.notes];
	const noteIndex = newTransitionNotes.findIndex(({ id }) => id === noteId);
	if (noteIndex > -1) {
		newTransitionNotes.splice(noteIndex, 1);
	}

	newTransition.notes = newTransitionNotes;
	newItem.stage_transitions[transitionIndex] = newTransition;

	return {
		...pipeline,
		items: newItems,
	};
}

export function useDeletePipelineItemTransitionNote(
	itemId: number,
	pipelineId: number,
	profileId: number,
	transitionId: number,
) {
	return useMutation({
		mutationFn: async (noteId: number) => remove(`/notes/${noteId}`),
		onSuccess: (_, noteId) => {
			updateQueryData(
				personPipelinesQueryOptions(profileId),
				(pipelines) => {
					return pipelines.map((pipeline) => {
						if (pipeline.id !== pipelineId) return pipeline;

						return removeTransitionNote(
							pipeline,
							noteId,
							itemId,
							transitionId,
						);
					});
				},
			);

			updateQueryData(
				pipelineDetailQueryOptions(pipelineId),
				(pipeline) => {
					return removeTransitionNote(
						pipeline,
						noteId,
						itemId,
						transitionId,
					);
				},
			);
		},
	});
}

export function useUpdatePipelineItemTransitionNote(
	itemId: number,
	pipelineId: number,
	profileId: number,
	transitionId: number,
	artificialDelayMs = 300,
) {
	return useMutation({
		mutationFn: async (
			note: Pick<NoteData, 'comment' | 'id' | 'raw_comment'>,
		) => {
			const startTime = performance.now();
			const response = await patch<NoteData>(`/notes/${note.id}`, {
				body: { ...note },
			});
			const endTime = performance.now();

			if (endTime - startTime < artificialDelayMs) {
				await delay(artificialDelayMs - (endTime - startTime));
			}

			return response;
		},
		onSuccess: (newNote) => {
			updateTransitionNoteState({
				itemId,
				note: newNote,
				pipelineId,
				profileId,
				transitionId,
			});
		},
	});
}
