import { colors, effects } from '@drivecapital/design-system';
import {
	buttonStyle,
	controlButtonStyles,
	iconButtonStyles,
	type Button as ThemeButton,
} from '@drivecapital/design-system/components';
import {
	ChevronDownIcon,
	ChevronUpIcon,
} from '@drivecapital/design-system/icons/arrows';
import { EllipsisHorizontalIcon } from '@drivecapital/design-system/icons/system';
import React, { type ComponentProps, useCallback, useState } from 'react';
import { Button, DialogTrigger, type Popover } from 'react-aria-components';
import styled from 'styled-components';

import { Chevron } from './shared-styling/chevron';
import MenuPopover from './shared-styling/menu-popover';
import { MenuText } from './shared-styling/menu-text';

type ButtonProps = React.ComponentPropsWithoutRef<typeof ThemeButton>;

const StyledControlButton = styled(Button)`
	${controlButtonStyles}
`;
const StyledMenuButton = styled(Button)<ButtonProps>`
	${(props) => (props.mode || props.variant) && buttonStyle}

	${(props) => !(props.mode || props.variant) && effects.shadow.shadow}
	${(props) =>
		!(props.mode || props.variant)
		&& `
		align-items: center;
		appearance: none;
		background: ${colors.button.primary};
		border: none;
		border-radius: 8px;
		cursor: pointer;
		display: flex;
		gap: 4px;
		justify-self: stretch;
		padding: 0 0 0 12px;
		transition: all 0.1s ease-in-out;

		&:hover {
			background: ${colors.button.primaryHover};
		}
	`}
	&:hover {
		${(props) => !(props.mode || props.variant) && effects.shadow.hover}
		${effects.shadow.hover}
	}
`;
const StyledIconButton = styled(Button)<{ size?: 'default' | 'small' }>`
	${iconButtonStyles}
`;

interface Props {
	readonly buttonMode?: ButtonProps['mode'];
	readonly buttonVariant?: ButtonProps['variant'];
	readonly children: React.ReactNode;
	readonly label: string;
	readonly onHoverStart?: () => void;
	readonly placement?: ComponentProps<typeof Popover>['placement'];
}

type _ControlledMenuProps = {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
};
type _UncontrolledMenuProps = {
	isOpen?: never;
	onOpenChange?: never;
};

type MenuProps = Props & (_ControlledMenuProps | _UncontrolledMenuProps);

export default function Menu({
	buttonMode,
	buttonVariant,
	children,
	isOpen,
	label,
	onHoverStart,
	onOpenChange,
	placement,
}: MenuProps) {
	const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
	const useThemeButtonStyling = Boolean(buttonMode || buttonVariant);

	const handleOpenChange = useCallback(
		(val: boolean) => {
			if (onOpenChange) {
				onOpenChange(val);
			} else {
				setUncontrolledIsOpen(val);
			}
		},
		[onOpenChange, setUncontrolledIsOpen],
	);

	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
			<StyledMenuButton
				mode={buttonMode}
				onFocus={onHoverStart}
				onHoverStart={onHoverStart}
				variant={buttonVariant}
			>
				{!useThemeButtonStyling && (
					<>
						<MenuText>{label}</MenuText>{' '}
						<Chevron
							as={
								(isOpen ?? uncontrolledIsOpen)
									? ChevronUpIcon
									: ChevronDownIcon
							}
						/>
					</>
				)}
				{useThemeButtonStyling && (
					<>
						<span>{label}</span>{' '}
						{(isOpen ?? uncontrolledIsOpen) ? (
							<ChevronUpIcon />
						) : (
							<ChevronDownIcon />
						)}
					</>
				)}
			</StyledMenuButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}

type _BaseControlMenuProps = Props & {
	readonly Icon: React.ComponentType;
};

type _ControlledControlMenuProps = {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
};
type _UncontrolledControlMenuProps = {
	isOpen?: never;
	onOpenChange?: never;
};

type ControlMenuProps = _BaseControlMenuProps
	& (_ControlledControlMenuProps | _UncontrolledControlMenuProps);

export function ControlMenu({
	Icon,
	children,
	isOpen,
	label,
	onHoverStart,
	onOpenChange,
	placement,
}: ControlMenuProps) {
	const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);

	const handleOpenChange = useCallback(
		(val: boolean) => {
			if (onOpenChange) {
				onOpenChange(val);
			} else {
				setUncontrolledIsOpen(val);
			}
		},
		[onOpenChange, setUncontrolledIsOpen],
	);

	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
			<StyledControlButton
				variant="secondary"
				active={isOpen ?? uncontrolledIsOpen}
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
			>
				{<Icon />}
				{label}
			</StyledControlButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}

type _BaseIconMenuProps = Omit<Props, 'label'> & {
	readonly Icon?: React.ComponentType;
	readonly buttonSize?: 'default' | 'small';
	readonly disabled?: boolean;
	readonly placement?: ComponentProps<typeof Popover>['placement'];
};

type _ControlledIconMenuProps = {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
};
type _UncontrolledIconMenuProps = {
	isOpen?: never;
	onOpenChange?: never;
};

type IconMenuProps = _BaseIconMenuProps
	& (_ControlledIconMenuProps | _UncontrolledIconMenuProps);

export function IconMenu({
	Icon = EllipsisHorizontalIcon,
	buttonSize,
	children,
	disabled = false,
	isOpen,
	onHoverStart,
	onOpenChange,
	placement,
}: IconMenuProps) {
	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
			<StyledIconButton
				isDisabled={disabled}
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
				size={buttonSize}
			>
				{<Icon />}
			</StyledIconButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}
