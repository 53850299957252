import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import FinishRegistration from '../authentication/registration/finish';
import authorizedRoute, {
	AuthorizedElement,
} from '../components/authorized-route';
import { routes as dataRoomRoutes } from '../dataroom';
import { routes as internalDashboardRoutes } from '../internal-dashboards';
import { routes as kpiRoutes } from '../kpi';
import { routes as lpFundraisingRoutes } from '../lp-fundraising';
import { routes as marketMapRoutes } from '../market-maps';
import { routes as newSearchRoutes } from '../new-search';
import { routes as onePagerRoutes } from '../one-pagers';
import { routes as overdriveRoutes } from '../overdrive';
import { SearchView } from '../search';
import { routes as semiAnnualRoutes } from '../semi-annuals';
import { routes as talentRoutes } from '../talent';
import { routes as watchListRoutes } from '../watchlists';

import { View as Home } from './home';
import Login from './Login';
import Logout from './logout';
import { routes as passwordResetRoutes } from './password-reset';
import ReminderRedirect from './reminder-redirect';
import Root, { ErrorPage } from './root';
import { routes as statsRoutes } from './stats';

const Account = lazy(async () => import('./Account'));

const CompanyDetail = lazy(async () => import('./company'));

const DataRooms = lazy(async () => import('../datarooms'));

const FundTracker = lazy(async () => import('./fund-tracker'));

const PersonDetail = lazy(async () => import('./person'));

const Portfolio = lazy(async () => import('../portfolio/view'));

const Travel = lazy(async () => import('../travel'));

export const routes: Array<RouteObject> = [
	{
		path: '/',
		element: (
			<Root
				unauthenticatedPaths={[
					'/invite/:id/:token',
					'/login',
					'/password/*',
				]}
			/>
		),
		errorElement: <ErrorPage />,
		children: [
			{ index: true, element: <Home /> },
			// START AUTHENTICATION ROUTES
			{ path: 'login', element: <Login /> },
			{ path: 'logout', element: <Logout /> },
			{ path: 'invite/:id/:token', element: <FinishRegistration /> },
			passwordResetRoutes,
			// END AUTHENTICATION ROUTES
			{ path: 'account', element: <Account /> },
			{
				path: 'atlas',
				element: (
					<AuthorizedElement auth="contacts.market_maps">
						<Navigate to="/market-maps" replace />
					</AuthorizedElement>
				),
			},
			{ path: 'companies/:companyId', element: <CompanyDetail /> },
			...dataRoomRoutes,
			authorizedRoute(
				{ path: 'datarooms', element: <DataRooms /> },
				'contacts.dataroom_for_lps',
			),
			{ path: 'fundtracking', element: <FundTracker /> },
			internalDashboardRoutes,
			kpiRoutes,
			lpFundraisingRoutes,
			...newSearchRoutes,
			marketMapRoutes,
			onePagerRoutes,
			overdriveRoutes,
			{ path: 'people/:id', element: <PersonDetail /> },
			authorizedRoute(
				{ path: 'portfolio', element: <Portfolio /> },
				'contacts.kpi',
			),
			authorizedRoute(
				{ path: 'reminders/:id', element: <ReminderRedirect /> },
				'contacts.reminders',
			),
			{ path: 'search', element: <SearchView /> },
			semiAnnualRoutes,
			statsRoutes,
			talentRoutes,
			authorizedRoute(
				{ path: 'travel', element: <Travel /> },
				'contacts.travel',
			),
			watchListRoutes,
		],
	},
];
