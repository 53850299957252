import {
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	MenuPopover,
} from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import React from 'react';
import styled from 'styled-components';

import { FunnelIcon } from '../components/icons';
import { type HeaderArgs, SortableHeader } from '../table';
import SelectColumn from '../table/columns/select';
import noop from '../utils/noop';

import { type Probability, probabilityOptions } from './probability';
import type { LPData } from './types';

const Checkbox = styled.input`
	margin: 0;
`;

const Container = styled.div`
	align-items: baseline;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const Icon = styled(FunnelIcon)`
	height: 16px;
	width: 16px;
`;

const Label = styled.label`
	cursor: inherit;
	display: block;
	padding: 12px;
`;

const LabelText = styled.span`
	margin-left: 6px;
`;

const StyledMenuButton = styled(MenuButton)`
	align-self: center;
	appearance: none;
	background: transparent;
	border: none;
	color: #889;
	cursor: pointer;
	font-size: 0;
	padding: 2px;
	transition: color 0.1s ease;

	&:hover,
	&[aria-expanded='true'] {
		color: #445;
	}
`;

const StyledMenuItem = styled(MenuItem)`
	&[data-reach-menu-item] {
		padding: 0;

		&[data-selected] {
			background: transparent;
			color: inherit;
		}
	}
`;

const StyledMenuItems = styled(MenuItems)`
	/* Increase selector specificity */
	&[data-reach-menu-items] {
		border-width: 0px;
		padding: 0;
	}
`;

const StyledMenuPopover = styled(MenuPopover)`
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	z-index: 24; /* Maximum sticky header z-index + 1 */
`;

function stopPropagation(event: React.SyntheticEvent<HTMLElement>) {
	event.stopPropagation();
}

/**
 * React Context works, but it is not the best possible API to enable filtering
 * in column headers. In an ideal world, the table has native APIs for column-
 * based filters and standard components for header filter options. With this as
 * our sole current use case, we don't yet know what a generic API would need to
 * look like. Consider this a prototype, and if we add similar filters in other
 * tables, we can add a first-class API for it once we better understand the
 * use cases.
 */
export const ExcludeLowProbabilityInvestorsContext = React.createContext<
	readonly [boolean, (value: boolean) => void]
>([true, noop]);

function FilterDropdown() {
	const [excludeLowProbabilityInvestors, setExcludeLowProbabilityInvestors] =
		React.useContext(ExcludeLowProbabilityInvestorsContext);
	const onChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setExcludeLowProbabilityInvestors(!event.currentTarget.checked);
		},
		[setExcludeLowProbabilityInvestors],
	);

	return (
		<Menu>
			<StyledMenuButton onClick={stopPropagation}>
				<Icon />
			</StyledMenuButton>
			<StyledMenuPopover onClick={stopPropagation}>
				<StyledMenuItems>
					<StyledMenuItem onSelect={noop}>
						<Label>
							<Checkbox
								checked={!excludeLowProbabilityInvestors}
								onChange={onChange}
								type="checkbox"
							/>
							<LabelText>Show 5s</LabelText>
						</Label>
					</StyledMenuItem>
				</StyledMenuItems>
			</StyledMenuPopover>
		</Menu>
	);
}

export default class ProbabilityColumn extends SelectColumn<
	LPData,
	`${Probability}`
> {
	public constructor(
		args: Omit<
			ConstructorParameters<
				typeof SelectColumn<LPData, `${Probability}`>
			>[0],
			'options'
		>,
	) {
		super({
			...args,
			options: new Map<`${Probability}`, string>(
				[...probabilityOptions.entries()].map(([key, value]) => [
					`${key}`,
					value,
				]),
			),
		});
	}

	public override header({ onSort, props, sort, sortIndex }: HeaderArgs) {
		return (
			<SortableHeader
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				<Container>
					{this.name}
					<FilterDropdown />
				</Container>
			</SortableHeader>
		);
	}
}
