import { colors } from '@drivecapital/design-system';
import { IconButton as UnstyledIconButton } from '@drivecapital/design-system/components';
import {
	TextEditorBoldIcon,
	TextEditorItalicIcon,
	TextEditorStrikethroughIcon,
	TextEditorUnderlineIcon,
} from '@drivecapital/design-system/icons/document';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
	$getSelection,
	$isRangeSelection,
	COMMAND_PRIORITY_EDITOR,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	gap: 8px;
	grid-area: toolbar;
`;
const IconButton = styled(UnstyledIconButton)<{ $enabled: boolean }>`
	border-radius: 8px;
	${({ $enabled }) =>
		$enabled
			? `color: ${colors.icon.primary};`
			: `color: ${colors.icon.secondary}`};
	${({ $enabled }) =>
		$enabled
			? `background: ${colors.button.secondaryActive};`
			: `background: ${colors.button.secondary}`};
	> svg {
		height: 16px;
		width: 16px;
	}
`;

const emptyFormattingState = {
	bold: false,
	italic: false,
	strikethrough: false,
	underline: false,
} as const;

export type FormattingType = keyof typeof emptyFormattingState;
type FormattingState = Record<FormattingType, boolean>;

const formatToIcon: Record<FormattingType, React.ReactNode> = {
	bold: <TextEditorBoldIcon />,
	italic: <TextEditorItalicIcon />,
	strikethrough: <TextEditorStrikethroughIcon />,
	underline: <TextEditorUnderlineIcon />,
} satisfies Record<FormattingType, React.ReactNode>;

interface FormattingButtonProps {
	readonly enabled: boolean;
	readonly format: FormattingType;
	readonly onClick: (format: FormattingType) => void;
}
function FormattingButton({ enabled, format, onClick }: FormattingButtonProps) {
	const handleClick = useCallback(() => {
		onClick(format);
	}, [format, onClick]);

	return (
		<IconButton
			$enabled={enabled}
			label={`format-${format}`}
			onClick={handleClick}
			onMouseDown={(evt: React.MouseEvent) => {
				// Prevent default on mouse down stops the content editable from bluring
				evt.preventDefault();
			}}
		>
			{formatToIcon[format]}
		</IconButton>
	);
}

interface Props {
	readonly extraControls?: React.ReactNode;
	readonly formats: Array<FormattingType>;
	readonly onFormatApply?: (format: FormattingType) => void;
	readonly open: boolean;
}

export default function ToolBar({
	extraControls,
	formats,
	onFormatApply,
	open,
}: Props) {
	const [editor] = useLexicalComposerContext();
	const [formattingState, setFormattingState] =
		useState<FormattingState>(emptyFormattingState);
	const handleClick = useCallback(
		(format: FormattingType) => {
			editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
			onFormatApply?.(format);
		},
		[editor, onFormatApply],
	);

	useEffect(() => {
		return editor.registerCommand(
			SELECTION_CHANGE_COMMAND,
			() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					setFormattingState({
						bold: selection.hasFormat('bold'),
						italic: selection.hasFormat('italic'),
						strikethrough: selection.hasFormat('strikethrough'),
						underline: selection.hasFormat('underline'),
					});
				}
				return false;
			},
			COMMAND_PRIORITY_EDITOR,
		);
	}, [editor]);

	if (!open) {
		return null;
	}

	return (
		<Container>
			{formats.map((format) => (
				<FormattingButton
					enabled={formattingState[format]}
					format={format}
					key={format}
					onClick={handleClick}
				/>
			))}
			{extraControls}
		</Container>
	);
}
