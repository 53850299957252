import { colors, effects, fonts } from '@drivecapital/design-system';
import { TextInput as ThemeInput } from '@drivecapital/design-system/components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	type Key as AriaKey,
	ComboBox,
	InputContext,
	type InputProps,
	ListBox,
	ListBoxItem,
	Popover as UnstyledPopover,
	useContextProps,
} from 'react-aria-components';
import styled from 'styled-components';

import fuzzyFilter from '../../utils/fuzzy-filter';

const Popover = styled(UnstyledPopover)`
	&[data-trigger='ComboBox'] {
		// 2 1px borders + 12px horizontal padding
		width: calc(var(--trigger-width) + 26px);
		margin-left: -13px;

		.react-aria-ListBox {
			${effects.shadow.hover}
			-webkit-overflow-scrolling: touch;
			background: ${colors.layer.layer};
			border-radius: 8px;
			border: 1px solid ${colors.border.subtle};
			max-height: 20vh;
			overflow-scrolling: touch;
			overflow-x: hidden;
			overflow-y: scroll;
		}

		.react-aria-ListBoxItem {
			${fonts.paragraph.paragraph}
			cursor: pointer;
			padding: 8px 12px;

			span {
				color: ${colors.text.secondary};
			}

			&[data-focus-visible] {
				background: ${colors.layer.hover};
			}

			&[data-hovered] {
				background: ${colors.layer.hover};
			}

			&[data-disabled] {
				color: ${colors.text.disabled};
			}
		}
	}
`;

const SearchInput = React.forwardRef(function Search(
	props: React.ComponentProps<typeof ThemeInput> & InputProps,
	ref: React.ForwardedRef<HTMLInputElement>,
) {
	// https://react-spectrum.adobe.com/react-aria/ComboBox.html#custom-children
	// eslint-disable-next-line no-param-reassign
	[props, ref] = useContextProps(props, ref, InputContext);

	return <ThemeInput {...props} ref={ref} />;
});

interface Item {
	text: string;
	id: number | string;
	subtext?: string;
}

interface Props {
	readonly allOptions: Item[];
	readonly className?: string;
	readonly defaultOptions: Item[];
	readonly defaultValue?: string;
	readonly disabled?: boolean;
	readonly id: string;
	readonly label: string;
	readonly limit?: number;
	readonly onSubmit: (id: number | string | null) => void;
	readonly placeholder: string;
}

export default function TextComboBox({
	allOptions,
	className,
	defaultOptions,
	defaultValue = '',
	disabled,
	label,
	id,
	limit,
	onSubmit,
	placeholder,
}: Props) {
	const [input, setInput] = useState<string>('');

	useEffect(() => {
		setInput(defaultValue);
	}, [defaultValue]);

	const items = useMemo(() => {
		if (input === '') return defaultOptions;
		return fuzzyFilter(
			allOptions,
			input,
			(item) => `${item.text} ${item?.subtext ?? ''}`,
			true,
		).slice(0, limit);
	}, [allOptions, defaultOptions, input, limit]);

	const handleBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			if (value !== '') {
				onSubmit(event.currentTarget.value);
			}
		},
		[onSubmit],
	);
	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setInput(event.currentTarget.value);
		},
		[],
	);
	const handleSelectionChange = useCallback(
		(key: AriaKey | null) => {
			if (key != null) {
				onSubmit(key);
			}
		},
		[onSubmit],
	);

	return (
		<ComboBox
			allowsCustomValue
			allowsEmptyCollection
			aria-label={id}
			className={className}
			isDisabled={disabled}
			items={items}
			menuTrigger="focus"
			name={id}
			onBlur={handleBlur}
			onSelectionChange={handleSelectionChange}
			onOpenChange={(isOpen) => {
				if (!isOpen) setInput('');
			}}
		>
			<SearchInput
				label={label}
				mode="light"
				onChange={handleInputChange}
				placeholder={placeholder}
				type="search"
				value={input}
			/>
			<Popover>
				<ListBox<Item>>
					{(item) => (
						<ListBoxItem id={item.id} textValue={item.text}>
							{item.text} <span>{item.subtext}</span>
						</ListBoxItem>
					)}
				</ListBox>
			</Popover>
		</ComboBox>
	);
}
