// @flow

import listify from '@drivecapital/listify';
import React from 'react';
import { connect } from 'react-redux';

import './admin-toolbar.scss';
import Authorized, * as auth from '../../components/Authorized';
import type { State as StoreState } from '../../reducers';
import { getEntityByTypeAndId } from '../../selectors';
import type { SelectedSet } from '../view';

import MergeButton from './merge-button';
import RobotButton from './robot-button';

type OwnProps = {| selected: SelectedSet |};
type StateProps = {| names: Array<string> |};
type Props = {|
	...OwnProps,
	...StateProps,
|};

class SearchAdminToolbar extends React.Component<Props> {
	render() {
		const {
			selected: { companies, people },
		} = this.props;

		if (companies.length === 0 && people.length === 0) return null;

		return (
			<Authorized
				auth={auth.or('contacts.mark_robot', 'contacts.merge_profiles')}
			>
				<div className="SearchAdminToolbar">
					<RobotButton selected={this.props.selected} />
					<MergeButton selected={this.props.selected} />
					<p className="SearchAdminToolbar-names">
						{listify(this.props.names)}
					</p>
				</div>
			</Authorized>
		);
	}
}

export default connect<_, OwnProps, _, _, _, _>(
	(state: StoreState, props: OwnProps): StateProps => ({
		names: [
			...props.selected.companies.map(
				(id) => getEntityByTypeAndId(state, 'companies', id).name,
			),
			...props.selected.people.map((id) => {
				const { first_name, last_name } = getEntityByTypeAndId(
					state,
					'people',
					id,
				);
				return `${first_name} ${last_name}`;
			}),
		],
	}),
	() => ({}),
)(SearchAdminToolbar);
