import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';

import * as colors from '../colors';
import Authorized from '../components/Authorized';
import {
	Buttons,
	NormalState,
	useHeaderActions,
} from '../components/buttons/header_actions';
import { EditIcon, NewTrashIcon } from '../components/icons';
import ListSearchField from '../components/list-search-field';
import Download from '../components/watchlists/download';
import EditColumnDescriptors from '../watchlists/edit/column-descriptors';

import MoveToPipeline from './move-to-pipeline';
import type { CandidatePool } from './types';

const Container = styled.div`
	padding: 8px;
	background-color: ${colors.backgroundGray.string()};
`;

const NormalContainer = styled(Container)`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const EditingContainer = styled(Container)`
	display: flex;
	width: 100%;
	align-items: flex-start;
`;
const ColumnsContainer = styled.div`
	width: 100%;
	padding: 0 8px;
`;

const ActionsContainer = styled.div`
	display: flex;
	align-items: center;
`;

const IconDivider = styled.div`
	border-left: 1px solid ${colors.disabledGray.lighten(0.3).string()};
	height: 28px;
	width: 1px;
	margin: 0 10px 0 4px;
`;
const StyledDownload = styled(Download)`
	svg {
		height: 30px;
		width: 30px;
		fill: ${colors.charcoalGray.string()};
	}
`;
const StyledEditIcon = styled(EditIcon)<{ disabled: boolean }>`
	margin: 0 0 0 2px;
	height: 24px;
	width: 24px;
	color: ${colors.charcoalGray.string()};

	${({ disabled }) =>
		disabled
			? `
	color: ${colors.disabledGray.string()};
	&:hover {
		cursor: not-allowed;
	}
			`
			: `
	&:hover {
		cursor: pointer;
		color: ${colors.charcoalGray.darken(1).string()};
	}
		`}
`;
const StyledTrashIcon = styled(NewTrashIcon)<{ disabled: boolean }>`
	height: 26px;
	width: 26px;
	color: ${({ disabled }) =>
		disabled ? colors.disabledGray.string() : colors.charcoalGray.string()};
	margin-left: 8px;

	${({ disabled }) =>
		disabled
			? `
	&:hover {
		cursor: not-allowed;
	}
	`
			: `
	&:hover {
		cursor: pointer;
		color: ${colors.red.string()};
	}
			`}
`;

const StyledSearch = styled(ListSearchField)`
	border: 1px solid ${colors.herbieGray.string()};
	box-shadow: none;
	min-width: 250px;
`;

const StyledButtons = styled(Buttons)`
	button {
		margin-top: 0;
	}
`;

export default function CandidatePoolHeader({
	columnDescriptors,
	isEditor,
	isOwner,
	onDownload,
	onItemsDelete,
	onMoveToPipeline,
	onSearch,
	onSave,
	search,
	selectedItemCount,
}: {
	readonly columnDescriptors: CandidatePool['columns'];
	readonly isEditor: boolean;
	readonly isOwner: boolean;
	readonly onDownload: () => void;
	readonly onItemsDelete: () => Promise<void>;
	readonly onMoveToPipeline: () => Promise<void>;
	readonly onSearch: (search: string) => void;
	readonly onSave: (newColumns: CandidatePool['columns']) => Promise<void>;
	readonly search: string;
	readonly selectedItemCount: number;
}): JSX.Element {
	const [actionState, transitionActionState] = useHeaderActions();
	const editedColumnDescriptors = useRef<EditColumnDescriptors | null>(null);
	const handleSave = useCallback(async () => {
		if (!editedColumnDescriptors.current) return Promise.resolve();
		return onSave(editedColumnDescriptors.current.getColumnDescriptors());
	}, [onSave]);
	const handleEditClick = useCallback(() => {
		if (actionState instanceof NormalState) {
			transitionActionState(actionState.onEdit());
		}
	}, [actionState, transitionActionState]);

	if (actionState instanceof NormalState) {
		return (
			<NormalContainer>
				<StyledSearch
					defaultValue={search}
					onChange={(event) => {
						onSearch(event.target.value);
					}}
					placeholder="Search names or locations"
					type="text"
				/>
				<ActionsContainer>
					{isEditor && (
						<MoveToPipeline
							disabled={selectedItemCount <= 0}
							onMove={onMoveToPipeline}
						/>
					)}
					{isEditor && (
						<StyledTrashIcon
							disabled={selectedItemCount <= 0}
							onClick={onItemsDelete}
						/>
					)}
					<Authorized auth="contacts.watchlists_export">
						<StyledDownload onDownload={onDownload} />
					</Authorized>
					{isOwner && (
						<>
							<IconDivider />{' '}
							<StyledEditIcon
								disabled={selectedItemCount > 0}
								onClick={handleEditClick}
							/>
						</>
					)}
				</ActionsContainer>
			</NormalContainer>
		);
	}

	return (
		<EditingContainer>
			<ColumnsContainer>
				<EditColumnDescriptors
					columnDescriptors={columnDescriptors}
					protectedDescriptorKeys={['logo_url', 'name']}
					ref={editedColumnDescriptors}
				/>
			</ColumnsContainer>
			<StyledButtons
				state={actionState}
				transition={transitionActionState}
				onSave={handleSave}
			/>
		</EditingContainer>
	);
}
