import classnames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import {
	ComboBox as AriaComboBox,
	type Key as AriaKey,
	ListBox,
	ListBoxItem,
	Popover as UnstyledPopover,
} from 'react-aria-components';
import styled from 'styled-components';

import { colors, effects, fonts } from '../..';
import { AriaTextInput } from '../text-input';

const comboBoxClassName = 'HerbieComboBox';
const popoverClassName = `${comboBoxClassName}__Popover`;
const inputClassName = `${comboBoxClassName}__Input`;
const listBoxClassName = `${comboBoxClassName}__ListBox`;
const listItemClassName = `${comboBoxClassName}__ListBoxItem`;

const Popover = styled(UnstyledPopover)`
	&[data-trigger='ComboBox'] {
		// 2 1px borders + 12px horizontal padding
		width: calc(var(--trigger-width) + 26px);
		margin-left: -13px;

		.${listBoxClassName} {
			${effects.shadow.hover}
			-webkit-overflow-scrolling: touch;
			background: ${colors.layer.layer};
			border-radius: 8px;
			border: 1px solid ${colors.border.subtle};
			max-height: 20vh;
			overflow-scrolling: touch;
			overflow-x: hidden;
			overflow-y: scroll;
		}

		.${listItemClassName} {
			${fonts.paragraph.paragraph}
			cursor: pointer;
			padding: 8px 12px;

			&[data-focus-visible] {
				background: ${colors.layer.hover};
			}

			&[data-hovered] {
				background: ${colors.layer.hover};
			}

			&[data-disabled] {
				color: ${colors.text.disabled};
			}
		}
	}
`;

interface MinimumItem {
	id: number | string;
	text: string;
}

function defaultFilterItem(item: MinimumItem, query: string): boolean {
	return item.text.toLowerCase().includes(query.toLowerCase());
}

function defaultRenderItem(item: MinimumItem): React.ReactNode {
	return item.text;
}

interface BaseProps<T extends MinimumItem> {
	readonly className?: string;
	readonly defaultItems?: Array<T>;
	readonly filterItem?: (item: T, query: string) => boolean;
	readonly id: string;
	readonly inputType?: React.ComponentPropsWithoutRef<
		typeof AriaTextInput
	>['type'];
	readonly isDisabled?: boolean;
	readonly items: Array<T>;
	readonly label: React.ComponentPropsWithoutRef<
		typeof AriaTextInput
	>['label'];
	readonly onSelect?: (item: T | null) => void;
	readonly openOn?: React.ComponentPropsWithoutRef<
		typeof AriaComboBox
	>['menuTrigger'];
	readonly placeholder?: React.ComponentPropsWithoutRef<
		typeof AriaTextInput
	>['placeholder'];
	readonly renderItem?: (item: T) => React.ReactNode;
}

interface ControlledProps<T extends MinimumItem> extends BaseProps<T> {
	readonly inputValue: string;
	readonly onInputValueChange: (value: string) => void;
}

export function ControlledComboBox<T extends MinimumItem>({
	className,
	defaultItems = [],
	filterItem = defaultFilterItem,
	id,
	inputType,
	inputValue,
	isDisabled = false,
	items,
	label,
	onInputValueChange,
	onSelect,
	openOn = 'focus',
	placeholder,
	renderItem = defaultRenderItem,
}: ControlledProps<T>) {
	const filteredItems = useMemo(() => {
		if (inputValue === '') return defaultItems;
		return items.filter((item) => filterItem(item, inputValue));
	}, [defaultItems, filterItem, inputValue, items]);
	const handleSelectionChange = useCallback(
		(key: AriaKey | null) => {
			const foundItem = items.find((item) => item.id === key);
			onSelect?.(foundItem ?? null);
		},
		[items, onSelect],
	);
	const handleBlur = useCallback(() => {
		if (inputValue === '') {
			onSelect?.(null);
		}
	}, [inputValue, onSelect]);

	return (
		<AriaComboBox
			allowsCustomValue
			allowsEmptyCollection
			aria-label={id}
			className={classnames('HerbieComboBox', className)}
			inputValue={inputValue}
			isDisabled={isDisabled}
			items={filteredItems}
			menuTrigger={openOn}
			name={id}
			onBlur={handleBlur}
			onInputChange={onInputValueChange}
			onSelectionChange={handleSelectionChange}
		>
			<AriaTextInput
				className={inputClassName}
				disabled={isDisabled}
				label={label}
				placeholder={placeholder}
				type={inputType}
			/>
			<Popover className={popoverClassName}>
				<ListBox<T> className={listBoxClassName}>
					{(item) => (
						<ListBoxItem
							className={listItemClassName}
							id={item.id}
							textValue={item.text}
						>
							{renderItem(item)}
						</ListBoxItem>
					)}
				</ListBox>
			</Popover>
		</AriaComboBox>
	);
}

interface UncntrolledProps<T extends MinimumItem> extends BaseProps<T> {
	readonly defaultInputValue?: string;
}

export function UncontrolledComboBox<T extends MinimumItem>({
	defaultInputValue = '',
	onSelect,
	...props
}: UncntrolledProps<T>) {
	const [input, setInput] = useState(defaultInputValue);
	const handleSelect = useCallback(
		(item: T | null) => {
			setInput(item?.text ?? '');
			onSelect?.(item);
		},
		[onSelect],
	);

	return (
		<ControlledComboBox
			{...props}
			inputValue={input}
			onInputValueChange={setInput}
			onSelect={handleSelect}
		/>
	);
}
