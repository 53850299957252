import React, { useCallback } from 'react';

import { Radio, RadioGroup } from '../../components/radio-group';

import type {
	FilterControlHeaderProps,
	FilterControlProps,
	IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import type { SearchQueryStrengthFilter } from './types';

const FIELD_NAME: SearchQueryStrengthFilter['fieldName'] =
	'searchQueryStrength';
function FilterControl({
	filter,
	onChange,
}: FilterControlProps<SearchQueryStrengthFilter>) {
	return (
		<RadioGroup
			label="Search Query Strength"
			onChange={(value: string) =>
				value != null
				&& onChange({
					fieldName: FIELD_NAME,
					value: value as SearchQueryStrengthFilter['value'],
				})
			}
			value={filter?.value ?? null}
		>
			<Radio value="VERY STRONG">Only the strongest matches</Radio>
			<Radio value="STRONG">Allow some looser matches</Radio>
			<Radio value="AVERAGE">Include all matches (default)</Radio>
		</RadioGroup>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<SearchQueryStrengthFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Search Query Strength"
		/>
	);
}

const Control: IFilterControl<SearchQueryStrengthFilter> = {
	control: FilterControl,
	description: () => {
		return [];
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default Control;
