import { queryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { updateCompaniesMarketMaps } from '../market-maps/api';
import { get, post, queryClient, updateQueryData } from '../utils/api';
import identity from '../utils/identity';

import type {
	SearchRequest,
	SearchResults,
	SearchResultsDismissal,
} from './types';
import type { EntityType } from './use-entity-type';
import { toApi } from './use-search-query';
import { isSearchRequestEmpty } from './utils';

async function fetchSearchResults(
	searchRequest: SearchRequest,
	signal?: AbortSignal,
): Promise<SearchResults> {
	if (isSearchRequestEmpty(searchRequest)) {
		return {
			companies: { data: [], entityType: 'companies', total: 0 },
			people: { data: [], entityType: 'people', total: 0 },
		};
	}

	return get<SearchResults>('/search', {
		signal,
		query: {
			entity_type: searchRequest.entityType,
			filter: searchRequest.filters.map((filter) =>
				JSON.stringify(filter),
			),
			pagination: JSON.stringify(searchRequest.pagination),
			query: toApi(searchRequest.query),
			query_type: searchRequest.queryType,
			sorting: searchRequest.sorting.map((sort) => JSON.stringify(sort)),
		},
	});
}

function searchResultsQueryOptions({
	pagination,
	...searchRequest
}: SearchRequest) {
	return queryOptions({
		queryKey: ['searchResults', searchRequest, pagination],
		queryFn: async ({ signal }) => {
			const searchResults = await fetchSearchResults(
				{ ...searchRequest, pagination },
				signal,
			);

			updateCompaniesMarketMaps(
				searchResults.companies.data.map(
					({ id: companyId, market_maps: marketMaps }) => ({
						companyId,
						marketMaps,
					}),
				),
			);

			return {
				searchRequest: { ...searchRequest, pagination },
				searchResults,
			};
		},
		placeholderData: identity,
		staleTime: 2 * 60 * 1000, // 2 minutes
	});
}

export default function useSearchResults(searchRequest: SearchRequest) {
	return useQuery(searchResultsQueryOptions(searchRequest));
}

export function refreshSearchResults(searchRequest: SearchRequest) {
	const queryKey = searchResultsQueryOptions(searchRequest).queryKey;
	void queryClient.invalidateQueries({
		// Invalidate all pages of the search results
		queryKey: queryKey.slice(0, queryKey.length - 1),
	});
}

async function dismissSearchResultsMutationFn({
	attributionType,
	dismissedEntities,
	query,
	reason,
	queryType,
}: SearchResultsDismissal) {
	return post('/search/dismiss', {
		body: {
			attribution_type: attributionType,
			dismissed_entities: dismissedEntities,
			query: query.map((q) => ({
				...q,
				embedding_model_version: q.embeddingModelVersion,
			})),
			query_type: queryType,
			reason,
		},
	});
}

function updateDismissedSearchResults(
	dismissal: SearchResultsDismissal,
	entityType: EntityType,
	searchResults: SearchResults,
): SearchResults {
	switch (entityType) {
		case 'companies':
			return {
				...searchResults,
				companies: {
					...searchResults.companies,
					data: searchResults.companies.data.filter(
						({ id }) => !dismissal.dismissedEntities.includes(id),
					),
					total:
						searchResults.companies.total
						- dismissal.dismissedEntities.length,
				},
			};
		case 'people':
			return {
				...searchResults,
				people: {
					...searchResults.people,
					data: searchResults.people.data.filter(
						({ id }) => !dismissal.dismissedEntities.includes(id),
					),
					total:
						searchResults.people.total
						- dismissal.dismissedEntities.length,
				},
			};
		default:
			return searchResults;
	}
}

export function useDismissSearchResults(searchRequest: SearchRequest) {
	return useMutation({
		mutationFn: dismissSearchResultsMutationFn,
		onSuccess: (_, variables) => {
			updateQueryData(
				searchResultsQueryOptions(searchRequest),
				(queryData) => ({
					...queryData,
					searchResults: updateDismissedSearchResults(
						variables,
						searchRequest.entityType,
						queryData.searchResults,
					),
				}),
			);
		},
	});
}

export async function prefetchSearchResults(
	searchRequest: SearchRequest,
): Promise<void> {
	await queryClient.prefetchQuery(searchResultsQueryOptions(searchRequest));
}

function similarCompaniesProfileSearchQueryOptions(id: number) {
	return queryOptions({
		queryKey: ['searchResults', 'similarCompanies', id],
		queryFn: async ({ signal }) =>
			get<{
				results: SearchResults;
				founded_year: number | null;
				market_maps: number[] | null;
			}>(`/companies/${id}/similar_companies`, {
				signal,
			}),
		staleTime: 2 * 60 * 1000, // 2 minutes
	});
}

export function useDismissSimilarCompaniesProfileSearchResults(id: number) {
	return useMutation({
		mutationFn: dismissSearchResultsMutationFn,
		onSuccess: (_, variables) => {
			updateQueryData(
				similarCompaniesProfileSearchQueryOptions(id),
				({ results, ...queryData }) => {
					return {
						results: updateDismissedSearchResults(
							variables,
							'companies',
							results,
						),
						...queryData,
					};
				},
			);
		},
	});
}

export function useSimilarCompaniesProfileSearch(id: number) {
	return useQuery(similarCompaniesProfileSearchQueryOptions(id));
}

export async function prefetchSimilarCompaniesProfileSearch(
	id: number,
): Promise<void> {
	await queryClient.prefetchQuery(
		similarCompaniesProfileSearchQueryOptions(id),
	);
}
