import { colors, effects, fonts } from '@drivecapital/design-system';
import { TextInput as ThemeInput } from '@drivecapital/design-system/components';
import numeral from 'numeral';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	type Key as AriaKey,
	ComboBox,
	InputContext,
	type InputProps,
	ListBox,
	ListBoxItem,
	Popover as UnstyledPopover,
	useContextProps,
} from 'react-aria-components';
import styled from 'styled-components';

function defaultFormatter(value: number) {
	return numeral(value).format('0,0');
}

function parseFormattedString(value: string) {
	return numeral(value).value();
}

function safeParseInt(value: string) {
	const parsed = parseInt(value, 10);
	return Number.isNaN(parsed) ? null : parsed;
}

const Form = styled.form``;
const Popover = styled(UnstyledPopover)`
	&[data-trigger='ComboBox'] {
		// 2 1px borders + 12px horizontal padding
		width: calc(var(--trigger-width) + 28px);
		margin-left: -13px;

		.react-aria-ListBox {
			${effects.shadow.hover}
			-webkit-overflow-scrolling: touch;
			background: ${colors.layer.layer};
			border-radius: 8px;
			border: 1px solid ${colors.border.subtle};
			max-height: 20vh;
			overflow-scrolling: touch;
			overflow-x: hidden;
			overflow-y: scroll;
		}

		.react-aria-ListBoxItem {
			${fonts.paragraph.paragraph}
			cursor: pointer;
			font-variant-numeric: tabular-nums;
			padding: 8px 12px;

			&[data-focus-visible] {
				${fonts.paragraph.strong}
				background: ${colors.layer.hover};
			}

			&[data-hovered] {
				${fonts.paragraph.strong}
				background: ${colors.layer.hover};
			}
		}
	}
`;

const SearchInput = React.forwardRef(function Search(
	props: React.ComponentProps<typeof ThemeInput> & InputProps,
	ref: React.ForwardedRef<HTMLInputElement>,
) {
	// https://react-spectrum.adobe.com/react-aria/ComboBox.html#custom-children
	// eslint-disable-next-line no-param-reassign
	[props, ref] = useContextProps(props, ref, InputContext);

	return <ThemeInput {...props} ref={ref} />;
});

interface Item {
	formattedText: string;
	id: number;
	rawText: string;
}

function buildItem(value: number, format: typeof defaultFormatter): Item {
	return {
		formattedText: format(value),
		id: value,
		rawText: value.toString(),
	};
}

interface Props {
	readonly allOptions: number[];
	readonly className?: string;
	readonly defaultOptions: number[];
	readonly defaultValue: number | null;
	readonly format?: typeof defaultFormatter;
	readonly id: string;
	readonly label: string;
	readonly onSubmit: (value: number | null) => void;
	readonly placeholder: string;
}

export default function IntegerComboBox({
	allOptions,
	className,
	defaultOptions,
	defaultValue,
	format = defaultFormatter,
	label,
	id,
	onSubmit,
	placeholder,
}: Props) {
	const [inputItem, setInputItem] = useState<Item | null>(
		defaultValue == null ? null : buildItem(defaultValue, format),
	);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		setInputItem(
			defaultValue == null ? null : buildItem(defaultValue, format),
		);
	}, [defaultValue, format]);

	const allItems = useMemo(
		() => allOptions.map((value) => buildItem(value, format)),
		[allOptions, format],
	);
	const defaultItems = useMemo(
		() => defaultOptions.map((value) => buildItem(value, format)),
		[defaultOptions, format],
	);
	const items = useMemo(() => {
		if (inputItem == null) return defaultItems;
		return allItems.filter((item) =>
			item.rawText.startsWith(inputItem.rawText),
		);
	}, [allItems, defaultItems, inputItem]);

	const handleBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			const parsed = safeParseInt(event.currentTarget.value);
			onSubmit(parsed);
		},
		[onSubmit],
	);
	const handleFormSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			const formData = new FormData(event.currentTarget);
			const parsed = safeParseInt(formData.get(id) as string);
			onSubmit(parsed);
		},
		[id, onSubmit],
	);
	const handleInputChange = useCallback(
		(value: string) => {
			const parsed = parseFormattedString(value);
			if (parsed == null) {
				setInputItem(null);
			} else {
				setInputItem(buildItem(parsed, format));
			}
		},
		[format],
	);
	const handleInputKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				const parsed = safeParseInt(event.currentTarget.value);
				onSubmit(parsed);
			}
		},
		[onSubmit],
	);
	const handleSelectionChange = useCallback(
		(key: AriaKey | null) => {
			if (key != null) {
				const parsed =
					typeof key === 'number' ? key : safeParseInt(key);
				onSubmit(parsed);
			}
		},
		[onSubmit],
	);

	return (
		<Form className={className} onSubmit={handleFormSubmit}>
			<ComboBox
				allowsCustomValue
				aria-label={id}
				formValue="key"
				inputValue={
					isFocused
						? inputItem?.rawText || ''
						: inputItem?.formattedText || ''
				}
				items={items}
				menuTrigger="focus"
				name={id}
				onBlur={handleBlur}
				onFocusChange={setIsFocused}
				onInputChange={handleInputChange}
				onKeyDown={handleInputKeyDown}
				onSelectionChange={handleSelectionChange}
			>
				<SearchInput
					label={label}
					mode="light"
					placeholder={placeholder}
				/>
				<Popover>
					<ListBox<Item>>
						{(item) => (
							<ListBoxItem id={item.id} textValue={item.rawText}>
								{item.formattedText}
							</ListBoxItem>
						)}
					</ListBox>
				</Popover>
			</ComboBox>
		</Form>
	);
}
