import React, {
	createContext,
	type RefObject,
	useContext,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';

import noop from '../../../utils/noop';

const Node = styled.div`
	min-height: 0;
	overflow-x: hidden;
	overflow-y: scroll;
`;

type ScrollRefUpdater = (scrollRef?: RefObject<Element>) => void;

/**
 * Unintuitively, context doesn't contain the `scrollRef`. Instead, it contains
 * the callback through which the scroll container, if it exists, can update the
 * `scrollRef`. Inverting the usual arrangement, the `scrollRef` itself needs to
 * live in state created outside of the provider so that the same component can
 * both pass the `scrollRef` to the `<Popover>` and render the context provider.
 */
const ScrollRefUpdaterContext = createContext<ScrollRefUpdater>(
	// Providing a no-op function as the context's default value allows the
	// `<Scroller>` to be used independent of a `<ScrollRefProvider>`.
	noop,
);

export const ScrollRefProvider = ScrollRefUpdaterContext.Provider;

/**
 * Provides the correct `scrollRef` to the `react-aria-components` `<Popover>`
 * when a `<Scroller>` is used inside the `<Popover>`.
 *
 * By default, the `<Popover>` automatically calculates when its contents need
 * more space than is available in the preferred placement (e.g. bottom left)
 * and will flip to the opposite side (e.g. top left) if that would give more
 * space.
 *
 * When a scroll container is nested inside of the `<Popover>`'s `children`, we
 * need to pass a `scrollRef` to the `<Popover>` so that it can use the scroll
 * container's full scroll height when calculating the desired size and ideal
 * placement.
 *
 * This context allows the `<Scroller>` component below to self-report its
 * existence by creating a `scrollRef`. When no `<Scroller>` is present, the
 * `scrollRef` will be `undefined`, allowing the `<Popover>` to default the
 * scroll container to the outermost child.
 *
 * @returns A `scrollRef`, which should be passed to a `<Popover>`, and the
 * `value` that should be passed to the `<ScrollRefProvider>`.
 *
 * @example
 * ```tsx
 * function MyPopover({ children }) {
 * 	const { scrollRef, value } = useScrollRefProvider();
 *
 * 	return (
 * 		<Popover scrollRef={scrollRef}>
 * 			<ScrollRefProvider value={value}>
 * 				{children}
 * 			</ScrollRefProvider>
 * 		</Popover>
 * 	);
 * }
 * ```
 */
export function useScrollRefProvider(): {
	/**
	 * Unfortunately, `<Popover>` internally defaults `scrollRef = popoverRef`
	 * during destructuring, so the default `popoverRef` value is only applied
	 * if `scrollRef` is `undefined`.
	 *
	 * Ideally, `<Popover>` would've instead done `scrollNode =
	 * scrollRef.current ?? popoverRef.current`. Then, we could've always passed
	 * a plain `RefObject<Element | null>` as the `scrollRef`.
	 *
	 * Instead, we have to pass either a `RefObject<Element>` or `undefined` to
	 * the `<Popover>` based on whether the `<Scroller>` is present. That means
	 * that instead of a simple `useRef<Element>(null)`, we have to keep
	 * `RefObject<Element> | undefined` in state and update that state after the
	 * first render with a `useLayoutEffect()`.
	 */
	scrollRef: RefObject<Element> | undefined;
	value: ScrollRefUpdater;
} {
	const [scrollRef, value] = useState<RefObject<Element> | undefined>();
	return { scrollRef, value };
}

/**
 * When using a custom scroll container other than `<Scroller>` inside of a
 * `<MenuPopover>`, call this hook and pass the returned `ref` to the custom
 * scroll container.
 *
 * @example
 * ```tsx
 * function MyScrollContainer({ children }) {
 * 	const scrollRef = useScrollRef<HTMLDivElement>();
 *
 * 	return (
 * 		<StyledScrollContainer ref={scrollRef}>
 * 			{children}
 * 		</StyledScrollContainer>
 * 	);
 * }
 * ```
 */
export function useScrollRef<T extends Element>(): RefObject<T> {
	const scrollRef = useRef<T>(null);
	const updateScrollRef = useContext(ScrollRefUpdaterContext);

	// Renders must be pure, so we have to wait until the layout phase to update
	// the `scrollRef`.
	useLayoutEffect(() => {
		updateScrollRef(scrollRef.current ? scrollRef : void 0);
	}, [scrollRef, updateScrollRef]);

	return scrollRef;
}

export default function Scroller({
	children,
}: {
	readonly children: React.ReactNode;
}) {
	const scrollRef = useScrollRef<HTMLDivElement>();

	return <Node ref={scrollRef}>{children}</Node>;
}
