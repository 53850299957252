/**
 * @file
 * Chips are used to call out e.g. Drive portfolio companies or "+3" quantity
 * indicators.
 *
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=261%3A122212&mode=dev
 */
import React from 'react';
import styled from 'styled-components';

import { colors, fonts } from '..';
import { ChevronDownIcon, ChevronUpIcon } from '../icons/arrows';
import { DriveCapitalLogomarkIcon } from '../icons/brand';

const Container = styled.span<{
	background: string;
	border?: string;
	color: string;
	size: 'default' | 'small';
}>`
	align-items: center;
	${({ border }) => border && `border: 1px solid ${border};`}
	border-radius: 9999px;
	background: ${(props) => props.background};
	color: ${(props) => props.color};
	display: inline-flex;
	gap: 2px;
	padding: ${(props) => (props.size === 'default' ? '2px 8px' : '0px 8px')};

	svg {
		flex-shrink: 0;
		height: 12px;
		width: 12px;
	}
`;

const Label = styled.span`
	${fonts.label.label}
`;

interface Props extends React.ComponentPropsWithoutRef<'span'> {
	readonly background: string;
	readonly border?: string;
	readonly color: string;
	readonly icon?: React.ReactNode;
	readonly label?: React.ReactNode;
	readonly menu?: boolean;
	readonly menuDirection?: 'up' | 'down';
	readonly size?: 'default' | 'small';
}

export default function Chip({
	background,
	menu = false,
	menuDirection = 'down',
	color,
	icon,
	label,
	size = 'default',
	...props
}: Props): React.JSX.Element {
	return (
		<Container {...props} background={background} color={color} size={size}>
			{icon}
			{label && <Label>{label}</Label>}
			{menu
				&& (menuDirection === 'down' ? (
					<ChevronDownIcon />
				) : (
					<ChevronUpIcon />
				))}
		</Container>
	);
}

export function PortfolioChip() {
	return (
		<Chip
			background={colors.data.blue.layerSubtle}
			color={colors.data.blue.textSecondary}
			icon={<DriveCapitalLogomarkIcon />}
			label="Drive Portfolio"
		/>
	);
}

export function LPChip() {
	return (
		<Chip
			background={colors.data.blue.layerSubtle}
			color={colors.data.blue.textSecondary}
			icon={<DriveCapitalLogomarkIcon />}
			label="Drive LP"
		/>
	);
}
