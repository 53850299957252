import querystring from 'querystring';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import HerbieLoader from '../components/herbie-loader';
import { mixpanelProjectId } from '../config';
import { get } from '../utils/api';
import useAbortableEffect from '../utils/hooks/use-abortable-effect';
import reportError from '../utils/sentry';

const CenteredLoader = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
`;

export default function useMixpanelInsights<T>(
	bookmark_id: string,
): [T | null, () => JSX.Element] {
	const [data, setData] = useState<T | null>(null);

	useAbortableEffect(
		useCallback(
			async (signal) => {
				try {
					const mixpanelPassword = await get<string>(
						'/internal_dashboards/herbie_usage',
						{ signal },
					);
					const url = new URL(
						'https://mixpanel.com/api/2.0/insights',
					);
					url.search = querystring.stringify({
						project_id: mixpanelProjectId,
						bookmark_id,
					});
					const request = new Request(url.href, {
						method: 'GET',
						headers: {
							Accept: 'application/json',
							Authorization: `Basic ${mixpanelPassword}`,
						},
						signal,
					});
					const response = await fetch(request);
					setData((await response.json()) as T | null);
				} catch (error) {
					if (error instanceof Error) {
						reportError(error);
					} else {
						console.error(error);
					}
				}
			},
			[bookmark_id],
		),
	);

	return [
		data,
		() => (
			<CenteredLoader>
				<HerbieLoader />
			</CenteredLoader>
		),
	];
}
