import { colors, effects, fonts } from '@drivecapital/design-system';
import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
	${effects.shadow.elevated};
	border: 1px solid ${colors.border.subtle};
	border-radius: 12px;
	overflow: hidden;
`;
const Title = styled.div`
	${fonts.h5.productive};
	background: ${colors.card.header};
	color: ${colors.text.primary};
	padding: 8px 12px;
`;
const Shortcuts = styled.ul`
	${fonts.paragraph.paragraph};
	background: ${colors.card.card};
	display: flex;
	flex-direction: column;
`;
const Shortcut = styled.li`
	align-items: center;
	display: flex;
	justify-content: space-between;
	gap: 12px;
	padding: 8px 12px;

	&:not(:last-child) {
		border-bottom: 1px solid ${colors.border.subtle};
	}

	&:nth-child(odd) {
		background: ${colors.layer.layer};
	}
`;
const ShortcutKey = styled.kbd`
	${fonts.label.label};
	background: ${colors.layer.layer};
	border-radius: 4px;
	border: 1px solid ${colors.border.subtle};
	color: ${colors.text.secondary};
	min-width: 20px;
	padding: 2px 5px;
	text-align: center;
	vertical-align: middle;
`;

export default function KeyboardShortcutExplanation() {
	return (
		<Body>
			<Title>Keyboard Shortcuts</Title>
			<Shortcuts>
				<Shortcut>
					<span>Move Selection Up</span>
					<ShortcutKey>k</ShortcutKey>
				</Shortcut>
				<Shortcut>
					<span>Move Selection Down</span>
					<ShortcutKey>j</ShortcutKey>
				</Shortcut>
				<Shortcut>
					<span>Open Current Row</span>
					<ShortcutKey>o</ShortcutKey>
				</Shortcut>
				<Shortcut>
					<span>Select Current Row</span>
					<ShortcutKey>x</ShortcutKey>
				</Shortcut>
				<Shortcut>
					<span>Snooze Selected</span>
					<ShortcutKey>h</ShortcutKey>
				</Shortcut>
				<Shortcut>
					<span>Dismiss Selected</span>
					<ShortcutKey>e</ShortcutKey>
				</Shortcut>
			</Shortcuts>
		</Body>
	);
}
