/**
 * @file A Chip that opens a dropdown menu.
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2087%3A58991&mode=dev
 */
import {
	MenuPopover,
	Menu as ReachMenu,
	MenuButton as ReachMenuButton,
	MenuItem as ReachMenuItem,
	MenuItems as ReachMenuItems,
	MenuLink as ReachMenuLink,
	useMenuButtonContext,
} from '@reach/menu-button';
import { animated, useTransition } from '@react-spring/web';
import React from 'react';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { colors, effects } from '..';

export const MenuButton = styled(ReachMenuButton)`
	appearance: none;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
`;

const StyledMenuPopover = animated(styled(MenuPopover)`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	display: block;
	margin-top: 4px;
	overflow: hidden;
	padding: 4px 0;
	transform-origin: top left;

	&[hidden] {
		/* Override hiding via CSS so react-spring can animate exit. */
		display: block;
	}
`);

export const MenuItems = styled(ReachMenuItems)`
	border: none;
	background: none;
	padding: 0;
	font-size: inhterit;
`;

const menuItemStyles = css`
	display: block;
	transition: all 0.1s ease-in-out;
	padding: 4px 8px;

	&[data-reach-menu-item][data-selected] {
		background: ${colors.layer.hover};
		color: ${colors.text.primary};
	}
`;

export const MenuItem = styled(ReachMenuItem)`
	${menuItemStyles}
`;

export const MenuLink = styled(ReachMenuLink)`
	${menuItemStyles}
`;

function Body({ children }: { children: ReactNode }) {
	// `isExpanded` is only available inside a descendant node, so we need this
	// intermediate component.
	const { isExpanded } = useMenuButtonContext();

	const transitions = useTransition(isExpanded, {
		from: { opacity: 0, scale: 0.9 },
		enter: { opacity: 1, scale: 1 },
		leave: { opacity: 0, scale: 0.9 },
		config: { mass: 1, tension: 2000, friction: 75 },
	});

	return transitions(
		(styles, item) =>
			item && (
				<StyledMenuPopover style={styles}>{children}</StyledMenuPopover>
			),
	);
}

interface Props {
	readonly children: ReactNode;
	readonly menu: ReactNode;
}

export function Menu({ children, menu }: Props) {
	return (
		<ReachMenu>
			{menu}
			<Body>{children}</Body>
		</ReachMenu>
	);
}
