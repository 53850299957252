import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { GoogleIcon } from '../components/icons';
import { googleOAuthClientId } from '../config';
import { trackEvent } from '../utils/analytics';
import type { EventName, ViewType } from '../utils/analytics';
import { post } from '../utils/api';
import type { ResponseError } from '../utils/api';
import useAbortSignal from '../utils/hooks/use-abort-signal';
import reportError from '../utils/sentry';

import { isEnabled } from './google';

const Container = styled.div`
	text-align: center;
	width: 100%;
`;

const Button = styled.button`
	margin: 12px 0;
	background-color: white;
	width: 100%;
	border-radius: 6px;
	border: 1px solid lightgray;
	outline: none;
	padding: 13px 0;
	font-size: inherit;

	&:hover {
		cursor: pointer;
	}
`;

const IconContainer = styled.div`
	float: left;
	margin-left: 10px;
	height: 17px;
	width: 17px;
`;

const ErrorMessage = styled.div`
	text-align: center;
	color: red;
	padding: 10px;
`;

export function useGoogleOAuthConsent({
	analyticsEventName,
	analyticsViewType,
	onSuccess,
	scopes,
}: {
	analyticsEventName: EventName;
	analyticsViewType: ViewType;
	onSuccess: (apiJWT: string) => void;
	scopes: Array<string>;
}):
	| {
			enabled: true;
			triggerConsentFlow: () => void;
			consentError: string | null;
	  }
	| {
			enabled: false;
	  } {
	const signal = useAbortSignal();
	const [consentFailure, setConsentFailure] = useState<string | null>(null);
	const apiSignUp = useCallback(
		(response: google.accounts.oauth2.CodeResponse) => {
			post<{ token: string }>('/auth/google-sign-up', {
				body: { code: response.code },
				signal,
			})
				.then(({ token }) => {
					trackEvent(
						analyticsEventName,
						'google-oauth-consent',
						analyticsViewType,
						{
							source: 'herbie',
							success: true,
						},
					);
					onSuccess(token);
				})
				.catch((error: ResponseError) => {
					if (error.name !== 'AbortError') {
						reportError(error);
					}
					setConsentFailure('Account needs to be registered first');
				});
		},
		[analyticsEventName, analyticsViewType, onSuccess, signal],
	);
	const oAuthClient = useMemo(
		() =>
			isEnabled
				? google.accounts.oauth2.initCodeClient({
						client_id: googleOAuthClientId,
						scope: scopes
							.concat(
								'openid',
								'https://www.googleapis.com/auth/userinfo.email',
							)
							.join(' '),
						callback: apiSignUp,
						ux_mode: 'popup',
						error_callback: () => {
							setConsentFailure('Google failed to authorize');
						},
					})
				: null,
		[apiSignUp, scopes],
	);
	const triggerConsentFlow = useCallback(() => {
		if (scopes.length === 0) {
			throw new Error(
				'Must specify at least one scope to go through the Google OAuth flow',
			);
		}
		oAuthClient?.requestCode();
	}, [oAuthClient, scopes]);

	if (isEnabled) {
		return {
			consentError: consentFailure,
			triggerConsentFlow,
			enabled: true,
		};
	} else {
		return {
			enabled: false,
		};
	}
}

interface Props {
	readonly errorMessage: string | null;
	readonly triggerConsentFlow: () => void;
}

export default function GoogleOAuthConsent({
	errorMessage,
	triggerConsentFlow,
}: Props): JSX.Element | null {
	return (
		<Container>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
			<Button onClick={triggerConsentFlow} type="button">
				<IconContainer>
					<GoogleIcon />
				</IconContainer>
				<span>Register With Google</span>
			</Button>
		</Container>
	);
}
