import d3 from 'd3';
import identity from 'lodash.identity';
import nv from 'nvd3';
import type { Nvd3Element } from 'nvd3';
import React, { Component } from 'react';

import './pie-chart.scss';

type Props = {
	readonly data: Array<{
		key: string;
		values: Array<ValueData>;
	}>;
	readonly options?: Record<string, unknown>;
	readonly title?: string;
	readonly valueFormat?: (num: number) => string;
} & typeof defaultProps;

const defaultProps = {
	options: {},
	title: '',
	valueFormat: identity,
};

interface ValueData {
	label: string;
	value: number;
}

export default class PieChart extends Component<Props> {
	static defaultProps = defaultProps;
	buildPieChart = (ref: SVGSVGElement | null): void => {
		if (!ref) return;

		nv.addGraph((): Nvd3Element => {
			const chart = nv.models
				.pieChart()
				.x((d: ValueData) => d.label)
				.y((d: ValueData) => d.value)
				.showLabels(false)
				.options(this.props.options);
			const { valueFormat } = this.props;

			if (typeof valueFormat !== 'undefined') {
				chart.valueFormat(valueFormat);
			}

			const selectedChart = d3
				.select(ref)
				.datum(this.props.data)
				.call(chart);

			// Get Chart width the top spacing for title alignment
			const { width, top } = ref.getBoundingClientRect();

			selectedChart
				.append('text')
				.attr('x', width / 2)
				.attr('y', top - 10)
				.attr('font-size', 16)
				.attr('text-anchor', 'middle')
				.text(this.props.title)
				.transition()
				.duration(350);

			nv.utils.windowResize(() => chart.update());
			return chart;
		});
	};

	public override render(): JSX.Element {
		return <svg className="PieChart" ref={this.buildPieChart} />;
	}
}
