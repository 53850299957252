/**
 * @file [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?type=design&node-id=153-120131&mode=design&t=WMKVsgOJcoQ6659n-0
 */
import React from 'react';
import { Input as AriaInput } from 'react-aria-components';
import styled, { css } from 'styled-components';

import { colors, effects, fonts } from '..';
import {
	CheckCircleIcon,
	ExclamationMarkCircleIcon,
	WarningIcon,
} from '../icons/system';

type Status = 'default' | 'success' | 'warning' | 'danger';
type DarkModeLightMode = 'dark' | 'light';

const darkModeStatusBorderColors = {
	default: colors.borderDark.subtle,
	success: colors.semanticDark.success,
	warning: colors.borderDark.strong,
	danger: colors.semanticDark.danger,
} as const;

const lightModeStatusBorderColors = {
	default: colors.border.subtle,
	success: colors.semantic.success,
	warning: colors.border.strong,
	danger: colors.semantic.danger,
} as const;

const statusIcons = {
	default: null,
	success: CheckCircleIcon,
	warning: WarningIcon,
	danger: ExclamationMarkCircleIcon,
} as const;

const darkModeStatusTextColors = {
	default: colors.textDark.secondary,
	success: colors.textDark.success,
	warning: colors.textDark.warning,
	danger: colors.textDark.danger,
} as const;

const lightModeStatusTextColors = {
	default: colors.text.secondary,
	success: colors.text.success,
	warning: colors.text.warning,
	danger: colors.text.danger,
} as const;

const Label = styled.span<{ $mode: DarkModeLightMode }>`
	${fonts.label.label}
	color: ${({ $mode }) =>
		$mode === 'light' ? colors.text.secondary : colors.textDark.secondary};
`;

export const inputStyles = css<{ $mode: DarkModeLightMode }>`
	${fonts.paragraph.paragraph}
	color: ${({ $mode }) =>
		$mode === 'light' ? colors.text.primary : colors.textDark.primary};
	background: transparent;
	border: none;
	flex: 1;
	min-width: 0;
	outline: none;
	padding: 0;
`;

const Input = styled.input`
	${inputStyles}
`;
const StyledAriaInput = styled(AriaInput)`
	${inputStyles}

	&:disabled {
		cursor: not-allowed;
		color: ${({ $mode }) =>
			$mode === 'light'
				? colors.text.disabled
				: colors.textDark.disabled};
	}
`;

const StatusText = styled.span<{
	$mode: DarkModeLightMode;
	$status: Status;
}>`
	${fonts.label.label}
	color: ${({ $mode, $status }) =>
		$mode === 'light'
			? lightModeStatusTextColors[$status]
			: darkModeStatusTextColors[$status]};
`;

export const StyledIcon = styled.span<{ $mode: DarkModeLightMode }>`
	color: ${({ $mode }) =>
		$mode === 'light' ? colors.icon.secondary : colors.iconDark.secondary};
	height: 16px;
	width: 16px;

	&:disabled {
		color: ${({ $mode }) =>
			$mode === 'light'
				? colors.icon.disabled
				: colors.iconDark.disabled};
	}
`;

const Icon = styled.svg`
	height: 16px;
	width: 16px;
`;

export const Wrapper = styled.div<{
	$mode: DarkModeLightMode;
	$status: Status;
}>`
	align-items: center;
	align-self: stretch;
	background: ${({ $mode }) =>
		$mode === 'light' ? colors.field.field : colors.fieldDark.field};
	border: 1px solid
		${({ $mode, $status }) =>
			$mode === 'light'
				? lightModeStatusBorderColors[$status]
				: darkModeStatusBorderColors[$status]};
	border-radius: 8px;
	cursor: text;
	display: flex;
	gap: 16px;
	padding: 6px 12px;
	transition:
		background 0.1s ease-in-out,
		border-color 0.1s ease-in-out;

	&:hover {
		border-color: ${({ $mode }) =>
			$mode === 'light'
				? colors.border.subtleHover
				: colors.borderDark.subtleHover};
		background: ${({ $mode }) =>
			$mode === 'light' ? colors.field.hover : colors.fieldDark.hover};
	}

	&:focus-within {
		${effects.shadow.focus}
		background: ${({ $mode }) =>
			$mode === 'light' ? colors.field.hover : colors.fieldDark.hover};
		border-color: ${({ $mode }) =>
			$mode === 'light'
				? colors.semantic.focus
				: colors.semanticDark.focus};
	}

	&:has(${Input}:disabled) {
		border-color: ${({ $mode }) =>
			$mode === 'light'
				? colors.border.disabled
				: colors.borderDark.disabled};
		background: ${({ $mode }) =>
			$mode === 'light'
				? colors.field.disabled
				: colors.fieldDark.disabled};
		cursor: default;
	}
`;

const Container = styled.label<{ $mode: DarkModeLightMode }>`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 4px;

	&:has(${Input}:disabled) :is(${Label}, ${StatusText}) {
		color: ${({ $mode }) =>
			$mode === 'light'
				? colors.text.disabled
				: colors.textDark.disabled};
	}
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	readonly icon?: React.ReactNode;
	readonly label?: React.ReactNode;
	readonly mode?: DarkModeLightMode;
	readonly status?: Status;
	readonly statusText?: string;
}

export default React.forwardRef(function TextInput(
	{
		className,
		disabled,
		icon,
		label,
		mode = 'light',
		status = 'default',
		statusText,
		...props
	}: Props,
	ref: React.Ref<HTMLInputElement>,
) {
	const StatusIcon = statusIcons[status];

	return (
		<Container $mode={mode} className={className}>
			{label && <Label $mode={mode}>{label}</Label>}
			<Wrapper $mode={mode} $status={status}>
				{icon && <StyledIcon $mode={mode}>{icon}</StyledIcon>}
				<Input $mode={mode} disabled={disabled} {...props} ref={ref} />
				{StatusIcon && <Icon as={StatusIcon} />}
			</Wrapper>
			{statusText != null && (
				<StatusText $mode={mode} $status={status}>
					{statusText}
				</StatusText>
			)}
		</Container>
	);
});

/**
 * EXPERIMENTAL: A replacement of <TextInput /> using the <Input /> from react-aria-components. Do not use without significant testing.
 */
const AriaTextInput = React.forwardRef(function AriaTextInput(
	{
		className,
		disabled,
		icon,
		label,
		mode = 'light',
		status = 'default',
		statusText,
		...props
	}: Omit<Props, 'style'>,
	ref: React.Ref<HTMLInputElement>,
) {
	const StatusIcon = statusIcons[status];

	return (
		<Container $mode={mode} className={className}>
			{label && <Label $mode={mode}>{label}</Label>}
			<Wrapper $mode={mode} $status={status}>
				{icon && <StyledIcon $mode={mode}>{icon}</StyledIcon>}
				<StyledAriaInput
					$mode={mode}
					disabled={disabled}
					{...props}
					ref={ref}
				/>
				{StatusIcon && <Icon as={StatusIcon} />}
			</Wrapper>
			{statusText != null && (
				<StatusText $mode={mode} $status={status}>
					{statusText}
				</StatusText>
			)}
		</Container>
	);
});

export { AriaTextInput };
