import d3 from 'd3';
import nv from 'nvd3';
import type { Margin, Nvd3Element } from 'nvd3';
import React, { Component } from 'react';

import './discrete-bar-chart.scss';

type Props = {
	readonly contentGenerator?: (
		data: Array<{
			key: string;
			values: Array<ValueData>;
		}>,
	) => string;
	readonly data: Array<{
		key: string;
		values: Array<ValueData>;
	}>;
	readonly labelRotation?: number;
	readonly margin?: Margin;
	readonly onBarClick?: (evt: MouseEvent) => void;
	readonly options?: Parameters<Nvd3Element['options']>[0];
	readonly showXAxis?: boolean;
	readonly title?: string;
	readonly xAxisLabel?: string;
	readonly xAxisTickFormat?: (axis: string | number) => string | number;
	readonly yAxisLabel?: string;
	readonly yAxisTickFormat?: (axis: string | number) => string | number;
} & typeof defaultProps;

const formatIdentity = (axis: string | number) => axis.toString();

const defaultProps = {
	labelRotation: -35,
	margin: {},
	options: {},
	showXAxis: true,
	title: '',
	xAxisLabel: '',
	xAxisTickFormat: formatIdentity,
	yAxisLabel: '',
	yAxisTickFormat: formatIdentity,
};

interface ValueData {
	label: string;
	value: number;
}

export default class DiscreteBarChart extends Component<Props> {
	static defaultProps = defaultProps;

	buildDiscreteBarChart = (ref: SVGSVGElement | null): void => {
		if (!ref) return;

		nv.addGraph((): Nvd3Element => {
			const chart = nv.models
				.discreteBarChart()
				.margin(this.props.margin)
				.x((d: ValueData) => d.label)
				.y((d: ValueData) => d.value)
				.color([
					'#754289',
					'#009B7B',
					'#3C83BD',
					'#EBB840',
					'#D64E74',
					'#8DB764',
					'#D98635',
					'#368493',
					'#BF378D',
					'#EA8176',
					'#494C8A',
					'#A6AB99',
					'#66C5CC',
					'#F6CF70',
					'#F89C74',
					'#DCB0F2',
				])
				.options(this.props.options);

			const {
				contentGenerator,
				showXAxis,
				title,
				xAxisLabel,
				xAxisTickFormat,
				yAxisLabel,
				yAxisTickFormat,
			} = this.props;

			chart.showXAxis(showXAxis);
			chart.xAxis
				.axisLabel(xAxisLabel ? xAxisLabel : '')
				.tickFormat(xAxisTickFormat);
			chart.xAxis.rotateLabels(this.props.labelRotation);
			chart.yAxis
				.axisLabel(yAxisLabel ? yAxisLabel : '')
				.tickFormat(yAxisTickFormat);

			if (contentGenerator) {
				chart.tooltip.contentGenerator(contentGenerator);
			}
			const selectedChart = d3
				.select(ref)
				.datum(this.props.data)
				.call(chart);

			// Get Chart width the top spacing for title alignment
			const { width, top } = ref.getBoundingClientRect();

			selectedChart
				.append('text')
				.attr('x', width / 2)
				.attr('y', top / 6)
				.attr('font-size', 16)
				.attr('text-anchor', 'middle')
				.text(title);

			nv.utils.windowResize(() => chart.update());
			return chart;
		}, this.addClickHandler);
	};

	addClickHandler = (): void => {
		const { onBarClick } = this.props;

		if (onBarClick) {
			d3.selectAll('.nv-bar').on('click', (evt: MouseEvent) => {
				onBarClick(evt);
			});
		}
	};

	public override render() {
		return (
			<svg
				className="DiscreteBarChart"
				ref={this.buildDiscreteBarChart}
			/>
		);
	}
}
